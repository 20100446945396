import { graphql } from 'react-relay'
import { OnboardingSequence } from '../../../Signup/OnboardingSequence'
import { useNavigate } from '../../react-router-wrappers'
import { OnboardingStep } from './__generated__/useResumeOnboarding_user.graphql'

graphql`
  fragment useResumeOnboarding_user on User {
    lastCompletedOnboardingStep
  }
`

export function getNextOnboardingStepPath(onboardingStep: OnboardingStep) {
  switch (onboardingStep) {
    case 'CreateUser':
      return OnboardingSequence.TheBasics.nextPath
    // UpdateEmploymentInfo could be any of 3 pages; start user at beginning to ensure they fill out everything
    case 'UpdateEmploymentInfo':
      return OnboardingSequence.TheBasics.nextPath
    case 'SubmitEmploymentInfo':
      return OnboardingSequence.EmployerInfo.nextPath
    case 'VerifyDevice':
      return OnboardingSequence.CheckYourPhone.nextPath
    case 'VerifyPersonalInfo':
      return OnboardingSequence.VerifyPersonalInfo.nextPath
    case 'ActivateMembership':
      throw new Error(
        'User should be Active if last onboarding step is ActivateMembership'
      )
    default:
      throw new Error(`Unknown onboarding step "${onboardingStep}"`)
  }
}

export default function useResumeOnboarding() {
  const navigate = useNavigate()
  return (onboardingStep: OnboardingStep) => {
    navigate(getNextOnboardingStepPath(onboardingStep))
  }
}
