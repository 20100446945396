/**
 * @generated SignedSource<<02ee7e53e74f30a2f5cd431ca5dce548>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserStatus = "Active" | "Closed" | "Ineligible" | "Pending" | "SecurityLocked" | "%future added value";
export type SignupQuery$variables = {};
export type SignupQuery$data = {
  readonly currentUser: {
    readonly id: string | null;
    readonly status: UserStatus;
  } | null;
};
export type SignupQuery = {
  response: SignupQuery$data;
  variables: SignupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SignupQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0d9b33a39274474d3c10e010cb54b654",
    "id": null,
    "metadata": {},
    "name": "SignupQuery",
    "operationKind": "query",
    "text": "query SignupQuery {\n  currentUser {\n    id\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "802d79ea348fc9961df24b3308b52395";

export default node;
