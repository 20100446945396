import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as analytics from '../../analytics/analytics-adapter'

export const searchParamsStorageKey = 'quil-search-params'

export const PreserveSearchParams: React.FC = () => {
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (
      !sessionStorage.getItem(searchParamsStorageKey) &&
      searchParams.toString() !== ''
    ) {
      sessionStorage.setItem(searchParamsStorageKey, searchParams.toString())
    }

    // track marketing attribution
    if (searchParams.has('quilPromotionId')) {
      const promotionId = searchParams.get('quilPromotionId')
      analytics.identifyAnonymous({
        promotionId,
      })

      // TODO: This block to be deleted if/when we use promotionId for all promotions
    } else if (searchParams.has('utm_campaign')) {
      const promotionId = searchParams.get('utm_campaign')
      analytics.identifyAnonymous({
        promotionId,
      })
    }
  }, [analytics, searchParams])
  return null
}
