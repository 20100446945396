import styled from 'styled-components'
import { AvibraLogo, AvibraName } from '../../icons'
import { fromTheme } from '../../theme'

const Container = styled.div`
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  font-size: 1rem;
  margin: 1.4em 0 0.6em 0;
`

const Badge = styled.div`
  display: flex;
  align-items: center;
  height: 3.2rem;
  border-radius: 1.6rem;
  padding: 0 2rem;
  background: ${fromTheme(({ colors }) => colors.avibraBadgeBackground.raw)};
  font-size: 1.2rem;
`

const StyledLogo = styled(AvibraLogo)`
  width: 2.2em;
`

export default function AvibraBadge() {
  return (
    <Container>
      <Badge>
        Powered by <StyledLogo /> <AvibraName />
      </Badge>
    </Container>
  )
}
