const ProfileOverview = 'profile_overview' as const

export const nameHeader = `${ProfileOverview}_name_header`
export const phoneLink = `${ProfileOverview}_phone_link`
export const emailLink = `${ProfileOverview}_email_link`
export const addressLink = `${ProfileOverview}_address_link`
export const paymentInformationLink = `${ProfileOverview}_payment_information_link`
export const reportFraudLink = `${ProfileOverview}_report_fraud_link`
export const legalLink = `${ProfileOverview}_legal_link`
export const closeMembershipLink = `${ProfileOverview}_close_membership_link`
