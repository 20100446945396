import {
  ButtonPrimary,
  ButtonSecondary,
  ProcessingOverlay,
  useShowToast,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import { useNavigate } from '@web/common/react-router-wrappers'
import { useCallback, useEffect } from 'react'
import { useMutation } from 'react-relay'
import { graphql } from 'relay-runtime'
import { NewClaimPageProps } from '../NewClaimPageProps'
import NewClaimProgressTracker from '../NewClaimProgressTracker'
import { NewClaimSequence, basePath } from '../NewClaimSequence'
import useNewClaimFlow from '../useNewClaimFlow'
import { PayoutAccountExistingMutation } from './__generated__/PayoutAccountExistingMutation.graphql'

const PayoutAccountExisting = (props: NewClaimPageProps) => {
  const navigate = useNavigate()
  const showToast = useShowToast()
  const [data] = useNewClaimFlow('Payout Account Existing')

  const goToNewPayoutAccount = useCallback(() => {
    navigate(`${basePath}/${NewClaimSequence.PayoutAccount.path}`)
  }, [navigate])

  useEffect(() => {
    if (!data.payoutAccount) {
      goToNewPayoutAccount()
    }
  }, [goToNewPayoutAccount])

  const [commitMutation, commitMutationInFlight] =
    useMutation<PayoutAccountExistingMutation>(graphql`
      mutation PayoutAccountExistingMutation(
        $input: UpdateClaimPayoutAccountInput!
      ) {
        updateClaimPayoutAccount(input: $input) {
          payoutAccount {
            accountNumberMask
            bankName
          }
        }
      }
    `)

  // If the claim has an account linked, use that one, otherwise use the most recent payout account linked
  const primaryAccount =
    data.pendingLayoffClaim.payoutAccount ?? data.payoutAccount

  function handleUseExistingClick() {
    if (!data.pendingLayoffClaim.payoutAccount) {
      commitMutation({
        variables: {
          input: {
            claimId: data.pendingLayoffClaim.id,
            payoutAccountId: data.payoutAccount.id,
          },
        },
        onCompleted() {
          navigate(props.nextPath)
        },
        onError() {
          showToast({
            severity: 'Error',
            headline: 'Something went wrong',
            message: 'Please link a new account',
            onClick() {
              goToNewPayoutAccount()
            },
          })
        },
      })
    } else {
      navigate(props.nextPath)
    }
  }

  return (
    <LayoutInAppTracked
      title={data.content.newClaimPage.heading}
      pageName="New Claim: Existing Payout Account"
      headerEnd={<NewClaimProgressTracker step={props.step} />}
      headerStart={<GoBack to={props.previousPath} />}
      footerContent={
        <>
          <ButtonPrimary
            onClick={handleUseExistingClick}
            data-name="Use Existing Payout Account"
            disabled={commitMutationInFlight}
          >
            {data.content.newClaimPage.mainCtaText}
          </ButtonPrimary>
          <ButtonSecondary
            onClick={goToNewPayoutAccount}
            data-name="Pick Another Payout Account"
            disabled={commitMutationInFlight}
          >
            {data.content.newClaimPage.secondaryCtaText}
          </ButtonSecondary>
        </>
      }
    >
      <RichText
        content={data.content.newClaimPage.body.raw}
        data={{
          bankName: primaryAccount.bankName || '',
          accountNumberMask: primaryAccount.accountNumberMask,
        }}
      />
      <ProcessingOverlay processing={commitMutationInFlight} />
    </LayoutInAppTracked>
  )
}

export default PayoutAccountExisting
