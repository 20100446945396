import { parseISO } from 'date-fns'
import { graphql, useFragment } from 'react-relay'
import styled from 'styled-components'
import { HeroImage_query$key } from './__generated__/HeroImage_query.graphql'

type Props = {
  children: JSX.Element
  query: HeroImage_query$key
}

const Container = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

const Img = styled.img`
  display: block;
  max-width: 360px;
  width: 100%;
`

const ImgContainer = styled.div`
  padding: 0 20px 0;
  display: flex;
  justify-content: center;
  text-align: center;
`

export function HeroImage(props: Props) {
  const data = useFragment(
    graphql`
      fragment HeroImage_query on Query {
        content {
          heroImages {
            image {
              url
              mimeType
            }
            color {
              hex
            }
            altText
            startDateTime
            endDateTime
          }
        }
      }
    `,
    props.query
  )

  const defaultHeroImage = data.content.heroImages.find((image) => {
    return !image.startDateTime && !image.endDateTime
  })

  const scheduledHeroImage = data.content.heroImages.find((image) => {
    const activationDateTime = parseISO(image.startDateTime)
    const deactivationDateTime = parseISO(image.endDateTime)
    return activationDateTime < new Date() && deactivationDateTime > new Date()
  })

  const activeHeroImage = scheduledHeroImage
    ? scheduledHeroImage
    : defaultHeroImage

  return (
    <Container color={activeHeroImage.color.hex}>
      {props.children}
      <ImgContainer>
        <Img src={activeHeroImage.image.url} alt={activeHeroImage.altText} />
      </ImgContainer>
    </Container>
  )
}
