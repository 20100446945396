/**
 * @generated SignedSource<<e45b2aa8fcf999defb65cb5bba00739f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HoursQuery$variables = {};
export type HoursQuery$data = {
  readonly content: {
    readonly onboardingPage: {
      readonly " $fragmentSpreads": FragmentRefs<"useEmploymentQuestionCopy_onboardingPage">;
    } | null;
  };
};
export type HoursQuery = {
  response: HoursQuery$data;
  variables: HoursQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "Employment Hours"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HoursQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useEmploymentQuestionCopy_onboardingPage"
              }
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Employment Hours\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HoursQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "heading",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Cms_RichText",
                "kind": "LinkedField",
                "name": "body",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "raw",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subheading",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mainCtaText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondaryCtaText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Employment Hours\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14486e5d5d755424b0d76b88ec11d200",
    "id": null,
    "metadata": {},
    "name": "HoursQuery",
    "operationKind": "query",
    "text": "query HoursQuery {\n  content {\n    onboardingPage(where: {pageName: \"Employment Hours\"}) {\n      ...useEmploymentQuestionCopy_onboardingPage\n      id\n    }\n  }\n}\n\nfragment useEmploymentQuestionCopy_onboardingPage on Cms_OnboardingPage {\n  heading\n  body {\n    raw\n  }\n  subheading\n  mainCtaText\n  secondaryCtaText\n}\n"
  }
};
})();

(node as any).hash = "a0a74f2b93e749486d8a845af9f348e6";

export default node;
