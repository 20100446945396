import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { DocumentUpload, ImageUpload } from '../../icons'
import { fromTheme } from '../../theme'
import TextLinkInline from '../TextLinkInline'
import type FileState from './FileState'
import FileUploadStateIndicator from './FileUploadStateIndicator'
export * from './FileState'

const Container = styled.div`
  margin-bottom: 1.6rem;

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${fromTheme(({ colors }) => colors.foregroundMuted.raw)};
    padding: 1rem 0.8rem 1rem 1.6rem;
    border-radius: 0.5rem;
  }

  .start {
    display: flex;
    transition: opacity 0s ease-in-out 1s;
    min-width: 0;

    .info {
      min-width: 0;

      a {
        font-size: 1.6rem;
        /* truncate the text */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 1.6rem;
        max-width: 100%;
      }
    }

    svg {
      margin-right: 0.8rem;
    }
  }

  &[data-state='pending'] .start {
    opacity: 0.5;
    transition: unset;
  }

  .end {
    display: flex;
    align-items: center;
  }

  .metadata {
    color: ${fromTheme(({ colors }) => colors.foregroundMuted.raw)};
    font-size: 1.2rem;
  }

  .error-text {
    color: ${fromTheme(({ colors }) => colors.foregroundDanger.raw)};
    font-size: 1.2rem;
    margin-right: 0.8rem;
  }

  .error-message {
    color: ${fromTheme(({ colors }) => colors.foregroundDanger.raw)};
    margin-top: 0.6rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`

type NativeFile = File

type IdleFileProps = {
  state: 'idle'
  file: NativeFile
  uri: string
  size?: number
}

type LiveFileProps<T> = {
  state: FileState
  file: NativeFile
  uri: string
  size?: number
  error: string | null
  handle: T
  onRequestToRemove: (id: T) => void
}

type Props<T> = IdleFileProps | LiveFileProps<T>

function isLive<T>(props: Props<T>): props is LiveFileProps<T> {
  return 'onRequestToRemove' in props && 'handle' in props
}

export function File<T>(props: Props<T>) {
  const extension = props.file.name.split('.').pop() || ''
  const fileName = props.file.name.replace(`.${extension}`, '')

  function handleRequestToRemove() {
    if (isLive(props)) {
      props.onRequestToRemove(props.handle)
    }
  }

  return (
    <Container data-state={props.state}>
      <div className="card">
        <div className="start">
          <div className="icon">
            {props.file.type.startsWith('image/') ? (
              <ImageUpload />
            ) : (
              <DocumentUpload />
            )}
          </div>
          <div className="info">
            <TextLinkInline
              data-name="file"
              as="a"
              target="_blank"
              rel="noopener"
              href={props.uri}
            >
              {fileName}
            </TextLinkInline>
            <div className="metadata">
              {extension.toUpperCase()},{' '}
              {formatSize(props.size ?? props.file.size)}
            </div>
          </div>
        </div>
        <div className="end">
          <AnimatePresence>
            {props.state === 'error' && (
              <motion.div
                className="error-text"
                initial={{ opacity: 0, y: 2 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1.2 }}
              >
                Upload Failed
              </motion.div>
            )}
          </AnimatePresence>
          <FileUploadStateIndicator
            state={props.state}
            onRequestToRemove={handleRequestToRemove}
          />
        </div>
      </div>
      {isLive(props) && props.error ? (
        <div className="error-message">{props.error}</div>
      ) : null}
    </Container>
  )
}

function roundToTwoDecimals(num: number) {
  // https://stackoverflow.com/a/11832950/104380
  return Math.round((num + Number.EPSILON) * 100) / 100
}

/**
 * Formats a number of bytes into a human-readable format.
 *
 * Note: I originally went with 1024 as the base, but that's not what the
 * browser does, so I changed it to 1000.
 *
 * @param bytes - The number of bytes to format.
 * @returns The human-readable size string.
 */
function formatSize(bytes: number): string {
  if (bytes < 1000) {
    return `${bytes}B`
  }

  const kilobytes = bytes / 1000
  if (kilobytes < 1000) {
    return `${roundToTwoDecimals(kilobytes)}KB`
  }

  const megabytes = kilobytes / 1000
  return `${roundToTwoDecimals(megabytes)}MB`
}
