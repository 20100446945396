import {
  ButtonPrimary,
  File as FileComponent,
  InlineButtonContainer,
  InputCheckbox,
  ProcessingOverlay,
  useShowToast,
} from '@quil/ui'
import { booleanToHuman, pascalToTitleCase } from '@quil/utils'
import { LayoutInAppTracked } from '@web/common/analytics'
import Form from '@web/common/components/Form'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import { constructFile } from '@web/common/file-utils'
import { formatPhone } from '@web/common/formatPhone'
import { groupBy, prop } from 'ramda'
import { useState } from 'react'
import { graphql, useMutation } from 'react-relay'
import { Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { NewClaimPageProps } from '../NewClaimPageProps'
import NewClaimProgressTracker from '../NewClaimProgressTracker'
import useNewClaimFlow from '../useNewClaimFlow'
import { ReviewSubmitLayoffClaimMutation } from './__generated__/ReviewSubmitLayoffClaimMutation.graphql'

const Container = styled.div`
  .text-review-label {
    font-size: 1.2rem;
  }

  .text-review-value {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  .file-review-label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .disclaimer-label {
    font-size: 1.2rem;
    margin-top: -0.5rem;
  }

  .documents-section {
    margin-bottom: 2.8rem;
  }

  .disclaimers-section {
    margin-bottom: 2.8rem;
  }
`

const pageName = 'New Claim: Review'

const terminationReasonEnumToLabel = {
  BusinessShutDown: 'Business shut down',
  CovidLayoffs: 'COVID layoffs',
  LackOfWork: 'Lack of work',
  StrikeOrLockout: 'Strike or lockout',
  Other: 'Other',
}
function Review(props: NewClaimPageProps) {
  const [{ pendingLayoffClaim, content }] = useNewClaimFlow('Review')
  const [dataConsented, setDataConsented] = useState(false)
  const [employerConsented, setEmployerConsented] = useState(false)
  const showToast = useShowToast()
  const navigate = useNavigate()

  const [submitClaim, submitClaimInFlight] =
    useMutation<ReviewSubmitLayoffClaimMutation>(graphql`
      mutation ReviewSubmitLayoffClaimMutation($id: ID!) {
        submitLayoffClaim(id: $id) {
          __typename
          id
        }
      }
    `)

  function handleError(error) {
    console.error(error)
    showToast({
      severity: 'Error',
      headline: 'Something went wrong',
      message: 'Please try again',
      linkText: 'Retry',
    })
  }

  async function handleSubmit() {
    submitClaim({
      variables: {
        id: pendingLayoffClaim.id,
      },
      onCompleted({ submitLayoffClaim }) {
        navigate(props.nextPath)
      },
      onError(error) {
        handleError(error)
      },
      updater(store) {
        // Invalidate `noticeEvents` records, to force Alerts component to re-fetch its fragment.
        // If `noticeEvents` is undefined or an empty array, we set the value to `undefined` which invalidates the whole "noticeEvents" query;
        // this is required for scenarios where the user starts with no alerts but one is added during the active session (because looping through an empty array invalidates nothing).
        const noticeEvents = store.getRoot().getLinkedRecords('noticeEvents')
        if (noticeEvents?.length) {
          noticeEvents.forEach((r) => r?.invalidateRecord())
        } else {
          store.getRoot().setValue(undefined, 'noticeEvents')
        }

        // AppNav.tsx can open a modal on New Claim button clicks. The modal's content depends on the user's layoffProtectionStatus.
        // Unless this property is updated manually after submission, the modal will incorrectly show the "Pending" modal instead of the "InReview" modal.
        // Future enhancement: if this mutation returned a complex object with the user and the claim, the Relay store could update automatically.
        store
          .getRoot()
          .getLinkedRecord('currentUser')
          .setValue('InReview', 'layoffProtectionStatus')
      },
    })
  }

  if (!pendingLayoffClaim) {
    return <Navigate to="/home/claims/new" />
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title={content.newClaimPage.heading}
      headerStart={<GoBack to={props.previousPath} />}
      headerEnd={<NewClaimProgressTracker step={props.step} />}
    >
      <Container>
        <RichText content={content.newClaimPage.body.raw} />
        <Form onSubmit={handleSubmit}>
          <div className="text-review-label">Reason for termination</div>
          <div className="text-review-value">
            {terminationReasonEnumToLabel[pendingLayoffClaim.terminationReason]}
          </div>

          {pendingLayoffClaim.employerEmail && (
            <>
              <div className="text-review-label">Employer email</div>
              <div className="text-review-value">
                {pendingLayoffClaim.employerEmail}
              </div>
            </>
          )}

          {pendingLayoffClaim.employerPhone && (
            <>
              <div className="text-review-label">Employer phone</div>
              <div className="text-review-value">
                {formatPhone(pendingLayoffClaim.employerPhone)}
              </div>
            </>
          )}

          <div className="text-review-label">Name of employer</div>
          <div className="text-review-value">
            {pendingLayoffClaim.employerName}
          </div>

          <div className="text-review-label">Occupation</div>
          <div className="text-review-value">
            {pendingLayoffClaim.occupation}
          </div>

          <div className="text-review-label">Start date</div>
          <div className="text-review-value">
            {pendingLayoffClaim.employmentStartDate}
          </div>

          <div className="text-review-label">End date</div>
          <div className="text-review-value">
            {pendingLayoffClaim.employmentEndDate}
          </div>

          {pendingLayoffClaim.returnedToWorkAtDate && (
            <>
              <div className="text-review-label">Returned to work on</div>
              <div className="text-review-value">
                {pendingLayoffClaim.returnedToWorkAtDate}
              </div>
            </>
          )}

          <div className="text-review-label">
            Have you changed jobs recently?
          </div>
          <div className="text-review-value">
            {booleanToHuman(pendingLayoffClaim.changedJobsRecently)}
          </div>

          <div className="text-review-label">Did you work full-time?</div>
          <div className="text-review-value">
            {booleanToHuman(pendingLayoffClaim.minimumWeeklyHours)}
          </div>

          <div className="text-review-label">
            Are you currently receiving state unemployment benefits?
          </div>
          <div className="text-review-value">
            {booleanToHuman(pendingLayoffClaim.receivingStateBenefits)}
          </div>

          <div className="text-review-label">
            Were you let go without cause?
          </div>
          <div className="text-review-value">
            {booleanToHuman(pendingLayoffClaim.terminatedWithoutCause)}
          </div>

          {pendingLayoffClaim.additionalInfo && (
            <>
              <div className="text-review-label">Additional Info</div>
              <div className="text-review-value">
                {pendingLayoffClaim.additionalInfo}
              </div>
            </>
          )}

          <div className="text-review-label">Payout account</div>
          <div className="text-review-value">
            {pendingLayoffClaim.payoutAccount.bankName || 'Account ending in'} *
            {pendingLayoffClaim.payoutAccount.accountNumberMask}
          </div>

          <div className="documents-section">
            {Object.entries(
              groupBy(prop('type'), pendingLayoffClaim.fileUploads)
            ).map(([documentType, files]) => (
              <div key={documentType}>
                <div className="file-review-label">
                  {pascalToTitleCase(documentType)}
                </div>
                {files.map((file) => (
                  <FileComponent
                    key={file.originalFileName}
                    file={constructFile(file.originalFileName, file.extension)}
                    size={file.size}
                    state="idle"
                    uri={file.uri}
                  />
                ))}
              </div>
            ))}
          </div>

          <div className="disclaimers-section">
            <InputCheckbox
              checked={dataConsented}
              onChange={setDataConsented}
              label={
                <span className="disclaimer-label">
                  I agree the above information is accurate and correct and
                  understand that knowingly providing false information in an
                  insurance claim is against the law and may void my policy.
                </span>
              }
            />

            <InputCheckbox
              checked={employerConsented}
              onChange={setEmployerConsented}
              label={
                <span className="disclaimer-label">
                  I consent to any employer, insurer or other organization or
                  person having any records, data or information regarding this
                  claim to furnish such records, data or information to Quil or
                  its authorized representative as requested.
                </span>
              }
            />
          </div>
          <InlineButtonContainer>
            <ButtonPrimary
              disabled={
                !dataConsented || !employerConsented || submitClaimInFlight
              }
              data-name="Continue"
            >
              {content.newClaimPage.mainCtaText}
            </ButtonPrimary>
          </InlineButtonContainer>
        </Form>
        <ProcessingOverlay processing={submitClaimInFlight} />
      </Container>
    </LayoutInAppTracked>
  )
}

export default Review
