/**
 * @generated SignedSource<<f5ea638b44acc5773bbb1facc3485922>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserStatus = "Active" | "Closed" | "Ineligible" | "Pending" | "SecurityLocked" | "%future added value";
export type UserCreateExpInput = {
  email: string;
};
export type AttributionInput = {
  promotionId?: string | null;
  promotionMeta?: string | null;
};
export type TheBasicsEmailMutation$variables = {
  attribution?: AttributionInput | null;
  data: UserCreateExpInput;
};
export type TheBasicsEmailMutation$data = {
  readonly createUserExp: {
    readonly __typename: "PhoneNumberNotAvailable";
    readonly message: string | null;
  } | {
    readonly __typename: "User";
    readonly email: string | null;
    readonly id: string | null;
    readonly status: UserStatus;
  } | {
    readonly __typename: "ValidationErrors";
    readonly messages: ReadonlyArray<{
      readonly field: string;
      readonly help: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type TheBasicsEmailMutation = {
  response: TheBasicsEmailMutation$data;
  variables: TheBasicsEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attribution"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "data"
},
v2 = [
  {
    "kind": "Literal",
    "name": "acceptedAgreements",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "attribution",
    "variableName": "attribution"
  },
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ValidationError",
      "kind": "LinkedField",
      "name": "messages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "field",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "help",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ValidationErrors",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "PhoneNumberNotAvailable",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TheBasicsEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createUserExp",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TheBasicsEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createUserExp",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b03343bb680c52688717e1ff872bc7f9",
    "id": null,
    "metadata": {},
    "name": "TheBasicsEmailMutation",
    "operationKind": "mutation",
    "text": "mutation TheBasicsEmailMutation(\n  $data: UserCreateExpInput!\n  $attribution: AttributionInput\n) {\n  createUserExp(data: $data, attribution: $attribution, acceptedAgreements: true) {\n    __typename\n    ... on User {\n      id\n      status\n      email\n    }\n    ... on ValidationErrors {\n      messages {\n        field\n        help\n      }\n    }\n    ... on PhoneNumberNotAvailable {\n      message\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "702f5552067bd92ef102d80cb7aa16d5";

export default node;
