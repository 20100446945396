import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { fromTheme } from '../../../theme'

const ActionListLinkCss = css`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  background-color: ${fromTheme((theme) => theme.colors.background.raw)};
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  font-weight: 400;
  text-decoration: none;
  width: 100%;
`

type ActionListLinkProps = {
  'data-name': string
}

export const ActionListLink = styled(Link)<ActionListLinkProps>`
  ${ActionListLinkCss}
`

export const ActionListAnchor = styled.a<ActionListLinkProps>`
  ${ActionListLinkCss}
`
