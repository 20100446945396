/**
 * Takes an optional subject and body param and converts it into a Quil support
 * email mailto URI. It's recommended you use string literals (backtick strings)
 * because this component will convert new lines to paragraphs for emails.
 *
 * @example
 * createSupportEmailUri(
 *   'My Subject',
 *   `
 *     Dearest Avocado,
 *
 *     It's been but 2 days since we met and yet here you are brown and dead.
 *
 *     Love,
 *     Quil
 *   `
 * )
 *
 * @param {string} [subject=''] The subject line to put in the email
 * @param {string} [body=''] The body copy to put in the email. This will keep new
 * lines automatically and remove new lines from the start or end of the copy.
 *
 */
export default function createSupportEmailUri(subject = '', body = ''): string {
  const encodedSubject = encodeURI(subject.trim().replace(/^\s+/gm, '\n'))
  const encodedBody = encodeURI(body.trim().replace(/^\s+/gm, '\n'))
  return `mailto:support@getquil.com?subject=${encodedSubject}&body=${encodedBody}`
}
