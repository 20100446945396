/**
 * @generated SignedSource<<7ca1cf2ebd215c787358ae95de8fe608>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OnboardingStep = "ActivateMembership" | "CreateUser" | "SubmitEmploymentInfo" | "UpdateEmploymentInfo" | "VerifyDevice" | "VerifyPersonalInfo" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useResumeOnboarding_user$data = {
  readonly lastCompletedOnboardingStep: OnboardingStep;
  readonly " $fragmentType": "useResumeOnboarding_user";
};
export type useResumeOnboarding_user$key = {
  readonly " $data"?: useResumeOnboarding_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useResumeOnboarding_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useResumeOnboarding_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastCompletedOnboardingStep",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "cf79bb05b075c8c5dc6e8b146893f0b4";

export default node;
