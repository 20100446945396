import { EventDefinition } from '@quil/analytics-events'
import { useEffect, useRef } from 'react'
import { useDebounce } from 'use-debounce'
import * as analytics from './analytics-adapter'

export type UseTrackedValueConfig = {
  debounceTime: number
}

const defaultConfig: UseTrackedValueConfig = {
  debounceTime: 500,
}

export function useTrackedValue<T>(
  value: T,
  formatEvent: (value: T) => EventDefinition,
  config = defaultConfig
) {
  // ignore function updates. this is meant to be static configuration
  const formatEventRef = useRef(formatEvent)
  const didMountRef = useRef(false)
  const [debouncedValue] = useDebounce(value, config.debounceTime)

  useEffect(() => {
    // skip effect on mount, we only want updates
    if (didMountRef.current) {
      analytics.track(formatEventRef.current(debouncedValue))
    } else {
      didMountRef.current = true
    }
  }, [debouncedValue])
}
