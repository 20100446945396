/**
 * @generated SignedSource<<31ae14bb6fc663e00a4077aec737f6b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCadence = "Monthly" | "Yearly" | "%future added value";
export type PlanLabel = "affordable" | "popular" | "premium" | "%future added value";
export type VerifyPersonalInfoQuery$variables = {};
export type VerifyPersonalInfoQuery$data = {
  readonly availableSubscriptionPlans: ReadonlyArray<{
    readonly cadence: BillingCadence;
    readonly coverage: {
      readonly formatted: string;
      readonly raw: number;
    };
    readonly label: PlanLabel;
    readonly monthlyListPrice: {
      readonly formatted: string;
    } | null;
    readonly monthlyPrice: {
      readonly formatted: string;
    };
    readonly suggested: boolean;
    readonly yearlyListPrice: {
      readonly formatted: string;
    } | null;
    readonly yearlyPrice: {
      readonly formatted: string;
    } | null;
  }>;
  readonly content: {
    readonly onboardingPage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SupportRequestModal_content">;
  };
  readonly currentSubscription: {
    readonly cadence: BillingCadence;
    readonly coverage: {
      readonly formatted: string;
      readonly raw: number;
    };
  } | null;
  readonly currentUser: {
    readonly addressLineOne: string | null;
    readonly addressLineTwo: string | null;
    readonly city: string | null;
    readonly dob: any | null;
    readonly email: string | null;
    readonly firstName: string | null;
    readonly fullName: string | null;
    readonly id: string | null;
    readonly isNewYorkProhibited: boolean;
    readonly lastName: string | null;
    readonly phone: string | null;
    readonly state: string | null;
    readonly unverifiedEmail: string | null;
    readonly zip: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"useContinueOnboarding_user">;
  } | null;
  readonly disclosures: {
    readonly " $fragmentSpreads": FragmentRefs<"DocLink_disclosures">;
  } | null;
};
export type VerifyPersonalInfoQuery = {
  response: VerifyPersonalInfoQuery$data;
  variables: VerifyPersonalInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unverifiedEmail",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dob",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressLineOne",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressLineTwo",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zip",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNewYorkProhibited",
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "Verify Personal Info"
    }
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "raw",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    (v16/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formatted",
  "storageKey": null
},
v20 = [
  (v19/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "Money",
  "kind": "LinkedField",
  "name": "coverage",
  "plural": false,
  "selections": [
    (v19/*: any*/),
    (v16/*: any*/)
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cadence",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionPlan",
  "kind": "LinkedField",
  "name": "availableSubscriptionPlans",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "monthlyPrice",
      "plural": false,
      "selections": (v20/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "monthlyListPrice",
      "plural": false,
      "selections": (v20/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "yearlyPrice",
      "plural": false,
      "selections": (v20/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "yearlyListPrice",
      "plural": false,
      "selections": (v20/*: any*/),
      "storageKey": null
    },
    (v21/*: any*/),
    (v22/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suggested",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionChoice",
  "kind": "LinkedField",
  "name": "currentSubscription",
  "plural": false,
  "selections": [
    (v21/*: any*/),
    (v22/*: any*/)
  ],
  "storageKey": null
},
v25 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyPersonalInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useContinueOnboarding_user"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocLink_disclosures"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Verify Personal Info\"})"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SupportRequestModal_content"
          }
        ],
        "storageKey": null
      },
      (v23/*: any*/),
      (v24/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VerifyPersonalInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastCompletedOnboardingStep",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "electronicCommunications",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "mobileNetworkConsent",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "patriotAct",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraPrivacyPolicy",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraTermsOfService",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraMembershipAgreement",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitsDisclosure",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "plateauApplication",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "privacyPolicy",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "termsOfService",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitEligibility",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "promotionalTerms",
            "plural": false,
            "selections": (v25/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Verify Personal Info\"})"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "name": "Incorrect Prefill Data"
                }
              }
            ],
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondaryCtaText",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": "modal(where:{\"name\":\"Incorrect Prefill Data\"})"
          }
        ],
        "storageKey": null
      },
      (v23/*: any*/),
      (v24/*: any*/)
    ]
  },
  "params": {
    "cacheID": "5b52a68632e73018b6a77678ee5187bc",
    "id": null,
    "metadata": {},
    "name": "VerifyPersonalInfoQuery",
    "operationKind": "query",
    "text": "query VerifyPersonalInfoQuery {\n  currentUser {\n    id\n    email\n    unverifiedEmail\n    fullName\n    firstName\n    lastName\n    dob\n    phone\n    addressLineOne\n    addressLineTwo\n    city\n    state\n    zip\n    isNewYorkProhibited\n    ...useContinueOnboarding_user\n  }\n  disclosures {\n    ...DocLink_disclosures\n  }\n  content {\n    onboardingPage(where: {pageName: \"Verify Personal Info\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n    ...SupportRequestModal_content\n  }\n  availableSubscriptionPlans {\n    monthlyPrice {\n      formatted\n    }\n    monthlyListPrice {\n      formatted\n    }\n    yearlyPrice {\n      formatted\n    }\n    yearlyListPrice {\n      formatted\n    }\n    coverage {\n      formatted\n      raw\n    }\n    cadence\n    label\n    suggested\n  }\n  currentSubscription {\n    coverage {\n      formatted\n      raw\n    }\n    cadence\n  }\n}\n\nfragment DocLink_disclosures on Disclosures {\n  electronicCommunications {\n    url\n    version\n  }\n  mobileNetworkConsent {\n    url\n    version\n  }\n  patriotAct {\n    url\n    version\n  }\n  avibraPrivacyPolicy {\n    url\n    version\n  }\n  avibraTermsOfService {\n    url\n    version\n  }\n  avibraMembershipAgreement {\n    url\n    version\n  }\n  benefitsDisclosure {\n    url\n    version\n  }\n  plateauApplication {\n    url\n    version\n  }\n  privacyPolicy {\n    url\n    version\n  }\n  termsOfService {\n    url\n    version\n  }\n  benefitEligibility {\n    url\n    version\n  }\n  promotionalTerms {\n    url\n    version\n  }\n}\n\nfragment SupportRequestModal_content on Content {\n  modal(where: {name: \"Incorrect Prefill Data\"}) {\n    heading\n    body {\n      raw\n    }\n    mainCtaText\n    secondaryCtaText\n    id\n  }\n}\n\nfragment useContinueOnboarding_user on User {\n  lastCompletedOnboardingStep\n  status\n}\n"
  }
};
})();

(node as any).hash = "35f764cb09187ec16aa4d014ea4162f5";

export default node;
