import styled from 'styled-components'
import { QuilLogo } from '../../icons'

type Props = {
  className?: string
}

function Logo(props: Props) {
  return <QuilLogo className={props.className} />
}

// make eligible for styled-components interpolation
// https://styled-components.com/docs/advanced#caveat
const StyledLogo = styled(Logo)``

export default StyledLogo
