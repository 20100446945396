/**
 * @generated SignedSource<<d8929bc030ee2f581db48eceb034dfd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClosureReason = "CostRelated" | "DidNotUnderstandProduct" | "ExperienceRelated" | "ImprovedFinancialSituation" | "MoreConfidentWithEmploymentStatus" | "NotEnoughCoverage" | "Other" | "ReductionOfMonthlyExpenses" | "StripeRefundDetected" | "%future added value";
export type SubscriptionStatus = "Active" | "Canceled" | "Pending" | "PendingClosure" | "Unpaid" | "%future added value";
export type CloseMembershipMutation$variables = {
  detail?: string | null;
  reason: ClosureReason;
};
export type CloseMembershipMutation$data = {
  readonly closeMembership: {
    readonly __typename: "SubscriptionChoice";
    readonly endDate: {
      readonly formatted: string;
    } | null;
    readonly status: SubscriptionStatus;
  } | {
    readonly __typename: "ValidationErrors";
    readonly messages: ReadonlyArray<{
      readonly field: string;
      readonly help: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type CloseMembershipMutation = {
  response: CloseMembershipMutation$data;
  variables: CloseMembershipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "detail"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reason"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "detail",
        "variableName": "detail"
      },
      {
        "kind": "Variable",
        "name": "reason",
        "variableName": "reason"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "closeMembership",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Instant",
            "kind": "LinkedField",
            "name": "endDate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formatted",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "SubscriptionChoice",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidationError",
            "kind": "LinkedField",
            "name": "messages",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "field",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "help",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ValidationErrors",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CloseMembershipMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CloseMembershipMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f20c056e28cf50eaf62db54e7a24be43",
    "id": null,
    "metadata": {},
    "name": "CloseMembershipMutation",
    "operationKind": "mutation",
    "text": "mutation CloseMembershipMutation(\n  $reason: ClosureReason!\n  $detail: String\n) {\n  closeMembership(reason: $reason, detail: $detail) {\n    __typename\n    ... on SubscriptionChoice {\n      status\n      endDate {\n        formatted\n      }\n    }\n    ... on ValidationErrors {\n      messages {\n        field\n        help\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8dd0e2e8d71e4280aeaf9c9c16b05261";

export default node;
