import { Loader } from '@googlemaps/js-api-loader'
import {
  InputAddressCity,
  InputAddressState,
  InputAddressStreet,
  InputAddressUnit,
  InputAddressZip,
} from '@quil/ui'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import InputRow from '../InputRow'
import parsePlaceDetails from './parse-place-details'

export type Value = {
  addressLineOne: string | null
  addressLineTwo: string | null
  city: string | null
  state: string | null
  zip: string | null
}
export type Errors = {
  [K in keyof Value]: string | null
}

export type TestIds = {
  [K in keyof Value]: string
}

type Props = {
  errors: Errors
  value: Value
  onChange: (value: Value, updatedFields: (keyof Value)[]) => void
  testIds?: TestIds
  addressLineOneLabel?: string
}

const Component: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  forwardRef
) => {
  const didMountRef = useRef(false)
  const addressLine1Ref = useRef<HTMLInputElement | null>(null)
  const addressLine2Ref = useRef<HTMLInputElement | null>(null)
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)

  // give parent a reference to addressLine1Ref via AddressInput's ref prop
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    forwardRef,
    () => addressLine1Ref.current
  )

  function createChangeHandler(field: keyof Value) {
    return function (value: string) {
      props.onChange(
        {
          ...props.value,
          [field]: value,
        },
        [field]
      )
    }
  }

  const handlePredictionSelection = useCallback(() => {
    if (autocompleteRef.current === null) {
      return
    }

    const place = autocompleteRef.current.getPlace()
    const { addressLineOne, city, state, zip } = parsePlaceDetails(place)

    props.onChange(
      {
        ...props.value,
        addressLineOne,
        city,
        state,
        zip,
      },
      ['addressLineOne', 'city', 'state', 'zip']
    )

    // focus on line 2 since autocomplete won't return that
    addressLine2Ref.current?.focus()
  }, [props])

  useEffect(() => {
    async function initializeAutocomplete() {
      try {
        // Load google maps if we don't have it yet
        if (!window.google?.maps?.places) {
          await new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY ?? '',
            ...{ libraries: ['places'] },
          }).load()
        }

        // make sure we haven't unmounted
        if (addressLine1Ref.current) {
          autocompleteRef.current = new google.maps.places.Autocomplete(
            addressLine1Ref.current,
            {
              componentRestrictions: { country: ['us', 'ca'] },
              fields: ['address_components', 'geometry'],
              types: ['address'],
            }
          )

          autocompleteRef.current.addListener(
            'place_changed',
            handlePredictionSelection
          )
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (!didMountRef.current) {
      initializeAutocomplete()
      didMountRef.current = true
    }
  }, [handlePredictionSelection])

  return (
    <>
      <InputAddressStreet
        label={props.addressLineOneLabel}
        ref={addressLine1Ref}
        value={props.value.addressLineOne}
        error={props.errors.addressLineOne}
        data-testid={props.testIds?.addressLineOne}
        onChange={createChangeHandler('addressLineOne')}
      />
      <InputAddressUnit
        ref={addressLine2Ref}
        value={props.value.addressLineTwo}
        error={props.errors.addressLineTwo}
        data-testid={props.testIds?.addressLineTwo}
        onChange={createChangeHandler('addressLineTwo')}
      />
      <InputAddressCity
        value={props.value.city}
        error={props.errors.city}
        data-testid={props.testIds?.city}
        onChange={createChangeHandler('city')}
      />

      <InputRow>
        <InputAddressState
          value={props.value.state}
          error={props.errors.state}
          data-testid={props.testIds?.state}
          onChange={createChangeHandler('state')}
        />

        <InputAddressZip
          value={props.value.zip}
          error={props.errors.zip}
          data-testid={props.testIds?.zip}
          onChange={createChangeHandler('zip')}
        />
      </InputRow>
    </>
  )
}

export const AddressInput = React.forwardRef(Component)
