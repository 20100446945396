/**
 * @generated SignedSource<<a6c9ab0d8a952ea7175786d2d0830da8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BillingCadence = "Monthly" | "Yearly" | "%future added value";
export type DisclosureType = "AvibraMembershipAgreement" | "AvibraPrivacyPolicy" | "AvibraTermsOfService" | "BenefitEligibility" | "BenefitsDisclosure" | "ElectronicCommunications" | "MobileNetworkConsent" | "PatriotAct" | "PlateauApplication" | "PlateauPolicyCertificate" | "PrivacyPolicy" | "PromotionalTerms" | "TermsOfService" | "%future added value";
export type SubscriptionChoiceInput = {
  cadence: BillingCadence;
  coverage: number;
};
export type UpdateUserInput = {
  addressLineOne?: string | null;
  addressLineTwo?: string | null;
  city?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  state?: string | null;
  zip?: string | null;
};
export type CreateAgreementInput = {
  acceptedAt?: any | null;
  type: DisclosureType;
};
export type CreateCheckoutSessionInput = {
  cancelUrl: string;
  successUrl: string;
};
export type VerifyPersonalInfoSubmitPersonalInfoMutation$variables = {
  agreements: ReadonlyArray<CreateAgreementInput | null>;
  createSessionInput: CreateCheckoutSessionInput;
  submitPersonalInfoInput: UpdateUserInput;
  subscriptionChoiceInput: SubscriptionChoiceInput;
};
export type VerifyPersonalInfoSubmitPersonalInfoMutation$data = {
  readonly createAgreements: ReadonlyArray<{
    readonly type: DisclosureType;
  } | null>;
  readonly createCheckoutSession: {
    readonly url: string;
  };
  readonly saveSubscriptionChoice: {
    readonly __typename: "SubscriptionChoice";
  };
  readonly submitPersonalInfo: {
    readonly __typename: "AvibraProfile";
    readonly profileId: string;
  } | {
    readonly __typename: "PersonalInfoOutcome";
    readonly canContinue: boolean;
    readonly isIneligible: boolean;
    readonly isProhibitedNewYorkResident: boolean;
  } | {
    readonly __typename: "ValidationErrors";
    readonly messages: ReadonlyArray<{
      readonly field: string;
      readonly help: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type VerifyPersonalInfoSubmitPersonalInfoMutation = {
  response: VerifyPersonalInfoSubmitPersonalInfoMutation$data;
  variables: VerifyPersonalInfoSubmitPersonalInfoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agreements"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createSessionInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "submitPersonalInfoInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subscriptionChoiceInput"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "subscriptionChoiceInput"
    }
  ],
  "concreteType": "SubscriptionChoice",
  "kind": "LinkedField",
  "name": "saveSubscriptionChoice",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "data",
      "variableName": "submitPersonalInfoInput"
    }
  ],
  "concreteType": null,
  "kind": "LinkedField",
  "name": "submitPersonalInfo",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isProhibitedNewYorkResident",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isIneligible",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canContinue",
          "storageKey": null
        }
      ],
      "type": "PersonalInfoOutcome",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileId",
          "storageKey": null
        }
      ],
      "type": "AvibraProfile",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ValidationError",
          "kind": "LinkedField",
          "name": "messages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "field",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "help",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ValidationErrors",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "agreements",
    "variableName": "agreements"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "createSessionInput"
    }
  ],
  "concreteType": "CheckoutSession",
  "kind": "LinkedField",
  "name": "createCheckoutSession",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyPersonalInfoSubmitPersonalInfoMutation",
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Agreement",
        "kind": "LinkedField",
        "name": "createAgreements",
        "plural": true,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "VerifyPersonalInfoSubmitPersonalInfoMutation",
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Agreement",
        "kind": "LinkedField",
        "name": "createAgreements",
        "plural": true,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "b23dbb1faf20c79fcbb077aeeb4bce0d",
    "id": null,
    "metadata": {},
    "name": "VerifyPersonalInfoSubmitPersonalInfoMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyPersonalInfoSubmitPersonalInfoMutation(\n  $subscriptionChoiceInput: SubscriptionChoiceInput!\n  $submitPersonalInfoInput: UpdateUserInput!\n  $agreements: [CreateAgreementInput]!\n  $createSessionInput: CreateCheckoutSessionInput!\n) {\n  saveSubscriptionChoice(input: $subscriptionChoiceInput) {\n    __typename\n  }\n  submitPersonalInfo(data: $submitPersonalInfoInput) {\n    __typename\n    ... on PersonalInfoOutcome {\n      isProhibitedNewYorkResident\n      isIneligible\n      canContinue\n    }\n    ... on AvibraProfile {\n      profileId\n    }\n    ... on ValidationErrors {\n      messages {\n        field\n        help\n      }\n    }\n  }\n  createAgreements(agreements: $agreements) {\n    type\n    id\n  }\n  createCheckoutSession(input: $createSessionInput) {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "070f8fb28db469e2f23c4c2faae8f6c8";

export default node;
