import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { ReactComponent as AlertIcon } from '../../icons/toast-error.svg'

const defaultStyle = { top: 12, right: -12, position: 'absolute' }

const ErrorBadge: React.FC<{ error: boolean; style?: any }> = (props) => (
  <AnimatePresence>
    {props.error && (
      <motion.div
        style={{ ...defaultStyle, ...props.style }}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0 }}
      >
        <AlertIcon />
      </motion.div>
    )}
  </AnimatePresence>
)

export default ErrorBadge
