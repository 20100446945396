type Properties = Record<string, unknown>
export type EventDefinition = {
  event: string
  properties: Properties
}

type BackendEventDefinition = EventDefinition & {
  userId: string
}

export function buttonClicked(
  buttonName: string,
  pageName: string,
  text: string,
  href?: string
): EventDefinition {
  return {
    event: 'Button Clicked',
    properties: {
      buttonName,
      pageName,
      text,
      isExternal: typeof href === 'string' && href.includes('://'),
      href,
    },
  }
}

export function formSubmitted(formName: string): EventDefinition {
  return {
    event: 'Form Submitted',
    properties: {
      formName,
    },
  }
}

export function inputChanged(
  inputName: string,
  properties: Properties
): EventDefinition {
  return {
    event: 'Input Changed',
    properties: {
      inputName,
      ...properties,
    },
  }
}

export function loginSucceeded(): EventDefinition {
  return {
    event: 'Login Succeeded',
    properties: {},
  }
}

export function claimResumed(
  lastStep: string,
  claimType = 'Layoff Insurance'
): EventDefinition {
  return {
    event: 'Claim Resumed',
    properties: {
      lastStep,
      claimType,
    },
  }
}

export function contactVerificationSucceeded(
  type: 'Email' | 'Phone',
  userId: string,
  contact: string
): BackendEventDefinition {
  const properties = {
    type,
  }
  properties[type] = contact
  return {
    userId,
    // TODO see about changing name to `Succeeded`
    event: 'Contact Verification Updated',
    properties,
  }
}

export function contactVerificationFailed(
  type: 'Email' | 'Phone',
  userId: string,
  failureReason: string
): BackendEventDefinition {
  return {
    userId,
    // TODO see about changing name to just `Failed`
    event: 'Contact Verification Update Failed',
    properties: {
      type,
      failureReason,
    },
  }
}

export function creditCheckSucceeded(userId: string): BackendEventDefinition {
  return {
    event: 'Credit Check Succeeded',
    userId,
    properties: {},
  }
}

export function documentLinkClicked(type: string): EventDefinition {
  return {
    event: 'Document Link Clicked',
    properties: {
      type,
    },
  }
}

export function fraudAlertDetected(
  userId: string,
  fraudAlerts
): BackendEventDefinition {
  return {
    event: 'Fraud Alert Detected',
    userId,
    properties: {
      fraudAlerts,
    },
  }
}

export function leadCreated(
  leadId: string,
  // just Fluent for now
  promotionId: 'fluent',
  email: string,
  phone: string,
  dob: string
): BackendEventDefinition {
  return {
    userId: leadId,
    event: 'Lead Created',
    properties: {
      promotionId,
      email,
      phone,
      dob,
    },
  }
}

export function leadConverted(
  userId: string,
  leadId: string,
  properties: Properties
): BackendEventDefinition {
  return {
    userId,
    event: 'Lead Converted',
    properties: {
      leadId,
      ...properties,
    },
  }
}

export function loanAgreementViewed(): EventDefinition {
  return {
    event: 'Loan Agreement Viewed',
    properties: {},
  }
}

export function membershipActivated(
  userId: string,
  properties: Properties
): BackendEventDefinition {
  return {
    event: 'Membership Activated',
    userId,
    properties,
  }
}

export function paymentMethodAdded(
  userId: string,
  properties: Properties
): BackendEventDefinition {
  return {
    event: 'Payment Method Added',
    userId,
    properties,
  }
}

export function plaidLinkSessionEnded(
  result: 'success' | 'failure',
  linkToken: string,
  props: Properties = {}
): EventDefinition {
  return {
    event: 'Plaid Link Session Ended',
    properties: {
      linkToken,
      result,
      ...props,
    },
  }
}

export function plaidLinkEventEmitted(plaidEventName: string): EventDefinition {
  return {
    event: 'Plaid Event Emitted',
    properties: {
      plaidEventName,
    },
  }
}

export function proveSmsLinkSent(
  userId: string,
  proveRequestId: string
): BackendEventDefinition {
  return {
    userId,
    event: 'Prove Sms Link Sent',
    properties: {
      proveRequestId,
    },
  }
}

export function provePrefillFailed(
  userId: string,
  reason: string,
  authPath: string,
  trustScore: number
): BackendEventDefinition {
  return {
    userId,
    event: 'Prove Prefill Failed',
    properties: {
      reason,
      authPath,
      trustScore,
    },
  }
}

type PrefillProperties = {
  firstName: string
  lastName: string
  addressLineOne: string
  addressLineTwo: string
  city: string
  state: string
  zip: string
  ssnPrefilled: boolean
}
export function provePrefillSucceeded(
  userId: string,
  properties: PrefillProperties
): BackendEventDefinition {
  return {
    userId,
    event: 'Prove Prefill Succeeded',
    properties,
  }
}

export function stripeBillingPortalViewed(
  userId: string,
  stripeCustomerId: string
): BackendEventDefinition {
  return {
    event: 'Stripe Billing Portal Viewed',
    userId,
    properties: {
      stripeCustomerId,
    },
  }
}

type PaymentFailedProperties = {
  stripeCustomerId: string
  failureCode: string
  failureReason: string
  description?: string
}
export function paymentFailed(
  userId: string,
  properties: PaymentFailedProperties
): BackendEventDefinition {
  return {
    event: 'Payment Failed',
    userId,
    properties,
  }
}

type PaymentSucceededProperties = {
  isOnboarding: boolean
  stripeCustomerId: string
  description: string
  zip: string
  cardBrand: string // e.g. visa, mastercard
  // Below props required for Impact Radius tracking; see user-conversion-tracking.js
  eventDate: string
  paymentId: string
  successfulPaymentCount: number
  amountCents: number
}
export function paymentSucceeded(
  userId: string,
  properties: PaymentSucceededProperties
): BackendEventDefinition {
  return {
    event: 'Payment Succeeded',
    userId,
    properties,
  }
}

export function stripeCheckoutViewed(userId: string): BackendEventDefinition {
  return {
    userId,
    event: 'Stripe Checkout Viewed',
    properties: {},
  }
}

export function stripeSubscriptionCreated(
  userId: string,
  stripeSubscriptionId: string
): BackendEventDefinition {
  return {
    userId,
    event: 'Stripe Subscription Created',
    properties: {
      stripeSubscriptionId,
    },
  }
}

export function subscriptionPlanSelected(
  userId: string,
  properties: {
    selectedPlan: string
    suggestedPlan: string
    didChooseSuggestedPlan: boolean
    [additionalProps: string]: unknown
  }
): BackendEventDefinition {
  return {
    userId,
    event: 'Subscription Plan Selected',
    properties,
  }
}

export function supportLinkClicked(
  context?: 'Name Change' | 'Report Fraud' | 'Phone Number Change'
): EventDefinition {
  return {
    event: 'Support Link Clicked',
    properties: {
      context,
    },
  }
}

export function legalLinkClicked(): EventDefinition {
  return {
    event: 'Legal Link Clicked',
    properties: {},
  }
}

export function userAccountCreated(
  userId: string,
  eventDate: Date | string,
  email: string,
  attribution,
  promotion
): BackendEventDefinition {
  return {
    userId,
    event: 'User Account Created',
    properties: {
      eventDate,
      email,
      ...attribution,
      ...promotion,
    },
  }
}

export function userAccountCreationFailed(errors?: string): EventDefinition {
  return {
    event: 'User Account Creation Failed',
    properties: { errors },
  }
}

export function userAccountUpdated(field?: string): EventDefinition {
  return {
    event: 'User Account Updated',
    properties: { field },
  }
}

export function existingAvibraCustomer(userId: string, avibraProfile: string) {
  return {
    userId,
    properties: {
      avibraProfile,
    },
    event: 'Existing Avibra customer pending activation',
  }
}

export function userAccountUpdateFailed(
  field: string,
  reason?: string,
  errorMessage?: string
): EventDefinition {
  return {
    event: 'User Account Update Failed',
    properties: {
      field,
      reason,
      errorMessage,
    },
  }
}

export function userAccountClosed(
  userId: string,
  properties: Properties
): BackendEventDefinition {
  return {
    userId,
    event: 'User Account Closed',
    properties,
  }
}

export function userStatusChanged(
  userId: string,
  properties: {
    from: string
    to: string
    [additionalProp: string]: unknown
  }
): BackendEventDefinition {
  return {
    userId,
    event: 'User Status Changed',
    properties,
  }
}

export function newYorkResidentSignupAttempted(
  userId: string,
  email: string
): BackendEventDefinition {
  return {
    userId,
    event: 'New York Resident Signup Attempted',
    properties: {
      email,
    },
  }
}
