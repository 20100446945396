import {
  ActionListItem,
  ActionListLink,
  ActionListList,
  Beehive,
  BodyText,
  ClaimBadge,
  Headline,
  Subheading,
} from '@quil/ui'
import { graphql, useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components'
import { LayoutInAppTracked } from '../../common/analytics'
import GoBack from '../../common/components/GoBack'
import { Center, resumeClaimPath, styledIcon } from '../common'
import { formatClaimId } from '../format-claim-id'
import {
  ClaimsListQuery,
  LayoffClaimStatus,
} from './__generated__/ClaimsListQuery.graphql'

const Icon = styledIcon(Beehive)

const Heading = styled(Subheading)`
  margin-bottom: 0.2rem;
`
const Body = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.2rem;
  margin: 0;
`
export default function ClaimsList() {
  const pageName = 'Claim List'

  const data = useLazyLoadQuery<ClaimsListQuery>(
    graphql`
      query ClaimsListQuery {
        recentClaims {
          id
          status
          minimumWeeklyHours
          changedJobsRecently
          terminatedWithoutCause
          receivingStateBenefits
          employerName
          occupation
          employmentStartDate
          employmentEndDate
          terminationReason
          additionalInfo
          employerEmail
          employerPhone
          payoutAccount {
            accountNumberMask
          }
          submittedAt {
            formatted
          }
        }
      }
    `,
    {}
  )

  return (
    <LayoutInAppTracked pageName={pageName} headerStart={<GoBack />}>
      {data.recentClaims.length ? (
        <>
          <Headline>Claims</Headline>
          <ActionListList>
            {data.recentClaims.map((claim) => (
              <ActionListItem key={claim.id}>
                <ActionListLink
                  to={
                    claim.status === 'Pending'
                      ? resumeClaimPath(claim)
                      : `/home/claims/${claim.id}`
                  }
                  data-name="Claim"
                  data-page-name={pageName}
                >
                  <ClaimBadge
                    type={
                      claim.status as Exclude<
                        LayoffClaimStatus,
                        '%future added value'
                      >
                    }
                  />
                  <Heading>Layoff Insurance Claim</Heading>
                  <Body>
                    {claim.submittedAt?.formatted ? (
                      <>
                        Submitted on: {claim.submittedAt.formatted}
                        <br />
                      </>
                    ) : null}
                    Claim ID: {formatClaimId(claim.id)}
                  </Body>
                </ActionListLink>
              </ActionListItem>
            ))}
          </ActionListList>
        </>
      ) : (
        <Center>
          <Icon />
          {/* TODO: CMS */}
          <Subheading>No Claims yet!</Subheading>
          <BodyText>
            After you file your first claim you can check their status updates
            here.
          </BodyText>
        </Center>
      )}
    </LayoutInAppTracked>
  )
}
