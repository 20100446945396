/**
 * @generated SignedSource<<803488b49df009e587483f04117ac32e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CounselingQuery$variables = {};
export type CounselingQuery$data = {
  readonly vendorBenefits: {
    readonly counseling: ReadonlyArray<{
      readonly description: string;
      readonly displayName: string;
      readonly icon: string;
      readonly name: string;
    } | null>;
  } | null;
};
export type CounselingQuery = {
  response: CounselingQuery$data;
  variables: CounselingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VendorBenefits",
    "kind": "LinkedField",
    "name": "vendorBenefits",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VendorBenefit",
        "kind": "LinkedField",
        "name": "counseling",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "icon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CounselingQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CounselingQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a38ea15b5833c15af39e6c1ca036d0af",
    "id": null,
    "metadata": {},
    "name": "CounselingQuery",
    "operationKind": "query",
    "text": "query CounselingQuery {\n  vendorBenefits {\n    counseling {\n      name\n      displayName\n      icon\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "de4aa3640bc4d9c71e0d965b995762a6";

export default node;
