import TheBasicsPhoneDob from '@web/experiments/TheBasicsPhoneDob'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { Navigate, Route, Routes } from 'react-router-dom'
import TheBasicsEmail from '../experiments/TheBasicsEmail'
import CheckYourPhone from './CheckYourPhone'
import Customize from './Customize'
import Denied from './Denied'
import { OnboardingSequence } from './OnboardingSequence'
import TheBasics from './TheBasics'
import VerifyPersonalInfo from './VerifyPersonalInfo'
import SeeYouLater from './VerifyPersonalInfo/SeeYouLater'
import UpgradeAvibraPlan from './VerifyPersonalInfo/UpgradeAvibraPlan'
import {
  SignupQuery,
  SignupQuery$data,
} from './__generated__/SignupQuery.graphql'

const query = graphql`
  query SignupQuery {
    currentUser {
      id
      status
    }
  }
`

function hasCompletedOnboarding(
  user?: SignupQuery$data['currentUser']
): boolean {
  return !!user && user?.status === 'Active'
}

function Signup() {
  const data = useLazyLoadQuery<SignupQuery>(query, {})
  const { showPhoneDobLaterInOnboarding } = useFlags()

  // Redirect to home if user has already completed onboarding
  if (hasCompletedOnboarding(data.currentUser)) {
    return <Navigate to="/home" replace />
  }

  return (
    <Routes>
      <Route
        path="the-basics"
        element={
          showPhoneDobLaterInOnboarding ? (
            <TheBasicsEmail {...OnboardingSequence.TheBasics} />
          ) : (
            <TheBasics {...OnboardingSequence.TheBasics} />
          )
        }
      />
      <Route
        path="phone-dob"
        element={
          <TheBasicsPhoneDob {...OnboardingSequence.TheBasicsPhoneDob} />
        }
      />
      <Route path="customize/*" element={<Customize />} />
      <Route
        path="check-your-phone"
        element={<CheckYourPhone {...OnboardingSequence.CheckYourPhone} />}
      />
      <Route
        path="verify-personal-info"
        element={
          <VerifyPersonalInfo {...OnboardingSequence.VerifyPersonalInfo} />
        }
      />
      <Route
        path="upgrade-plan"
        element={
          <UpgradeAvibraPlan {...OnboardingSequence.UpgradeAvibraPlan} />
        }
      />
      <Route
        path="see-you-later"
        element={<SeeYouLater {...OnboardingSequence.SeeYouLater} />}
      />
      <Route
        path="denied/employment"
        element={<Denied reason={'employment'} />}
      />
      <Route path="denied/identity" element={<Denied reason={'identity'} />} />
      <Route
        path="denied/personal-info"
        element={<Denied reason={'prefill'} />}
      />
      <Route path="ny-coming-soon" element={<Denied reason={'newYork'} />} />

      <Route path="*" element={<Navigate to="the-basics" replace />} />
    </Routes>
  )
}

export default Signup
