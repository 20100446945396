import {
  ButtonPrimary,
  InputAutocomplete,
  InputText,
  ProcessingOverlay,
} from '@quil/ui'
import React from 'react'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { LayoutSignupTracked } from '../../../common/analytics'
import Form from '../../../common/components/Form'
import GoBack from '../../../common/components/GoBack'
import RichText from '../../../common/components/RichText'
import { useNavigate } from '../../../common/react-router-wrappers'
import { useFormReducer } from '../../../common/useFormReducer'
import { SignupPageProps } from '../../SignupPageProps'
import SignupProgressTracker from '../../SignupProgressTracker'
import * as testIds from '../test-ids'
import * as options from './options'
import { EmployerInfoMutation } from './__generated__/EmployerInfoMutation.graphql'
import { EmployerInfoQuery } from './__generated__/EmployerInfoQuery.graphql'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

type FormState = {
  values: {
    employerName: string | null
    jobTitle: string | null
  }
  errors: {
    employerName: string | null
    jobTitle: string | null
  }
}

const initialFormState: FormState = {
  values: {
    employerName: null,
    jobTitle: null,
  },
  errors: {
    employerName: null,
    jobTitle: null,
  },
}

const EmployerInfo: React.FC<SignupPageProps> = ({
  step,
  nextPath,
  previousPath,
}) => {
  const data = useLazyLoadQuery<EmployerInfoQuery>(
    graphql`
      query EmployerInfoQuery {
        content {
          onboardingPage(where: { pageName: "Employer Info" }) {
            heading
            body {
              raw
            }
            mainCtaText
          }
        }
      }
    `,
    {}
  )
  const pageName = 'Employer Info'
  const navigate = useNavigate()
  const { employerInfoAutocomplete } = useFlags()
  const ldClient = useLDClient()
  const [formState, actions] = useFormReducer(initialFormState)

  const [commitMutation, mutationInFlight] =
    useMutation<EmployerInfoMutation>(graphql`
      mutation EmployerInfoMutation($input: UpdateEmploymentInfoInput!) {
        submitEmploymentInfo(input: $input) {
          __typename

          ... on User {
            id
            status
          }

          ... on ValidationErrors {
            messages {
              field
              help
            }
          }
        }
      }
    `)

  function handleSubmit() {
    if (!mutationInFlight) {
      commitMutation({
        variables: {
          input: formState.values,
        },
        async onCompleted(result) {
          switch (result.submitEmploymentInfo.__typename) {
            case 'User':
              await ldClient.track('employer_info_submitted')
              if (result.submitEmploymentInfo.status === 'Ineligible') {
                navigate('/signup/denied/employment')
              } else {
                navigate(nextPath)
              }
              break
            case 'ValidationErrors':
              actions.receiveValidationErrors(
                result.submitEmploymentInfo.messages
              )
              break
          }
        },
      })
    }
  }

  const employerProps = {
    label: 'Name of Employer',
    value: formState.values.employerName,
    error: formState.errors.employerName,
    onChange: actions.createFieldUpdater('employerName'),
    'data-testid': testIds.employerName,
  }

  const jobTitleProps = {
    label: 'Job Title',
    value: formState.values.jobTitle,
    error: formState.errors.jobTitle,
    onChange: actions.createFieldUpdater('jobTitle'),
    'data-testid': testIds.jobTitle,
  }

  const autocompleteInputsComponent = (
    <>
      <InputAutocomplete {...employerProps} options={options.employerNames} />
      <InputAutocomplete {...jobTitleProps} options={options.jobTitles} />
    </>
  )

  const autocompletePlusInputsComponent = (
    <>
      <InputAutocomplete
        {...employerProps}
        options={options.employerNames}
        includeValueInSuggestions={true}
      />
      <InputAutocomplete
        {...jobTitleProps}
        options={options.jobTitles}
        includeValueInSuggestions={true}
      />
    </>
  )

  const rawTextInputsComponent = (
    <>
      <InputText {...employerProps} />
      <InputText {...jobTitleProps} />
    </>
  )

  let inputsComponent
  // switch statement covers experiment variations set in LD
  switch (employerInfoAutocomplete) {
    case 'none':
      inputsComponent = rawTextInputsComponent
      break
    case 'plus':
      inputsComponent = autocompletePlusInputsComponent
      break
    case 'control':
      inputsComponent = autocompleteInputsComponent
      break
    default:
      // default same as control just to be explicit
      inputsComponent = autocompleteInputsComponent
  }

  return (
    <>
      <LayoutSignupTracked
        pageName={pageName}
        title={data.content.onboardingPage.heading}
        headerStart={previousPath && <GoBack />}
        headerEnd={<SignupProgressTracker step={step} />}
        footerContent={
          <ButtonPrimary
            data-page-name={pageName}
            data-name="Primary CTA"
            onClick={handleSubmit}
            disabled={mutationInFlight}
          >
            {data.content.onboardingPage.mainCtaText}
          </ButtonPrimary>
        }
      >
        <RichText content={data.content.onboardingPage.body.raw} />
        <Form onSubmit={handleSubmit}>{inputsComponent}</Form>
      </LayoutSignupTracked>
      <ProcessingOverlay processing={mutationInFlight} />
    </>
  )
}

export default EmployerInfo
