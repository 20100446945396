import { ButtonBack } from '@quil/ui'
import { useNavigate } from '../react-router-wrappers'

type Props = {
  to?: string | null
}

function GoBack(props: Props) {
  const navigate = useNavigate()

  function handleClick() {
    if (props.to) return navigate(props.to)
    return navigate(-1)
  }

  return <ButtonBack onClick={handleClick} />
}

export default GoBack
