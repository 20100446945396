import { EffectCallback, useEffect } from 'react'

export function useMountEffect(effectFunction: EffectCallback) {
  // useEffect wrapper that passes an empty dependency array which is the accepted pattern
  // to only run your effect once on component mount.

  // https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
  // https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once/56767883#56767883

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(effectFunction, [])
}
