/**
 * @generated SignedSource<<fe91a4c3a391f0a0ff9a4ea27403b35a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeQuery$variables = {};
export type HomeQuery$data = {
  readonly currentUser: {
    readonly eligibilityCompletionPercentage: number;
    readonly id: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"Alerts_query" | "HeroImage_query" | "MembershipCard_query" | "PartnerPlacements_query" | "RecentActivity_query">;
};
export type HomeQuery = {
  response: HomeQuery$data;
  variables: HomeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eligibilityCompletionPercentage",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hex",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ctaText",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "formatted",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Alerts_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "HeroImage_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MembershipCard_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RecentActivity_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PartnerPlacements_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "membershipActivatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLastInvoiceUnpaid",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NoticeEvent",
        "kind": "LinkedField",
        "name": "noticeEvents",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliveredAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noticeType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "precedence",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "headline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "severity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkText",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Cms_HeroImage",
            "kind": "LinkedField",
            "name": "heroImages",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Cms_Asset",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mimeType",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Cms_Color",
                "kind": "LinkedField",
                "name": "color",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "altText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDateTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDateTime",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Cms_RecentActivity",
            "kind": "LinkedField",
            "name": "recentActivities",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Cms_PartnerPlacement",
            "kind": "LinkedField",
            "name": "partnerPlacements",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Cms_Asset",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageAltText",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Cms_Color",
                "kind": "LinkedField",
                "name": "backgroundColor",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ctaUrl",
                "storageKey": null
              },
              (v6/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "name": "Partner placements section heading"
                }
              }
            ],
            "concreteType": "Cms_Miscellaneous",
            "kind": "LinkedField",
            "name": "miscellaneous",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": "miscellaneous(where:{\"name\":\"Partner placements section heading\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SubscriptionChoice",
        "kind": "LinkedField",
        "name": "currentSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Money",
            "kind": "LinkedField",
            "name": "coverage",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Instant",
            "kind": "LinkedField",
            "name": "endDate",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "recentActivities",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "data",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "user",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d67825f4a6e94af3d28ad00424cdb62",
    "id": null,
    "metadata": {},
    "name": "HomeQuery",
    "operationKind": "query",
    "text": "query HomeQuery {\n  currentUser {\n    id\n    eligibilityCompletionPercentage\n  }\n  ...Alerts_query\n  ...HeroImage_query\n  ...MembershipCard_query\n  ...RecentActivity_query\n  ...PartnerPlacements_query\n}\n\nfragment Alerts_query on Query {\n  noticeEvents {\n    id\n    deliveredAt\n    displayType\n    noticeType\n    content\n    precedence\n    headline\n    severity\n    linkText\n  }\n}\n\nfragment HeroImage_query on Query {\n  content {\n    heroImages {\n      image {\n        url\n        mimeType\n        id\n      }\n      color {\n        hex\n      }\n      altText\n      startDateTime\n      endDateTime\n      id\n    }\n  }\n}\n\nfragment MembershipCard_query on Query {\n  currentUser {\n    fullName\n    membershipActivatedAt\n    isLastInvoiceUnpaid\n    id\n  }\n  currentSubscription {\n    coverage {\n      formatted\n    }\n    endDate {\n      formatted\n    }\n  }\n}\n\nfragment PartnerPlacements_query on Query {\n  user: currentUser {\n    id\n  }\n  content {\n    partnerPlacements {\n      heading\n      image {\n        url\n        id\n      }\n      imageAltText\n      body {\n        raw\n      }\n      backgroundColor {\n        hex\n      }\n      ctaUrl\n      ctaText\n      id\n    }\n    miscellaneous(where: {name: \"Partner placements section heading\"}) {\n      value\n      id\n    }\n  }\n}\n\nfragment RecentActivity_query on Query {\n  recentActivities {\n    type\n    data\n  }\n  content {\n    recentActivities {\n      name\n      heading\n      body {\n        raw\n      }\n      ctaText\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ff01b7cbf8253a27c7910e830213f0b";

export default node;
