/**
 * @generated SignedSource<<97aff6fb60e1212f5bc484cc3ea83155>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LayoffClaimStatus = "Approved" | "Denied" | "InReview" | "Pending" | "%future added value";
export type PayoutAccountQuery$variables = {};
export type PayoutAccountQuery$data = {
  readonly content: {
    readonly modal: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
      readonly secondaryCtaText: string | null;
    } | null;
    readonly profilePage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly header: string | null;
      readonly mainCtaText: string | null;
    } | null;
  };
  readonly currentLayoffClaim: {
    readonly id: string | null;
    readonly status: LayoffClaimStatus;
  } | null;
  readonly payoutAccount: {
    readonly accountNumberMask: string;
    readonly bankName: string | null;
  } | null;
};
export type PayoutAccountQuery = {
  response: PayoutAccountQuery$data;
  variables: PayoutAccountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Payout Account"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Settings Confirm Link New Account"
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryCtaText",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "LayoffClaim",
  "kind": "LinkedField",
  "name": "currentLayoffClaim",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumberMask",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayoutAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Payout Account\"})"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": "modal(where:{\"name\":\"Settings Confirm Link New Account\"})"
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "PayoutAccount",
        "kind": "LinkedField",
        "name": "payoutAccount",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PayoutAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Payout Account\"})"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": "modal(where:{\"name\":\"Settings Confirm Link New Account\"})"
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "PayoutAccount",
        "kind": "LinkedField",
        "name": "payoutAccount",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a435d0eee4f7417dc077684befdaf154",
    "id": null,
    "metadata": {},
    "name": "PayoutAccountQuery",
    "operationKind": "query",
    "text": "query PayoutAccountQuery {\n  content {\n    profilePage(where: {name: \"Payout Account\"}) {\n      header\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n    modal(where: {name: \"Settings Confirm Link New Account\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      secondaryCtaText\n      id\n    }\n  }\n  currentLayoffClaim {\n    id\n    status\n  }\n  payoutAccount {\n    bankName\n    accountNumberMask\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1a66ac3557e79f84dd2a4c381974f00";

export default node;
