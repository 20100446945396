import { SubheadingAlt } from '@quil/ui'
import React from 'react'
import { graphql, useFragment } from 'react-relay'
import styled from 'styled-components'
import { UnverifiedLabel } from './UnverifiedLabel'
import { EmailText_user$key } from './__generated__/EmailText_user.graphql'

const Email = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const EmailHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

type Props = {
  user: EmailText_user$key
}

export const EmailText: React.FC<Props> = (props) => {
  const data = useFragment(
    graphql`
      fragment EmailText_user on User {
        email
        unverifiedEmail
      }
    `,
    props.user
  )

  const email = data.unverifiedEmail ?? data.email

  return (
    <>
      <EmailHeaderContainer>
        <SubheadingAlt> Email</SubheadingAlt>
        <>{!!data.unverifiedEmail && <UnverifiedLabel />}</>
      </EmailHeaderContainer>
      <Email>{email ? <>{email}</> : 'Add your email'}</Email>
    </>
  )
}
