import React from 'react'
import { HomeSolid as HomeIcon } from '../../icons'
import InputAdornment from '../InputAdornment'
import InputText, { Props as InputTextProps } from '../InputText'

type Props = Pick<
  InputTextProps,
  'value' | 'onChange' | 'error' | 'data-testid'
>

const InputAddressUnit: React.ForwardRefRenderFunction<
  HTMLInputElement,
  Props
> = (props, ref) => {
  return (
    <InputText
      {...props}
      ref={ref}
      startAdornment={
        <InputAdornment>
          <HomeIcon />
        </InputAdornment>
      }
      label="Apartment, unit, suite, or floor #"
      name="address_unit"
      placeholder="Unit 321"
      autoComplete="address-line2"
    />
  )
}

export default React.forwardRef(InputAddressUnit)
