import {
  BodyText,
  ButtonPrimary,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import { useEffect, useRef, useState } from 'react'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { LayoutSignupTracked } from '../../common/analytics'
import {
  AddressInput,
  Errors,
  Value as AddressValue,
} from '../../common/components/AddressInput'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import Row from '../../common/components/Row'
import { isTouchDevice } from '../../common/isTouchDevice'
import * as testIds from './test-ids'
import { UpdateAddressMutation } from './__generated__/UpdateAddressMutation.graphql'
import { UpdateAddressQuery } from './__generated__/UpdateAddressQuery.graphql'

const query = graphql`
  query UpdateAddressQuery {
    currentUser {
      addressLineOne
      addressLineTwo
      city
      state
      zip
    }
    content {
      profilePage(where: { name: "Update Address" }) {
        header
        body {
          raw
        }
        mainCtaText
      }
    }
  }
`
const mutation = graphql`
  mutation UpdateAddressMutation($data: UpdateAddressInput!) {
    updateAddress(data: $data) {
      __typename
      ... on User {
        addressLineOne
        addressLineTwo
        city
        state
        zip
      }
      ... on ValidationErrors {
        messages {
          field
          help
        }
      }
    }
  }
`

type AddressState = {
  values: AddressValue
  errors: Errors
}

export default function UpdateAddress() {
  const pageName = 'Update Address'
  const showToast = useShowToast()
  const [commitMutation, mutationInFlight] =
    useMutation<UpdateAddressMutation>(mutation)
  const { currentUser, content } = useLazyLoadQuery<UpdateAddressQuery>(
    query,
    {}
  )

  const [state, setState] = useState<AddressState>({
    values: {
      addressLineOne: currentUser.addressLineOne,
      addressLineTwo: currentUser.addressLineTwo,
      city: currentUser.city,
      state: currentUser.state,
      zip: currentUser.zip,
    },
    errors: {
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      zip: '',
    },
  })

  // autofocus on address on mount (on non-touch devices)
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!isTouchDevice()) {
      inputRef.current?.focus()
    }
  }, [])

  function handleAddressChange(value: AddressValue) {
    setState((prevState) => ({
      ...prevState,
      values: value,
    }))
  }

  function handleSubmit() {
    if (mutationInFlight) {
      return
    }

    commitMutation({
      variables: {
        data: state.values,
      },
      onCompleted(response) {
        switch (response.updateAddress.__typename) {
          case 'User':
            setState((prevState) => ({
              ...prevState,
              errors: {} as AddressState['errors'],
            }))
            showToast({
              severity: 'Success',
              headline: standardToastHeadlines.success,
              message: 'Address updated',
              testId: testIds.toast,
            })
            break
          case 'ValidationErrors': {
            showToast({
              severity: 'Error',
              headline: standardToastHeadlines.validationErrors,
              message: standardToastMessages.validationErrors,
            })
            response.updateAddress.messages.forEach((error) => {
              setState((prevState) => ({
                ...prevState,
                errors: {
                  ...prevState.errors,
                  [error.field]: error.help,
                },
              }))
            })
          }
        }
      },
      onError: () => {
        showGenericError()
      },
    })
  }

  function showGenericError() {
    showToast({
      severity: 'Error',
      headline: standardToastHeadlines.genericError,
      message: standardToastMessages.retry,
      onClick: handleSubmit,
    })
  }

  return (
    <LayoutSignupTracked
      pageName={pageName}
      title={content.profilePage.header}
      headerStart={<GoBack />}
      footerContent={
        <ButtonPrimary
          data-page-name={pageName}
          data-name="Primary CTA"
          onClick={handleSubmit}
        >
          {content.profilePage.mainCtaText}
        </ButtonPrimary>
      }
    >
      <RichText
        content={content.profilePage.body.raw}
        renderers={{ p: ({ children }) => <BodyText>{children}</BodyText> }}
      />
      <Row>
        <AddressInput
          value={{
            addressLineOne: state.values.addressLineOne,
            addressLineTwo: state.values.addressLineTwo,
            city: state.values.city,
            state: state.values.state,
            zip: state.values.zip,
          }}
          errors={{
            addressLineOne: state.errors.addressLineOne,
            addressLineTwo: state.errors.addressLineTwo,
            city: state.errors.city,
            state: state.errors.state,
            zip: state.errors.zip,
          }}
          onChange={handleAddressChange}
          testIds={testIds.address}
        />
      </Row>
    </LayoutSignupTracked>
  )
}
