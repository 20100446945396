/**
 * @generated SignedSource<<8c81bfa4d0a773fd07f5caf1ac900d8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LayoffClaimReason = "BusinessShutDown" | "CovidLayoffs" | "LackOfWork" | "Other" | "StrikeOrLockout" | "%future added value";
export type LayoffClaimStatus = "Approved" | "Denied" | "InReview" | "Pending" | "%future added value";
export type ClaimsListQuery$variables = {};
export type ClaimsListQuery$data = {
  readonly recentClaims: ReadonlyArray<{
    readonly additionalInfo: string | null;
    readonly changedJobsRecently: boolean | null;
    readonly employerEmail: string | null;
    readonly employerName: string | null;
    readonly employerPhone: string | null;
    readonly employmentEndDate: string | null;
    readonly employmentStartDate: string | null;
    readonly id: string | null;
    readonly minimumWeeklyHours: boolean | null;
    readonly occupation: string | null;
    readonly payoutAccount: {
      readonly accountNumberMask: string;
    } | null;
    readonly receivingStateBenefits: boolean | null;
    readonly status: LayoffClaimStatus;
    readonly submittedAt: {
      readonly formatted: string;
    } | null;
    readonly terminatedWithoutCause: boolean | null;
    readonly terminationReason: LayoffClaimReason | null;
  } | null>;
};
export type ClaimsListQuery = {
  response: ClaimsListQuery$data;
  variables: ClaimsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumWeeklyHours",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "changedJobsRecently",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminatedWithoutCause",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivingStateBenefits",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "occupation",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentStartDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentEndDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminationReason",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalInfo",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerEmail",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerPhone",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumberMask",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Instant",
  "kind": "LinkedField",
  "name": "submittedAt",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formatted",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClaimsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "recentClaims",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClaimsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "recentClaims",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ff566903d77fab9a7dd59875904d4c1",
    "id": null,
    "metadata": {},
    "name": "ClaimsListQuery",
    "operationKind": "query",
    "text": "query ClaimsListQuery {\n  recentClaims {\n    id\n    status\n    minimumWeeklyHours\n    changedJobsRecently\n    terminatedWithoutCause\n    receivingStateBenefits\n    employerName\n    occupation\n    employmentStartDate\n    employmentEndDate\n    terminationReason\n    additionalInfo\n    employerEmail\n    employerPhone\n    payoutAccount {\n      accountNumberMask\n      id\n    }\n    submittedAt {\n      formatted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d75e96cca9de0f516152ca88cbc5be9c";

export default node;
