/**
 * @generated SignedSource<<16f79383cc009f1374da9eee741650b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateClaimPayoutAccountInput = {
  claimId: string;
  payoutAccountId: string;
};
export type PayoutAccountExistingMutation$variables = {
  input: UpdateClaimPayoutAccountInput;
};
export type PayoutAccountExistingMutation$data = {
  readonly updateClaimPayoutAccount: {
    readonly payoutAccount: {
      readonly accountNumberMask: string;
      readonly bankName: string | null;
    } | null;
  };
};
export type PayoutAccountExistingMutation = {
  response: PayoutAccountExistingMutation$data;
  variables: PayoutAccountExistingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumberMask",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayoutAccountExistingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "updateClaimPayoutAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayoutAccountExistingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "updateClaimPayoutAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0e68680b32be038a923ff94691e6881",
    "id": null,
    "metadata": {},
    "name": "PayoutAccountExistingMutation",
    "operationKind": "mutation",
    "text": "mutation PayoutAccountExistingMutation(\n  $input: UpdateClaimPayoutAccountInput!\n) {\n  updateClaimPayoutAccount(input: $input) {\n    payoutAccount {\n      accountNumberMask\n      bankName\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "64fa9d7e32e42f9cf92c11eba734f864";

export default node;
