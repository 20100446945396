import {
  BodyText,
  ButtonPrimary,
  FileUpload,
  Headline,
  InputTextarea,
  IntroBlock,
  LegalText,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import {
  FileEntity,
  uploadClaimDocument,
  useDeleteFile,
} from '@web/common/file-utils'
import { Navigate, useNavigate } from '@web/common/react-router-wrappers'
import { useFormReducer } from '@web/common/useFormReducer'
import { stdSerializers } from 'pino'
import { assoc, isNotNil } from 'ramda'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { formatClaimId } from '../format-claim-id'
import { SubmitAdditionalInformationFulfillMutation } from './__generated__/SubmitAdditionalInformationFulfillMutation.graphql'
import {
  SubmitAdditionalInformationQuery,
  SubmitAdditionalInformationQuery$data,
} from './__generated__/SubmitAdditionalInformationQuery.graphql'
import * as testIds from './test-ids'

const StyledIntroBlock = styled(IntroBlock)`
  font-weight: 400;
`

export default function ClaimSubmitAdditionalInformation() {
  const pageName = 'Layoff Claim Submit Additional Information'
  const { id } = useParams()
  const showToast = useShowToast()
  const navigate = useNavigate()
  const data = useLazyLoadQuery<SubmitAdditionalInformationQuery>(
    graphql`
      query SubmitAdditionalInformationQuery($id: ID!) {
        layoffClaimInformationRequests(claimId: $id, filter: { status: Open }) {
          id
          claimId
          status
          type
          title
          detail
          requestedAt {
            formatted
          }
        }
      }
    `,
    { id }
  )
  const [commitDeleteFile] = useDeleteFile()

  const [{ values, errors }, actions] = useFormReducer(
    initialState(data.layoffClaimInformationRequests)
  )
  const uploadsInProgress = Object.values(values).some(
    (item) => item.fileUpload?.state === 'pending'
  )

  function handleError(error: unknown, onClick?: () => void) {
    console.error(error)
    showToast({
      severity: 'Error',
      headline: 'Something went wrong',
      message: 'Please try again',
      linkText: 'Retry',
      onClick,
    })
  }

  const createNoteUpdateHandler = (id: string) => (value: string) =>
    actions.updateFields({
      [id]: {
        layoffclaimInformationRequestId: id,
        note: value,
        fileUpload: null,
      },
    })

  function createAddFileHandler(
    layoffclaimInformationRequestId: string,
    claimId: string,
    type: string
  ) {
    return function (files: File[]) {
      // Due to the construction of the "fulfillInformationRequest" mutation and underlying model,
      // file uploads on this page can only be single uploads (not multiple).
      // This behavior will be changed in the future https://quin-inc.monday.com/boards/1528612958/pulses/4606152749.
      // Single file uploads can be "replaced" if the user clicks "Upload" again, after having already selected+uploaded a file.
      // For data consistency, we delete previous file uploads when the selected file is replaced.
      const id = values[layoffclaimInformationRequestId].fileUpload?.id
      if (id) {
        commitDeleteFile({ variables: { id } })
      }
      files.forEach(async (file, index) => {
        const handle = `${Date.now()}_${index}`

        actions.updateFields({
          [layoffclaimInformationRequestId]: {
            layoffclaimInformationRequestId,
            note: null,
            fileUpload: {
              file,
              handle,
              state: 'pending',
              uri: URL.createObjectURL(file),
              error: null,
            },
          },
        })

        try {
          const res = await uploadClaimDocument({
            claimId,
            file,
            type,
          })

          // set success
          actions.updateFields({
            [layoffclaimInformationRequestId]: {
              layoffclaimInformationRequestId,
              note: null,
              fileUpload: {
                file,
                handle,
                id: res.id,
                state: 'complete',
                uri: URL.createObjectURL(file),
                error: null,
              },
            },
          })
        } catch (e) {
          // set failure on file
          actions.updateFields({
            [layoffclaimInformationRequestId]: {
              layoffclaimInformationRequestId,
              note: null,
              fileUpload: {
                file,
                handle,
                state: 'error',
                uri: URL.createObjectURL(file),
                error: null,
              },
            },
          })

          // display error toast
          handleError({
            message: 'Unknown response type',
            error: stdSerializers.err(e),
          })
        }
      })
    }
  }

  function createRemoveHandler(layoffclaimInformationRequestId: string) {
    return () => {
      const fileUploadId =
        values[layoffclaimInformationRequestId].fileUpload?.id
      if (fileUploadId) {
        commitDeleteFile({ variables: { id: fileUploadId } })
      }
      actions.updateFields({
        [layoffclaimInformationRequestId]: {
          layoffclaimInformationRequestId,
          note: null,
          fileUpload: null,
        },
      })
    }
  }

  const [
    fulfillInformationRequestsMutation,
    fulfillInformationRequestsInFlight,
  ] = useMutation<SubmitAdditionalInformationFulfillMutation>(graphql`
    mutation SubmitAdditionalInformationFulfillMutation(
      $input: [FulfillInformationRequestInput!]!
    ) {
      fulfillLayoffClaimInformationRequests(input: $input) {
        __typename
        ... on FulfillLayoffClaimInformationRequestsSuccess {
          informationRequests {
            id
            status
            type
            title
            detail
            requestedAt {
              formatted
            }
          }
        }

        ... on ValidationErrors {
          messages {
            field
            help
          }
        }
      }
    }
  `)

  function handleSubmit() {
    if (!fulfillInformationRequestsInFlight) {
      actions.clearValidationErrors()
      fulfillInformationRequestsMutation({
        variables: {
          input: Object.values(values).map((v) => ({
            layoffclaimInformationRequestId: v.layoffclaimInformationRequestId,
            note: v.note,
            fileUploadId: v.fileUpload?.id ?? null,
          })),
        },
        onCompleted({ fulfillLayoffClaimInformationRequests }) {
          switch (fulfillLayoffClaimInformationRequests.__typename) {
            case 'FulfillLayoffClaimInformationRequestsSuccess':
              navigate(`/home/claims/${id}`)
              break
            case 'ValidationErrors':
              actions.receiveValidationErrors(
                fulfillLayoffClaimInformationRequests.messages
              )
              showToast({
                severity: 'Error',
                headline: standardToastHeadlines.validationErrors,
                message: standardToastMessages.validationErrors,
              })
              break
          }
        },
        onError(error) {
          handleError(
            {
              error: stdSerializers.err(error),
              mutation: 'SubmitAdditionalInformationFulfillMutation',
            },
            handleSubmit
          )
        },
        updater(store) {
          store.get(id)?.invalidateRecord()
        },
      })
    }
  }

  // future enhancement: render an "empty" view instead of redirecting
  if (data.layoffClaimInformationRequests.length === 0) {
    return <Navigate to={`/home/claims/${id}`} />
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      footerContent={
        <ButtonPrimary
          onClick={handleSubmit}
          data-page-name={pageName}
          data-name="Primary CTA"
          disabled={fulfillInformationRequestsInFlight || uploadsInProgress}
        >
          Submit
        </ButtonPrimary>
      }
    >
      <Headline>Layoff Claim</Headline>
      <IntroBlock>More information needed</IntroBlock>
      <BodyText>
        We need the following additional pieces of information on claim ID:{' '}
        {formatClaimId(id)}
      </BodyText>
      {data.layoffClaimInformationRequests.map((request, index) =>
        request.type === 'FileUpload' ? (
          <div key={request.id}>
            <FileUpload
              title={<StyledIntroBlock>{request.title}</StyledIntroBlock>}
              description={<LegalText>{request.detail}</LegalText>}
              ctaText="Upload"
              // currently information requests can only support 1 file at a time https://quin-inc.monday.com/boards/1528612958/pulses/4427515392/posts/2163327942
              multiple={false}
              items={[values[request.id].fileUpload].filter(isNotNil)}
              onRequestToAdd={createAddFileHandler(
                request.id,
                request.claimId,
                request.title
              )}
              onRequestToRemove={createRemoveHandler(request.id)}
              data-testid={`${testIds.fileUpload}_${index}`}
              error={errors[request.id]}
            />
          </div>
        ) : (
          <div key={request.id}>
            <StyledIntroBlock>{request.title}</StyledIntroBlock>
            <InputTextarea
              name={request.title}
              label={request.detail}
              id={request.id}
              data-testid={`${testIds.note}_${index}`}
              error={errors[request.id]}
              value={values[request.id]?.note}
              onChange={createNoteUpdateHandler(request.id)}
            />
          </div>
        )
      )}
    </LayoutInAppTracked>
  )
}

type InitialValues =
  SubmitAdditionalInformationQuery$data['layoffClaimInformationRequests']

type InformationRequest = {
  layoffclaimInformationRequestId: string
  fileUpload: null | FileEntity
  note: null | string
}

/**
 * Convert array of "informationRequests" to a map of ID:InformationRequest
 */
function initialState(initialValues: InitialValues): {
  values: Record<string, InformationRequest>
  errors: Record<string, string>
} {
  return {
    values: initialValues.reduce(
      (all, informationRequest) =>
        assoc(
          informationRequest.id,
          {
            // This data point is redundant with the key, but simplifies the API call when we serialize values to the expected input shape
            layoffclaimInformationRequestId: informationRequest.id,
            fileUpload: null,
            note: null,
          },
          all
        ),
      {}
    ),
    errors: initialValues.reduce(
      (all, informationRequest) => assoc(informationRequest.id, '', all),
      {}
    ),
  }
}
