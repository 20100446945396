import {
  ActionListItem,
  ActionListLink,
  ActionListList,
  BodyText,
  SubheadingAlt,
  TextLinkInline,
} from '@quil/ui'
import { graphql, useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components'
import { LayoutInAppTracked } from '../../common/analytics'
import { avibraActivationLink } from '../../common/avibraActivationLink'
import GoBack from '../../common/components/GoBack'
import * as testIds from './test-ids'
import { BenefitsOverviewQuery } from './__generated__/BenefitsOverviewQuery.graphql'

const Center = styled(BodyText)`
  text-align: center;
  margin: 0;
  font-size: 1.4em;
`

export default function BenefitsOverview() {
  const pageName = 'Benefits'
  const { vendorBenefits, user } = useLazyLoadQuery<BenefitsOverviewQuery>(
    graphql`
      query BenefitsOverviewQuery {
        user: currentUser {
          id
        }
        vendorBenefits {
          protection {
            name
            displayName
            icon
            description
          }
          counseling {
            name
            displayName
            icon
            description
          }
        }
      }
    `,
    {}
  )

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title="Benefits"
      headerStart={<GoBack />}
    >
      <ActionListList>
        <ActionListItem>
          <ActionListLink
            to="layoff-insurance"
            state={{ from: '/home/benefits' }}
            data-testid={testIds.layoffInsuranceButton}
            data-name="Layoff Insurance"
            data-page-name={pageName}
          >
            <SubheadingAlt>Layoff Insurance</SubheadingAlt>
            Get cash if you become unemployed
          </ActionListLink>
        </ActionListItem>
        {vendorBenefits.counseling.length > 0 && (
          <ActionListItem>
            <ActionListLink
              to="counseling"
              state={{ from: '/home/benefits' }}
              data-testid={testIds.counselingButton}
              data-name="Counseling"
              data-page-name={pageName}
            >
              <SubheadingAlt>Counseling</SubheadingAlt>
              Financial, Well-being, and housing counseling
            </ActionListLink>
          </ActionListItem>
        )}
        {vendorBenefits.protection.length > 0 && (
          <ActionListItem>
            <ActionListLink
              to="protection"
              state={{ from: '/home/benefits' }}
              data-testid={testIds.protectionButton}
              data-name="Protection"
              data-page-name={pageName}
            >
              <SubheadingAlt>Protection</SubheadingAlt>
              Products that protect you and your family
            </ActionListLink>
          </ActionListItem>
        )}
      </ActionListList>

      <Center>Looking for even more benefits?</Center>
      <Center>
        <TextLinkInline
          href={avibraActivationLink(user.id)}
          data-page-name={pageName}
          data-name="View Avibra benefits"
        >
          View all Avibra benefits here
        </TextLinkInline>
      </Center>
    </LayoutInAppTracked>
  )
}
