import {
  ButtonPrimary,
  InlineButtonContainer,
  ProcessingOverlay,
  useShowToast,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import { useNavigate } from '@web/common/react-router-wrappers'
import { useState } from 'react'
import { NewClaimPageProps } from '../NewClaimPageProps'
import NewClaimProgressTracker from '../NewClaimProgressTracker'
import useNewClaimFlow from '../useNewClaimFlow'

function JobChange(props: NewClaimPageProps) {
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const showToast = useShowToast()
  const [data, commitMutation, mutationInFlight] = useNewClaimFlow('Job Change')

  function createAnswerHandler(changedJobsRecently: boolean) {
    function handleError(error) {
      console.error(error)
      setSubmitting(false)
      showToast({
        severity: 'Error',
        headline: 'Something went wrong',
        message: 'Please try again',
        linkText: 'Retry',
        onClick: handleSubmit,
      })
    }

    async function handleSubmit() {
      if (!mutationInFlight) {
        setSubmitting(true)

        commitMutation({
          variables: {
            input: {
              id: data.pendingLayoffClaim?.id,
              changedJobsRecently,
            },
          },
          onCompleted({ upsertLayoffClaim }) {
            switch (upsertLayoffClaim.__typename) {
              case 'LayoffClaim':
                navigate(props.nextPath)
                break
              // ValidationErrors are not actionable on a boolean - just show error
              case 'ValidationErrors':
                handleError({
                  upsertLayoffClaim,
                  message: 'Received ValidationErrors on a boolean input',
                })
                break
              default:
                handleError({
                  message: 'Unknown response type',
                  upsertLayoffClaim,
                })
            }
          },
          onError(error) {
            handleError(error)
          },
        })
      }
    }

    return handleSubmit
  }

  return (
    <LayoutInAppTracked
      pageName="New Claim: Job Change"
      title={data.content.newClaimPage.heading}
      headerStart={<GoBack to={props.previousPath} />}
      headerEnd={<NewClaimProgressTracker step={props.step} />}
      footerContent={
        <InlineButtonContainer>
          <ButtonPrimary
            disabled={submitting}
            data-name="Yes"
            onClick={createAnswerHandler(true)}
          >
            {data.content.newClaimPage.yesButton ?? 'Yes'}
          </ButtonPrimary>
          <ButtonPrimary
            disabled={submitting}
            data-name="No"
            onClick={createAnswerHandler(false)}
          >
            {data.content.newClaimPage.noButton ?? 'No'}
          </ButtonPrimary>
        </InlineButtonContainer>
      }
    >
      <RichText content={data.content.newClaimPage.body.raw} />
      <ProcessingOverlay processing={submitting} />
    </LayoutInAppTracked>
  )
}

export default JobChange
