/**
 * @generated SignedSource<<6c6c85eda3ad00269ba62bb1137a7310>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReviewSubmitLayoffClaimMutation$variables = {
  id: string;
};
export type ReviewSubmitLayoffClaimMutation$data = {
  readonly submitLayoffClaim: {
    readonly __typename: "LayoffClaim";
    readonly id: string | null;
  };
};
export type ReviewSubmitLayoffClaimMutation = {
  response: ReviewSubmitLayoffClaimMutation$data;
  variables: ReviewSubmitLayoffClaimMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "LayoffClaim",
    "kind": "LinkedField",
    "name": "submitLayoffClaim",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewSubmitLayoffClaimMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReviewSubmitLayoffClaimMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ed43aa2070d93e7c0c2d38941ee2d2e9",
    "id": null,
    "metadata": {},
    "name": "ReviewSubmitLayoffClaimMutation",
    "operationKind": "mutation",
    "text": "mutation ReviewSubmitLayoffClaimMutation(\n  $id: ID!\n) {\n  submitLayoffClaim(id: $id) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "178943e6f9c08ac2c0bc6ad36352e46f";

export default node;
