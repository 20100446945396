import { AnimatePresence, motion, useAnimationControls } from 'framer-motion'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { fromTheme, useQuilTheme } from '../../theme'
import FileState from './FileState'

const Container = styled.div`
  width: 40px;
  position: relative;
  flex-shrink: 0;

  .x {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${fromTheme(({ colors }) => colors.foregroundMuted.raw)};
    cursor: pointer;
  }

  .red-circle {
    position: absolute;
    top: 50%;
    left: 50%;
  }
`

const strokeVariants = {
  idle: {
    pathLength: 0,
    opacity: 0,
    transition: {
      pathLength: { duration: 2 },
      opacity: { duration: 0.5, delay: 1 },
    },
  },
  transitionToPending: {
    pathLength: 0.25,
    opacity: 1,
    transition: {
      pathLength: { duration: 0.5, ease: 'easeIn' },
    },
  },
  pending: {
    pathLength: [0.25, 0.6, 0.25],
    opacity: 1,
    transition: {
      pathLength: {
        duration: 2,
        repeat: Infinity,
      },
    },
  },
  complete: {
    pathLength: 1,
    opacity: 0,
    transition: {
      pathLength: { duration: 1 },
      // wait for circle complete before fading out
      opacity: { duration: 0.5, delay: 1.5 },
    },
  },
}

type RedCircleProps = {
  visible: boolean
}

// The background circle that appears when the file is in an error state
function RedCircle({ visible }: RedCircleProps) {
  const theme = useQuilTheme()

  return (
    <AnimatePresence>
      {visible && (
        <motion.svg
          className="red-circle"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1 }}
          style={{
            x: '-50%',
            y: '-50%',
          }}
          transition={{
            duration: 0.2,
            // wait for spinner to fade out before showing circle
            delay: 1.2,
          }}
        >
          <circle
            cx="17"
            cy="17"
            r="17"
            fill={theme.colors.foregroundDanger.raw}
          />
        </motion.svg>
      )}
    </AnimatePresence>
  )
}

function X({
  visible,
  state,
  onRequestToRemove: onRequestRemove,
}: {
  visible: boolean
  state: FileState
  onRequestToRemove: () => void
}) {
  const theme = useQuilTheme()

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {visible && (
        <motion.svg
          onClick={onRequestRemove}
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          className="x"
          variants={{
            default: {
              color: theme.colors.foregroundMuted.raw,
            },
            error: {
              color: theme.colors.background.raw,
            },
          }}
          transition={{
            duration: 0.2,
            // wait for spinner to fade out before updating x color
            delay: 1.2,
          }}
          animate={state === 'error' ? 'error' : 'default'}
        >
          <rect
            y="11"
            width="16"
            height="2"
            rx="1"
            transform="rotate(-45 0 11)"
          />
          <rect
            x="1.5"
            y="0"
            width="16"
            height="2"
            rx="1"
            transform="rotate(45 1.4 0)"
          />
        </motion.svg>
      )}
    </>
  )
}

const rotateContinuously = {
  rotate: [0, 360],
  transition: {
    rotate: {
      duration: 0.8,
      repeat: Infinity,
      ease: 'linear',
    },
  },
}

type Props = {
  state: FileState
  onRequestToRemove: () => void
}
export default function FileUploadStateIndicator({
  state,
  onRequestToRemove,
}: Props) {
  const pathLengthControls = useAnimationControls()
  const theme = useQuilTheme()
  const mounted = useRef(false)

  useEffect(() => {
    async function handleStateChange() {
      switch (state) {
        case 'pending':
          // on first mount, start from idle so that the spinner animates in
          if (!mounted.current) {
            await pathLengthControls.start('idle')
          }
          await pathLengthControls.start('transitionToPending')
          pathLengthControls.start('pending')
          break
        default:
          // otherwise, we just want to transition to the new state. The spinner will hide itself for the error case which is the same as the idle state.
          pathLengthControls.start(state === 'error' ? 'idle' : state)
      }
    }

    handleStateChange()
    mounted.current = true
  }, [state, pathLengthControls])

  return (
    <Container>
      <motion.svg
        width="100%"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        animate={rotateContinuously}
      >
        <motion.circle
          cx="20"
          cy="20"
          r="18"
          stroke={theme.colors.foregroundHighlight.raw}
          strokeWidth="4"
          strokeLinecap="round"
          variants={strokeVariants}
          animate={pathLengthControls}
          style={{
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
            fill: 'transparent',
          }}
        />
      </motion.svg>
      <RedCircle visible={state === 'error'} />
      <X
        visible={state !== 'idle'}
        state={state}
        onRequestToRemove={onRequestToRemove}
      />
    </Container>
  )
}
