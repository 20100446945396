import { useEffect, useState } from 'react'
import { graphql, useMutation } from 'react-relay'
import { usePlaidLinkTokenMutation } from './__generated__/usePlaidLinkTokenMutation.graphql'

export default function usePlaidLinkToken() {
  const [linkToken, setLinkToken] = useState<string | null>(null)
  const [linkTokenError, setLinkTokenError] = useState<Error | null>(null)
  const [createLinkToken, createLinkTokenInFlight] =
    useMutation<usePlaidLinkTokenMutation>(graphql`
      mutation usePlaidLinkTokenMutation {
        createLinkToken {
          linkToken
        }
      }
    `)

  useEffect(() => {
    if (!linkToken && !createLinkTokenInFlight && !linkTokenError) {
      createLinkToken({
        variables: {},
        onCompleted(response) {
          setLinkToken(response.createLinkToken?.linkToken ?? null)
        },
        onError(error) {
          setLinkTokenError(error)
        },
      })
    }
  }, [
    linkToken,
    createLinkTokenInFlight,
    createLinkToken,
    linkTokenError,
    setLinkTokenError,
  ])

  return [linkToken, createLinkTokenInFlight, linkTokenError] as const
}
