/**
 * @generated SignedSource<<661981339d9dfb092dfc9b21b240c981>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type W2Query$variables = {};
export type W2Query$data = {
  readonly content: {
    readonly onboardingPage: {
      readonly " $fragmentSpreads": FragmentRefs<"useEmploymentQuestionCopy_onboardingPage">;
    } | null;
  };
};
export type W2Query = {
  response: W2Query$data;
  variables: W2Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "Employment W2"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "W2Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useEmploymentQuestionCopy_onboardingPage"
              }
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Employment W2\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "W2Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "heading",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Cms_RichText",
                "kind": "LinkedField",
                "name": "body",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "raw",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subheading",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mainCtaText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondaryCtaText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Employment W2\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d70902a3c2672fa7589f35459c789556",
    "id": null,
    "metadata": {},
    "name": "W2Query",
    "operationKind": "query",
    "text": "query W2Query {\n  content {\n    onboardingPage(where: {pageName: \"Employment W2\"}) {\n      ...useEmploymentQuestionCopy_onboardingPage\n      id\n    }\n  }\n}\n\nfragment useEmploymentQuestionCopy_onboardingPage on Cms_OnboardingPage {\n  heading\n  body {\n    raw\n  }\n  subheading\n  mainCtaText\n  secondaryCtaText\n}\n"
  }
};
})();

(node as any).hash = "7588de93814118a75db56dcda35fdaec";

export default node;
