import {
  ActionListItem,
  ActionListList,
  fromTheme,
  SubheadingAlt,
} from '@quil/ui'
import { kebabCase } from '@quil/utils'
import type { MouseEvent } from 'react'
import { fetchQuery, useLazyLoadQuery, useRelayEnvironment } from 'react-relay'
import { graphql } from 'relay-runtime'
import styled from 'styled-components'
import { LayoutInAppTracked } from '../../common/analytics'
import GoBack from '../../common/components/GoBack'
import { useNavigate } from '../../common/react-router-wrappers'
import { LegalDocsAgreementQuery } from './__generated__/LegalDocsAgreementQuery.graphql'
import {
  DisclosureType,
  LegalDocsQuery,
  LegalDocsQuery$data,
} from './__generated__/LegalDocsQuery.graphql'

type RealDisclosureType = Exclude<DisclosureType, '%future added value'>

const DisplayName: Partial<Record<RealDisclosureType, string>> = {
  ElectronicCommunications: 'Electronic Communications',
  BenefitEligibility: 'Benefit Eligibility',
  BenefitsDisclosure: 'Benefits Discloure',
  MobileNetworkConsent: 'Mobile Network Consent',
  PatriotAct: 'Patriot Act',
  PrivacyPolicy: 'Privacy Policy',
  TermsOfService: 'Quil Terms Of Service',
  PlateauPolicyCertificate: 'Insurance Policy Certificate',
  PlateauApplication: 'Insurance Application',
}

const StyledActionListItem = styled(ActionListItem)`
  padding: 1.5rem 0;
`

const DocumentLink = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
  text-decoration: none;
  color: ${fromTheme((theme) => theme.colors.textLink.raw)};
  display: block;
`

export function LegalDocs() {
  const pageName = 'Documents'
  const environment = useRelayEnvironment()
  const navigate = useNavigate()
  const data = useLazyLoadQuery<LegalDocsQuery>(
    graphql`
      query LegalDocsQuery {
        content {
          profilePage(where: { name: "Legal" }) {
            header
            body {
              raw
            }
          }
        }
        agreements {
          termsOfService {
            id
            url
            type
          }
          benefitEligibility {
            id
            url
            type
          }
          benefitsDisclosure {
            id
            url
            type
          }
          privacyPolicy {
            id
            url
            type
          }
          patriotAct {
            id
            url
            type
          }
          plateauApplication {
            id
            url
            type
          }
          plateauPolicyCertificate {
            id
            url
            type
          }
          electronicCommunications {
            id
            url
            type
          }
          mobileNetworkConsent {
            id
            url
            type
          }
        }
      }
    `,
    {}
  )

  function handleClick(
    event: MouseEvent,
    agreement: LegalDocsQuery$data['agreements'][keyof LegalDocsQuery$data['agreements']]
  ) {
    fetchQuery<LegalDocsAgreementQuery>(
      environment,
      graphql`
        query LegalDocsAgreementQuery($type: DisclosureType!) {
          agreement(type: $type) {
            id
            html
            url
          }
        }
      `,
      { type: agreement.type }
    ).subscribe({
      next(data) {
        if (data.agreement.html) {
          navigate(`/home/settings/legal/${kebabCase(agreement.type)}`)
        } else {
          // respect "new tab" behavior
          if (event.metaKey || event.ctrlKey) {
            window.open(agreement.url)
          } else {
            window.location.assign(agreement.url)
          }
        }
      },
      error(error) {
        console.error({
          error,
          agreement,
          message: 'Failed to fetch agreement',
        })
      },
    })
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title={data.content.profilePage.header}
      headerStart={<GoBack />}
    >
      <ActionListList>
        {Object.values(data.agreements)
          .filter(Boolean)
          .map((agreement) => {
            return (
              <StyledActionListItem key={agreement.type}>
                <DocumentLink
                  onClick={(event) => handleClick(event, agreement)}
                >
                  <SubheadingAlt noMargin>
                    {DisplayName[agreement.type]}
                  </SubheadingAlt>
                </DocumentLink>
              </StyledActionListItem>
            )
          })}
      </ActionListList>
    </LayoutInAppTracked>
  )
}
