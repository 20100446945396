import React from 'react'
import { SyncLoader } from 'react-spinners'
import styled from 'styled-components'
import { useQuilTheme } from '../../theme'

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

type Props = {
  'data-testid'?: string
}

const SpinnerFullScreen: React.FC<Props> = (props) => {
  const { colors } = useQuilTheme()

  return (
    <Container data-testid={props['data-testid']}>
      <SyncLoader color={colors.foregroundPrimary.raw} />
    </Container>
  )
}

export default SpinnerFullScreen
