import styled from 'styled-components'

// TODO: we will probably want some
// sort of framework for vertical flow
// in @quil/ui. Leaving this in the app
// until that is nailed down.
const InputRow = styled.div`
  margin: 0 0 2.4rem;
  display: flex;
  gap: 1.2rem;

  & > * {
    flex-grow: 1;
    margin: 0;
    min-width: 0;
    width: 50%;
  }
`
export default InputRow
