import { AppHeader, AppHeaderSegment, fromTheme, Logo } from '@quil/ui'
import { Suspense } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components'
import { AnalyticsPageCall } from '../common/analytics/AnalyticsPageName'
import { Alerts } from '../common/components/Alerts'
import { HeroImage } from '../common/components/HeroImage'
import { MembershipCard } from '../common/components/MembershipCard'
import { PartnerPlacements } from '../common/components/PartnerPlacements'
import { RecentActivity } from '../common/components/RecentActivity'
import { ZendeskChatButton } from '../common/components/ZendeskChatButton'
import { useDocumentTitle } from '../common/useDocumentTitle'
import { HomeQuery } from './__generated__/HomeQuery.graphql'

const HomeContentContainer = styled.div`
  max-width: 60rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  /* bottom padding for fixed nav clearance */
  padding: 0 ${fromTheme(({ spacing }) => spacing.horizontalPadding)}
    ${fromTheme(({ spacing }) => spacing.navClearance)};

  /* ≈ 780px @ base font size of 16px */
  @media (min-width: 48rem) {
    max-width: 78rem;
    align-items: start;
    justify-content: center;
    flex-direction: row;
    gap: 2.4rem;
  }
`

const Column = styled.div`
  flex-grow: 1;

  align-self: stretch;

  /* ≈ 780px @ base font size of 16px */
  @media (min-width: 48rem) {
    align-items: start;
    justify-content: center;
    flex-direction: row;
    gap: 2.4rem;
    width: 50%;
  }
`
const query = graphql`
  query HomeQuery {
    currentUser {
      id
      eligibilityCompletionPercentage
    }

    ...Alerts_query
    ...HeroImage_query
    ...MembershipCard_query
    ...RecentActivity_query
    ...PartnerPlacements_query
  }
`

function Home() {
  useDocumentTitle('Home')
  const pageName = 'Home'
  const data = useLazyLoadQuery<HomeQuery>(query, {})

  return (
    <>
      <AnalyticsPageCall pageName={pageName} />
      <HeroImage query={data}>
        <AppHeader>
          <AppHeaderSegment />
          <AppHeaderSegment>
            <Logo />
          </AppHeaderSegment>
          <AppHeaderSegment>
            <ZendeskChatButton />
          </AppHeaderSegment>
        </AppHeader>
      </HeroImage>
      <HomeContentContainer>
        <Column>
          {/* No Suspense fallback needed, alerts will either render after being fetched, or if query returns empty array then there is nothing to do.
              This only affects subsequent mounts: initial page load will always suspend while the first HomeQuery executes */}
          <Suspense>
            <Alerts query={data} />
          </Suspense>
          <MembershipCard query={data} />
        </Column>
        <Column>
          <RecentActivity query={data} pageName={pageName} />
          <PartnerPlacements query={data} pageName={pageName} />
        </Column>
      </HomeContentContainer>
    </>
  )
}

export default Home
