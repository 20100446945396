/**
 * @generated SignedSource<<7e7d1cba57e59e00186a13cefe9d376f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAddressInput = {
  addressLineOne?: string | null;
  addressLineTwo?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
};
export type UpdateAddressMutation$variables = {
  data: UpdateAddressInput;
};
export type UpdateAddressMutation$data = {
  readonly updateAddress: {
    readonly __typename: "User";
    readonly addressLineOne: string | null;
    readonly addressLineTwo: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly zip: string | null;
  } | {
    readonly __typename: "ValidationErrors";
    readonly messages: ReadonlyArray<{
      readonly field: string;
      readonly help: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type UpdateAddressMutation = {
  response: UpdateAddressMutation$data;
  variables: UpdateAddressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressLineOne",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressLineTwo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ValidationError",
      "kind": "LinkedField",
      "name": "messages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "field",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "help",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ValidationErrors",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateAddress",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateAddress",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7d621563a63978ab6f02c83bea97edc",
    "id": null,
    "metadata": {},
    "name": "UpdateAddressMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAddressMutation(\n  $data: UpdateAddressInput!\n) {\n  updateAddress(data: $data) {\n    __typename\n    ... on User {\n      addressLineOne\n      addressLineTwo\n      city\n      state\n      zip\n    }\n    ... on ValidationErrors {\n      messages {\n        field\n        help\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea378d31d7efcd4ab70aa5f55daef217";

export default node;
