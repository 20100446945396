// Wrapping inputs in a `Form` allows the data to be submitted on `enter`
// This is React, so we always preventDefault() unless you explicitly disable it
import React, { FormEvent } from 'react'

type Props = {
  doNotPreventDefault?: boolean
  onSubmit: () => void
  children: React.ReactNode
  className?: string
}

const Form: React.FC<Props> = (props) => {
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    if (!props.doNotPreventDefault) {
      e.preventDefault()
    }
    props.onSubmit()
  }

  return (
    <form onSubmit={handleSubmit} className={props.className}>
      {/* required to allow submitting form on 'enter' */}
      <input type="submit" style={{ display: 'none' }} />
      {props.children}
    </form>
  )
}

export default Form
