import { Ulid } from 'id128'

// LD requires us to generate and reuse our own anonymous
// guest IDs instead of the anonymous ones they generate
export function getOrCreateGuestID() {
  const localStorageKey = 'guest_id'
  const existingValue = localStorage.getItem(localStorageKey)

  if (existingValue) {
    return existingValue
  } else {
    const newId = Ulid.generate().toCanonical()
    localStorage.setItem(localStorageKey, newId)
    return newId
  }
}
