import {
  BodyText,
  ButtonPrimary,
  fromTheme,
  Headline,
  TextLinkPrimary,
  useAlternateTheme,
  useQuilTheme,
  useShowToast,
} from '@quil/ui'
import { motion } from 'framer-motion'
import { graphql, useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components'
import { LayoutSignupTracked } from '../common/analytics'
import { useLocation, useNavigate } from '../common/react-router-wrappers'
import { useDocumentTitle } from '../common/useDocumentTitle'
import { WelcomeQuery } from './__generated__/WelcomeQuery.graphql'
import * as testIds from './test-ids'

const Page = styled.div`
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  height: 100%;

  ${TextLinkPrimary}, ${BodyText} {
    color: ${fromTheme((theme) => theme.colors.textLink.raw)};
  }

  ${TextLinkPrimary}:hover,
  ${TextLinkPrimary}:focus,
  ${TextLinkPrimary}:focus-visible {
    box-shadow: 0 0 0 2px ${fromTheme(({ colors }) => colors.background.raw)},
      0 0 0 4px ${fromTheme(({ colors }) => colors.textLink.raw)};
  }
`

const Body = styled.div`
  align-items: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Container = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const WelcomeSubheading = styled(Headline)`
  font-size: 3.2;
  font-family: Manrope;
  margin-bottom: 3rem;
`

const pageName = 'Welcome'

function Welcome() {
  useDocumentTitle(pageName)

  const data = useLazyLoadQuery<WelcomeQuery>(
    graphql`
      query WelcomeQuery {
        content {
          onboardingPage(where: { pageName: "Welcome" }) {
            heading
            subheading
          }
        }
      }
    `,
    {}
  )

  const { colors } = useQuilTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const showToast = useShowToast()

  function handleSignupClick() {
    if (process.env.REACT_APP_KILLSWITCH_ENABLED === 'true') {
      showToast({
        severity: 'Error',
        headline: 'Sorry, we are not accepting new signups at the moment.',
        message: 'Please contact us at support@getquil.com with questions.',
      })
    } else {
      const queryParams = new URLSearchParams(location.search)
      if (
        queryParams.get('quilPromotionId') === 'usalliance' ||
        queryParams.get('promotionId') === 'usalliance' ||
        queryParams.get('promotion_id') === 'usalliance'
      ) {
        // if marketing campaigns hit this page we want location.search to be persisted until /signup/the-basics
        navigate(`/signup${location.search}`)
      } else {
        showToast({
          severity: 'Error',
          headline: 'Sorry, we are not accepting new signups at the moment.',
          message: 'Please contact us at support@getquil.com with questions.',
        })
      }
    }
  }

  function handleLoginClick() {
    navigate(`/login${location.search}`, {
      state: {
        redirectPath: location.state?.redirectPath,
      },
    })
  }

  useAlternateTheme('quilThemeVivid')

  return (
    <Page>
      <LayoutSignupTracked
        pageName={pageName}
        headerEnd={
          <TextLinkPrimary
            data-page-name={pageName}
            data-name="Secondary CTA"
            onClick={handleLoginClick}
            data-testid={testIds.loginCta}
          >
            Log in
          </TextLinkPrimary>
        }
        footerContent={
          <ButtonPrimary
            data-page-name={pageName}
            data-name="Primary CTA"
            onClick={handleSignupClick}
            data-testid={testIds.signUpCta}
          >
            Sign up
          </ButtonPrimary>
        }
      >
        <Container data-testid={testIds.container}>
          <Body>
            <svg
              width="105"
              height="132"
              viewBox="0 0 105 132"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                marginBottom: 8.5,
                fill: colors.textPrimary.raw,
              }}
            >
              <path d="M55.0471 15.7277C64.0729 15.7664 72.718 19.369 79.1003 25.7513C85.4826 32.1335 89.0852 40.7787 89.1238 49.8045V81.26C89.0852 90.2858 85.4826 98.9309 79.1003 105.313C72.718 111.695 64.0729 115.298 55.0471 115.337H49.8045C40.7787 115.298 32.1335 111.695 25.7513 105.313C19.369 98.9309 15.7664 90.2858 15.7277 81.26V49.8045C15.7664 40.7787 19.369 32.1335 25.7513 25.7513C32.1335 19.369 40.7787 15.7664 49.8045 15.7277H55.0471ZM55.0471 0H49.8045C22.412 0 0 22.412 0 49.8045V81.26C0 108.652 22.412 131.064 49.8045 131.064H55.0471C82.4395 131.064 104.852 108.652 104.852 81.26V49.8045C104.852 22.412 82.4395 0 55.0471 0Z" />
            </svg>

            <svg
              width="64"
              height="17"
              viewBox="0 0 64 17"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: 'translate(50%)',
                fill: colors.textPrimary.raw,
              }}
            >
              <path d="M59.284 16.3115H1.09134C0.952294 16.3115 0.818954 16.2563 0.720637 16.1579C0.622319 16.0596 0.567078 15.9263 0.567078 15.7872V4.77783C0.567078 3.6655 1.00896 2.59872 1.7955 1.81218C2.58204 1.02564 3.64881 0.583771 4.76114 0.583771H62.9538C63.0928 0.583771 63.2262 0.638996 63.3245 0.737314C63.4228 0.835631 63.478 0.968987 63.478 1.10803V12.1174C63.478 13.2298 63.0361 14.2965 62.2496 15.0831C61.4631 15.8696 60.3963 16.3115 59.284 16.3115Z" />
            </svg>
          </Body>
          <Headline as={motion.div} style={{ marginTop: '2.4rem' }}>
            {data.content.onboardingPage.heading}
          </Headline>
          <WelcomeSubheading>
            {data.content.onboardingPage.subheading}
          </WelcomeSubheading>
        </Container>
      </LayoutSignupTracked>
    </Page>
  )
}

export default Welcome
