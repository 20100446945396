import { isNil } from 'ramda'

export function formatName(input: string): string {
  if (!input) {
    return input
  }
  return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function pascalToTitleCase(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1').trim()
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function formatPhone(phone: string) {
  const match = phone
    ?.replace(/^\+1/, '')
    ?.replace(/\D/g, '')
    .match(/^(\d{3})(\d{3})(\d+)$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return phone
}

export function kebabCase(input: string) {
  return input
    ?.replace(/\s|_/g, '-')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .toLowerCase()
}

export function booleanToHuman(value: boolean) {
  if (isNil(value)) return ''
  return value ? 'Yes' : 'No'
}
