import { format } from 'date-fns'
import styled from 'styled-components'
import { Q } from '../../icons'
import { fromTheme, hsla } from '../../theme'

export type MembershipCardStatus = 'Active' | 'Payment Failed'

type Props = {
  memberFullName: string
  coverage: string
  memberSince: Date
  membershipStatus: MembershipCardStatus
  className?: string
  closingOn?: string
}

const Container = styled.div`
  color: ${fromTheme(({ colors }) => colors.background.raw)};
  background-color: ${fromTheme(({ colors }) => colors.foregroundPrimary.raw)};
  padding: 16px;
  border-radius: 20px;
  font-size: 1.6rem;
  font-weight: 100;
  box-shadow: 0 0 10px ${fromTheme(({ colors }) => hsla(colors.shadow, 0.1))};
`

const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1.6rem;

  @media (min-width: 380px) {
    gap: 0;
    flex-direction: row;
  }
`

const Column = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &:last-child {
    @media (min-width: 380px) {
      text-align: right;
    }
  }
`

const Label = styled.div`
  font-weight: 700;
`

const StatusIndicator = styled.span<{ status: MembershipCardStatus }>`
  display: inline-block;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: ${fromTheme(({ colors }, { status }) =>
    status === 'Active'
      ? colors.foregroundHighlight.raw
      : colors.foregroundDanger.raw
  )};
`

type LabelValueProps = {
  label: string
  value: string | JSX.Element
}

function LabelValue({ label, value }: LabelValueProps) {
  return (
    <div>
      <Label>{label}</Label>
      <div>{value}</div>
    </div>
  )
}

export function MembershipCard(props: Props) {
  return (
    <Container className={props.className}>
      <Q />
      <Row>
        <Column>
          <LabelValue label="Member Name" value={props.memberFullName} />
          <LabelValue label="Coverage Amount" value={props.coverage} />
          {props.closingOn ? (
            <LabelValue label="Closing On" value={props.closingOn} />
          ) : (
            <LabelValue
              label="Member Since"
              value={format(props.memberSince, 'MMMM do, yyyy')}
            />
          )}
        </Column>
        <Column>
          <LabelValue
            label="Member Status"
            value={
              <>
                <StatusIndicator status={props.membershipStatus} />
                {props.membershipStatus}
              </>
            }
          />
        </Column>
      </Row>
    </Container>
  )
}
