import styled from 'styled-components'
import { fromTheme } from '../../theme'
import ButtonPrimary from '../ButtonPrimary'

const ButtonSecondary = styled(ButtonPrimary)`
  background-color: ${fromTheme((theme) => theme.colors.background.raw)};
  color: ${fromTheme((theme) => theme.colors.foregroundPrimary.raw)};
  border: 1px solid ${fromTheme((theme) => theme.colors.foregroundPrimary.raw)};
`
export default ButtonSecondary
