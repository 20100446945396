/**
 * @generated SignedSource<<15cacafa650ac87f94a60183356b5cc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LayoffClaimInformationRequestStatus = "Fulfilled" | "Open" | "%future added value";
export type LayoffClaimInformationRequestType = "FileUpload" | "Note" | "%future added value";
export type LayoffClaimReason = "BusinessShutDown" | "CovidLayoffs" | "LackOfWork" | "Other" | "StrikeOrLockout" | "%future added value";
export type LayoffClaimStatus = "Approved" | "Denied" | "InReview" | "Pending" | "%future added value";
export type DetailQuery$variables = {
  id: string;
};
export type DetailQuery$data = {
  readonly changedJobsRecently: {
    readonly newClaimPage: {
      readonly heading: string;
    } | null;
  };
  readonly layoffClaim: {
    readonly additionalInfo: string | null;
    readonly changedJobsRecently: boolean | null;
    readonly employerEmail: string | null;
    readonly employerName: string | null;
    readonly employerPhone: string | null;
    readonly employmentEndDate: string | null;
    readonly employmentStartDate: string | null;
    readonly fileUploads: ReadonlyArray<{
      readonly extension: string;
      readonly id: string | null;
      readonly originalFileName: string;
      readonly size: number;
      readonly type: string;
      readonly uri: string;
    } | null>;
    readonly id: string | null;
    readonly informationRequests: ReadonlyArray<{
      readonly id: string | null;
      readonly note: string | null;
      readonly requestedAt: {
        readonly formatted: string;
      };
      readonly status: LayoffClaimInformationRequestStatus;
      readonly title: string;
      readonly type: LayoffClaimInformationRequestType;
    }>;
    readonly minimumWeeklyHours: boolean | null;
    readonly occupation: string | null;
    readonly payoutAccount: {
      readonly accountNumberMask: string;
      readonly bankName: string | null;
    } | null;
    readonly receivingStateBenefits: boolean | null;
    readonly returnedToWorkAtDate: string | null;
    readonly status: LayoffClaimStatus;
    readonly submittedAt: {
      readonly formatted: string;
    } | null;
    readonly terminatedWithoutCause: boolean | null;
    readonly terminationReason: LayoffClaimReason | null;
  } | null;
  readonly minimumWeeklyHours: {
    readonly newClaimPage: {
      readonly heading: string;
    } | null;
  };
  readonly receivingStateBenefits: {
    readonly newClaimPage: {
      readonly heading: string;
    } | null;
  };
  readonly terminatedWithoutCause: {
    readonly newClaimPage: {
      readonly heading: string;
    } | null;
  };
};
export type DetailQuery = {
  response: DetailQuery$data;
  variables: DetailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalInfo",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "changedJobsRecently",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerEmail",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerPhone",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentEndDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentStartDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumWeeklyHours",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "occupation",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivingStateBenefits",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "returnedToWorkAtDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminatedWithoutCause",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminationReason",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "FileUpload",
  "kind": "LinkedField",
  "name": "fileUploads",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extension",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalFileName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uri",
      "storageKey": null
    },
    (v17/*: any*/)
  ],
  "storageKey": null
},
v19 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "formatted",
    "storageKey": null
  }
],
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Instant",
  "kind": "LinkedField",
  "name": "submittedAt",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumberMask",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "LayoffClaimInformationRequest",
  "kind": "LinkedField",
  "name": "informationRequests",
  "plural": true,
  "selections": [
    (v17/*: any*/),
    (v2/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instant",
      "kind": "LinkedField",
      "name": "requestedAt",
      "plural": false,
      "selections": (v19/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Laid Off"
    }
  }
],
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v26 = [
  (v25/*: any*/)
],
v27 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Job Change"
    }
  }
],
v28 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Employment Type"
    }
  }
],
v29 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "State Unemployment"
    }
  }
],
v30 = [
  (v25/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "layoffClaim",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v18/*: any*/),
          (v20/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v21/*: any*/),
              (v22/*: any*/)
            ],
            "storageKey": null
          },
          (v23/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "terminatedWithoutCause",
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v24/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": (v26/*: any*/),
            "storageKey": "newClaimPage(where:{\"name\":\"Laid Off\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "changedJobsRecently",
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v27/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": (v26/*: any*/),
            "storageKey": "newClaimPage(where:{\"name\":\"Job Change\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "minimumWeeklyHours",
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v28/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": (v26/*: any*/),
            "storageKey": "newClaimPage(where:{\"name\":\"Employment Type\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "receivingStateBenefits",
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v29/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": (v26/*: any*/),
            "storageKey": "newClaimPage(where:{\"name\":\"State Unemployment\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "layoffClaim",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v18/*: any*/),
          (v20/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v21/*: any*/),
              (v22/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v23/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "terminatedWithoutCause",
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v24/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": (v30/*: any*/),
            "storageKey": "newClaimPage(where:{\"name\":\"Laid Off\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "changedJobsRecently",
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v27/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": (v30/*: any*/),
            "storageKey": "newClaimPage(where:{\"name\":\"Job Change\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "minimumWeeklyHours",
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v28/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": (v30/*: any*/),
            "storageKey": "newClaimPage(where:{\"name\":\"Employment Type\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "receivingStateBenefits",
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v29/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": (v30/*: any*/),
            "storageKey": "newClaimPage(where:{\"name\":\"State Unemployment\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "614aaadcd4c11178fe29a0b865dfd1b6",
    "id": null,
    "metadata": {},
    "name": "DetailQuery",
    "operationKind": "query",
    "text": "query DetailQuery(\n  $id: ID!\n) {\n  layoffClaim(id: $id) {\n    id\n    additionalInfo\n    changedJobsRecently\n    employerEmail\n    employerName\n    employerPhone\n    employmentEndDate\n    employmentStartDate\n    minimumWeeklyHours\n    occupation\n    receivingStateBenefits\n    returnedToWorkAtDate\n    status\n    terminatedWithoutCause\n    terminationReason\n    fileUploads {\n      id\n      size\n      extension\n      originalFileName\n      uri\n      type\n    }\n    submittedAt {\n      formatted\n    }\n    payoutAccount {\n      accountNumberMask\n      bankName\n      id\n    }\n    informationRequests {\n      type\n      id\n      status\n      title\n      note\n      requestedAt {\n        formatted\n      }\n    }\n  }\n  terminatedWithoutCause: content {\n    newClaimPage(where: {name: \"Laid Off\"}) {\n      heading\n      id\n    }\n  }\n  changedJobsRecently: content {\n    newClaimPage(where: {name: \"Job Change\"}) {\n      heading\n      id\n    }\n  }\n  minimumWeeklyHours: content {\n    newClaimPage(where: {name: \"Employment Type\"}) {\n      heading\n      id\n    }\n  }\n  receivingStateBenefits: content {\n    newClaimPage(where: {name: \"State Unemployment\"}) {\n      heading\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1bb318eda1c39abcdbef17e7acc0a83";

export default node;
