import { ButtonPrimary } from '@quil/ui'
import { LayoutSignupTracked } from '@web/common/analytics'
import { graphql, useLazyLoadQuery } from 'react-relay'
import RichText from '../../../common/components/RichText'
import { Link } from '../../../common/react-router-wrappers'
import { SignupPageProps } from '../../SignupPageProps'
import SignupProgressTracker from '../../SignupProgressTracker'
import { CustomizeIntroQuery } from './__generated__/CustomizeIntroQuery.graphql'

const CustomizeIntro: React.FC<SignupPageProps> = ({ step, nextPath }) => {
  const pageName = 'Customize Intro'
  const data = useLazyLoadQuery<CustomizeIntroQuery>(
    graphql`
      query CustomizeIntroQuery {
        currentUser {
          ...useContinueOnboarding_user
        }
        content {
          onboardingPage(where: { pageName: "Customize Intro" }) {
            heading
            body {
              raw
            }
            mainCtaText
          }
        }
      }
    `,
    {}
  )

  return (
    <LayoutSignupTracked
      pageName="Customize Intro"
      footerContent={
        <ButtonPrimary
          as={Link}
          to={nextPath}
          data-name="Continue"
          data-page-name={pageName}
        >
          {data.content.onboardingPage.mainCtaText}
        </ButtonPrimary>
      }
      title={data.content.onboardingPage.heading}
      headerEnd={<SignupProgressTracker step={step} />}
    >
      <RichText content={data.content.onboardingPage.body.raw} />
    </LayoutSignupTracked>
  )
}

export default CustomizeIntro
