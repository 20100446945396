import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { fromTheme } from '../../theme'
import { Notification, NotificationProps } from '../Notification'

const Animation = styled(motion.div)`
  margin-bottom: 1.6rem;
  &:last-child {
    margin-bottom: 4.2rem;
  }
`

const ToastsContainer = styled.div`
  position: fixed;
  top: 3.2rem;
  width: 100vw;
  max-width: 60rem;
  left: 50vw;
  transform: translateX(-50%);
  padding: 0 ${fromTheme(({ spacing }) => spacing.horizontalPadding)};
  z-index: 1;
`

type ToastProps = Omit<NotificationProps, 'type'>
type ToastStateItem = ToastProps & { key: string; timeoutId: number }

type ContextValue = {
  showToast: (props: ToastProps & { key: string }) => void
}

const ToastsContext = createContext<null | ContextValue>(null)

export const ToastsProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastStateItem[]>([])

  const removeToast = useCallback((key: string) => {
    setToasts((toasts) => toasts.filter((toast) => toast.key !== key))
  }, [])

  const showToast = useCallback((newToast: ToastProps & { key: string }) => {
    const timeout = window.setTimeout(() => {
      removeToast(newToast.key)
    }, 5000)

    const existing = toasts.find((toast) => toast.key === newToast.key)

    if (existing) {
      removeToast(newToast.key)
      clearTimeout(existing.timeoutId)
    }

    window.setTimeout(() => {
      setToasts((toasts) => [...toasts, { ...newToast, timeoutId: timeout }])
    }, 300)
    // TODO: without the `toasts` dependency passed, the `replace` option does not work, and React keys may not be correct (related: https://github.com/helloquin/quil/pull/48/files#r963835518)
    // ignoring dependencies to prevent infinite loop downstream- ignore dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleRetryClick(key: string, onClick: () => void) {
    return () => {
      removeToast(key)

      if (onClick) {
        setTimeout(() => {
          onClick && onClick()
        }, 200)
      }
    }
  }

  return (
    <ToastsContext.Provider value={{ showToast }}>
      {children}
      {createPortal(
        <ToastsContainer>
          <LayoutGroup>
            <AnimatePresence>
              {toasts.map(({ key, ...toastProps }, index) => (
                <Animation
                  layout
                  style={{ zIndex: index }}
                  key={key}
                  initial={{ opacity: 0, y: 8 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 8 }}
                >
                  <Notification
                    {...toastProps}
                    type="toast"
                    onClick={
                      toastProps.onClick
                        ? handleRetryClick(key, toastProps.onClick)
                        : undefined
                    }
                  />
                </Animation>
              ))}
            </AnimatePresence>
          </LayoutGroup>
        </ToastsContainer>,
        document.body
      )}
    </ToastsContext.Provider>
  )
}

export function useShowToast() {
  const keyRef = useRef(1)
  const showToast = useContext(ToastsContext)?.showToast

  return function (options: ToastProps & { replace?: boolean }) {
    if (showToast) {
      showToast({
        ...options,
        key: `${options.replace === false ? keyRef.current++ : keyRef.current}`,
      })
    }
  }
}

export const standardToastHeadlines = {
  genericError: 'Oops! Something went wrong.',
  validationErrors: "We're sorry, something isn't right",
  success: 'Congratulations!',
}

export const standardToastMessages = {
  retry: "Let's try that again.",
  validationErrors: 'Please address the errors above before proceeding.',
} as const
