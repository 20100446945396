import { fromTheme, ToastError } from '@quil/ui'
import React from 'react'
import styled from 'styled-components'

const Icon = styled(ToastError)`
  fill: ${fromTheme((theme) => theme.colors.foregroundPrimary.raw)};
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
`

const IconWrapper = styled.div`
  margin-left: 1.6rem;
  display: flex;
  align-items: center;
`

const Container = styled.div`
  font-weight: 700;
  color: ${fromTheme((theme) => theme.colors.foregroundDanger.raw)};
`

export const UnverifiedLabel: React.FC = () => (
  <Container>
    <IconWrapper>
      <Icon />
      Unverified
    </IconWrapper>
  </Container>
)
