import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { fromTheme, hsla } from '../../theme'

type Props = {
  interval?: number
  className?: string
  children?: JSX.Element | JSX.Element[]
  onSlideChanged?: (index: number) => void
}

const DropShadow = styled.div`
  box-shadow: 0 0 30px ${fromTheme(({ colors }) => hsla(colors.shadow, 0.1))};
  border-radius: 5px;
`

const FlexStretch = styled.div`
  display: flex;
  align-items: stretch;
`

const PaginationContainer = styled.div`
  margin-top: 1.6rem;
  display: flex;
  justify-content: center;
`
const PaginationDot = styled.div<{ active: boolean }>`
  border-radius: 50%;
  margin: 0 6px;
  height: 16px;
  width: 16px;
  background-color: ${fromTheme((theme, props) =>
    props.active
      ? theme.colors.foregroundPrimary.raw
      : theme.colors.background.raw
  )};
  border: 1px solid ${fromTheme((theme) => theme.colors.foregroundPrimary.raw)};
  ${(props) =>
    !props.active &&
    css`
      cursor: pointer;
    `}
`

const Carousel: React.FC<Props> = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    loop: true,
    slideChanged(s) {
      const slide = s.track.details.rel
      setCurrentSlide(slide)

      if (props.onSlideChanged) {
        props.onSlideChanged(slide)
      }
    },
  })

  useEffect(() => {
    if (props.interval) {
      const timeout = setTimeout(() => {
        slider.current?.next()
      }, props.interval)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [props.interval, slider, currentSlide])

  const childrenArray = React.Children.toArray(props.children)

  return (
    <div className={props.className}>
      <DropShadow ref={sliderRef} className="keen-slider">
        {childrenArray.map((child, idx) => (
          <FlexStretch className="keen-slider__slide" key={idx}>
            {child}
          </FlexStretch>
        ))}
      </DropShadow>
      {childrenArray.length > 1 && (
        <PaginationContainer>
          {childrenArray.map((_slide, idx) => (
            <PaginationDot
              key={idx}
              active={idx === currentSlide}
              onClick={() => {
                slider.current?.moveToIdx(idx)
              }}
            />
          ))}
        </PaginationContainer>
      )}
    </div>
  )
}

export default Carousel
