import Documents from './Documents'
import EmploymentInfo from './EmploymentInfo'
import EmploymentType from './EmploymentType'
import JobChange from './JobChange'
import LaidOff from './LaidOff'
import { NewClaimPageProps } from './NewClaimPageProps'
import PayoutAccount from './PayoutAccount'
import PayoutAccountExisting from './PayoutAccountExisting'
import PayoutAccountManual from './PayoutAccountManual'
import Review from './Review'
import StateUnemployment from './StateUnemployment'

export const basePath = '/home/claims/new'

type PageKey =
  | 'EmploymentType'
  | 'JobChange'
  | 'LaidOff'
  | 'StateUnemployment'
  | 'EmploymentInfo'
  | 'Documents'
  | 'Review'
  | 'PayoutAccount'
  | 'PayoutAccountManual'
  | 'PayoutAccountExisting'

export const NewClaimSequence: Record<PageKey, NewClaimPageProps> = {
  EmploymentType: {
    previousPath: null,
    nextPath: `${basePath}/job-change`,
    step: 1,
    path: `employment-type`,
    Component: EmploymentType,
  },
  JobChange: {
    previousPath: `${basePath}/employment-type`,
    nextPath: `${basePath}/laid-off`,
    step: 2,
    path: `job-change`,
    Component: JobChange,
  },
  LaidOff: {
    previousPath: `${basePath}/job-change`,
    nextPath: `${basePath}/state-unemployment`,
    step: 3,
    path: `laid-off`,
    Component: LaidOff,
  },
  StateUnemployment: {
    previousPath: `${basePath}/laid-off`,
    nextPath: `${basePath}/employment-info`,
    step: 4,
    path: `state-unemployment`,
    Component: StateUnemployment,
  },
  EmploymentInfo: {
    previousPath: `${basePath}/state-unemployment`,
    nextPath: `${basePath}/documents`,
    step: 5,
    path: `employment-info`,
    Component: EmploymentInfo,
  },
  Documents: {
    previousPath: `${basePath}/employment-info`,
    nextPath: `${basePath}/payout-account`,
    step: 6,
    path: `documents`,
    Component: Documents,
  },
  PayoutAccount: {
    previousPath: `${basePath}/documents`,
    nextPath: `${basePath}/review`,
    step: 7,
    path: `payout-account`,
    Component: PayoutAccount,
  },
  PayoutAccountManual: {
    previousPath: `${basePath}/payout-account`,
    nextPath: `${basePath}/review`,
    step: 7,
    path: `payout-account-manual`,
    Component: PayoutAccountManual,
  },
  PayoutAccountExisting: {
    previousPath: `${basePath}/documents`,
    nextPath: `${basePath}/review`,
    step: 7,
    path: `payout-account-existing`,
    Component: PayoutAccountExisting,
  },
  Review: {
    previousPath: `${basePath}/payout-account-existing`,
    nextPath: `${basePath}/success`,
    step: 8,
    path: `review`,
    Component: Review,
  },
}
