/**
 * @generated SignedSource<<d2847e055e936104444d5e7017a73a5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddManualAccountInput = {
  accountNumber: string;
  claimId?: string | null;
  routingNumber: string;
};
export type PayoutAccountManualMutation$variables = {
  input: AddManualAccountInput;
};
export type PayoutAccountManualMutation$data = {
  readonly addManualAccount: {
    readonly __typename: "PayoutAccount";
    readonly accountNumberMask: string;
    readonly bankName: string | null;
    readonly id: string | null;
  } | {
    readonly __typename: "ValidationErrors";
    readonly messages: ReadonlyArray<{
      readonly field: string;
      readonly help: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type PayoutAccountManualMutation = {
  response: PayoutAccountManualMutation$data;
  variables: PayoutAccountManualMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountNumberMask",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankName",
      "storageKey": null
    }
  ],
  "type": "PayoutAccount",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ValidationError",
      "kind": "LinkedField",
      "name": "messages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "field",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "help",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ValidationErrors",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayoutAccountManualMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addManualAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayoutAccountManualMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addManualAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f5fe397a038c36a6174532adf1e3116e",
    "id": null,
    "metadata": {},
    "name": "PayoutAccountManualMutation",
    "operationKind": "mutation",
    "text": "mutation PayoutAccountManualMutation(\n  $input: AddManualAccountInput!\n) {\n  addManualAccount(input: $input) {\n    __typename\n    ... on PayoutAccount {\n      id\n      accountNumberMask\n      bankName\n    }\n    ... on ValidationErrors {\n      messages {\n        field\n        help\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7925a6bdd3e45e042991770e79ce02ba";

export default node;
