import { datadogLogs } from '@datadog/browser-logs'
import { GlobalStyles, ThemeProvider, ToastsProvider } from '@quil/ui'
import { RelayEnvironmentProvider } from 'react-relay'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { addAnalyticsListeners, initializeGA } from './common/analytics'
import { AppNav } from './common/components/AppNav'
import { AppSuspendableErrorBoundary } from './common/components/AppSuspendableErrorBoundary'
import { PreserveSearchParams } from './common/components/PreserveSearchParams'
import ScrollToTop from './common/components/ScrollToTop'
import { ModalProvider } from './common/modal-context'
import relayEnvironment from './common/relay-env'

if (['production', 'staging', 'sandbox'].includes(process.env.REACT_APP_ENV)) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATA_DOG_LOGS_CLIENT_ID,
    service: 'web-frontend',
    env: process.env.REACT_APP_ENV,
    forwardConsoleLogs: 'all',

    // e.g. marketing site
    trackSessionAcrossSubdomains: true,
    // set in apps/web-frontend/env.js
    version: process.env.REACT_APP_VERSION,
  })
}

initializeGA()
addAnalyticsListeners()

// App renders top-level Providers
function App() {
  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <BrowserRouter>
        <ScrollToTop />
        <ThemeProvider>
          <ModalProvider>
            <ToastsProvider>
              <GlobalStyles />
              <AppNav />
              <AppSuspendableErrorBoundary>
                <PreserveSearchParams />
                <Routes />
              </AppSuspendableErrorBoundary>
            </ToastsProvider>
          </ModalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </RelayEnvironmentProvider>
  )
}

export default App
