/**
 * @generated SignedSource<<b7d8308b6711908251fe7dc23c80475a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCadence = "Monthly" | "Yearly" | "%future added value";
export type PlanLabel = "affordable" | "popular" | "premium" | "%future added value";
export type MyPlanQuery$variables = {};
export type MyPlanQuery$data = {
  readonly availableSubscriptionPlans: ReadonlyArray<{
    readonly cadence: BillingCadence;
    readonly coverage: {
      readonly formatted: string;
      readonly raw: number;
    };
    readonly label: PlanLabel;
    readonly monthlyListPrice: {
      readonly cents: number;
      readonly dollars: number;
    } | null;
    readonly monthlyPrice: {
      readonly cents: number;
      readonly dollars: number;
    };
    readonly suggested: boolean;
    readonly yearlyListPrice: {
      readonly cents: number;
      readonly dollars: number;
    } | null;
    readonly yearlyPrice: {
      readonly cents: number;
      readonly dollars: number;
      readonly formatted: string;
    } | null;
  }>;
  readonly content: {
    readonly myPlan: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
    readonly myPlanPreselected: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
    readonly myPlanPromotion: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
  };
  readonly currentSubscription: {
    readonly cadence: BillingCadence;
    readonly coverage: {
      readonly formatted: string;
      readonly raw: number;
    };
  } | null;
  readonly currentUser: {
    readonly promotion: {
      readonly promotionId: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"useContinueOnboarding_user">;
  } | null;
  readonly disclosures: {
    readonly " $fragmentSpreads": FragmentRefs<"DocLink_disclosures">;
  } | null;
  readonly features: {
    readonly launchPromoPrice: boolean;
  } | null;
};
export type MyPlanQuery = {
  response: MyPlanQuery$data;
  variables: MyPlanQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "My Plan"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "raw",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "My Plan Preselected"
    }
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "My Plan Promo"
    }
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Promotion",
  "kind": "LinkedField",
  "name": "promotion",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "promotionId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formatted",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Money",
  "kind": "LinkedField",
  "name": "coverage",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cadence",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionChoice",
  "kind": "LinkedField",
  "name": "currentSubscription",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dollars",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cents",
  "storageKey": null
},
v15 = [
  (v13/*: any*/),
  (v14/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionPlan",
  "kind": "LinkedField",
  "name": "availableSubscriptionPlans",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "monthlyPrice",
      "plural": false,
      "selections": (v15/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "monthlyListPrice",
      "plural": false,
      "selections": (v15/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "yearlyPrice",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "yearlyListPrice",
      "plural": false,
      "selections": (v15/*: any*/),
      "storageKey": null
    },
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suggested",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "FeatureSet",
  "kind": "LinkedField",
  "name": "features",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "launchPromoPrice",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v19 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v4/*: any*/),
  (v18/*: any*/)
],
v20 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": "myPlan",
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "onboardingPage(where:{\"pageName\":\"My Plan\"})"
          },
          {
            "alias": "myPlanPreselected",
            "args": (v6/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "onboardingPage(where:{\"pageName\":\"My Plan Preselected\"})"
          },
          {
            "alias": "myPlanPromotion",
            "args": (v7/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "onboardingPage(where:{\"pageName\":\"My Plan Promo\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useContinueOnboarding_user"
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      (v12/*: any*/),
      (v16/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocLink_disclosures"
          }
        ],
        "storageKey": null
      },
      (v17/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": "myPlan",
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": "onboardingPage(where:{\"pageName\":\"My Plan\"})"
          },
          {
            "alias": "myPlanPreselected",
            "args": (v6/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": "onboardingPage(where:{\"pageName\":\"My Plan Preselected\"})"
          },
          {
            "alias": "myPlanPromotion",
            "args": (v7/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": "onboardingPage(where:{\"pageName\":\"My Plan Promo\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastCompletedOnboardingStep",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          (v8/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      (v12/*: any*/),
      (v16/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "electronicCommunications",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "mobileNetworkConsent",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "patriotAct",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraPrivacyPolicy",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraTermsOfService",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraMembershipAgreement",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitsDisclosure",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "plateauApplication",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "privacyPolicy",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "termsOfService",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitEligibility",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "promotionalTerms",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v17/*: any*/)
    ]
  },
  "params": {
    "cacheID": "0c725a180d8ce7f749467b5430dafd42",
    "id": null,
    "metadata": {},
    "name": "MyPlanQuery",
    "operationKind": "query",
    "text": "query MyPlanQuery {\n  content {\n    myPlan: onboardingPage(where: {pageName: \"My Plan\"}) {\n      mainCtaText\n      heading\n      body {\n        raw\n      }\n      id\n    }\n    myPlanPreselected: onboardingPage(where: {pageName: \"My Plan Preselected\"}) {\n      mainCtaText\n      heading\n      body {\n        raw\n      }\n      id\n    }\n    myPlanPromotion: onboardingPage(where: {pageName: \"My Plan Promo\"}) {\n      mainCtaText\n      heading\n      body {\n        raw\n      }\n      id\n    }\n  }\n  currentUser {\n    ...useContinueOnboarding_user\n    promotion {\n      promotionId\n    }\n    id\n  }\n  currentSubscription {\n    coverage {\n      formatted\n      raw\n    }\n    cadence\n  }\n  availableSubscriptionPlans {\n    monthlyPrice {\n      dollars\n      cents\n    }\n    monthlyListPrice {\n      dollars\n      cents\n    }\n    yearlyPrice {\n      formatted\n      dollars\n      cents\n    }\n    yearlyListPrice {\n      dollars\n      cents\n    }\n    coverage {\n      formatted\n      raw\n    }\n    label\n    cadence\n    suggested\n  }\n  disclosures {\n    ...DocLink_disclosures\n  }\n  features {\n    launchPromoPrice\n  }\n}\n\nfragment DocLink_disclosures on Disclosures {\n  electronicCommunications {\n    url\n    version\n  }\n  mobileNetworkConsent {\n    url\n    version\n  }\n  patriotAct {\n    url\n    version\n  }\n  avibraPrivacyPolicy {\n    url\n    version\n  }\n  avibraTermsOfService {\n    url\n    version\n  }\n  avibraMembershipAgreement {\n    url\n    version\n  }\n  benefitsDisclosure {\n    url\n    version\n  }\n  plateauApplication {\n    url\n    version\n  }\n  privacyPolicy {\n    url\n    version\n  }\n  termsOfService {\n    url\n    version\n  }\n  benefitEligibility {\n    url\n    version\n  }\n  promotionalTerms {\n    url\n    version\n  }\n}\n\nfragment useContinueOnboarding_user on User {\n  lastCompletedOnboardingStep\n  status\n}\n"
  }
};
})();

(node as any).hash = "7e733c45cb1e7f185deeb7041a5e8ec5";

export default node;
