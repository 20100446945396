export const employerNames = [
  '3M',
  '7-Eleven',
  'AAM - American Axle & Manufacturing',
  'AbbVie Illinois',
  'Ace Hardware',
  'Acorns',
  'Adidas',
  'Adobe',
  'Aerotek',
  'Aetna',
  'Airport',
  'Alaska Airlines',
  'Albertsons',
  'Aldis',
  'Allstate',
  'Ally Financial',
  'Alphabet',
  'Amazon',
  'American Electric Power',
  'American Express',
  'American Family Insurance',
  'Amgen California',
  'Amtrak',
  'Apple',
  'Applebees',
  'Asana',
  'AT&T',
  'Austin Capital Bank',
  'Auto-Owners Insurance',
  'Bank of America',
  'Baptist Health (Kentucky)',
  'Barnes and Noble',
  'Bath and Body Works',
  'BayCare',
  'Bed Bath and Beyond',
  'Berkshire Hathaway',
  'Best Buy',
  'Big Lots',
  'Black Rock Coffee Bar',
  'Blue Cross Blue Shield',
  'BlueCross BlueShield of Tennessee',
  'BMW Group',
  'Boeing',
  'Boost Mobile',
  'Bosch',
  'Boutique',
  'Brex',
  'Brigit',
  'Brunswick',
  'Buffalo Wild Wings',
  'Burger King',
  'Burgerville',
  'Calm',
  'Capital One',
  'Carls Jr.',
  'Carnival Corporation',
  'Carvana',
  'CashApp',
  'Caviar',
  'Centers for Medicare & Medicaid Services',
  'Chanel',
  'Charles Schwab',
  'Charter Communications',
  'Chevron',
  'Cigna',
  'Cisco Systems',
  'Citigroup',
  'Clemson University',
  'Cleveland Clinic	Ohio',
  'Clorox',
  'Coca-Cola',
  'Coinbase',
  'Comcast',
  'Consumers Energy',
  'Cornell University',
  'Costco Wholesale',
  'Craft Warehouse',
  'Credit Karma',
  'Cricket Mobile',
  'CVS',
  'Daily Harvest',
  'Dave',
  'Deaconess Health System',
  'Dell Technologies',
  'Delta Air Lines',
  'Delta',
  'Dillards',
  'Disney',
  'DocuSign',
  'Dollar General',
  'Dollar Tree',
  'Dominos Pizza',
  'DTE Energy',
  'Duke University Health System',
  'Duke University',
  'Dunkin Donuts',
  'Dutch Bros',
  'Edward Jones',
  'Emory Healthcare',
  'Emory University',
  'Evonik Industries',
  'Express',
  'ExtraMile',
  'Exxon Mobile',
  'FedEx',
  'Fidelity Investments',
  'Fidelity National Financial',
  'First Fed',
  'Five Below',
  'Flex-N-Gate',
  'Florida Blue',
  'FM Payments',
  'Ford Motors',
  'Forever 21',
  'Fred Meyer',
  'Free People',
  'Frontier',
  'Fujifilm Holdings America',
  'FullStory',
  'Geico',
  'Genentech',
  'General Electric',
  'General Mills',
  'General Motors',
  'Glassdoor',
  'Glossier',
  'GoHealth',
  'GoodRx',
  'Google',
  'GoPuff',
  'Groupon',
  'GrubHub',
  'Guardian',
  'H-E-B',
  'H&M',
  'Hawaiin Airlines',
  'HBO',
  'Healthcare.com',
  'HelloFresh',
  'HII',
  'Hilton',
  'Hobby Lobby',
  'Home Depot',
  'Home Goods',
  'Honda',
  'Hopin',
  'Houston Methodist',
  'HP',
  'Hubspot',
  'Humana',
  'Huntington National Bank',
  'Hyatt Hotels',
  'IBM',
  'Ikea',
  'In-N-Out Burger',
  'Instacart',
  'Intel',
  'Intuit',
  'iRobot',
  'J.C. Penney',
  'JLL',
  'Johns Hopkins Medicine',
  'Johnson & Johnson',
  'JPMorgan Chase',
  'Kaiser Permanente',
  'Klarna',
  'Kohls',
  'Kroger',
  'Kwik Trip/Kwik Star',
  'Lam Research',
  'Latch',
  'Legacy Health',
  'Lemonade',
  'Les Schwab Tire',
  'Lilly',
  'LinkedIn',
  'Little Caesars',
  'Live Nation Entertainment',
  'Lockheed Martin',
  'Lockton Companies',
  'Loom',
  'Lush Cosmetics',
  'Lyft',
  'MaineHealth',
  'Marriott International',
  'Massachusetts Institute of Technology',
  'Mayo Clinic',
  'McDonalds',
  'MD Anderson Cancer Center',
  'Memorial Sloan Kettering Cancer Center',
  'Merck & Co.',
  'Meta',
  'MetLife',
  'Michaels Crafts',
  'Michelin Group',
  'Microsoft',
  'MoneyLion',
  'Morgan Stanley',
  'MUSC Health',
  'Mutual of Omaha',
  'NASA',
  'Nationwide',
  'Nautilus',
  'Navy Federal Credit Union',
  'Netflix',
  'New Relic',
  'New Seasons',
  'New York Life',
  'NextEra Energy',
  'Nike',
  'Noom',
  'Nordstrom',
  'North Carolina State University',
  'Northrop Grumman',
  'Northwestern Medicine',
  'Norton Healthcare',
  'Nutanix',
  'Ohio State University Wexner Medical Center',
  'Ohio State University, Columbus',
  'Old Dominion Freight Line',
  'Oracle',
  'Orlando Health',
  'Overstock',
  'Pac Sun',
  'Panda Express',
  'PayPal',
  'Peloton',
  'Penn Medicine',
  'PepsiCo',
  'Pfizer',
  'Phillips 66',
  'Pizza Hut',
  'Plaid',
  'Postmates',
  'Princess Cruises',
  'Procter & Gamble',
  'Progressive',
  'Publix Super Markets',
  'Purdue University',
  'QFC',
  'Qualcomm',
  'Quil',
  'Raymond James Financial',
  'Realogy',
  'RealPage',
  'Red Robin',
  'Redfin',
  'Revolve',
  'RingCentral',
  'Rite Aid',
  'Riverside Payments',
  'Rivian',
  'Robinhood',
  'Ross',
  'Royal Caribbean Group',
  'Rutgers University',
  'Safeway',
  'Salesforce',
  'Sams Club',
  'Schneider National',
  'Sears',
  'Sendgrid',
  'Sephora',
  'Sherwin-Williams',
  'Shopify',
  'Six Flags',
  'Slack',
  'Slalom Consulting',
  'Snapchat',
  'Sony',
  'SoundCloud',
  'Southern Company',
  'Southwest Airlines',
  'SpaceX California',
  'Spirit',
  'Spotify',
  'Sprint',
  'Stanley Black & Decker',
  'Starbucks',
  'State Farm',
  'Steelcase',
  'Stitch Fix',
  'Stripe',
  'StubHub',
  'Subarau',
  'Subaru-Indiana Automotive',
  'Sundae',
  'Sunrun',
  'Superhuman',
  'Sysco',
  'Taco Bell',
  'Takeda Pharmaceutical',
  'Target',
  'Tesla',
  'The Washington Post',
  'ThredUp',
  'TicketMaster',
  'Tik Tok',
  'TJ Maxx',
  'TMobile',
  'Toyota',
  'Truist Financial',
  'Twilio',
  'Twitter',
  'Tyson Foods',
  'U.S. Department of the Interior',
  'U.S. Federal Reserve System',
  'Uber',
  'UberEats',
  'UCHealth (Colorado)',
  'UCLA Health',
  'Ulta',
  'Ultimate Kronos Group',
  'United Airlines',
  'United Rentals',
  'United Services Automobile Association (USAA)',
  'University Hospitals',
  'University of Alabama at Birmingham',
  'University of California, San Diego',
  'University of Illinois, Urbana-Champaign',
  'University of Kentucky',
  'University of Maryland Medical System (UMMS)',
  'University of Maryland, Baltimore',
  'University of Michigan',
  'University of Notre Dame',
  'University of Oklahoma',
  'University of Tennessee, Knoxville',
  'University of Texas Southwestern Medical Center',
  'University of Wisconsin, Madison',
  'UPS',
  'Upstate Medical University - State University of New York',
  'Urban Outfitters',
  'US Bank',
  'UScellular',
  'Vanderbilt University Medical Center',
  'Vanguard',
  'Venmo',
  'Verizon Communications',
  'Vimeo',
  'Vroom',
  'Walgreens',
  'Walmart',
  'Warby Parker',
  'Washington Post',
  'Washington University in Saint Louis',
  'Wayfair',
  'Wegmans Food Markets',
  'Wells Fargo',
  'Whole Foods',
  'Winco',
  'Wing Stop',
  'WL Gore & Associates',
  'Wolters Kluwer',
  'Yahoo',
  'Yale University',
  'Zumiez',
  "L'Oreal USA",
  "Lowe's",
  "Macy's",
  "Nationwide Children's Hospital",
  "Shari's",
  "The Children's Hospital of Philadelphia",
  "Trader Joe's",
]

export const jobTitles = [
  'Accountant',
  'Administrative Assistant',
  'Administrator',
  'Agent',
  'Art Director',
  'Assistant Manager',
  'Baker',
  'Bank Teller',
  'Barber',
  'Bartender',
  'Bookkeeper',
  'Business Analyst',
  'Carpenter',
  'Cashier',
  'Concierge',
  'Construction Worker',
  'Cosmetologist',
  'Customer Service Representative',
  'Data Analyst',
  'Data Scientist',
  'Electrician',
  'Engineer',
  'Esthetician',
  'Finance',
  'Financial Analyst',
  'Florist',
  'Food Preparation Worker',
  'Fork Lift Operator',
  'General Manager',
  'Graphic Designer',
  'HR',
  'Hair Stylist',
  'Housekeeper',
  'Janitor',
  'Jeweler',
  'Laborer',
  'Lawyer',
  'Legal Assistant',
  'Line Supervisor',
  'Marketing Specialist',
  'Mechanic',
  'Medical Assistant',
  'Nail Technician',
  'Notary',
  'Nurse Practitioner',
  'Nurse',
  'Office Assistant',
  'Office Clerk',
  'Operations Manager',
  'Operator',
  'Party Planner',
  'Personal Assistant',
  'Physical Therapist',
  'Police Officer',
  'Principal',
  'Product Manager',
  'Receptionist',
  'Recruiter',
  'Registered Nurse',
  'Sales Associate',
  'Sales Manager',
  'Sales',
  'Server',
  'Social Media Manager',
  'Software Developer',
  'Software Engineer',
  'Stocking Associate',
  'Supervisor',
  'Teacher',
  'Truck Driver',
  'Tutor',
  'Virtual Assistant',
  'Web Developer',
]
