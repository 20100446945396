import styled, { css } from 'styled-components'

const Row = styled.div<{ small?: boolean; centered?: boolean }>`
  margin-bottom: ${(props) => (props.small ? '1.6' : '2.4')}rem;

  ${(props) =>
    props.centered &&
    css`
      display: flex;
      justify-content: center;
    `}
`
export default Row
