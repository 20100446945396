import { LabelHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { fromTheme } from '../../theme'

type Props = LabelHTMLAttributes<HTMLLabelElement> & {
  children: string | JSX.Element
  error?: boolean
  endContent?: string | JSX.Element
  className?: string
  disabled?: boolean
}

const Container = styled.label<{ error?: boolean }>`
  display: flex;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  justify-content: space-between;
`

const Label = styled.label<{ error?: boolean; disabled?: boolean }>`
  color: ${fromTheme((theme, props) =>
    props.error
      ? theme.colors.foregroundDanger.raw
      : theme.colors.textPrimary.raw
  )};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

export function InputLabel({
  children,
  error,
  endContent,
  className,
  ...htmlLabelProps
}: Props) {
  return (
    <Container className={className}>
      <Label {...htmlLabelProps}>{children}</Label>
      {endContent && <span>{endContent}</span>}
    </Container>
  )
}
