const login = 'login'

export const submitButton = `${login}_submit_button` as const
export const toastMessage = `${login}_toast_message` as const

export const instructionMessage = `${login}_instruction_message` as const

export const phone = `${login}_phone` as const
export const smsOtp = `${login}_sms_otp` as const
export const emailOtp = `${login}_email_otp` as const
