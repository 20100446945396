import styled from 'styled-components'
import { fromTheme } from '../../theme'

export const AppHeaderSegment = styled.div`
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AppHeaderTitle = styled.h4`
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  font-size: 2.2rem;
  margin: 0;
`

export const AppHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: stretch;
  height: 11.2rem;
  padding: 2.8rem ${fromTheme(({ spacing }) => spacing.horizontalPadding)} 4rem;

  ${AppHeaderSegment}:last-child {
    justify-content: flex-end;
  }

  ${AppHeaderSegment}:first-child {
    justify-content: flex-start;
  }
`
