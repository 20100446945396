import React, { Suspense } from 'react'

type ErrorBoundaryProps = {
  errorState?: React.ReactNode
  children?: React.ReactNode
}

type State = {
  unhandledError: boolean
  error: Error | null
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      unhandledError: false,
      error: null,
    }
  }

  static getDerivedStateFromError(error: unknown) {
    return {
      unhandledError: true,
      error: null,
    }
  }

  render() {
    if (this.state.unhandledError) {
      return this.props.errorState
    }

    return this.props.children
  }
}

// I ❤️ open source https://github.com/bvaughn/react-error-boundary/blob/a0a370c7fe145e5cf169063eb4b7ff1a059fd84c/src/index.tsx#L142-L159
export function withErrorBoundary<P>(
  Component: React.ComponentType<P>,
  errorBoundaryProps: ErrorBoundaryProps
): React.ComponentType<P> {
  const Wrapped: React.ComponentType<P> = (props) => {
    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }

  // Format for display in DevTools
  const name = Component.displayName || Component.name || 'Unknown'
  Wrapped.displayName = `withErrorBoundary(${name})`

  return Wrapped
}

type SuspendableErrorBoundaryProps = ErrorBoundaryProps & {
  fallback?: React.ReactNode
  children?: React.ReactNode
}

export const SuspendableErrorBoundary: React.FC<
  SuspendableErrorBoundaryProps
> = (props) => {
  return (
    <ErrorBoundary {...props}>
      <Suspense fallback={props.fallback}>{props.children}</Suspense>
    </ErrorBoundary>
  )
}

export function withSuspendableErrorBoundary<P>(
  Component: React.ComponentType<P>,
  suspendableErrorBoundaryProps: SuspendableErrorBoundaryProps
): React.ComponentType<P> {
  const Wrapped: React.ComponentType<P> = (props) => {
    return (
      <SuspendableErrorBoundary {...suspendableErrorBoundaryProps}>
        <Component {...props} />
      </SuspendableErrorBoundary>
    )
  }

  // Format for display in DevTools
  const name = Component.displayName || Component.name || 'Unknown'
  Wrapped.displayName = `withSuspendableErrorBoundary(${name})`

  return Wrapped
}
