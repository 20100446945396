import { motion } from 'framer-motion'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import GlobalStyles from '../../components/GlobalStyles'
import {
  Claims as ClaimsIcon,
  Heart as HeartIcon,
  Home as HomeIcon,
  Plus as PlusIcon,
  Profile as SettingsIcon,
} from '../../icons'
import { fromTheme, hsla } from '../../theme'
const Container = styled.div`
  filter: drop-shadow(
    0 0 10px ${fromTheme(({ colors }) => hsla(colors.shadow, 0.1))}
  );
  position: fixed;
  bottom: 0;
  // account for left/right padding in max width
  max-width: calc(
    490px + ${fromTheme(({ spacing }) => spacing.horizontalPadding)} +
      ${fromTheme(({ spacing }) => spacing.horizontalPadding)}
  );
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: ${fromTheme(({ breakpoints }) => breakpoints.small)}px) {
    padding: 2rem ${fromTheme(({ spacing }) => spacing.horizontalPadding)};
  }
`

const Navigation = styled.nav`
  position: relative;
  background: ${fromTheme(({ colors }) => colors.background.raw)};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 68px;
  padding: 0 20px;
  @media (min-width: ${fromTheme(({ breakpoints }) => breakpoints.small)}px) {
    border-radius: 34px;
    padding: 0 26px;
  }

  // rounded edge in the middle of the container, behind the '+' button
  &::before {
    content: '';
    position: absolute;
    height: 72px;
    width: 72px;
    top: 0;
    background: ${fromTheme(({ colors }) => colors.background.raw)};
    display: block;
    left: 50%;
    top: 0;
    border-radius: 50%;
    transform: translate(-50%, -24px);
  }
`

const Item = styled.div`
  text-align: center;
  width: 60px;
  z-index: 1;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 0 0.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`

const NewClaimItem = styled(Item)`
  width: 70px;
`

const NewClaimButton = styled.div`
  color: ${fromTheme(({ colors }) => colors.buttonPrimaryText.raw)};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${fromTheme(({ colors }) => colors.textPrimary.raw)};
  font-size: 3.6rem;
  position: absolute;
  left: 50%;
  top: 0;
  box-shadow: 0 0 10px 0 ${fromTheme(({ colors }) => hsla(colors.shadow, 0.3))};
  transform: translate(-50%, -13px);
`

const Underline = styled(motion.div)`
  height: 5px;
  width: 100%;
  background-color: ${fromTheme(({ colors }) => colors.foregroundPrimary.raw)};
  position: relative;
  margin-bottom: -5px;
  bottom: -3px;
  border-radius: 3px 3px 0 0;
`
const underlineLayoutId = 'underline'

export type NavPageName =
  | 'home'
  | 'claims'
  | 'newClaim'
  | 'settings'
  | 'benefits'

type Props = {
  activePage: NavPageName
  onRequestNavigation(to: NavPageName): void
}

export function Nav(props: Props) {
  function createNavigationHandler(to: NavPageName) {
    return function handleNavigation() {
      props.onRequestNavigation(to)
    }
  }

  return createPortal(
    <Container>
      <GlobalStyles />
      <Navigation>
        <Item onClick={createNavigationHandler('home')}>
          <HomeIcon />
          <span>
            Home
            {props.activePage === 'home' && (
              <Underline layoutId={underlineLayoutId} />
            )}
          </span>
        </Item>
        <Item onClick={createNavigationHandler('claims')}>
          <ClaimsIcon />
          <span>
            Claims
            {props.activePage === 'claims' && (
              <Underline layoutId={underlineLayoutId} />
            )}
          </span>
        </Item>
        <NewClaimItem onClick={createNavigationHandler('newClaim')}>
          <NewClaimButton>
            <PlusIcon />
          </NewClaimButton>
          <span>
            New Claim
            {props.activePage === 'newClaim' && (
              <Underline layoutId={underlineLayoutId} />
            )}
          </span>
        </NewClaimItem>
        <Item onClick={createNavigationHandler('settings')}>
          <SettingsIcon />
          <span>
            Settings
            {props.activePage === 'settings' && (
              <Underline layoutId={underlineLayoutId} />
            )}
          </span>
        </Item>
        <Item onClick={createNavigationHandler('benefits')}>
          <HeartIcon style={{ marginBottom: -4 }} />
          <span>
            Benefits
            {props.activePage === 'benefits' && (
              <Underline layoutId={underlineLayoutId} />
            )}
          </span>
        </Item>
      </Navigation>
    </Container>,
    document.body
  )
}
