import * as event from '@quil/analytics-events'
import {
  Alert,
  ButtonPrimary,
  InputCheckbox,
  InputDate,
  InputEmail,
  InputName,
  InputPhone,
  ProcessingOverlay,
  Select,
  standardToastHeadlines,
  standardToastMessages,
  TextLinkPrimary,
  useShowToast,
} from '@quil/ui'
import { stdSerializers } from 'pino'
import { pick, tap } from 'ramda'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import { LayoutSignupTracked } from '../../common/analytics'
import * as analytics from '../../common/analytics/analytics-adapter'
import {
  AddressInput,
  Value as AddressValue,
} from '../../common/components/AddressInput'
import DocLink from '../../common/components/DocLink'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import Row from '../../common/components/Row'
import useContinueOnboarding from '../../common/components/useContinueOnboarding'
import { useModal } from '../../common/modal-context'
import { useNavigate } from '../../common/react-router-wrappers'
import { useFormReducer } from '../../common/useFormReducer'
import { SignupPageProps } from '../SignupPageProps'
import SignupProgressTracker from '../SignupProgressTracker'
import { createInitialState, VerifyPersonalInfoState } from './state'
import SupportRequestModal from './SupportRequestModal'
import * as testIds from './test-ids'
import {
  VerifyPersonalInfoQuery,
  VerifyPersonalInfoQuery$data,
} from './__generated__/VerifyPersonalInfoQuery.graphql'
import { VerifyPersonalInfoSubmitPersonalInfoMutation } from './__generated__/VerifyPersonalInfoSubmitPersonalInfoMutation.graphql'
import { VerifyPersonalInfoSupportRequestMutation } from './__generated__/VerifyPersonalInfoSupportRequestMutation.graphql'

const noop = () => {
  /* */
}

const NotYouLink = styled(TextLinkPrimary)`
  font-size: 1.2rem;
  padding: 0;
`

const NoWrap = styled.span`
  white-space: nowrap;
`

const query = graphql`
  query VerifyPersonalInfoQuery {
    currentUser {
      id
      email
      unverifiedEmail
      fullName
      firstName
      lastName
      dob
      phone
      addressLineOne
      addressLineTwo
      city
      state
      zip
      isNewYorkProhibited
      ...useContinueOnboarding_user
    }
    disclosures {
      ...DocLink_disclosures
    }
    content {
      onboardingPage(where: { pageName: "Verify Personal Info" }) {
        heading
        body {
          raw
        }
        mainCtaText
      }
      ...SupportRequestModal_content
    }
    availableSubscriptionPlans {
      monthlyPrice {
        formatted
      }
      monthlyListPrice {
        formatted
      }
      yearlyPrice {
        formatted
      }
      yearlyListPrice {
        formatted
      }
      coverage {
        formatted
        raw
      }
      cadence
      label
      suggested
    }
    currentSubscription {
      coverage {
        formatted
        raw
      }
      cadence
    }
  }
`
function omitEmptyStringProps<T>(obj: T): Partial<T> {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key]) {
      return {
        ...acc,
        [key]: obj[key],
      }
    }

    return acc
  }, {})
}

const submitPersonalInfoMutation = graphql`
  mutation VerifyPersonalInfoSubmitPersonalInfoMutation(
    $subscriptionChoiceInput: SubscriptionChoiceInput!
    $submitPersonalInfoInput: UpdateUserInput!
    $agreements: [CreateAgreementInput]!
    $createSessionInput: CreateCheckoutSessionInput!
  ) {
    saveSubscriptionChoice(input: $subscriptionChoiceInput) {
      __typename
    }
    submitPersonalInfo(data: $submitPersonalInfoInput) {
      __typename
      ... on PersonalInfoOutcome {
        isProhibitedNewYorkResident
        isIneligible
        canContinue
      }

      ... on AvibraProfile {
        profileId
      }

      ... on ValidationErrors {
        messages {
          field
          help
        }
      }
    }
    createAgreements(agreements: $agreements) {
      type
    }
    createCheckoutSession(input: $createSessionInput) {
      url
    }
  }
`

const supportRequestMutation = graphql`
  mutation VerifyPersonalInfoSupportRequestMutation(
    $data: CreateSupportRequestInput!
  ) {
    createSupportRequest(data: $data) {
      id
    }

    reportIncorrectPrefillData {
      id
      status
    }
  }
`

function VerifyPersonalInfo({
  step,
  allowedLastOnboardingSteps,
  nextPath,
  previousPath,
}: SignupPageProps) {
  const pageName = 'Verify Personal Info'
  const showToast = useShowToast()
  const { openModal, closeModal } = useModal()
  const data = useLazyLoadQuery<VerifyPersonalInfoQuery>(query, {})

  const navigate = useNavigate()

  const debouncedTrackInputChange = useDebouncedCallback(
    trackInputChange(),
    500
  )

  const debouncedTrackAddressChange = useDebouncedCallback(
    trackAddressChange(),
    500
  )

  const [{ errors, values }, actions] = useFormReducer(
    createInitialState({
      ...omitEmptyStringProps(data.currentUser),
      email: data.currentUser?.unverifiedEmail || data.currentUser?.email,
      subscriptionCoverage: formatCoverageValue(
        data.currentSubscription ??
          data.availableSubscriptionPlans.find((plan) => plan.suggested) ??
          tap(
            () => console.error({ data, msg: 'No suggested plan' }),
            data.availableSubscriptionPlans[1]
          ) ??
          tap(
            () => console.error({ data, msg: 'No plan at index 1' }),
            data.availableSubscriptionPlans[0]
          )
      ),
    })
  )

  const [commitUpdateUserMutation, updateUserMutationInFlight] =
    useMutation<VerifyPersonalInfoSubmitPersonalInfoMutation>(
      submitPersonalInfoMutation
    )

  const [commitSupportRequestMutation, commitSupportRequestMutationInFlight] =
    useMutation<VerifyPersonalInfoSupportRequestMutation>(
      supportRequestMutation
    )

  function handleNextClick() {
    analytics.track(event.formSubmitted('Verify Personal Info'))
    actions.clearValidationErrors()
    const { coverage, cadence } = ((value) => {
      try {
        return JSON.parse(value)
      } catch (error) {
        console.error({ value, error }, 'Could not parse subscriptionCoverage')
        showToast({
          severity: 'Error',
          headline: 'Something went wrong',
          message:
            'Sorry! We seem to have an issue. Our team has been notified and will get right on it.',
        })
        throw error
      }
    })(values.subscriptionCoverage)

    commitUpdateUserMutation({
      variables: {
        subscriptionChoiceInput: {
          coverage: parseInt(coverage.raw),
          cadence,
        },
        submitPersonalInfoInput: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          state: values.state,
          zip: values.zip,
        },
        agreements: [
          {
            type: 'AvibraPrivacyPolicy',
            acceptedAt: values.agreedAt,
          },
          {
            type: 'AvibraTermsOfService',
            acceptedAt: values.agreedAt,
          },
          {
            type: 'AvibraMembershipAgreement',
            acceptedAt: values.agreedAt,
          },
          {
            type: 'BenefitsDisclosure',
            acceptedAt: values.agreedAt,
          },
          {
            type: 'BenefitEligibility',
            acceptedAt: values.agreedAt,
          },
          {
            type: 'PlateauApplication',
            acceptedAt: values.agreedAt,
          },
          {
            type: 'TermsOfService',
            acceptedAt: values.agreedAt,
          },
        ],
        createSessionInput: {
          cancelUrl: window.location.href,
          successUrl: `${window.location.protocol}//${window.location.host}/checkout-success?sessionId={CHECKOUT_SESSION_ID}`,
        },
      },
      async onCompleted(response) {
        switch (response.submitPersonalInfo?.__typename) {
          case 'PersonalInfoOutcome': {
            await analytics.track(event.userAccountUpdated())

            if (response.submitPersonalInfo.isIneligible) {
              navigate('/signup/denied/identity')
              break
            }

            if (response.submitPersonalInfo.isProhibitedNewYorkResident) {
              navigate('/signup/ny-coming-soon')
              break
            }

            if (response.submitPersonalInfo.canContinue) {
              window.location.href = response.createCheckoutSession.url
              break
            }

            console.error({
              response,
              message: 'Unhandled response from submitPersonalInfo',
            })
            break
          }

          case 'AvibraProfile': {
            await analytics.track(event.userAccountUpdated())
            await analytics.track(
              event.existingAvibraCustomer(
                data.currentUser.id,
                response.submitPersonalInfo.profileId
              )
            )

            navigate('/signup/upgrade-plan')
            break
          }

          case 'ValidationErrors':
            showToast({
              severity: 'Error',
              headline: standardToastHeadlines.validationErrors,
              message: standardToastMessages.validationErrors,
            })
            actions.receiveValidationErrors([
              ...response.submitPersonalInfo.messages,
            ])
            break
        }
      },

      onError(error) {
        console.error({
          error: stdSerializers.err(error),
          mutation: 'submitPersonalInfo',
          userId: data.currentUser?.id,
        })
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.genericError,
          message: standardToastMessages.retry,
          onClick: handleNextClick,
        })
      },
      updater: (store, response) => {
        // If user is locked, invalidate cache so they cannot re-submit the form
        if (response.submitPersonalInfo?.['isIneligible']) {
          store.invalidateStore()
          analytics.reset()
        }
      },
    })
  }

  function handleSupportRequest() {
    if (!commitSupportRequestMutationInFlight) {
      commitSupportRequestMutation({
        variables: {
          data: {
            comment: `User ${data.currentUser.id} reported that prefilled name "${data.currentUser.fullName}" is not correct`,
            subject: 'Incorrect prefilled name',
          },
        },
        onCompleted() {
          navigate('/signup/denied/personal-info')
          closeModal()
        },
        onError(error) {
          console.error({
            error,
            mutation: 'createSupportRequest',
          })
          showToast({
            severity: 'Error',
            headline: standardToastHeadlines.genericError,
            message: standardToastMessages.retry,
            onClick: handleSupportRequest,
          })
        },
      })
    }
  }

  function handleNotYouClick() {
    openModal(
      <SupportRequestModal
        onSupportRequest={handleSupportRequest}
        closeModal={closeModal}
        content={data.content}
      />
    )
  }

  function createChangeHandler(
    fieldName: keyof VerifyPersonalInfoState['values']
  ) {
    return function (value: string) {
      if (!updateUserMutationInFlight) {
        debouncedTrackInputChange(fieldName, value)
        actions.updateField(fieldName, value)
      }
    }
  }

  function handleAddressChange(
    value: AddressValue,
    updatedFields: (keyof AddressValue)[]
  ) {
    if (!updateUserMutationInFlight) {
      debouncedTrackAddressChange(updatedFields, value)
      actions.updateFields({
        ...value,
      })
    }
  }

  function createAgreementCheckHandler(checked: boolean) {
    actions.updateFields({
      agreedAt: checked ? new Date().toISOString() : null,
    })
  }

  const agreementsBody = (
    <>
      {'I agree to become a member of: Quil Ventures, Inc '}
      <NoWrap>
        {'('}
        <DocLink disclosures={data.disclosures} kind="termsOfService">
          Terms of Service
        </DocLink>
      </NoWrap>
      {' and '}
      <NoWrap>
        <DocLink disclosures={data.disclosures} kind="benefitEligibility">
          Eligibility
        </DocLink>
        {');'}
      </NoWrap>
      {' our non-profit partner Alliance of Well-Being Association '}
      <NoWrap>
        {'('}
        <DocLink
          disclosures={data.disclosures}
          kind="avibraMembershipAgreement"
        >
          Membership Agreement
        </DocLink>
      </NoWrap>
      {' and '}
      <NoWrap>
        <DocLink disclosures={data.disclosures} kind="benefitsDisclosure">
          Benefits Disclosure
        </DocLink>
        {');'}
      </NoWrap>
      {' and our partner Avibra '}
      <NoWrap>
        {'('}
        <DocLink disclosures={data.disclosures} kind="avibraPrivacyPolicy">
          Privacy Policy
        </DocLink>
      </NoWrap>
      {' and '}
      <NoWrap>
        <DocLink disclosures={data.disclosures} kind="avibraTermsOfService">
          Terms of Service
        </DocLink>
        {').'}
      </NoWrap>
      {" I agree to Plateau's "}
      <DocLink disclosures={data.disclosures} kind="plateauApplication">
        Disclosure
      </DocLink>
      {' and have reviewed the fraud warning.'}
    </>
  )

  useContinueOnboarding(data.currentUser, allowedLastOnboardingSteps)

  return (
    <>
      <LayoutSignupTracked
        pageName={pageName}
        headerStart={previousPath && <GoBack />}
        headerEnd={<SignupProgressTracker step={step} />}
        title={data.content.onboardingPage.heading}
        footerContent={
          <ButtonPrimary
            data-page-name={pageName}
            data-name="Primary CTA"
            onClick={handleNextClick}
            data-testid={testIds.primaryCta}
            disabled={
              !values.agreedAt ||
              updateUserMutationInFlight ||
              isNewYorkProhibited(
                values.state,
                data.currentUser.isNewYorkProhibited
              )
            }
          >
            {data.content.onboardingPage.mainCtaText}
          </ButtonPrimary>
        }
      >
        <RichText content={data.content.onboardingPage.body.raw} />
        <InputName
          value={values.firstName}
          error={errors.firstName}
          data-testid={testIds.firstNameField}
          onChange={noop}
          type="First"
          disabled
          labelEnd={
            <NotYouLink
              data-testid={testIds.notYouCta}
              data-page-name={pageName}
              data-name="Not you CTA"
              onClick={handleNotYouClick}
            >
              Not you?
            </NotYouLink>
          }
        />
        <InputName
          value={values.lastName}
          error={errors.lastName}
          data-testid={testIds.lastNameField}
          onChange={noop}
          type="Last"
          disabled
        />
        <InputDate
          value={values.dob}
          error={errors.dob}
          data-testid={testIds.dobField}
          onChange={noop}
          label="Date of birth"
          disabled
        />
        <InputPhone
          value={values.phone}
          error={errors.phone}
          data-testid={testIds.phoneField}
          onChange={noop}
          disabled
        />
        <InputEmail
          value={values.email}
          error={errors.email}
          data-testid={testIds.emailField}
          onChange={createChangeHandler('email')}
        />
        <AddressInput
          value={{
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            city: values.city,
            state: values.state,
            zip: values.zip,
          }}
          errors={{
            addressLineOne: errors.addressLineOne,
            addressLineTwo: errors.addressLineTwo,
            city: errors.city,
            state: errors.state,
            zip: errors.zip,
          }}
          onChange={handleAddressChange}
          testIds={testIds.address}
        />
        {isNewYorkProhibited(
          values.state,
          data.currentUser.isNewYorkProhibited
        ) && (
          <Alert
            severity="Warning"
            headline="New York not supported"
            message="We don’t currently support New York residents. If this is in error, feel free to update your address."
            testId={testIds.residencyAlert}
            linkText="I live in New York →"
            onClick={() => navigate('/signup/ny-coming-soon')}
          />
        )}
        {/* Although React types suggest that <select> elements can take number values, the actual runtime value is still coerced to a string.
            (https://github.com/DefinitelyTyped/DefinitelyTyped/blob/2d512539e20a785e8b5d6f6413ccd3b5e242cbd4/types/react/index.d.ts#L2416)
            Rather than pretend something is a number when it is actually a string, we are just explicit that the value is a string. */}
        <Select
          label="Layoff insurance coverage"
          onChange={createChangeHandler('subscriptionCoverage')}
          options={formatCoverageOptions(data.availableSubscriptionPlans)}
          value={values.subscriptionCoverage}
          error={errors.subscriptionCoverage}
        />
        <Row>
          <InputCheckbox
            name="agreements_checkbox"
            label={agreementsBody}
            checked={!!values.agreedAt}
            onChange={createAgreementCheckHandler}
            data-testid={testIds.agreementCheckbox}
            disabled={updateUserMutationInFlight}
          />
        </Row>
      </LayoutSignupTracked>
      <ProcessingOverlay
        processing={updateUserMutationInFlight}
        stalledText="Verifying your information..."
      />
    </>
  )
}

export default VerifyPersonalInfo

const prefix = 'Signup:'

function trackInputChange() {
  return function (
    fieldName: keyof VerifyPersonalInfoState['values'],
    value: string
  ) {
    switch (fieldName) {
      case 'firstName':
        analytics.track(event.inputChanged(`${prefix} First Name`, { value }))
        break
      case 'lastName':
        analytics.track(event.inputChanged(`${prefix} Last Name`, { value }))
        break
      case 'email':
        analytics.track(event.inputChanged(`${prefix} Email`, { value }))
        break
    }
  }
}

function trackAddressChange() {
  return function (
    updatedFields: (keyof AddressValue)[],
    address: AddressValue
  ) {
    updatedFields.forEach((fieldName) => {
      switch (fieldName) {
        case 'addressLineOne':
          analytics.track(
            event.inputChanged(`${prefix} Address Line One`, {
              value: address.addressLineOne,
            })
          )
          break
        case 'addressLineTwo':
          analytics.track(
            event.inputChanged(`${prefix} Address Line Two`, {
              value: address.addressLineTwo,
            })
          )
          break
        case 'city':
          analytics.track(
            event.inputChanged(`${prefix} City`, { value: address.city })
          )
          break
        case 'state':
          analytics.track(
            event.inputChanged(`${prefix} State`, { value: address.state })
          )
          break
        case 'zip':
          analytics.track(
            event.inputChanged(`${prefix} Zip`, { value: address.zip })
          )
      }
    })
  }
}

export function isNewYorkProhibited(
  state: string,
  isProhibited: boolean
): boolean {
  const uppercase = state?.toUpperCase().replace(/[^A-Z]/g, '')
  return (uppercase === 'NY' || uppercase === 'NEWYORK') && isProhibited
}

type SubscriptionPlan =
  VerifyPersonalInfoQuery$data['availableSubscriptionPlans'][number]

function formatCoverageOptions(plans: ReadonlyArray<SubscriptionPlan>) {
  return plans.map((plan) => ({
    value: formatCoverageValue(plan),
    label: `${plan.coverage.formatted} coverage at ${getApplicablePlanPrice(
      plan
    )} / ${cadenceShortCode(plan)}`,
  }))
}

function formatCoverageValue(
  plan: Pick<SubscriptionPlan, 'coverage' | 'cadence'>
) {
  return JSON.stringify(pick(['coverage', 'cadence'], plan))
}

function getApplicablePlanPrice(plan: SubscriptionPlan) {
  return plan.cadence === 'Yearly'
    ? plan.yearlyPrice.formatted
    : plan.monthlyPrice.formatted
}

function cadenceShortCode(plan: SubscriptionPlan) {
  return plan.cadence === 'Yearly' ? 'yr' : 'mo'
}
