import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import { atBreakpoint, fromTheme, useQuilTheme } from '../../theme'
import PageFooter from '../PageFooter'

type Props = {
  isOpen: boolean
  onBackdropClick?: () => void
  'data-testid'?: string
  children: React.ReactNode
}

const Backdrop = styled(motion.div)`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // TODO: theme
  background-color: rgba(0, 0, 0, 0.5);
`

const Card = styled(motion.div)`
  background-color: ${fromTheme((theme) => theme.colors.background.raw)};
  border-radius: 2.4rem 2.4rem 0 0;
  // TODO: theme
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  max-height: 100%;
  overflow-y: auto;
  padding: 2.4rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  ${PageFooter} {
    margin: 0 -2.4rem -2.4rem;
  }

  ${atBreakpoint(
    'medium',
    () => css`
      width: 50rem;
      height: auto;
      min-height: 30rem;
      border-radius: 1.2rem;
      bottom: unset;
      top: 9.6rem;
      right: 0;
      margin: 0 auto;
    `
  )}
`

const backdropVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}

const createCardVariant = (breakpoint: number) => ({
  in: () =>
    window.innerWidth >= breakpoint
      ? {
          y: 0,
          opacity: 1,
        }
      : { y: 0 },
  out: () =>
    window.innerWidth >= breakpoint
      ? {
          y: '100vh',
          opacity: 0,
        }
      : {
          y: '100vh',
        },
})

const transition = {
  type: 'spring',
  bounce: 0.15,
  duration: 0.6,
}

const Modal: React.FC<Props> = (props) => {
  const theme = useQuilTheme()

  function cardCallbackRef(node: HTMLDivElement) {
    if (node) {
      if (window.innerWidth < theme.breakpoints.medium) {
        document.body.style.minHeight = `${node.offsetHeight / 10 - 9.6}rem`
        document.body.style.overflowX = 'hidden'
      } else {
        document.body.style.overflowX = 'hidden'
      }
    } else {
      document.body.style.minHeight = ''
      document.body.style.overflowX = ''
    }
  }

  return createPortal(
    <AnimatePresence>
      {props.isOpen && (
        <>
          <Backdrop
            variants={backdropVariants}
            initial="out"
            animate="in"
            exit="out"
            onClick={props.onBackdropClick}
          ></Backdrop>
          <Card
            ref={cardCallbackRef}
            variants={createCardVariant(theme.breakpoints.medium)}
            initial="out"
            animate="in"
            exit="out"
            transition={transition}
            data-testid={props['data-testid']}
          >
            {props.children}
          </Card>
        </>
      )}
    </AnimatePresence>,
    document.body
  )
}

export default Modal
