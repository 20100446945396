/**
 * @generated SignedSource<<9bdd6d1c86c4bc6eb02a68c9778522ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HelpQuery$variables = {};
export type HelpQuery$data = {
  readonly content: {
    readonly profilePage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly header: string | null;
      readonly mainCtaText: string | null;
      readonly secondaryBody: {
        readonly raw: any;
      } | null;
      readonly secondaryCtaText: string | null;
    } | null;
  };
  readonly currentSubscription: {
    readonly endDate: {
      readonly formatted: string;
    } | null;
  } | null;
};
export type HelpQuery = {
  response: HelpQuery$data;
  variables: HelpQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Help"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "raw",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "secondaryBody",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryCtaText",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionChoice",
  "kind": "LinkedField",
  "name": "currentSubscription",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Instant",
      "kind": "LinkedField",
      "name": "endDate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formatted",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HelpQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Help\"})"
          }
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HelpQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": "profilePage(where:{\"name\":\"Help\"})"
          }
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "2fffeaa6331710d030178be6710a9047",
    "id": null,
    "metadata": {},
    "name": "HelpQuery",
    "operationKind": "query",
    "text": "query HelpQuery {\n  content {\n    profilePage(where: {name: \"Help\"}) {\n      header\n      body {\n        raw\n      }\n      secondaryBody {\n        raw\n      }\n      mainCtaText\n      secondaryCtaText\n      id\n    }\n  }\n  currentSubscription {\n    endDate {\n      formatted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c69e403b4a5325b04efa63f0cfeafcf6";

export default node;
