import { graphql, useMutation } from 'react-relay'
import { useUpdateEmploymentInfoMutation } from './__generated__/useUpdateEmploymentInfoMutation.graphql'

export function useUpdateEmploymentInfo() {
  return useMutation<useUpdateEmploymentInfoMutation>(graphql`
    mutation useUpdateEmploymentInfoMutation(
      $input: UpdateEmploymentInfoInput
    ) {
      updateEmploymentInfo(input: $input) {
        __typename

        id
        employmentInfo {
          employerName
          jobTitle
          minimumWeeklyHours
          seasonal
          w2
        }
      }
    }
  `)
}
