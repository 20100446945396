import 'core-js/stable'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'regenerator-runtime/runtime'
import App from './App'
import { getOrCreateGuestID } from './common/experimentContext'
;(async () => {
  // Using the async LD provider setup eliminates potential flickering caused by the app
  // rendering, then LD initializing, then rerendering with possible flag/feature changes
  // https://docs.launchdarkly.com/sdk/client-side/react/react-web
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    context: {
      kind: 'guest',
      key: getOrCreateGuestID(),
    },
  })

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  )
  root.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>
  )

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('SW registered: ', registration)
        })
        .catch((registrationError) => {
          console.log('SW registration failed: ', registrationError)
        })
    })
  }
})()
