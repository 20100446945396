import {
  ActionListItem,
  ActionListLink,
  ActionListList,
  AvibraBadge,
  SubheadingAlt,
} from '@quil/ui'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { LayoutInAppTracked } from '../../common/analytics'
import GoBack from '../../common/components/GoBack'
import { Navigate } from '../../common/react-router-wrappers'
import { CounselingQuery } from './__generated__/CounselingQuery.graphql'

export default function Counseling() {
  const pageName = 'Counseling'
  const { vendorBenefits } = useLazyLoadQuery<CounselingQuery>(
    graphql`
      query CounselingQuery {
        vendorBenefits {
          counseling {
            name
            displayName
            icon
            description
          }
        }
      }
    `,
    {}
  )

  // map of Avibra benefit name to frontend route
  const routes = {
    myGrowth: 'well-being',
    esgAdvisor: 'esg-advisor',
    cc: 'credit-counseling',
    dc: 'debt-counseling',
    hc: 'housing-counseling',
  }

  if (vendorBenefits.counseling.length === 0) {
    return <Navigate to="/home/benefits" />
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title="Counseling"
      headerStart={<GoBack />}
    >
      <ActionListList>
        {vendorBenefits.counseling.map((benefit) => (
          <ActionListItem key={benefit.name}>
            <ActionListLink
              to={`/home/benefits/counseling/${
                routes[benefit.name] ?? benefit.name
              }`}
              state={{ from: '/home/benefits/counseling' }}
              data-testid={`benefits_button_${benefit.name}`}
              data-name={benefit.displayName}
              data-page-name={pageName}
            >
              <SubheadingAlt>{benefit.displayName}</SubheadingAlt>
              {benefit.description}
              <AvibraBadge />
            </ActionListLink>
          </ActionListItem>
        ))}
      </ActionListList>
    </LayoutInAppTracked>
  )
}
