/**
 * @generated SignedSource<<befb0397ba84efc2fa31c70d4e6b6ff9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProtectionQuery$variables = {};
export type ProtectionQuery$data = {
  readonly vendorBenefits: {
    readonly protection: ReadonlyArray<{
      readonly description: string;
      readonly displayName: string;
      readonly icon: string;
      readonly name: string;
    } | null>;
  } | null;
};
export type ProtectionQuery = {
  response: ProtectionQuery$data;
  variables: ProtectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VendorBenefits",
    "kind": "LinkedField",
    "name": "vendorBenefits",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VendorBenefit",
        "kind": "LinkedField",
        "name": "protection",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "icon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProtectionQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProtectionQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f1c4b9d777cc8722ec001d62c4a7dda7",
    "id": null,
    "metadata": {},
    "name": "ProtectionQuery",
    "operationKind": "query",
    "text": "query ProtectionQuery {\n  vendorBenefits {\n    protection {\n      name\n      displayName\n      icon\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d491cd21e390940c57093dfcad1265db";

export default node;
