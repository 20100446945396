import styled from 'styled-components'
import { fromTheme } from '../../../theme'

const ActionListItem = styled.li`
  padding: 1rem 0px 1rem;
  border-style: solid;
  border-color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  border-width: 1px 0 0;
  &:last-child {
    border-bottom: solid ${fromTheme((theme) => theme.colors.textPrimary.raw)}
      1px;
  }
  cursor: pointer;
`

export default ActionListItem
