/**
 * @generated SignedSource<<e529186e170c373cf08d2ee0793ab8c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserStatus = "Active" | "Closed" | "Ineligible" | "Pending" | "SecurityLocked" | "%future added value";
export type CheckoutSuccessActivateMembershipMutation$variables = {};
export type CheckoutSuccessActivateMembershipMutation$data = {
  readonly activateMembership: {
    readonly id: string | null;
    readonly status: UserStatus;
  };
};
export type CheckoutSuccessActivateMembershipMutation = {
  response: CheckoutSuccessActivateMembershipMutation$data;
  variables: CheckoutSuccessActivateMembershipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "activateMembership",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutSuccessActivateMembershipMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CheckoutSuccessActivateMembershipMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3bdfd715883c243e29323c7bfe803651",
    "id": null,
    "metadata": {},
    "name": "CheckoutSuccessActivateMembershipMutation",
    "operationKind": "mutation",
    "text": "mutation CheckoutSuccessActivateMembershipMutation {\n  activateMembership {\n    id\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7b84ef481e06ccb52cdc9a56cfa6c7b";

export default node;
