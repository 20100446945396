/**
 * @generated SignedSource<<c9b8524d802ae228d6afb9bc54d7a89c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RoutesQuery$variables = {};
export type RoutesQuery$data = {
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"AccessControl_user">;
  } | null;
};
export type RoutesQuery = {
  response: RoutesQuery$data;
  variables: RoutesQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoutesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccessControl_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RoutesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastCompletedOnboardingStep",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "07891eb1ac3ad8f5d002e8ffb9608f8b",
    "id": null,
    "metadata": {},
    "name": "RoutesQuery",
    "operationKind": "query",
    "text": "query RoutesQuery {\n  currentUser {\n    ...AccessControl_user\n    id\n  }\n}\n\nfragment AccessControl_user on User {\n  status\n  lastCompletedOnboardingStep\n}\n"
  }
};

(node as any).hash = "2aad94a8cdf262dd0f7751eacf0e2a2d";

export default node;
