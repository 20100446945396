/**
 * @generated SignedSource<<179144be33b61bb2b75cfc124cdd45a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BillingCadence = "Monthly" | "Yearly" | "%future added value";
export type SubscriptionChoiceInput = {
  cadence: BillingCadence;
  coverage: number;
};
export type PlansMutation$variables = {
  subscriptionChoiceInput: SubscriptionChoiceInput;
};
export type PlansMutation$data = {
  readonly saveSubscriptionChoice: {
    readonly __typename: "SubscriptionChoice";
  };
};
export type PlansMutation = {
  response: PlansMutation$data;
  variables: PlansMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subscriptionChoiceInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "subscriptionChoiceInput"
      }
    ],
    "concreteType": "SubscriptionChoice",
    "kind": "LinkedField",
    "name": "saveSubscriptionChoice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlansMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlansMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dc58287131875a2058ad515b81260cd3",
    "id": null,
    "metadata": {},
    "name": "PlansMutation",
    "operationKind": "mutation",
    "text": "mutation PlansMutation(\n  $subscriptionChoiceInput: SubscriptionChoiceInput!\n) {\n  saveSubscriptionChoice(input: $subscriptionChoiceInput) {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a4e09afffb0ec7fbc213e3a4ac4d1b9";

export default node;
