/**
 * @generated SignedSource<<7989b4eabfcd6e5684b29bf7aab02536>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCheckoutSessionInput = {
  cancelUrl: string;
  successUrl: string;
};
export type UpgradeAvibraPlanMutation$variables = {
  createSessionInput: CreateCheckoutSessionInput;
};
export type UpgradeAvibraPlanMutation$data = {
  readonly createCheckoutSession: {
    readonly url: string;
  };
};
export type UpgradeAvibraPlanMutation = {
  response: UpgradeAvibraPlanMutation$data;
  variables: UpgradeAvibraPlanMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "createSessionInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "createSessionInput"
      }
    ],
    "concreteType": "CheckoutSession",
    "kind": "LinkedField",
    "name": "createCheckoutSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpgradeAvibraPlanMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpgradeAvibraPlanMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e8b53ee06744086949b3b68d686e3636",
    "id": null,
    "metadata": {},
    "name": "UpgradeAvibraPlanMutation",
    "operationKind": "mutation",
    "text": "mutation UpgradeAvibraPlanMutation(\n  $createSessionInput: CreateCheckoutSessionInput!\n) {\n  createCheckoutSession(input: $createSessionInput) {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "0df572a30f8575762610be86c292ccd4";

export default node;
