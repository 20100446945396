import { ButtonPrimary } from '@quil/ui'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { LayoutInAppTracked } from '../../common/analytics'
import { avibraActivationLink } from '../../common/avibraActivationLink'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import { AvibraBenefitPageQuery } from './__generated__/AvibraBenefitPageQuery.graphql'

type Props = {
  pageName: string
}

/**
 * This component is a common page layout that is used for all Avibra benefit detail pages,
 * for example Life Insurance, Credit Counseling, etc.
 * The `pageName` differentiates what CMS content will be fetched, which is the only real difference between benefits pages.
 * The Layout is nearly identical to the LayoffInsurance page, with the following primary differences
 * - the CTA on this page is an external link, whereas the LayoffInsurance page has an internal link
 * - this page needs to query for the currentUser.id to populate the Avibra link whereas LayoffInsurance does not need to.
 *
 * These pages could be consolidated in the future if there was enough interest.
 */
export default function AvibraBenefitPage(props: Props) {
  const data = useLazyLoadQuery<AvibraBenefitPageQuery>(
    graphql`
      query AvibraBenefitPageQuery($name: String!) {
        content {
          benefitPage(where: { name: $name }) {
            header
            body {
              raw
            }
            mainCtaText
          }
        }
        currentUser {
          id
        }
      }
    `,
    { name: props.pageName }
  )

  return (
    <LayoutInAppTracked
      pageName={props.pageName}
      title={data.content.benefitPage.header}
      headerStart={<GoBack />}
    >
      <RichText content={data.content.benefitPage.body.raw} />
      <ButtonPrimary
        as="a"
        href={avibraActivationLink(data.currentUser.id)}
        data-text={data.content.benefitPage.mainCtaText}
        data-page-name={props.pageName}
        data-name={'Primary CTA'}
      >
        {data.content.benefitPage.mainCtaText}
      </ButtonPrimary>
    </LayoutInAppTracked>
  )
}
