import { plaidLinkSessionEnded } from '@quil/analytics-events'
import {
  ButtonPrimary,
  ButtonSecondary,
  ModalHeadline,
  PageFooter,
  Subheading,
  fromTheme,
  standardToastHeadlines,
  useShowToast,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import * as analytics from '@web/common/analytics/analytics-adapter'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import { useModal } from '@web/common/modal-context'
import usePlaidLinkToken from '@web/common/usePlaidLinkToken'
import { useCallback, useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import { useNavigate } from 'react-router-dom'
import { graphql } from 'relay-runtime'
import styled from 'styled-components'
import { PayoutAccountQuery } from './__generated__/PayoutAccountQuery.graphql'
import { PayoutAccountSettingsAddPlaidAccountMutation } from './__generated__/PayoutAccountSettingsAddPlaidAccountMutation.graphql'

const PayoutAccountContainer = styled.div`
  padding: 1rem 0px 1rem;
  border-style: solid;
  border-color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  border-width: 1px 0 0;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  background-color: ${fromTheme((theme) => theme.colors.background.raw)};
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  font-weight: 400;
  text-decoration: none;
  width: 100%;
  border-bottom: solid ${fromTheme((theme) => theme.colors.textPrimary.raw)} 1px;
`

const PayoutAccountName = styled(Subheading)`
  margin-bottom: 0.7rem;
`

export default function PayoutAccount() {
  const pageName = 'Settings: Payout Account'
  const navigate = useNavigate()
  const showToast = useShowToast()
  const { openModal, closeModal } = useModal()
  const data = useLazyLoadQuery<PayoutAccountQuery>(
    graphql`
      query PayoutAccountQuery {
        content {
          profilePage(where: { name: "Payout Account" }) {
            header
            body {
              raw
            }
            mainCtaText
          }
          modal(where: { name: "Settings Confirm Link New Account" }) {
            heading
            body {
              raw
            }
            mainCtaText
            secondaryCtaText
          }
        }
        currentLayoffClaim {
          id
          status
        }
        payoutAccount {
          bankName
          accountNumberMask
        }
      }
    `,
    {}
  )

  useEffect(() => {
    if (!data.payoutAccount) {
      navigate('/home/settings')
    }
  }, [])

  const [changePayoutAccount, changePayoutAccountInFlight] =
    useMutation<PayoutAccountSettingsAddPlaidAccountMutation>(graphql`
      mutation PayoutAccountSettingsAddPlaidAccountMutation(
        $input: AddPlaidAccountInput!
      ) {
        addPlaidAccount(input: $input) {
          id
          bankName
          accountNumberMask
        }
      }
    `)

  const goToManualLinkingStep = useCallback(() => {
    navigate('/home/settings/payout-account-manual')
  }, [navigate])

  const [linkToken, linkTokenLoading, linkTokenError] = usePlaidLinkToken()
  useEffect(() => {
    if (linkTokenError) {
      console.error(linkTokenError)
      goToManualLinkingStep()
    }
  }, [linkTokenError, goToManualLinkingStep])
  const updateClaim = ['Pending', 'InReview'].includes(
    data.currentLayoffClaim?.status
  )

  const { open, ready, error } = usePlaidLink({
    token: linkToken,
    onSuccess(publicToken, metadata) {
      analytics.track(
        plaidLinkSessionEnded('success', linkToken, { ...metadata })
      )
      changePayoutAccount({
        variables: {
          input: {
            claimId: updateClaim ? data.currentLayoffClaim.id : null,
            publicToken,
            linkToken,
          },
        },
        onError() {
          showToast({
            severity: 'Error',
            headline: standardToastHeadlines.genericError,
            message: 'Click to retry',
            onClick() {
              navigate(0)
            },
          })
        },
        updater(store, payload) {
          const payoutAccount = store.get(payload.addPlaidAccount.id)
          store.getRoot().setLinkedRecord(payoutAccount, 'payoutAccount')
          if (updateClaim) {
            store
              .getRoot()
              .getLinkedRecord('currentLayoffClaim')
              .setLinkedRecord(payoutAccount, 'payoutAccount')
          }
        },
      })
    },
    onExit(error, metadata) {
      analytics.track(
        plaidLinkSessionEnded('failure', linkToken, { ...error, ...metadata })
      )
      goToManualLinkingStep()
    },
  })

  useEffect(() => {
    if (error) {
      console.error(error)
      goToManualLinkingStep()
    }
  }, [error, goToManualLinkingStep])

  function handleChangeAccountClick() {
    openModal(
      <>
        <ModalHeadline>{data.content.modal.heading}</ModalHeadline>
        <RichText
          content={data.content.modal.body.raw}
          data={{
            bankName: data.payoutAccount.bankName ?? '',
            accountNumberMask: data.payoutAccount.accountNumberMask,
          }}
        />
        <PageFooter>
          <ButtonSecondary
            data-name="Dismiss Link New Account CTA"
            onClick={closeModal}
          >
            {data.content.modal.secondaryCtaText}
          </ButtonSecondary>
          <ButtonPrimary
            data-name="Link New Account CTA"
            onClick={handleConfirmClick}
          >
            {data.content.modal.mainCtaText}
          </ButtonPrimary>
        </PageFooter>
      </>
    )
  }

  function handleConfirmClick() {
    closeModal()
    open()
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      headerStart={<GoBack />}
      title={data.content.profilePage.header}
      footerContent={
        <ButtonPrimary
          data-name="change-payout-account"
          onClick={handleChangeAccountClick}
        >
          {data.content.profilePage.mainCtaText}
        </ButtonPrimary>
      }
    >
      <RichText content={data.content.profilePage.body.raw} />
      <PayoutAccountContainer>
        <PayoutAccountName>
          {data.payoutAccount.bankName ?? 'Account ending in'}
        </PayoutAccountName>
        *{data.payoutAccount.accountNumberMask}
      </PayoutAccountContainer>
    </LayoutInAppTracked>
  )
}
