/**
 * @generated SignedSource<<9486f72e7a2a8b4b60a1b0ffcc008b1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RecentActivityCardType = "AccountActivated" | "AccountClosed" | "ClaimApproved" | "ClaimDenied" | "ClaimSubmitted" | "EligibleForClaims" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecentActivity_query$data = {
  readonly content: {
    readonly recentActivities: ReadonlyArray<{
      readonly body: {
        readonly raw: any;
      } | null;
      readonly ctaText: string | null;
      readonly heading: string | null;
      readonly name: string | null;
    }>;
  };
  readonly recentActivities: ReadonlyArray<{
    readonly data: string | null;
    readonly type: RecentActivityCardType;
  }>;
  readonly " $fragmentType": "RecentActivity_query";
};
export type RecentActivity_query$key = {
  readonly " $data"?: RecentActivity_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentActivity_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentActivity_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "recentActivities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Cms_RecentActivity",
          "kind": "LinkedField",
          "name": "recentActivities",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "heading",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Cms_RichText",
              "kind": "LinkedField",
              "name": "body",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "raw",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ctaText",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d80759ce47c18b03e5b2c14a293a89ed";

export default node;
