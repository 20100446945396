import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { fromTheme, hsla, ThemeProvider } from '../../theme'
import ButtonPrimary from '../ButtonPrimary'

type Props = {
  title: string
  body: string | JSX.Element
  cta?: {
    text: string
    url: string
  }
  backgroundColor?: string
  image?: {
    alt: string
    url: string
  }
  className?: string
  'data-name': string
  'data-page-name'?: string
  'data-text'?: string
}

const Container = styled.div<{
  backgroundColor?: string
}>`
  color: ${fromTheme(({ colors }) => colors.textPrimary.raw)};
  background-color: ${fromTheme(
    ({ colors }, props) => props.backgroundColor ?? colors.background.raw
  )};
  box-shadow: 0 0 30px ${fromTheme(({ colors }) => hsla(colors.shadow, 0.1))};
  border-radius: 5px;
  overflow: hidden;
  /* allows buttons to "sink" to bottom of the container, when the container fills the available space */
  display: flex;
  flex-direction: column;

  ${ButtonPrimary} {
    display: block;
    /* "margin-top: auto" is required for button to sink to bottom, when container is larger than its contents */
    margin: auto 0.8rem 1.6rem;
  }
`

const CopyContainer = styled.div<{ hasImage: boolean }>`
  font-size: 1.6rem;
  padding: 1.6rem 0.8rem;
  width: calc(100% - ${(props) => (props.hasImage ? `14.4rem` : 0)});
`

const Header = styled.h4`
  font-size: 1.6rem;
  margin: 0 0 0.34rem;
`

const Img = styled.img`
  width: 14.4rem;
`
const Row = styled.div`
  display: flex;
  margin-bottom: 1.6rem;
  align-items: flex-start;
`
export function ContentCard(props: Props) {
  const isLinkExternal = props.cta?.url.indexOf('://') !== -1

  return (
    <ThemeProvider override="quilThemeLight">
      <Container
        className={props.className}
        backgroundColor={props.backgroundColor}
      >
        <Row>
          <CopyContainer hasImage={!!props.image}>
            <Header>{props.title}</Header>
            {props.body}
          </CopyContainer>
          {props.image && <Img src={props.image.url} alt={props.image.alt} />}
        </Row>
        {props.cta &&
          (isLinkExternal ? (
            <ButtonPrimary
              as="a"
              href={props.cta.url}
              data-name={props['data-name']}
              data-page-name={props['data-page-name']}
              data-text={props['data-text']}
            >
              {props.cta.text}
            </ButtonPrimary>
          ) : (
            <ButtonPrimary
              as={Link}
              to={props.cta.url}
              data-name={props['data-name']}
              data-page-name={props['data-page-name']}
              data-text={props['data-text']}
            >
              {props.cta.text}
            </ButtonPrimary>
          ))}
      </Container>
    </ThemeProvider>
  )
}
