import { BodyText, ButtonPrimary, SpinnerFullScreen } from '@quil/ui'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-relay'
import { graphql } from 'relay-runtime'
import { LayoutSignupTracked } from '../common/analytics'
import useResumeOnboarding from '../common/components/useResumeOnboarding'
import { useLocation, useNavigate } from '../common/react-router-wrappers'
import {
  ContactVerificationFailureType,
  VerifyContactMutation,
} from './__generated__/VerifyContactMutation.graphql'

const mutation = graphql`
  mutation VerifyContactMutation(
    $verificationId: String!
    $verificationCode: String!
  ) {
    verifyContact(
      verificationId: $verificationId
      verificationCode: $verificationCode
    ) {
      __typename
      ... on User {
        lastCompletedOnboardingStep
        status
      }
      ... on ContactVerificationFailure {
        type
      }
    }
  }
`

type ResultType = 'Success' | 'UnknownError' | ContactVerificationFailureType

function title(result: ResultType): string {
  switch (result) {
    case 'Success':
      return 'Verified'
    case 'Expired':
      return 'Link expired'
    default:
      return 'Something went wrong'
  }
}

function body(result: ResultType): string {
  switch (result) {
    case 'Success':
      return 'Thank you for verifying your contact info!'
    case 'Expired':
      return 'Oops! It looks like your verification link expired. Please log in and re-enter your new contact and we will send you a new link'
    default:
      return 'We could not verify your contact info. Please try again.'
  }
}

function VerifyContact() {
  const pageName = 'Verify Contact'
  const navigate = useNavigate()
  const resumeOnboarding = useResumeOnboarding()
  const location = useLocation()
  const [result, setResult] = useState<ResultType>('Success')
  const [commitVerifyContactMutation, verifyContactMutationInFlight] =
    useMutation<VerifyContactMutation>(mutation)

  function handleCtaClick() {
    navigate('/home')
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    commitVerifyContactMutation({
      variables: {
        verificationId: params.get('vi') ?? '',
        verificationCode: params.get('vc') ?? '',
      },
      onCompleted(response) {
        switch (response.verifyContact?.__typename) {
          case 'User': {
            const user = response.verifyContact
            // if user is still onboarding redirect them to the next step
            if (user.status === 'Pending') {
              return resumeOnboarding(user.lastCompletedOnboardingStep)
            }
            break
          }

          case 'ContactVerificationFailure':
            setResult(response.verifyContact.type)
            break

          default:
            // nothing to do: contact was likey verified without an active session
            return
        }
      },
      onError(error) {
        console.error({ error, mutation: 'verifyContact' })
        setResult('UnknownError')
      },
    })
    // Do not include resumeOnboarding here, it will cause an infinite loop
  }, [commitVerifyContactMutation, navigate, location.search])

  return verifyContactMutationInFlight ? (
    <SpinnerFullScreen />
  ) : (
    <LayoutSignupTracked
      pageName={pageName}
      title={title(result)}
      footerContent={
        <ButtonPrimary
          data-page-name={pageName}
          data-name="Primary CTA"
          onClick={handleCtaClick}
        >
          Home
        </ButtonPrimary>
      }
    >
      <BodyText>{body(result)}</BodyText>
    </LayoutSignupTracked>
  )
}

export default VerifyContact
