import { FileEntity } from '@web/common/file-utils'
import { useCallback, useReducer, useRef } from 'react'

enum ActionType {
  Add,
  Update,
  Remove,
}

type State = {
  payStubs: FileEntity[]
  proofOfStateBenefitsApplication: FileEntity[]
  proofOfStateBenefitsPayment: FileEntity[]
}

type AddFileAction = {
  type: ActionType.Add
  payload: {
    key: keyof State
    file: FileEntity
  }
}

type UpdateFileAction = {
  type: ActionType.Update
  payload: {
    key: keyof State
    handle: string
    updates: Partial<FileEntity>
  }
}

type RemoveFileAction = {
  type: ActionType.Remove
  payload: {
    key: keyof State
    handle: string
  }
}

type Action = AddFileAction | UpdateFileAction | RemoveFileAction

function throwBadAction(action: never): never
function throwBadAction(action: Action) {
  throw new Error('Unknown action type: ' + action.type)
}

function reducer(previousState: State, action: Action): State {
  switch (action.type) {
    case ActionType.Add: {
      const { key, file } = action.payload
      return {
        ...previousState,
        [key]: [...previousState[key], file],
      }
    }

    case ActionType.Update: {
      const { key, handle, updates } = action.payload
      return {
        ...previousState,
        [key]: previousState[key].map((file) =>
          file.handle === handle ? { ...file, ...updates } : file
        ),
      }
    }
    case ActionType.Remove: {
      const { key, handle } = action.payload
      return {
        ...previousState,
        [key]: previousState[key].filter((file) => file.handle !== handle),
      }
    }

    default:
      throwBadAction(action)
  }
}

export function useDocumentsReducer(initialState: State) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const addFile = useCallback((key: keyof State, file: FileEntity) => {
    dispatch({ type: ActionType.Add, payload: { key, file } })
  }, [])

  const removeFile = useCallback((key: keyof State, handle: string) => {
    dispatch({ type: ActionType.Remove, payload: { key, handle } })
  }, [])

  const updateFile = useCallback(
    (key: keyof State, handle: string, updates: Partial<FileEntity>) => {
      dispatch({ type: ActionType.Update, payload: { key, handle, updates } })
    },
    []
  )

  const actionsRef = useRef({
    addFile,
    removeFile,
    updateFile,
  })

  return [state, actionsRef.current] as const
}

export function uploadsInProgress(state: State) {
  return Object.values(state).some((files) =>
    files.some((file) => file.state === 'pending')
  )
}
