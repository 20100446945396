import { motion } from 'framer-motion'
import styled from 'styled-components'
import { fromTheme, useQuilTheme } from '../../theme'

type Props = {
  step: number
  of: number
}

const variants = {
  hidden: { pathLength: 0 },
  show: (pathLength: number) => ({
    pathLength,
    opacity: pathLength > 0 ? 1 : 0,
  }),
}

const Container = styled.div`
  position: relative;
  width: 4rem;
`

const Label = styled.div`
  font-family: 'Manrope';
  position: absolute;
  top: calc(50% - 0.2rem);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
`

export function ProgressTracker({ step, of }: Props) {
  const completed = step / of
  const theme = useQuilTheme()
  return (
    <Container>
      <motion.svg
        width="100%"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.circle
          fill="transparent"
          cx="20"
          cy="20"
          r="18"
          stroke={theme.colors.foregroundHighlight.raw}
          strokeWidth="4"
          strokeLinecap="round"
          custom={completed}
          variants={variants}
          initial="hidden"
          animate="show"
          style={{
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
          }}
        />
      </motion.svg>
      <Label>
        {step}/{of}
      </Label>
    </Container>
  )
}
