import { useState } from 'react'
import { graphql, useMutation } from 'react-relay'
import { logout } from '../../logout'
import { useLogoutMutation } from './__generated__/useLogoutMutation.graphql'

export function useLogout(): [boolean, () => void] {
  const [commitMutation, mutationInFlight] =
    useMutation<useLogoutMutation>(graphql`
      mutation useLogoutMutation {
        logout
      }
    `)

  // We use our own state for this rather than the state that
  // useMutation returns. The reason for this is there is a period of
  // time where the mutation to logout had resolved but the network
  // call for the refresh is still happening. We set out own state to
  // true indefinitely so that the spinner stays on the screen rather
  // than flashing only while the mutation is pending.
  const [loggingOut, setLoggingOut] = useState(false)

  function handleLogoutClick() {
    if (!mutationInFlight) {
      setLoggingOut(true)

      commitMutation({
        variables: {},
        onCompleted() {
          logout()
        },
        onError() {
          setLoggingOut(false)
        },
      })
    }
  }

  return [loggingOut || mutationInFlight, handleLogoutClick]
}
