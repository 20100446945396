import { ButtonPrimary, ChecklistSuccess } from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import RichText from '@web/common/components/RichText'
import { useNavigate } from '@web/common/react-router-wrappers'
import { graphql, useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components'
import { SuccessQuery } from './__generated__/SuccessQuery.graphql'

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  svg {
    height: 18.4rem;
    width: 16.4rem;
    filter: drop-shadow(-8px 8px 0px rgba(16, 5, 33, 0.1));
  }
`

const Success = () => {
  const pageName = 'New Claim: Success '
  const navigate = useNavigate()
  const data = useLazyLoadQuery<SuccessQuery>(
    graphql`
      query SuccessQuery {
        content {
          newClaimPage(where: { name: "Success" }) {
            heading
            body {
              raw
            }
            mainCtaText
          }
        }
      }
    `,
    { pageName }
  )

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title={data.content.newClaimPage.heading}
      footerContent={
        <ButtonPrimary
          data-page-name={pageName}
          data-name="Primary CTA"
          onClick={() => navigate('/home')}
        >
          {data.content.newClaimPage.mainCtaText}
        </ButtonPrimary>
      }
    >
      <RichText content={data.content.newClaimPage.body.raw} />
      <IconContainer>
        <ChecklistSuccess />
      </IconContainer>
    </LayoutInAppTracked>
  )
}

export default Success
