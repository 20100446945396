/**
 * @generated SignedSource<<c91ac52ad5e9f8e4e8dbf2eb500f883e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AvibraBenefitPageQuery$variables = {
  name: string;
};
export type AvibraBenefitPageQuery$data = {
  readonly content: {
    readonly benefitPage: {
      readonly body: {
        readonly raw: any;
      };
      readonly header: string;
      readonly mainCtaText: string;
    } | null;
  };
  readonly currentUser: {
    readonly id: string | null;
  } | null;
};
export type AvibraBenefitPageQuery = {
  response: AvibraBenefitPageQuery$data;
  variables: AvibraBenefitPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AvibraBenefitPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Cms_BenefitPage",
            "kind": "LinkedField",
            "name": "benefitPage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AvibraBenefitPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Cms_BenefitPage",
            "kind": "LinkedField",
            "name": "benefitPage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a67c9e14d2ef59264d12e72475c92991",
    "id": null,
    "metadata": {},
    "name": "AvibraBenefitPageQuery",
    "operationKind": "query",
    "text": "query AvibraBenefitPageQuery(\n  $name: String!\n) {\n  content {\n    benefitPage(where: {name: $name}) {\n      header\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n  }\n  currentUser {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8cbe3d92268ea735e2526c4b6689a48b";

export default node;
