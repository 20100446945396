import { EventDefinition } from '@quil/analytics-events'
import ReactGA from 'react-ga4'

// Wrapping analytics.js because:
// - We want to use @quil/analytics-events for vendor-agnostic event formatting without destructuring event objects at each call site
// - We want Promises/async instead of callbacks

export function reset() {
  //do nothing
}

export function page(name: string, properties?: Record<string, unknown>) {
  ReactGA.send({
    hitType: 'pageview',
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: name,
  })
}

function makeAsyncAnalyticsCall(config: {
  onInitialized: (resolve: (value: void | PromiseLike<void>) => void) => void
  onUninitialized: () => void
}) {
  return new Promise<void>((resolve) => {
    try {
      // if analytics has loaded, resolve the promise in the callback
      // otherwise, resolve immediately.
      // @ts-expect-error: definition missing initialized
      if (analytics.initialized) {
        config.onInitialized(resolve)
      } else {
        config.onUninitialized()
        resolve()
      }
    } catch (e) {
      // intentionally swallow errors. Analytics errors should not disrupt the functionality of the app. console.error is automatically sent to DD
      console.error(e)
      resolve()
    }
  })
}

export function track(e: EventDefinition) {
  //do nothing
}

type UserTraits = Record<string, unknown>

export function identify(userId: string, traits?: UserTraits) {
  ReactGA.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
    user_id: userId,
  })
}

export async function identifyAnonymous(traits: UserTraits) {
  //do nothing
}
