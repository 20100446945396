import { fromTheme } from '@quil/ui'
import { graphql, useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components'
import { LayoutInAppTracked } from '../../../common/analytics'
import GoBack from '../../../common/components/GoBack'
import { DisclosureType } from '../__generated__/LegalDocsAgreementQuery.graphql'
import { LegalDocDetailQuery } from './__generated__/LegalDocDetailQuery.graphql'

export const DisclosureContainer = styled.div`
  font-size: 1.6rem;
  overflow-x: auto;
  margin-bottom: 2.4rem;

  table {
    border: 1px solid ${fromTheme((theme) => theme.colors.foregroundMuted.raw)};
    width: 100%;

    border-collapse: collapse;
    th,
    td {
      border: 1px solid
        ${fromTheme((theme) => theme.colors.foregroundMuted.raw)};
      padding: 4px;
    }
  }

  img {
    max-width: 100%;
  }
`

type Props = {
  type: DisclosureType
}
export default function LegalDocDetail(props: Props) {
  const data = useLazyLoadQuery<LegalDocDetailQuery>(
    graphql`
      query LegalDocDetailQuery($type: DisclosureType!) {
        agreement(type: $type) {
          id
          html
          url
        }
      }
    `,
    { type: props.type }
  )

  if (!data.agreement?.html) {
    window.location.assign(data.agreement.url)
  }

  return (
    <LayoutInAppTracked
      pageName={`Legal Docs ${props.type}`}
      headerStart={<GoBack />}
    >
      <DisclosureContainer
        dangerouslySetInnerHTML={{ __html: data.agreement?.html }}
      />
    </LayoutInAppTracked>
  )
}
