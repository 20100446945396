import { useEffect } from 'react'
import { graphql, useFragment } from 'react-relay'
import { useNavigate } from '../../react-router-wrappers'
import useResumeOnboarding from '../useResumeOnboarding'
import {
  OnboardingStep,
  useContinueOnboarding_user$key,
} from './__generated__/useContinueOnboarding_user.graphql'

const fragment = graphql`
  fragment useContinueOnboarding_user on User {
    lastCompletedOnboardingStep
    status
  }
`

// Used to make sure that users complete onboarding pages in order post employment
// verification. This is to prevent things like getting to Verify Personal Info
// without being prefilled
export default function useContinueOnboarding(
  user: useContinueOnboarding_user$key,
  allowedOnboardingSteps: Readonly<OnboardingStep[]>
) {
  const resumeOnboarding = useResumeOnboarding()
  const navigate = useNavigate()
  const data = useFragment(fragment, user)
  return useEffect(() => {
    if (data.status === 'Ineligible') {
      return navigate('/signup/denied/identity')
    }
    if (!allowedOnboardingSteps.includes(data.lastCompletedOnboardingStep)) {
      return resumeOnboarding(data.lastCompletedOnboardingStep)
    }
  })
}
