import * as event from '@quil/analytics-events'
import { BodyText, ButtonPrimary, IntroBlock, TextLinkPrimary } from '@quil/ui'
import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import styled from 'styled-components'
import { LayoutInAppTracked } from '../../common/analytics'
import * as analytics from '../../common/analytics/analytics-adapter'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import { Link } from '../../common/react-router-wrappers'
import { HelpQuery } from './__generated__/HelpQuery.graphql'

const StyledLink = styled(TextLinkPrimary)`
  font-size: 1.6rem;
`
const CloseAccountContainer = styled.div`
  margin-top: 3.2rem;
`

export function Help() {
  const pageName = 'Help'
  const data = useLazyLoadQuery<HelpQuery>(
    graphql`
      query HelpQuery {
        content {
          profilePage(where: { name: "Help" }) {
            header
            body {
              raw
            }
            secondaryBody {
              raw
            }
            mainCtaText
            secondaryCtaText
          }
        }
        currentSubscription {
          endDate {
            formatted
          }
        }
      }
    `,
    {}
  )

  function handleContactSupport() {
    analytics.track(event.supportLinkClicked())
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title={data.content.profilePage.header}
      headerStart={<GoBack />}
    >
      <RichText content={data.content.profilePage.body.raw} />
      <ButtonPrimary
        onClick={handleContactSupport}
        as={'a'}
        href="mailto:support@getquil.com"
        data-name="Contact support"
        data-page-name={pageName}
      >
        {data.content.profilePage.mainCtaText}
      </ButtonPrimary>
      <CloseAccountContainer>
        {data.currentSubscription.endDate ? (
          <IntroBlock>
            You are set to be closed on{' '}
            {data.currentSubscription.endDate.formatted}
          </IntroBlock>
        ) : (
          <>
            <RichText
              content={data.content.profilePage.secondaryBody.raw}
              renderers={{
                p: ({ children }) => <BodyText>{children}</BodyText>,
              }}
            />
            <StyledLink
              as={Link}
              to="/home/settings/close-membership"
              data-name="Close membership"
              data-page-name={pageName}
              bold
            >
              {data.content.profilePage.secondaryCtaText}
            </StyledLink>
          </>
        )}
      </CloseAccountContainer>
    </LayoutInAppTracked>
  )
}
