/**
 * @generated SignedSource<<ce48786aed548210bf911d72a4c62f89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TheBasicsQuery$variables = {};
export type TheBasicsQuery$data = {
  readonly content: {
    readonly onboardingPage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
  };
  readonly disclosures: {
    readonly " $fragmentSpreads": FragmentRefs<"DocLink_disclosures">;
  } | null;
};
export type TheBasicsQuery = {
  response: TheBasicsQuery$data;
  variables: TheBasicsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "The Basics"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TheBasicsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocLink_disclosures"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"The Basics\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TheBasicsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "electronicCommunications",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "mobileNetworkConsent",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "patriotAct",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraPrivacyPolicy",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraTermsOfService",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraMembershipAgreement",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitsDisclosure",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "plateauApplication",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "privacyPolicy",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "termsOfService",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitEligibility",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "promotionalTerms",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"The Basics\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f39dcddc90e0a63bd25a874ca9c44e1",
    "id": null,
    "metadata": {},
    "name": "TheBasicsQuery",
    "operationKind": "query",
    "text": "query TheBasicsQuery {\n  disclosures {\n    ...DocLink_disclosures\n  }\n  content {\n    onboardingPage(where: {pageName: \"The Basics\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n  }\n}\n\nfragment DocLink_disclosures on Disclosures {\n  electronicCommunications {\n    url\n    version\n  }\n  mobileNetworkConsent {\n    url\n    version\n  }\n  patriotAct {\n    url\n    version\n  }\n  avibraPrivacyPolicy {\n    url\n    version\n  }\n  avibraTermsOfService {\n    url\n    version\n  }\n  avibraMembershipAgreement {\n    url\n    version\n  }\n  benefitsDisclosure {\n    url\n    version\n  }\n  plateauApplication {\n    url\n    version\n  }\n  privacyPolicy {\n    url\n    version\n  }\n  termsOfService {\n    url\n    version\n  }\n  benefitEligibility {\n    url\n    version\n  }\n  promotionalTerms {\n    url\n    version\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f116e02e039ac5b285b8f70beb17f5e";

export default node;
