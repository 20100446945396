import {
  ButtonPrimary,
  ButtonSecondary,
  Headline,
  PageFooter,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { LayoutSignupTracked } from '../../../common/analytics'
import RichText from '../../../common/components/RichText'
import useContinueOnboarding from '../../../common/components/useContinueOnboarding'
import { useModal } from '../../../common/modal-context'
import { useNavigate } from '../../../common/react-router-wrappers'
import { SignupPageProps } from '../../SignupPageProps'
import { UpgradeAvibraPlanMutation } from './__generated__/UpgradeAvibraPlanMutation.graphql'
import { UpgradeAvibraPlanQuery } from './__generated__/UpgradeAvibraPlanQuery.graphql'

const query = graphql`
  query UpgradeAvibraPlanQuery {
    currentUser {
      id
      ...useContinueOnboarding_user
    }
    content {
      onboardingPage(where: { pageName: "Upgrade Avibra Plan" }) {
        heading
        body {
          raw
        }
        mainCtaText
        secondaryCtaText
      }
      modal(where: { name: "Decline Upgrade Avibra Plan" }) {
        heading
        body {
          raw
        }
        mainCtaText
        secondaryCtaText
      }
    }
  }
`

const mutation = graphql`
  mutation UpgradeAvibraPlanMutation(
    $createSessionInput: CreateCheckoutSessionInput!
  ) {
    createCheckoutSession(input: $createSessionInput) {
      url
    }
  }
`

function baseUrl() {
  return `${window.location.protocol}//${window.location.host}`
}

function UpgradeAvibraPlan({ allowedLastOnboardingSteps }: SignupPageProps) {
  const pageName = 'Upgrade Avibra Plan'
  const showToast = useShowToast()
  const { openModal, closeModal } = useModal()
  const data = useLazyLoadQuery<UpgradeAvibraPlanQuery>(query, {})
  const navigate = useNavigate()

  const [commitMutation, mutationInFlight] =
    useMutation<UpgradeAvibraPlanMutation>(mutation)

  function handleChangePlansClick() {
    commitMutation({
      variables: {
        createSessionInput: {
          cancelUrl: `${baseUrl()}/signup/verify-personal-info`,
          successUrl: `${baseUrl()}/checkout-success?sessionId={CHECKOUT_SESSION_ID}`,
        },
      },
      async onCompleted(response) {
        window.location.href = response.createCheckoutSession.url
      },
      onError(error) {
        console.error({
          error,
          mutation: 'upgradeAvibraPlan',
          userId: data.currentUser?.id,
        })
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.genericError,
          message: standardToastMessages.retry,
          onClick: handleChangePlansClick,
        })
      },
    })
  }

  function handleConfirmKeepPlan() {
    navigate('/signup/see-you-later')
  }

  function handleKeepCurrentPlanClick() {
    openModal(
      <>
        <Headline>{data.content.modal.heading}</Headline>
        <RichText content={data.content.modal.body.raw} />
        <PageFooter>
          <ButtonSecondary
            data-page-name={pageName}
            data-name="Close Decline Upgrade Plan Modal CTA"
            onClick={handleConfirmKeepPlan}
          >
            {data.content.modal.secondaryCtaText}
          </ButtonSecondary>

          <ButtonPrimary
            data-page-name={pageName}
            data-name="Confirm Upgrade Plan CTA"
            onClick={closeModal}
          >
            {data.content.modal.mainCtaText}
          </ButtonPrimary>
        </PageFooter>
      </>
    )
  }

  useContinueOnboarding(data.currentUser, allowedLastOnboardingSteps)

  return (
    <LayoutSignupTracked
      pageName={pageName}
      title={data.content.onboardingPage.heading}
      footerContent={
        <>
          <ButtonSecondary
            data-page-name={pageName}
            data-name="Decline Upgrade Plan CTA"
            onClick={handleKeepCurrentPlanClick}
            disabled={mutationInFlight}
          >
            {data.content.onboardingPage.secondaryCtaText}
          </ButtonSecondary>
          <ButtonPrimary
            data-page-name={pageName}
            data-name="Upgrade Plan CTA"
            onClick={handleChangePlansClick}
            disabled={mutationInFlight}
          >
            {data.content.onboardingPage.mainCtaText}
          </ButtonPrimary>
        </>
      }
    >
      <RichText content={data.content.onboardingPage.body.raw} />
    </LayoutSignupTracked>
  )
}

export default UpgradeAvibraPlan
