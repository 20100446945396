/**
 * @generated SignedSource<<6fe7207cfe5111d4f1e5831a134ed464>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailText_user$data = {
  readonly email: string | null;
  readonly unverifiedEmail: string | null;
  readonly " $fragmentType": "EmailText_user";
};
export type EmailText_user$key = {
  readonly " $data"?: EmailText_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailText_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailText_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unverifiedEmail",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4cf1d8db43bf1a435bf8c29f8c144576";

export default node;
