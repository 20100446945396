import React from 'react'
import InputText, { Props as InputTextProps } from '../InputText'

type Props = Pick<
  InputTextProps,
  'value' | 'onChange' | 'error' | 'data-testid'
>

const InputAddressZip: React.ForwardRefRenderFunction<
  HTMLInputElement,
  Props
> = (props, ref) => {
  return (
    <InputText
      {...props}
      maxLength={5}
      ref={ref}
      label="Zip/Postal code"
      name="postal_code"
      placeholder="99100"
      autoComplete="postal-code"
    />
  )
}

export default React.forwardRef(InputAddressZip)
