import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { SyncLoader } from 'react-spinners'
import styled from 'styled-components'
import { fromTheme, hsla, useQuilTheme } from '../../theme'
import { Subheading } from '../typography'

type Props = {
  processing: boolean
  delayedTimeout?: number
  stalledTimeout?: number
  stalledText?: string
}

type LoadingState =
  // not started
  | 'standby'
  // started
  | 'started'
  // taking time
  | 'delayed'
  // taking an unexpectedly long time
  | 'stalled'

const Overlay = styled.div`
  align-items: center;
  background-color: ${fromTheme(({ colors: { background } }) =>
    hsla(background, 0.75)
  )};
  display: fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding: 2.4rem;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100vw;

  ${Subheading} {
    margin-top: 2.4rem;
  }
`

const PortaledOverlay: React.FC<{
  children: React.ReactNode
}> = (props) => createPortal(<Overlay>{props.children}</Overlay>, document.body)

export const ProcessingOverlay: React.FC<Props> = (props) => {
  const didMountRef = useRef(false)
  const [loadingState, setLoadingState] = useState<LoadingState>('standby')
  const { colors } = useQuilTheme()

  useEffect(() => {
    if (didMountRef.current) {
      if (props.processing) {
        setLoadingState('started')

        const delayedTimeout = setTimeout(() => {
          setLoadingState('delayed')
        }, props.delayedTimeout ?? 1000)

        const stalledTimeout = setTimeout(() => {
          setLoadingState('stalled')
        }, props.stalledTimeout ?? 5000)

        // clean up
        return () => {
          clearTimeout(delayedTimeout)
          clearTimeout(stalledTimeout)
        }
      } else {
        setLoadingState('standby')
      }
    } else {
      didMountRef.current = true
    }
  }, [
    setLoadingState,
    props.processing,
    props.delayedTimeout,
    props.stalledTimeout,
  ])

  switch (loadingState) {
    case 'delayed':
      return (
        <PortaledOverlay>
          <SyncLoader color={colors.foregroundPrimary.raw} />
        </PortaledOverlay>
      )
    case 'stalled':
      return (
        <PortaledOverlay>
          <SyncLoader color={colors.foregroundPrimary.raw} />{' '}
          <Subheading>
            {props.stalledText ?? 'Sorry, this is taking longer than expected.'}
          </Subheading>
        </PortaledOverlay>
      )
    case 'standby':
    case 'started':
    default:
      return null
  }
}
