import styled, { css } from 'styled-components'
import { brandFocusRing } from '../../common/styles/focus-ring'
import { fromTheme } from '../../theme'

export type Props = {
  disabled?: boolean
  bold?: boolean
  'data-name': string
}

const TextLinkPrimary = styled.button<Props>`
  color: ${fromTheme((theme) => theme.colors.textLink.raw)};
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: ${(props) => (props.bold ? 700 : 500)};
  padding: 0.2rem 0;
  background: none;
  border: none;
  position: relative;
  text-decoration: underline;

  ${brandFocusRing}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: default;
    `}
`
export default TextLinkPrimary
