import {
  ActionListItem,
  ActionListLink,
  ActionListList,
  SubheadingAlt,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import { useNavigate } from '@web/common/react-router-wrappers'
import { useEffect } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { PayoutOptionsQuery } from './__generated__/PayoutOptionsQuery.graphql'

export default function PayoutOptions() {
  const pageName = 'Payout Options'
  const navigate = useNavigate()
  const data = useLazyLoadQuery<PayoutOptionsQuery>(
    graphql`
      query PayoutOptionsQuery {
        content {
          profilePage(where: { name: "Payout Options" }) {
            header
            body {
              raw
            }
          }
        }
        payoutAccount {
          id
        }
      }
    `,
    {}
  )

  useEffect(() => {
    if (!data.payoutAccount) {
      navigate('/home/settings')
    }
  }, [])

  return (
    <LayoutInAppTracked
      pageName={pageName}
      headerStart={<GoBack />}
      title={data.content.profilePage.header}
    >
      <RichText content={data.content.profilePage.body.raw} />
      <ActionListList>
        <ActionListItem>
          <ActionListLink
            to="/home/settings/payout-account"
            data-name="payout-account"
            data-page-name={pageName}
          >
            <SubheadingAlt>Payout Account</SubheadingAlt>
            Link a new bank account to receive payments
          </ActionListLink>
        </ActionListItem>
        <ActionListItem>
          <ActionListLink
            to="/home/claims"
            data-name="claims"
            data-page-name={pageName}
          >
            <SubheadingAlt>View Claims</SubheadingAlt>
            See all your current and past claims
          </ActionListLink>
        </ActionListItem>
      </ActionListList>
    </LayoutInAppTracked>
  )
}
