import styled from 'styled-components'
import { Profile } from '../../icons'
import { fromTheme } from '../../theme'
import IconButton from '../IconButton'

const Container = styled.div`
  display: block;
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 2rem;
  box-shadow: -2px 2px 0 0
    ${
      // TODO: update theme if this gets used in the app
      fromTheme((theme) => theme.colors.foregroundMuted.raw)
    };
  transition: box-shadow 0.3s;
  svg {
    stroke: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  }
`

const StyledProfile = styled(Profile)`
  stroke: ${fromTheme(({ colors }) => colors.foregroundPrimary.raw)};

  .profile-circle {
    fill: ${fromTheme(({ colors }) => colors.background.raw)};
  }
`

type Props = {
  'data-testid'?: string
  onClick: () => void
}

function ProfileButton(props: Props) {
  return (
    <Container>
      <IconButton
        onClick={props.onClick}
        aria-label="Profile"
        data-testid={props['data-testid']}
        height={3.3}
        width={3.3}
      >
        <StyledProfile />
      </IconButton>
    </Container>
  )
}

export default ProfileButton
