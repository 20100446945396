import { LayoutInAppSingleColumn, LayoutSignupSingleColumn } from '@quil/ui'
import React, { ComponentProps } from 'react'
import { ZendeskChatButton } from '../components/ZendeskChatButton'
import { AnalyticsPageCall, PageCallProps } from './AnalyticsPageName'

export const LayoutSignupTracked: React.FC<
  PageCallProps & ComponentProps<typeof LayoutSignupSingleColumn>
> = ({ pageName, pageCallProperties, ...LayoutSignupSingleColumnProps }) => {
  return (
    <>
      <AnalyticsPageCall
        pageName={pageName}
        pageCallProperties={pageCallProperties}
      />
      <LayoutSignupSingleColumn {...LayoutSignupSingleColumnProps}>
        {LayoutSignupSingleColumnProps.children}
      </LayoutSignupSingleColumn>
    </>
  )
}

export const LayoutInAppTracked: React.FC<
  PageCallProps & ComponentProps<typeof LayoutInAppSingleColumn>
> = ({ pageName, pageCallProperties, ...appSingleColumnLayoutProps }) => {
  return (
    <>
      <AnalyticsPageCall
        pageName={pageName}
        pageCallProperties={pageCallProperties}
      />
      <LayoutInAppSingleColumn
        {...appSingleColumnLayoutProps}
        headerEnd={
          appSingleColumnLayoutProps.headerEnd ?? <ZendeskChatButton />
        }
      >
        {appSingleColumnLayoutProps.children}
      </LayoutInAppSingleColumn>
    </>
  )
}
