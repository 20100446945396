import { BodyText, Carousel, ContentCard, useQuilTheme } from '@quil/ui'
import { propEq } from 'ramda'
import { useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'
import styled from 'styled-components'
import { HomeHeadline } from '../HomeHeadline'
import RichText from '../RichText'
import Row from '../Row'
import type {
  RecentActivityCardType,
  RecentActivity_query$key,
} from './__generated__/RecentActivity_query.graphql'

type Props = {
  pageName: string
  query: RecentActivity_query$key
}

const FlexStretch = styled.div<{ marginBottom: string }>`
  display: flex;
  align-items: stretch;
  margin-bottom: ${(props) => props.marginBottom};
`
/**
 * This component fetches recent activities from the Quil API,
 * and joins them together with the relevant content from the CMS.
 * The joining of data + copy could be done in our API, which could
 * expose simpler fields on the Activity type, such as "title", "body", etc.
 * There are 2 reasons that we instead do the work on the frontend
 * 1. When the body contains more than 1 paragraph, the `body.raw` property
 *    is a deeply nested structure which gets rendered correctly by the
 *    RichText component. Multi-paragraph bodies would be incorrectly
 *    represented if we returned a simple `body: String!` property after
 *    interpolating the copy on the backend. The alternative (re-creating the
 *    complex structure of the body.raw property exposed by Hygraph) is
 *    a lot of work, though possible if there is sufficient need in the future.
 * 2. Recent Activity cards contain a CTA that links to an internal route in the app.
 *    Providing the routes from the backend introduces a strange coupling between
 *    our API and the frontend; why would the API know anything about frontend routing?
 *    This means the frontend needs to perform the work of deriving the path regardless
 *    of how much work the API does. If the frontend is already doing this work,
 *    it made sense to also do the work of joining the CMS content in one operation.
 */
export function RecentActivity(props: Props) {
  const { breakpoints } = useQuilTheme()
  const data = useFragment(
    graphql`
      fragment RecentActivity_query on Query {
        recentActivities {
          type
          data
        }
        content {
          recentActivities {
            name
            heading
            body {
              raw
            }
            ctaText
          }
        }
      }
    `,
    props.query
  )

  const cards = []

  // map Activity.type to the desired CTA path
  const typePathMap: Record<RecentActivityCardType, string> = {
    AccountActivated: '/home/benefits',
    ClaimSubmitted: '/home/claims',
    ClaimApproved: '/home/claims',
    ClaimDenied: '/home/claims',
    AccountClosed: '/home/settings',
    EligibleForClaims: '/home/claims/new',
    '%future added value': '/home', // fallback
  }
  for (const card of data.recentActivities) {
    const content = data.content.recentActivities.find(
      propEq(card.type, 'name')
    )
    if (!content) {
      console.error({
        message: 'Something went really wrong trying to build Recent Activity',
        card,
        content,
      })
      continue
    }

    cards.push({
      title: content.heading,
      bodyContent: content.body.raw,
      bodyData: {
        date: card.data,
      },
      linkText: content.ctaText,
      linkUrl: typePathMap[card.type] ?? '/home/settings',
    })
  }

  const isDesktop = useMemo(() => {
    // @ts-expect-error experimental feature, not available in all browsers
    const userAgentData = navigator.userAgentData
    return !(
      userAgentData?.mobile === true || window.innerWidth < breakpoints.medium
    )
  }, [breakpoints.medium])

  const renderedCards = cards.map((card, i) => {
    const cta = card.linkText
      ? { text: card.linkText, url: card.linkUrl }
      : null
    return (
      <FlexStretch key={i} marginBottom={isDesktop ? '2.4rem' : '0'}>
        <ContentCard
          title={card.title}
          body={
            <RichText
              content={card.bodyContent}
              data={card.bodyData}
              renderers={{
                p: ({ children }) => <BodyText>{children}</BodyText>,
              }}
            />
          }
          cta={cta}
          data-page-name={props.pageName}
          data-name={card.title}
          data-text={card.linkText}
        />
      </FlexStretch>
    )
  })

  return (
    <Row>
      <HomeHeadline>Recent Activity</HomeHeadline>
      {isDesktop ? renderedCards : <Carousel>{renderedCards}</Carousel>}
    </Row>
  )
}
