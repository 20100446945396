export default function parsePlaceDetails(place) {
  let addressLineOne = ''
  let city = ''
  let state = ''
  let zip = ''

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'street_number': {
        addressLineOne = `${component.long_name} ${addressLineOne}`
        break
      }

      case 'route': {
        addressLineOne += component.short_name
        break
      }

      case 'postal_code': {
        zip = component.long_name
        break
      }

      case 'locality':
        city = component.long_name
        break

      case 'administrative_area_level_1': {
        state = component.short_name
        break
      }
    }
  }

  return {
    addressLineOne,
    city,
    state,
    zip,
  }
}
