import { css } from 'styled-components'
import { fromTheme } from '../../theme'

export const focusRingBase = css`
  content: '';
  display: block;
  width: calc(100% + 1rem);
  height: calc(100% + 1rem);
  border-radius: 0.4rem;
  transform: translate(-50%, -50%) scale(0.9);
  top: 50%;
  left: 50%;
  border: 0.2rem solid
    ${fromTheme(({ colors }) => colors.foregroundPrimary.raw)};
  position: absolute;
  transition: transform 0.1s, opacity 0.1s;
  opacity: 0;
`

export const focusRingFocused = css`
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.2s, opacity 0.2s;
`

export const brandFocusRing = css`
  outline: none;
  position: relative;

  ::before {
    ${focusRingBase}
  }

  :focus-visible::before {
    ${focusRingFocused}
  }
`
