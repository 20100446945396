import { EventDefinition } from '@quil/analytics-events'
import { useState } from 'react'
import { useTrackedValue, UseTrackedValueConfig } from './useTrackedValue'

export function useTrackedState<T>(
  initialState: T,
  formatEvent: (value: T) => EventDefinition,
  config?: UseTrackedValueConfig
) {
  const [state, setState] = useState<T>(initialState)
  useTrackedValue(state, formatEvent, config)

  return [state, setState] as const // let callers know it's always a tuple not a mutable array
}
