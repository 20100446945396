import styled from 'styled-components'
import ButtonPrimary from '../ButtonPrimary'

export const InlineButtonContainer = styled.div`
  display: flex;
  justify-content: stretch;
  gap: 1.6rem;
  margin-bottom: 2.4rem;
  width: 100%;

  ${ButtonPrimary} {
    flex-grow: 1;
  }
`
