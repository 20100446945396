/**
 * @generated SignedSource<<5145ab39b0fac3527f309e40787150b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SupportRequestModal_content$data = {
  readonly modal: {
    readonly body: {
      readonly raw: any;
    } | null;
    readonly heading: string | null;
    readonly mainCtaText: string | null;
    readonly secondaryCtaText: string | null;
  } | null;
  readonly " $fragmentType": "SupportRequestModal_content";
};
export type SupportRequestModal_content$key = {
  readonly " $data"?: SupportRequestModal_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"SupportRequestModal_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SupportRequestModal_content",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "name": "Incorrect Prefill Data"
          }
        }
      ],
      "concreteType": "Cms_Modal",
      "kind": "LinkedField",
      "name": "modal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "heading",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Cms_RichText",
          "kind": "LinkedField",
          "name": "body",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "raw",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mainCtaText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondaryCtaText",
          "storageKey": null
        }
      ],
      "storageKey": "modal(where:{\"name\":\"Incorrect Prefill Data\"})"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "f28db6805e7e23343fe562461c8c2fdb";

export default node;
