import { Alert } from '@quil/ui'
import { head, partition, pluck, prop, sortBy } from 'ramda'
import React, { useEffect } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'
import { useLocation } from 'react-router-dom'
import { useModal } from '../../modal-context'
import {
  QuilLocationDescriptor,
  useNavigate,
} from '../../react-router-wrappers'
import Row from '../Row'
import { NoticeEventModal } from './NoticeEventModal'
import { AlertsBulkMarkDeliveredMutation } from './__generated__/AlertsBulkMarkDeliveredMutation.graphql'
import {
  Alerts_query$data,
  Alerts_query$key,
  NoticeEventSeverity,
  NoticeType,
} from './__generated__/Alerts_query.graphql'

type NoticeEvent = Alerts_query$data['noticeEvents'][number]

const isUndelivered = (alert: NoticeEvent) => alert.deliveredAt === null

type Props = {
  query: Alerts_query$key
}

const noticeTypesToPathnames: Partial<Record<NoticeType, string>> = {
  FailedPayment: '/home/settings',
  LpRequestInProgress: '/home/claims/new',
  LpInformationRequested: '/home/claims',
  LpRequestApproved: '/home/claims',
  LpRequestDenied: '/home/claims',
}

export const Alerts: React.FC<Props> = (props) => {
  const location = useLocation()
  const { openModal, closeModal } = useModal()
  const navigate = useNavigate()

  const data = useFragment(
    graphql`
      fragment Alerts_query on Query {
        noticeEvents {
          id
          deliveredAt
          displayType
          noticeType
          content
          precedence
          headline
          severity
          linkText
        }
      }
    `,
    props.query
  )

  const bulkMarkNoticesDeliveredMutation = graphql`
    mutation AlertsBulkMarkDeliveredMutation($ids: [ID!]!) {
      bulkMarkNoticeEventsDelivered(ids: $ids) {
        id
        deliveredAt
      }
    }
  `

  const [commitMarkDeliveredMutation, markDeliveredMutationInFlight] =
    useMutation<AlertsBulkMarkDeliveredMutation>(
      bulkMarkNoticesDeliveredMutation
    )

  const [modals, alerts] = partition(
    (noticeEvent) => noticeEvent.displayType === 'Modal',
    data.noticeEvents
  )

  useEffect(() => {
    const undeliveredAlerts = alerts.filter(isUndelivered)
    if (!markDeliveredMutationInFlight && undeliveredAlerts.length) {
      commitMarkDeliveredMutation({
        variables: {
          ids: pluck('id', undeliveredAlerts),
        },
      })
    }
  }, [alerts, commitMarkDeliveredMutation, markDeliveredMutationInFlight])

  const renderAlert = (alert: NoticeEvent) => {
    const to: QuilLocationDescriptor = {
      pathname: noticeTypesToPathnames[alert.noticeType],
      state: {
        redirectPath: location.pathname,
      },
    }

    return (
      <Row key={alert.id}>
        <Alert
          severity={
            alert.severity as Exclude<
              NoticeEventSeverity,
              '%future added value'
            >
          }
          headline={alert.headline}
          linkText={alert.linkText}
          message={alert.content}
          onClick={() => navigate(to)}
        />
      </Row>
    )
  }

  useEffect(() => {
    const handleDeliverNoticeEvent = (id: string) => {
      return () => {
        if (!markDeliveredMutationInFlight) {
          commitMarkDeliveredMutation({
            variables: {
              ids: [id],
            },
            onCompleted() {
              closeModal()
            },
            onError(error) {
              closeModal()
              console.error({
                error,
                mutation: 'bulkMarkNoticeEventsDelivered',
              })
            },
          })
        }
      }
    }

    const undeliveredModals = modals.filter(isUndelivered)

    if (undeliveredModals.length) {
      const modal = head(sortBy(prop('precedence'), undeliveredModals))
      openModal(
        <NoticeEventModal
          onClick={handleDeliverNoticeEvent(modal.id)}
          headline={modal.headline}
          content={modal.content}
          ctaText={modal.linkText ?? 'Got it'}
          ctaPath={noticeTypesToPathnames[modal.noticeType]}
        />
      )
    }
    // This effect must only run once: If dependencies are included in the array, the modal will re-render repeatedly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{sortBy(prop('precedence'), alerts).map(renderAlert)}</>
}
