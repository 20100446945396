import {
  ButtonPrimary,
  InlineButtonContainer,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { LayoutSignupTracked } from '../../../common/analytics'
import GoBack from '../../../common/components/GoBack'
import RichText from '../../../common/components/RichText'
import { useNavigate } from '../../../common/react-router-wrappers'
import { SignupPageProps } from '../../SignupPageProps'
import SignupProgressTracker from '../../SignupProgressTracker'
import Question from '../Question'
import * as testIds from '../test-ids'
import { useEmploymentQuestionCopy } from '../use-employment-question-copy'
import { useUpdateEmploymentInfo } from '../use-update-employment-info'
import { SeasonalQuery } from './__generated__/SeasonalQuery.graphql'

const Seasonal: React.FC<SignupPageProps> = ({
  step,
  nextPath,
  previousPath,
}) => {
  const data = useLazyLoadQuery<SeasonalQuery>(
    graphql`
      query SeasonalQuery {
        content {
          onboardingPage(where: { pageName: "Employment Seasonal" }) {
            ...useEmploymentQuestionCopy_onboardingPage
          }
        }
      }
    `,
    {}
  )
  const copy = useEmploymentQuestionCopy(data.content.onboardingPage)
  const pageName = 'Employment Seasonal'
  const [commitMutation, mutationInFlight] = useUpdateEmploymentInfo()
  const navigate = useNavigate()
  const showToast = useShowToast()
  function createHandler(seasonal: boolean) {
    return function handleAnswer() {
      commitMutation({
        variables: {
          input: {
            seasonal,
          },
        },

        onCompleted() {
          navigate(nextPath)
        },

        onError() {
          showToast({
            severity: 'Error',
            headline: standardToastHeadlines.genericError,
            message: standardToastMessages.retry,
            onClick: handleAnswer,
          })
        },
      })
    }
  }

  return (
    <LayoutSignupTracked
      pageName={pageName}
      title={copy.heading}
      headerStart={previousPath && <GoBack />}
      headerEnd={<SignupProgressTracker step={step} />}
      footerContent={
        <InlineButtonContainer>
          <ButtonPrimary
            data-page-name={pageName}
            data-name="Yes"
            onClick={createHandler(true)}
            disabled={mutationInFlight}
            data-testid={testIds.yesButton}
          >
            {copy.mainCtaText}
          </ButtonPrimary>
          <ButtonPrimary
            data-page-name={pageName}
            data-name="No"
            onClick={createHandler(false)}
            disabled={mutationInFlight}
            data-testid={testIds.noButton}
          >
            {copy.secondaryCtaText}
          </ButtonPrimary>
        </InlineButtonContainer>
      }
    >
      <RichText content={copy.body.raw} />
      <Question>{copy.subheading}</Question>
      <ProcessingOverlay processing={mutationInFlight} />
    </LayoutSignupTracked>
  )
}

export default Seasonal
