import styled from 'styled-components'
import { ChevronRight } from '../../../icons'
import { fromTheme } from '../../../theme'

const ActionListIcon = styled(ChevronRight)`
  fill: ${fromTheme((theme) => theme.colors.foregroundPrimary.raw)};
  height: 2rem;
  width: 2rem;
`

export default ActionListIcon
