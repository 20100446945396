/**
 * @generated SignedSource<<07a47ae08716de1a35d9bb70b6ae0860>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AlertsBulkMarkDeliveredMutation$variables = {
  ids: ReadonlyArray<string>;
};
export type AlertsBulkMarkDeliveredMutation$data = {
  readonly bulkMarkNoticeEventsDelivered: ReadonlyArray<{
    readonly deliveredAt: any | null;
    readonly id: string | null;
  } | null>;
};
export type AlertsBulkMarkDeliveredMutation = {
  response: AlertsBulkMarkDeliveredMutation$data;
  variables: AlertsBulkMarkDeliveredMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "NoticeEvent",
    "kind": "LinkedField",
    "name": "bulkMarkNoticeEventsDelivered",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliveredAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AlertsBulkMarkDeliveredMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AlertsBulkMarkDeliveredMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ed25ed51f211d0b65f1fb100c012dd7",
    "id": null,
    "metadata": {},
    "name": "AlertsBulkMarkDeliveredMutation",
    "operationKind": "mutation",
    "text": "mutation AlertsBulkMarkDeliveredMutation(\n  $ids: [ID!]!\n) {\n  bulkMarkNoticeEventsDelivered(ids: $ids) {\n    id\n    deliveredAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "1774f839767c801654e5e894120b0dc8";

export default node;
