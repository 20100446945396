import * as event from '@quil/analytics-events'
import {
  ButtonPrimary,
  ButtonSecondary,
  ModalHeadline,
  Nav,
  NavPageName,
  PageFooter,
  useShowToast,
} from '@quil/ui'
import * as analytics from '@web/common/analytics/analytics-adapter'
import { useModal } from '@web/common/modal-context'
import { useLocation, useNavigate } from '@web/common/react-router-wrappers'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import RichText from '../RichText'
import {
  AppNavQuery,
  AppNavQuery$data,
} from './__generated__/AppNavQuery.graphql'

function detectActivePage(pathName: string): NavPageName | null {
  if (pathName.includes('home/benefits')) return 'benefits'
  // TODO: will update later to avoid clashing w/ Sonja's current work
  if (pathName.includes('home/settings')) return 'settings'
  if (pathName.includes('home/claims/new')) return 'newClaim'
  if (pathName.includes('home/claims')) return 'claims'
  if (pathName.includes('home')) return 'home'

  return null
}

const pageNameToPath: Record<NavPageName, string> = {
  home: 'home',
  claims: 'home/claims',
  newClaim: 'home/claims/new',
  settings: 'home/settings',
  benefits: 'home/benefits',
}

export function AppNav() {
  const location = useLocation()
  const navigate = useNavigate()
  const { openModal, closeModal } = useModal()
  const showToast = useShowToast()
  const [commitMutation, commitMutationInFlight] = useMutation(graphql`
    mutation AppNavMutation {
      restartLayoffClaim {
        id
      }
    }
  `)

  const data = useLazyLoadQuery<AppNavQuery>(
    graphql`
      query AppNavQuery {
        content {
          approvedClaim: modal(where: { name: "Navbar Approved Claim" }) {
            heading
            body {
              raw
            }
            mainCtaText
          }
          inReview: modal(where: { name: "Navbar Claim In Review" }) {
            heading
            body {
              raw
            }
            mainCtaText
          }
          pending: modal(where: { name: "Navbar Pending Claim" }) {
            heading
            body {
              raw
            }
            mainCtaText
            secondaryCtaText
          }
          contactSupport: modal(where: { name: "Navbar Contact Support" }) {
            heading
            body {
              raw
            }
            mainCtaText
            secondaryCtaText
          }
        }
        currentUser {
          eligibleForLayoffProtectionAt {
            formatted
          }
          layoffProtectionStatus
        }
      }
    `,
    {},
    // refetch when navigating to a new page- on login, user is cached without
    // layoffProtectionStatus or eligibleForLayoffProtectionAt, so we need to refetch to get them
    {
      fetchKey: location.pathname,
    }
  )

  const activePage = detectActivePage(location.pathname)

  function handleClaimsClick() {
    navigate('home/claims')
  }

  function handleContinueClick() {
    if (location.pathname.includes('home/claims/new')) {
      closeModal()
    } else {
      navigate('home/claims/new')
    }
  }

  function handleRestartClaimClick() {
    commitMutation({
      variables: {},
      onError(error) {
        console.error({ error, mutation: 'restartLayoffClaim' })
        showToast({
          severity: 'Error',
          headline: 'Something went wrong',
          message: 'Please try again',
          linkText: 'Retry',
          onClick: handleRestartClaimClick,
        })
      },
      onCompleted() {
        navigate('home/claims/new')
      },
      updater(store) {
        store
          .getRoot()
          .getLinkedRecord('pendingLayoffClaim')
          ?.invalidateRecord()
      },
    })
  }

  function handleRequestNavigation(to: NavPageName): void {
    if (to === 'newClaim') {
      switch (data.currentUser.layoffProtectionStatus) {
        case 'Waiting':
        case 'Eligible':
          navigate(pageNameToPath[to])
          break
        case 'Approved':
          openModal(
            <ApprovedClaimModal
              content={data.content}
              nextEligibleOn={
                data.currentUser.eligibleForLayoffProtectionAt.formatted
              }
              onClick={handleClaimsClick}
            />
          )
          break
        case 'Pending':
          openModal(
            <PendingClaimModal
              content={data.content}
              onContinueClick={handleContinueClick}
              onStartOverClick={handleRestartClaimClick}
            />
          )
          break
        case 'ContactSupport':
          openModal(
            <ContactSupportModal
              content={data.content}
              onClick={() => analytics.track(event.supportLinkClicked())}
              onDismiss={closeModal}
            />
          )
          break
        case 'InReview':
          openModal(
            <InReviewModal content={data.content} onClick={handleClaimsClick} />
          )
      }
      return
    }

    navigate(pageNameToPath[to])
  }

  if (!activePage) {
    return null
  }

  return (
    <Nav
      activePage={activePage}
      onRequestNavigation={handleRequestNavigation}
    />
  )
}

function ApprovedClaimModal(props: {
  content: AppNavQuery$data['content']
  nextEligibleOn: string
  onClick: () => void
}) {
  return (
    <>
      <ModalHeadline>{props.content.approvedClaim.heading}</ModalHeadline>
      <RichText
        content={props.content.approvedClaim.body.raw}
        data={{ nextEligibleOn: props.nextEligibleOn }}
      />
      <PageFooter>
        <ButtonPrimary data-name="Claim Approved CTA" onClick={props.onClick}>
          {props.content.approvedClaim.mainCtaText}
        </ButtonPrimary>
      </PageFooter>
    </>
  )
}

function InReviewModal(props: {
  content: AppNavQuery$data['content']
  onClick: () => void
}) {
  return (
    <>
      <ModalHeadline>{props.content.inReview.heading}</ModalHeadline>
      <RichText content={props.content.inReview.body.raw} />
      <PageFooter>
        <ButtonPrimary data-name="In Review CTA" onClick={props.onClick}>
          View claims
        </ButtonPrimary>
      </PageFooter>
    </>
  )
}

function PendingClaimModal(props: {
  content: AppNavQuery$data['content']
  onContinueClick: () => void
  onStartOverClick: () => void
}) {
  return (
    <>
      <ModalHeadline>{props.content.pending.heading}</ModalHeadline>
      <RichText content={props.content.pending.body.raw} />
      <PageFooter>
        <ButtonPrimary
          data-name="Continue Claim CTA"
          onClick={props.onContinueClick}
        >
          {props.content.pending.mainCtaText}
        </ButtonPrimary>
        <ButtonSecondary
          data-name="Start over CTA"
          onClick={props.onStartOverClick}
        >
          {props.content.pending.secondaryCtaText}
        </ButtonSecondary>
      </PageFooter>
    </>
  )
}

function ContactSupportModal(props: {
  content: AppNavQuery$data['content']
  onClick: () => void
  onDismiss: () => void
}) {
  return (
    <>
      <ModalHeadline>{props.content.contactSupport.heading}</ModalHeadline>
      <RichText content={props.content.contactSupport.body.raw} />
      <PageFooter>
        <ButtonPrimary
          data-name="Contact support CTA"
          as={'a'}
          href="mailto:support@getquil.com"
          data-page-name="AppNav"
          onClick={props.onClick}
        >
          {props.content.contactSupport.mainCtaText}
        </ButtonPrimary>
        <ButtonSecondary
          data-name="Dismiss contact support CTA"
          onClick={props.onDismiss}
        >
          {props.content.contactSupport.secondaryCtaText}
        </ButtonSecondary>
      </PageFooter>
    </>
  )
}
