import {
  BodyText,
  ButtonPrimary,
  LayoutSignupSingleColumn,
  SpinnerFullScreen,
} from '@quil/ui'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-relay'
import { graphql } from 'relay-runtime'
import { useDebouncedCallback } from 'use-debounce'
import * as analytics from '../common/analytics/analytics-adapter'
import GoBack from '../common/components/GoBack'
import { getOrCreateGuestID } from '../common/experimentContext'
import { useLocation, useNavigate } from '../common/react-router-wrappers'
import { buttonPrimary } from './test-ids'
import { PrefillUserMutation } from './__generated__/PrefillUserMutation.graphql'

const mutation = graphql`
  mutation PrefillUserMutation($fingerprint: String!) {
    prefillUser(fingerprint: $fingerprint) {
      __typename
      ... on User {
        id
        status
        email
        state
        dob
      }
      ... on UnrecoverableError {
        code
        help
      }
    }
  }
`

function PrefillUser() {
  const pageName = 'Prefill User'
  const navigate = useNavigate()
  const location = useLocation()
  const ldClient = useLDClient()
  const [loadingError, setLoadingError] = useState(false)
  const errorHeader = 'Something went wrong'
  const errorBody =
    'We could not verify your login information. Please try again.'

  const [commitMutationBasic] = useMutation<PrefillUserMutation>(mutation)
  // The prefillUser mutation is called on mount.
  // Debouncing prevents duplicate side effects caused by multiple mounts (e.g. in the case of React.StrictMode in development environment)
  const commitMutation = useDebouncedCallback(commitMutationBasic, 1000, {
    leading: true,
    trailing: false,
  })

  function handleCtaClick() {
    navigate('/home')
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    commitMutation({
      variables: {
        fingerprint: params.get('vfp') ?? '',
      },
      async onCompleted(response) {
        switch (response.prefillUser.__typename) {
          case 'User': {
            const user = response.prefillUser
            // User is also identified with traits on the server.
            // this identify call is made on the front to link the existing anonymous id with the real user id,
            // in case the prefill was completed on a different device than the user started on (e.g. desktop --> mobile)
            analytics.identify(user.id)

            await ldClient.identify({
              kind: 'multi',
              user: {
                key: user.id,
                status: user.status,
              },
              guest: {
                key: getOrCreateGuestID(),
              },
            })

            if (user.status === 'Ineligible') {
              navigate('/signup/denied/identity')
            } else {
              navigate(
                params.get('redirect_to') ?? '/signup/verify-personal-info'
              )
            }
            break
          }
          case 'UnrecoverableError':
            setLoadingError(true)
            break
        }
      },
      onError(error) {
        console.error({ error, mutation: 'prefillUser' })
        setLoadingError(true)
      },

      // associate this user id with Query.currentUser in the relay store
      updater(store, payload) {
        if (payload.prefillUser.__typename === 'User') {
          if (payload.prefillUser.status === 'Ineligible') {
            store.invalidateStore()
          } else {
            const currentUser = store.get(payload.prefillUser.id)
            store.getRoot().setLinkedRecord(currentUser, 'currentUser')
          }
        }
      },
    })
  }, [commitMutation, navigate, location.search, analytics])

  return loadingError ? (
    <LayoutSignupSingleColumn
      title={errorHeader}
      headerStart={<GoBack />}
      footerContent={
        <ButtonPrimary
          data-page-name={pageName}
          data-name="Primary CTA"
          onClick={handleCtaClick}
          data-testid={buttonPrimary}
        >
          Home
        </ButtonPrimary>
      }
    >
      <BodyText centered>{errorBody}</BodyText>
    </LayoutSignupSingleColumn>
  ) : (
    <SpinnerFullScreen />
  )
}

export default PrefillUser
