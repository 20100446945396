import * as event from '@quil/analytics-events'
import {
  Birthday,
  ButtonPrimary,
  InputDate,
  InputPhone,
  IntroBlock,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useState } from 'react'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { useNavigate } from 'react-router-dom'
import { LayoutSignupTracked } from '../../common/analytics'
import * as analytics from '../../common/analytics/analytics-adapter'
import { useTrackedState } from '../../common/analytics/useTrackedState'
import Form from '../../common/components/Form'
import GoBack from '../../common/components/GoBack'
import { SignupPageProps } from '../../Signup/SignupPageProps'
import SignupProgressTracker from '../../Signup/SignupProgressTracker'
import { theBasicsQuery, ValidationError } from '../../Signup/TheBasics'
import { TheBasicsQuery } from '../../Signup/TheBasics/__generated__/TheBasicsQuery.graphql'
import * as testIds from './test-ids'
import { TheBasicsPhoneDobMutation } from './__generated__/TheBasicsPhoneDobMutation.graphql'
import { TheBasicsPhoneDobSendSmsMutation } from './__generated__/TheBasicsPhoneDobSendSmsMutation.graphql'

const theBasicsPhoneDobMutation = graphql`
  mutation TheBasicsPhoneDobMutation($data: UserUpdateExpInput!) {
    updateUserExp(data: $data) {
      __typename
      ... on User {
        id
        status
      }
      ... on ValidationErrors {
        messages {
          field
          help
        }
      }
      ... on PhoneNumberNotAvailable {
        message
      }
    }
  }
`

const theBasicsSendSmsMutation = graphql`
  mutation TheBasicsPhoneDobSendSmsMutation($targetUrl: String!) {
    sendIdentitySms(targetUrl: $targetUrl) {
      success
    }
  }
`

function TheBasicsPhoneDob({ step, nextPath, previousPath }: SignupPageProps) {
  const pageName = 'The Basics Phone'
  const showToast = useShowToast()
  const ldClient = useLDClient()

  const data = useLazyLoadQuery<TheBasicsQuery>(theBasicsQuery, {})

  const [phone, setPhone] = useTrackedState('', (value) =>
    event.inputChanged('Signup: Phone', { phone: value })
  )
  const [dateOfBirth, setDateOfBirth] = useTrackedState('', (value) =>
    event.inputChanged('Signup: DOB', { dob: value })
  )

  const [phoneValidationError, setPhoneValidationError] = useState<
    string | null
  >(null)
  const [dateOfBirthValidationError, setDateOfBirthValidationError] = useState<
    string | null
  >(null)

  function clearValidationErrors() {
    setDateOfBirthValidationError(null)
    setPhoneValidationError(null)
  }

  const navigate = useNavigate()
  const [commitTheBasicsPhoneDobMutation, theBasicsPhoneDobMutationInflight] =
    useMutation<TheBasicsPhoneDobMutation>(theBasicsPhoneDobMutation)

  const [commitSendSmsMutation, theBasicsSendSmsMutationInflight] =
    useMutation<TheBasicsPhoneDobSendSmsMutation>(theBasicsSendSmsMutation)

  function dobFilled() {
    if (!dateOfBirth) {
      return false
    }

    const [year, month, day] = dateOfBirth.split('-')
    return year && month && day
  }

  const formFilled = phone && dobFilled()

  function validate(): boolean {
    let hasErrors = false
    const dobInvalidFormat = !/^\d{4}-\d{2}-\d{2}$/.test(dateOfBirth)
    // JS Date is not perfect, but coarse control is fine for this purpose
    const dobInFuture = new Date(dateOfBirth) > new Date()
    const dobTooFarInPast = new Date(dateOfBirth) < new Date(1900, 1, 1)
    const dobMonth = parseInt(dateOfBirth.split('-')[1])
    const dobMontInvalidRange = dobMonth > 12 || dobMonth === 0
    const dobDay = parseInt(dateOfBirth.split('-')[2])
    const dobDayInvalidRange = dobDay > 31 || dobDay === 0

    if (
      dobInvalidFormat ||
      dobInFuture ||
      dobTooFarInPast ||
      dobMontInvalidRange ||
      dobDayInvalidRange
    ) {
      setDateOfBirthValidationError(
        'Date of birth must be a valid date in the format MM/DD/YYYY'
      )
      hasErrors = true
    }

    // server executes more robust validation
    if (phone === '') {
      setPhoneValidationError('Phone number must not be empty')
      hasErrors = true
    }

    return hasErrors
  }

  function setValidationError(message: ValidationError | null) {
    switch (message?.field) {
      case 'phone':
        return setPhoneValidationError(message.help)
      case 'dob':
        return setDateOfBirthValidationError(message.help)
    }
  }

  function handleNextClick() {
    analytics.track(event.formSubmitted('The Basics | Phone Dob'))

    clearValidationErrors()
    if (validate()) {
      return showToast({
        severity: 'Error',
        headline: standardToastHeadlines.validationErrors,
        message: standardToastMessages.validationErrors,
      })
    }

    commitTheBasicsPhoneDobMutation({
      variables: {
        data: {
          phone: phone.replace(/\s+/g, ''),
          dob: dateOfBirth,
        },
      },
      async onCompleted(response) {
        clearValidationErrors()

        switch (response.updateUserExp?.__typename) {
          case 'User':
            await ldClient.track('phone_submitted')
            commitSendSmsMutation({
              variables: {
                targetUrl: new URL(
                  `verify-device?redirect_to=${nextPath}`,
                  window.location.origin
                ).toString(),
              },
              onCompleted({ sendIdentitySms }) {
                if (sendIdentitySms.success) {
                  navigate(nextPath)
                } else {
                  showToast({
                    severity: 'Error',
                    headline: standardToastHeadlines.genericError,
                    message: standardToastMessages.retry,
                    onClick: handleNextClick,
                  })
                }
              },
              onError(error) {
                console.error({
                  mutation: 'MyPlanMutation',
                  error,
                })
                showToast({
                  severity: 'Error',
                  headline: standardToastHeadlines.genericError,
                  message: standardToastMessages.retry,
                  onClick: handleNextClick,
                })
              },
            })
            break
          case 'ValidationErrors':
            showToast({
              severity: 'Error',
              headline: standardToastHeadlines.validationErrors,
              message: standardToastMessages.validationErrors,
            })
            response.updateUserExp.messages?.forEach(setValidationError)
            break
          case 'PhoneNumberNotAvailable':
            navigate('/login', {
              state: { phone },
            })
            analytics.track(
              event.userAccountCreationFailed(
                JSON.stringify(response.updateUserExp.message)
              )
            )
            break
          default:
            analytics.track(event.userAccountCreationFailed())
        }
      },

      onError(error) {
        console.error({ error, mutation: 'updateUserExp' })
        clearValidationErrors()
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.genericError,
          message: standardToastMessages.retry,
          onClick: handleNextClick,
        })
      },
    })
  }

  return (
    <>
      <LayoutSignupTracked
        pageName={pageName}
        title={'Tell us a little more about you.'}
        data-testid={testIds.page}
        headerStart={previousPath && <GoBack />}
        headerEnd={<SignupProgressTracker step={step} />}
        footerContent={
          <ButtonPrimary
            data-page-name={pageName}
            data-name="Primary CTA"
            onClick={handleNextClick}
            disabled={
              !formFilled ||
              theBasicsPhoneDobMutationInflight ||
              theBasicsSendSmsMutationInflight
            }
            data-testid={testIds.primaryCta}
          >
            {data.content.onboardingPage.mainCtaText}
          </ButtonPrimary>
        }
      >
        <IntroBlock>
          Adding your information below helps us personalize and secure your
          account.
        </IntroBlock>

        <Form onSubmit={handleNextClick}>
          <InputPhone
            value={phone}
            onChange={setPhone}
            error={phoneValidationError ?? ''}
            data-testid={testIds.phoneField}
          />
          <InputDate
            label="Date of birth"
            value={dateOfBirth}
            onChange={setDateOfBirth}
            error={dateOfBirthValidationError ?? ''}
            data-testid={testIds.dateOfBirthField}
            startAdornment={<Birthday />}
          />
        </Form>
      </LayoutSignupTracked>
      <ProcessingOverlay
        processing={
          theBasicsPhoneDobMutationInflight || theBasicsSendSmsMutationInflight
        }
      />
    </>
  )
}

export default TheBasicsPhoneDob
