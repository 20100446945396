import React from 'react'
import { ReactComponent as CityIcon } from '../../icons/city.svg'
import InputAdornment from '../InputAdornment'
import InputText, { Props as InputTextProps } from '../InputText'

type Props = Pick<
  InputTextProps,
  'value' | 'onChange' | 'error' | 'data-testid'
>

const InputAddressCity: React.ForwardRefRenderFunction<
  HTMLInputElement,
  Props
> = (props, ref) => {
  return (
    <InputText
      {...props}
      ref={ref}
      startAdornment={
        <InputAdornment>
          <CityIcon />
        </InputAdornment>
      }
      label="City"
      name="address_city"
      placeholder="Cityville"
      autoComplete="address-level2"
    />
  )
}

export default React.forwardRef(InputAddressCity)
