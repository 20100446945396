import { MembershipCard as Card } from '@quil/ui'
import { parseISO } from 'date-fns'
import { graphql, useFragment } from 'react-relay'
import { HomeHeadline } from '../HomeHeadline'
import Row from '../Row'
import { MembershipCard_query$key } from './__generated__/MembershipCard_query.graphql'

type Props = {
  query: MembershipCard_query$key
}

export function MembershipCard(props: Props) {
  const data = useFragment(
    graphql`
      fragment MembershipCard_query on Query {
        currentUser {
          fullName
          membershipActivatedAt
          isLastInvoiceUnpaid
        }
        currentSubscription {
          coverage {
            formatted
          }
          endDate {
            formatted
          }
        }
      }
    `,
    props.query
  )
  return (
    <>
      <HomeHeadline>Membership Card</HomeHeadline>
      <Row>
        <Card
          memberFullName={data.currentUser.fullName}
          coverage={data.currentSubscription.coverage.formatted}
          memberSince={parseISO(data.currentUser.membershipActivatedAt)}
          membershipStatus={
            data.currentUser.isLastInvoiceUnpaid ? 'Payment Failed' : 'Active'
          }
          closingOn={data.currentSubscription.endDate?.formatted}
        />
      </Row>
    </>
  )
}
