import {
  BodyText,
  CouchWithClock,
  LegalText,
  Subheading,
  TextLinkInline,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import { avibraActivationLink } from '@web/common/avibraActivationLink'
import DocLink from '@web/common/components/DocLink'
import GoBack from '@web/common/components/GoBack'
import { addDays, format } from 'date-fns'
import { graphql, useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components'
import { Center, styledIcon } from '../../common'
import { WaitingForNewClaimQuery } from './__generated__/WaitingForNewClaimQuery.graphql'

const TextLinkBlock = styled(TextLinkInline)`
  display: block;
`

const IframeContainer = styled.div`
  width: 100%;
  // YouTube embed is 560x315 by default, equal to 1:0.5625 aspect ratio
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 2.4rem;

  iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // TODO: theme
    filter: drop-shadow(-0.8rem 0.8rem 0px rgba(16, 5, 33, 0.1));
  }
`

const Icon = styledIcon(CouchWithClock)

export default function NewClaim() {
  const pageName = 'New Claim: Waiting'
  const { currentUser, disclosures, content } =
    useLazyLoadQuery<WaitingForNewClaimQuery>(
      graphql`
        query WaitingForNewClaimQuery {
          currentUser {
            id
            eligibleForLayoffProtectionAt {
              formatted
            }
          }
          disclosures {
            ...DocLink_disclosures
          }
          content {
            youtubeVideoId: miscellaneous(
              where: { name: "YouTube Waiting Eligibility Video ID" }
            ) {
              value
            }
          }
        }
      `,
      {}
    )

  const eligibilityStart =
    currentUser.eligibleForLayoffProtectionAt.formatted ??
    format(addDays(new Date(), 90), 'MMMM d, yyyy')

  return (
    <LayoutInAppTracked pageName={pageName} headerStart={<GoBack />}>
      <Center>
        {content.youtubeVideoId?.value ? (
          <IframeContainer>
            <iframe
              src={`https://www.youtube.com/embed/${content.youtubeVideoId?.value}`}
              title="Welcome to Quil"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </IframeContainer>
        ) : (
          <Icon />
        )}
        <Subheading>
          {/* TODO: CMS */}
          Layoff Insurance eligibility begins on{' '}
          <strong>{eligibilityStart}</strong>
        </Subheading>
        <BodyText>
          You can't file a Layoff Insurance claim just yet! After you've been a
          member for 90 days you're eligible to file if you become unexpectedly
          unemployed.
        </BodyText>
        <BodyText>
          Questions?{' '}
          <DocLink kind="faq" disclosures={disclosures}>
            Check out our FAQs
          </DocLink>
        </BodyText>
        <LegalText>
          Trying to file a claim that's not related to Layoff Insurance?
          <TextLinkBlock
            href={avibraActivationLink(currentUser.id)}
            data-page-name={pageName}
            data-name="Avibra link"
          >
            Go to Avibra here
          </TextLinkBlock>
        </LegalText>
      </Center>
    </LayoutInAppTracked>
  )
}
