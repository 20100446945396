import React, { useEffect, useRef } from 'react'
import { page } from './analytics-adapter'

export type PageCallProps = {
  pageName: string
  pageCallProperties?: Record<string, unknown>
}

export const AnalyticsPageCall: React.FC<PageCallProps> = ({
  pageName,
  pageCallProperties,
}) => {
  // Ignore updates to these props
  const pageNameRef = useRef(pageName)
  const propertiesRef = useRef(pageCallProperties)

  useEffect(() => {
    page(pageNameRef.current, propertiesRef.current)
  }, [])

  return null
}
