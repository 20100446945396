import React from 'react'
import styled from 'styled-components'
import {
  ToastError,
  ToastInformational,
  ToastSuccess,
  ToastWarning,
} from '../../icons'
import { fromTheme, useQuilTheme } from '../../theme'
import TextLinkPrimary from '../TextLinkPrimary'
import { BodyText } from '../typography'

export type NotificationProps = {
  headline: string
  message: string | JSX.Element
  type: 'toast' | 'alert'
  onClick?: () => void
  linkText?: string
  testId?: string
  severity: 'Success' | 'Error' | 'Warning' | 'Informational'
}

type ItemContainerProps = {
  border: string
  background: string
  type: 'toast' | 'alert'
}

function border(type: 'alert' | 'toast', color: string) {
  if (type === 'alert') {
    return `border: 1px solid ${color}`
  } else {
    return `border-left: 3px solid ${color}`
  }
}

const ItemContainer = styled.div<ItemContainerProps>`
  background: ${(props) => props.background};
  ${(props) => border(props.type, props.border)};
  // TODO: theme
  box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  display: flex;
  flex-direction: row;
  max-width: 60rem;
  padding: 0.8rem 1rem;
  margin: 0 0 2.4rem;

  ${BodyText} {
    flex-grow: 0;
    color: ${fromTheme((theme, props) => theme.colors.palette.violet.main.raw)};
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0.8rem;
`

const ItemHeadline = styled(BodyText)`
  line-height: 1.9rem;
  font-weight: 700;
  font-size: 1.4rem;
  margin: 0;
`

const ItemBody = styled(BodyText)`
  line-height: 1.6rem;
  font-weight: 300;
  font-size: 1.2rem;
  margin: 0;
`

const TextLink = styled(TextLinkPrimary)<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 1.2rem;
  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    border-radius: 2px;
    box-shadow: 0 0 0 2px ${(props) => props.color};
  }
`

type VariationProperties = {
  border: string
  background: string
  Icon: React.ComponentType
  link: string
}

export const Notification: React.FC<NotificationProps> = (props) => {
  const { colors } = useQuilTheme()

  const propertyMap: Record<
    NotificationProps['severity'],
    VariationProperties
  > = {
    Error: {
      border: colors.palette.torch.main.raw,
      background: colors.palette.torch.alt1.raw,
      Icon: ToastError,
      link: colors.palette.torch.main.raw,
    },
    Success: {
      border: colors.palette.emerald.main.raw,
      background: colors.palette.emerald.alt1.raw,
      Icon: ToastSuccess,
      link: colors.palette.minsk.main.raw,
    },
    Informational: {
      border: colors.palette.anakiwa.main.raw,
      background: colors.palette.anakiwa.alt1.raw,
      Icon: ToastInformational,
      link: colors.palette.minsk.main.raw,
    },
    Warning: {
      border: colors.palette.kournikova.main.raw,
      background: colors.palette.kournikova.alt1.raw,
      Icon: ToastWarning,
      link: colors.palette.minsk.main.raw,
    },
  }

  const { Icon, ...colorProps } = propertyMap[props.severity]

  return (
    <ItemContainer {...colorProps} type={props.type} data-testid={props.testId}>
      <div>
        <Icon />
      </div>
      <TextContainer>
        <ItemHeadline>{props.headline}</ItemHeadline>
        <ItemBody>{props.message}</ItemBody>
        {props.onClick ? (
          <TextLink
            onClick={props.onClick}
            color={colorProps.link}
            data-name="Notification link"
            bold
          >
            {props.linkText ?? 'Retry'}
          </TextLink>
        ) : null}
      </TextContainer>
    </ItemContainer>
  )
}
