/**
 * @generated SignedSource<<3c562af550dadbbc727644c49576c150>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MembershipCard_query$data = {
  readonly currentSubscription: {
    readonly coverage: {
      readonly formatted: string;
    };
    readonly endDate: {
      readonly formatted: string;
    } | null;
  } | null;
  readonly currentUser: {
    readonly fullName: string | null;
    readonly isLastInvoiceUnpaid: boolean | null;
    readonly membershipActivatedAt: any | null;
  } | null;
  readonly " $fragmentType": "MembershipCard_query";
};
export type MembershipCard_query$key = {
  readonly " $data"?: MembershipCard_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"MembershipCard_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "formatted",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipCard_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "membershipActivatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLastInvoiceUnpaid",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriptionChoice",
      "kind": "LinkedField",
      "name": "currentSubscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Money",
          "kind": "LinkedField",
          "name": "coverage",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Instant",
          "kind": "LinkedField",
          "name": "endDate",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7c1787f2dc5b03456cdc133bf776a51e";

export default node;
