import { BodyText, ButtonPrimary, ModalHeadline } from '@quil/ui'
import React from 'react'
import styled from 'styled-components'
import { useNavigate } from '../../react-router-wrappers'

const StyledButton = styled(ButtonPrimary)`
  width: 100%;
`

type Props = {
  headline: string
  content: string
  ctaText: string
  ctaPath?: string
  onClick: () => void
}

export const NoticeEventModal: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  function handleClick() {
    props.onClick()
    if (props.ctaPath) {
      navigate(props.ctaPath)
    }
  }

  return (
    <>
      <ModalHeadline>{props.headline}</ModalHeadline>
      <BodyText>{props.content}</BodyText>
      <StyledButton data-name={props.ctaText} onClick={handleClick}>
        {props.ctaText}
      </StyledButton>
    </>
  )
}
