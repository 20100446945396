import {
  graphql,
  useLazyLoadQuery,
  useMutation,
  UseMutationConfig,
} from 'react-relay'
import { RecordSourceSelectorProxy } from 'relay-runtime'
import {
  useNewClaimFlowMutation,
  useNewClaimFlowMutation$data,
} from './__generated__/useNewClaimFlowMutation.graphql'
import { useNewClaimFlowQuery } from './__generated__/useNewClaimFlowQuery.graphql'

export default function useNewClaimFlow(pageName: string) {
  const data = useLazyLoadQuery<useNewClaimFlowQuery>(
    graphql`
      query useNewClaimFlowQuery($pageName: String!) {
        currentUser {
          employmentInfo {
            employerName
            jobTitle
          }
        }

        payoutAccount {
          id
          accountNumberMask
          bankName
        }

        # NOTE: These fields should be the same as the ones in the mutation
        # so that the cache is updated correctly.
        pendingLayoffClaim {
          id
          additionalInfo
          changedJobsRecently
          employerEmail
          employerName
          employerPhone
          employmentEndDate
          employmentStartDate
          receivingStateBenefits
          returnedToWorkAtDate
          minimumWeeklyHours
          occupation
          payoutAccount {
            accountNumberMask
            bankName
          }
          status
          terminatedWithoutCause
          terminationReason
          fileUploads {
            id
            size
            extension
            originalFileName
            uri
            type
          }
        }
        content {
          newClaimPage(where: { name: $pageName }) {
            heading
            body {
              raw
            }
            mainCtaText
            secondaryCtaText
            yesButton
            noButton
          }
        }
      }
    `,
    { pageName }
  )

  const [commitMutation, mutationInFlight] =
    useMutation<useNewClaimFlowMutation>(
      graphql`
        mutation useNewClaimFlowMutation($input: UpsertLayoffClaimInput!) {
          upsertLayoffClaim(input: $input) {
            __typename

            ... on ValidationErrors {
              messages {
                field
                help
              }
            }

            # NOTE: These fields should be the same as the ones in the query
            # so that the cache is updated correctly.
            ... on LayoffClaim {
              id
              additionalInfo
              changedJobsRecently
              employerEmail
              employerName
              employerPhone
              employmentEndDate
              employmentStartDate
              returnedToWorkAtDate
              minimumWeeklyHours
              occupation
              status
              payoutAccount {
                accountNumberMask
                bankName
              }
              terminatedWithoutCause
              terminationReason
              fileUploads {
                id
                size
                extension
                originalFileName
                uri
                type
              }
            }
          }
        }
      `
    )

  function commitMutationWithUpdater(
    config: UseMutationConfig<useNewClaimFlowMutation>
  ) {
    return commitMutation({
      ...config,

      updater(store, payload) {
        if (
          payload.upsertLayoffClaim.__typename === 'LayoffClaim' &&
          !data.pendingLayoffClaim
        ) {
          const record = store.get(payload.upsertLayoffClaim.id)

          store.getRoot().setLinkedRecord(record, 'pendingLayoffClaim')
          store
            .getRoot()
            .getLinkedRecord('currentUser')
            .setValue('Pending', 'layoffProtectionStatus')
        }
        // Invalidate `noticeEvents` records, to force Alerts component to re-fetch its fragment.
        // If `noticeEvents` is undefined or an empty array, we set the value to `undefined` which invalidates the whole "noticeEvents" query;
        // this is required for scenarios where the user starts with no alerts but one is added during the active session (because looping through an empty array invalidates nothing).
        invalidateCollection(store, 'noticeEvents')
        invalidateCollection(store, 'recentClaims')
      },
    })
  }

  function invalidateCollection(
    store: RecordSourceSelectorProxy<useNewClaimFlowMutation$data>,
    fieldName: string
  ) {
    const records = store.getRoot().getLinkedRecords(fieldName)

    if (records?.length) {
      records.forEach((r) => r?.invalidateRecord())
    } else {
      store.getRoot().setValue(undefined, fieldName)
    }
  }

  return [data, commitMutationWithUpdater, mutationInFlight] as const
}
