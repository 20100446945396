import { Navigate, Route, Routes } from 'react-router-dom'
import { useDocumentTitle } from '../common/useDocumentTitle'
import CloseMembership from './CloseMembership'
import { Help } from './Help'
import { LegalDocs } from './LegalDocs'
import LegalDocDetail from './LegalDocs/LegalDocDetail'
import PayoutAccount from './PayoutAccount'
import PayoutAccountManual from './PayoutAccountManual'
import PayoutOptions from './PayoutOptions'
import ProfileOverview from './SettingsOverview'
import UpdateAddress from './UpdateAddress'
import UpdateEmail from './UpdateEmail'
import UpdatePhone from './UpdatePhone'

function Profile() {
  useDocumentTitle('Settings')

  return (
    <Routes>
      <Route index element={<ProfileOverview />} />
      <Route path="update-phone" element={<UpdatePhone />} />
      <Route path="update-email" element={<UpdateEmail />} />
      <Route path="update-address" element={<UpdateAddress />} />
      <Route path="payout-options" element={<PayoutOptions />} />
      <Route path="payout-account" element={<PayoutAccount />} />
      <Route path="payout-account-manual" element={<PayoutAccountManual />} />
      <Route path="legal">
        <Route path="/legal" element={<LegalDocs />} />
        <Route
          path="/legal/terms-of-service"
          element={<LegalDocDetail type="TermsOfService" />}
        />
        <Route
          path="/legal/benefit-eligibility"
          element={<LegalDocDetail type="BenefitEligibility" />}
        />
        <Route
          path="/legal/benefits-disclosure"
          element={<LegalDocDetail type="BenefitsDisclosure" />}
        />
        <Route
          path="/legal/privacy-policy"
          element={<LegalDocDetail type="PrivacyPolicy" />}
        />
        <Route
          path="/legal/patriot-act"
          element={<LegalDocDetail type="PatriotAct" />}
        />
        <Route
          path="/legal/electronic-communications"
          element={<LegalDocDetail type="ElectronicCommunications" />}
        />
        <Route
          path="/legal/mobile-network-consent"
          element={<LegalDocDetail type="MobileNetworkConsent" />}
        />
        <Route
          path="/legal/plateau-policy-certificate"
          element={<LegalDocDetail type="PlateauPolicyCertificate" />}
        />
        <Route
          path="/legal/plateau-application"
          element={<LegalDocDetail type="PlateauApplication" />}
        />
        <Route
          path="*"
          element={<Navigate to="/home/settings/legal" replace />}
        />
      </Route>
      <Route path="help" element={<Help />} />
      <Route path="close-membership" element={<CloseMembership />} />
      <Route path="*" element={<Navigate to="/home/settings" replace />} />
    </Routes>
  )
}

export default Profile
