import * as event from '@quil/analytics-events'
import {
  Birthday,
  ButtonPrimary,
  InputDate,
  InputEmail,
  InputPhone,
  LegalText,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useRef, useState } from 'react'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LayoutSignupTracked } from '../../common/analytics'
import * as analytics from '../../common/analytics/analytics-adapter'
import { useTrackedState } from '../../common/analytics/useTrackedState'
import DocLink from '../../common/components/DocLink'
import Form from '../../common/components/Form'
import GoBack from '../../common/components/GoBack'
import { searchParamsStorageKey } from '../../common/components/PreserveSearchParams'
import RichText from '../../common/components/RichText'
import { getOrCreateGuestID } from '../../common/experimentContext'
import { isTouchDevice } from '../../common/isTouchDevice'
import { SignupPageProps } from '../SignupPageProps'
import SignupProgressTracker from '../SignupProgressTracker'
import * as testIds from './test-ids'
import {
  AttributionInput,
  TheBasicsMutation,
} from './__generated__/TheBasicsMutation.graphql'
import {
  BillingCadence,
  TheBasicsPreSubMutation,
} from './__generated__/TheBasicsPreSubMutation.graphql'
import { TheBasicsQuery } from './__generated__/TheBasicsQuery.graphql'

export type ValidationError = {
  field: string | null
  help: string | null
}

const theBasicsMutation = graphql`
  mutation TheBasicsMutation(
    $data: UserCreateInput!
    $attribution: AttributionInput
  ) {
    createUser(
      data: $data
      attribution: $attribution
      acceptedAgreements: true
    ) {
      __typename
      ... on User {
        id
        phone
        status
        dob
        email
      }
      ... on ValidationErrors {
        messages {
          field
          help
        }
      }
      ... on PhoneNumberNotAvailable {
        message
      }
    }
  }
`

export const preSubMutation = graphql`
  mutation TheBasicsPreSubMutation(
    $subscriptionChoiceInput: SubscriptionChoiceInput!
  ) {
    saveSubscriptionChoice(input: $subscriptionChoiceInput) {
      __typename
    }
  }
`

export const theBasicsQuery = graphql`
  query TheBasicsQuery {
    disclosures {
      ...DocLink_disclosures
    }
    content {
      onboardingPage(where: { pageName: "The Basics" }) {
        heading
        body {
          raw
        }
        mainCtaText
      }
    }
  }
`

function TheBasics({ step, nextPath, previousPath }: SignupPageProps) {
  const pageName = 'The Basics'
  const showToast = useShowToast()
  const ldClient = useLDClient()

  const data = useLazyLoadQuery<TheBasicsQuery>(theBasicsQuery, {})

  const [email, setEmail] = useTrackedState('', (value) =>
    event.inputChanged('Signup: Email', { email: value })
  )
  const [phone, setPhone] = useTrackedState('', (value) =>
    event.inputChanged('Signup: Phone', { phone: value })
  )
  const [dateOfBirth, setDateOfBirth] = useTrackedState('', (value) =>
    event.inputChanged('Signup: DOB', { dob: value })
  )

  const [emailValidationError, setEmailValidationError] = useState<
    string | null
  >(null)
  const [phoneValidationError, setPhoneValidationError] = useState<
    string | null
  >(null)
  const [dateOfBirthValidationError, setDateOfBirthValidationError] = useState<
    string | null
  >(null)

  const [searchParams] = useSearchParams()

  // autofocus email input on mount (on non touch-devices)
  const emailInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!isTouchDevice()) {
      emailInputRef.current?.focus()
    }
  }, [])

  function clearValidationErrors() {
    setDateOfBirthValidationError(null)
    setPhoneValidationError(null)
  }

  const navigate = useNavigate()
  const [commitTheBasicsMutation, theBasicsMutationInflight] =
    useMutation<TheBasicsMutation>(theBasicsMutation)

  const [commitPreSubMutation] =
    useMutation<TheBasicsPreSubMutation>(preSubMutation)

  function dobFilled() {
    if (!dateOfBirth) {
      return false
    }

    const [year, month, day] = dateOfBirth.split('-')
    return year && month && day
  }

  const formFilled = email && phone && dobFilled()

  function validate(): boolean {
    let hasErrors = false
    const dobInvalidFormat = !/^\d{4}-\d{2}-\d{2}$/.test(dateOfBirth)
    // JS Date is not perfect, but coarse control is fine for this purpose
    const dobInFuture = new Date(dateOfBirth) > new Date()
    const dobTooFarInPast = new Date(dateOfBirth) < new Date(1900, 1, 1)
    const dobMonth = parseInt(dateOfBirth.split('-')[1])
    const dobMontInvalidRange = dobMonth > 12 || dobMonth === 0
    const dobDay = parseInt(dateOfBirth.split('-')[2])
    const dobDayInvalidRange = dobDay > 31 || dobDay === 0

    if (
      dobInvalidFormat ||
      dobInFuture ||
      dobTooFarInPast ||
      dobMontInvalidRange ||
      dobDayInvalidRange
    ) {
      setDateOfBirthValidationError(
        'Date of birth must be a valid date in the format MM/DD/YYYY'
      )
      hasErrors = true
    }

    // server executes more robust validation
    if (phone === '') {
      setPhoneValidationError('Phone number must not be empty')
      hasErrors = true
    }

    return hasErrors
  }

  function setValidationError(message: ValidationError | null) {
    switch (message?.field) {
      case 'email':
        return setEmailValidationError(message.help)
      case 'phone':
        return setPhoneValidationError(message.help)
      case 'dob':
        return setDateOfBirthValidationError(message.help)
    }
  }

  function handleNextClick() {
    const { attribution, preselectedCoverage, billingCadence } =
      parseUrlParams(searchParams)

    analytics.track(event.formSubmitted('The Basics'))

    clearValidationErrors()
    if (validate()) {
      return showToast({
        severity: 'Error',
        headline: standardToastHeadlines.validationErrors,
        message: standardToastMessages.validationErrors,
      })
    }

    commitTheBasicsMutation({
      variables: {
        data: {
          phone: phone.replace(/\s+/g, ''),
          dob: dateOfBirth,
          email,
        },
        attribution,
      },
      async onCompleted(response) {
        clearValidationErrors()

        switch (response.createUser?.__typename) {
          case 'User':
            {
              const { id, status } = response.createUser

              // Note that User Account Created is tracked on the server.
              // User is also identified with traits on the server.
              //
              // this identify call is made on the front to link the existing anonymous id with the real user id.
              await analytics.identify(id)

              // identify with LD so correct context is used
              await ldClient.identify({
                kind: 'multi',
                user: {
                  key: id,
                  status,
                },
                guest: {
                  key: getOrCreateGuestID(),
                },
              })
              await ldClient.track('user_created')
              await ldClient.track('phone_submitted')

              // if preselected coverage was retained from marketing site, set it now that we have a user
              if (preselectedCoverage) {
                commitPreSubMutation({
                  variables: {
                    subscriptionChoiceInput: {
                      // coverage amounts received from marketing site in this way come in dollars
                      // while we need to send cents to our api
                      coverage: preselectedCoverage * 100,
                      cadence: billingCadence,
                    },
                  },
                  async onError(error) {
                    console.error({
                      error,
                      mutation: 'TheBasicsPreSubMutation',
                    })
                  },
                })
              }

              navigate(nextPath)
            }
            break
          case 'ValidationErrors':
            showToast({
              severity: 'Error',
              headline: standardToastHeadlines.validationErrors,
              message: standardToastMessages.validationErrors,
            })
            response.createUser.messages?.forEach(setValidationError)
            analytics.track(
              event.userAccountCreationFailed(
                JSON.stringify(response.createUser.messages)
              )
            )
            break
          case 'PhoneNumberNotAvailable':
            navigate('/login', {
              state: { phone },
            })
            analytics.track(
              event.userAccountCreationFailed(
                JSON.stringify(response.createUser.message)
              )
            )
            break
          default:
            analytics.track(event.userAccountCreationFailed())
        }
      },

      onError(error) {
        console.error({ error, mutation: 'createUser' })
        clearValidationErrors()
        analytics.track(event.userAccountCreationFailed())
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.genericError,
          message: standardToastMessages.retry,
          onClick: handleNextClick,
        })
      },

      // associate this user id with Query.currentUser in the relay store
      updater(store, payload) {
        if (payload.createUser.__typename === 'User') {
          const currentUser = store.get(payload.createUser.id)
          store.getRoot().setLinkedRecord(currentUser, 'currentUser')
        }
      },
    })
  }

  return (
    <>
      <LayoutSignupTracked
        pageName={pageName}
        title={data.content.onboardingPage.heading}
        data-testid={testIds.page}
        headerStart={previousPath && <GoBack />}
        headerEnd={<SignupProgressTracker step={step} />}
        footerContent={
          <ButtonPrimary
            data-page-name={pageName}
            data-name="Primary CTA"
            onClick={handleNextClick}
            disabled={!formFilled || theBasicsMutationInflight}
            data-testid={testIds.primaryCta}
          >
            {data.content.onboardingPage.mainCtaText}
          </ButtonPrimary>
        }
        legalContent={
          <>
            <LegalText>
              By applying for Quil, you agree to Quil’s{' '}
              <DocLink
                disclosures={data.disclosures}
                kind="electronicCommunications"
              >
                Electronic Communication
              </DocLink>
              ,{' '}
              <DocLink
                disclosures={data.disclosures}
                kind="mobileNetworkConsent"
              >
                Mobile Network Consent
              </DocLink>
              ,{' '}
              <DocLink disclosures={data.disclosures} kind="privacyPolicy">
                Privacy Policy
              </DocLink>{' '}
              and the{' '}
              <DocLink disclosures={data.disclosures} kind="patriotAct">
                Patriot Act
              </DocLink>
              .
            </LegalText>
            <LegalText>We don’t sell your data to any third parties.</LegalText>
            <LegalText>Message and data rates may apply.</LegalText>
          </>
        }
      >
        <RichText content={data.content.onboardingPage.body.raw} />

        <Form onSubmit={handleNextClick}>
          <InputEmail
            value={email}
            onChange={setEmail}
            error={emailValidationError ?? ''}
            data-testid={testIds.emailField}
            ref={emailInputRef}
          />
          <InputPhone
            value={phone}
            onChange={setPhone}
            error={phoneValidationError ?? ''}
            data-testid={testIds.phoneField}
          />
          <InputDate
            label="Date of birth"
            value={dateOfBirth}
            onChange={setDateOfBirth}
            error={dateOfBirthValidationError ?? ''}
            data-testid={testIds.dateOfBirthField}
            startAdornment={<Birthday />}
          />
        </Form>
      </LayoutSignupTracked>
      <ProcessingOverlay processing={theBasicsMutationInflight} />
    </>
  )
}

export default TheBasics

type ParsedUrlParams = {
  billingCadence: BillingCadence
  attribution: AttributionInput
  preselectedCoverage: number
}
export function parseUrlParams(searchParams: URLSearchParams): ParsedUrlParams {
  // Marketing site optionally sends 'coverage' and 'billingCadence' search params to indicate user selections.
  // When present, we save these as the user's subscription choice.
  let preselectedCoverage = null
  let billingCadence: BillingCadence = 'Monthly'
  let attribution: AttributionInput = null

  // To avoid dropping search params on page navigation,
  // all params are set to sessionStorage in PreserveSearchParams component.
  // Use any params on the page, or fall back to preserved search params
  const paramString =
    searchParams?.toString() ||
    (sessionStorage.getItem(searchParamsStorageKey) ?? '')
  if (paramString !== '') {
    const params = new URLSearchParams(paramString)
    const promotionId =
      params.get('quilPromotionId') ?? params.get('utm_campaign')
    params.delete('quilPromotionId')

    preselectedCoverage = params.get('coverage')
      ? parseInt(params.get('coverage'))
      : null
    // Marketing site might send 3000 until copy/values are updated; auto-upgrade to 3.5k coverage
    if (preselectedCoverage === 3000) {
      preselectedCoverage = 3500
    }
    if (['Monthly', 'Yearly'].includes(params.get('billingCadence'))) {
      billingCadence = params.get('billingCadence') as BillingCadence
    }

    if (promotionId === 'usalliance') {
      preselectedCoverage = preselectedCoverage ?? 3500
      billingCadence = billingCadence ?? 'Yearly'
    }

    attribution = {
      promotionId,
      promotionMeta: params.toString(),
    }
  }

  return { attribution, preselectedCoverage, billingCadence }
}
