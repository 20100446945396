/**
 * @generated SignedSource<<16f6ee6976ef6d313b12488ba7a0f040>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LayoffClaimInformationRequestStatus = "Fulfilled" | "Open" | "%future added value";
export type LayoffClaimInformationRequestType = "FileUpload" | "Note" | "%future added value";
export type SubmitAdditionalInformationQuery$variables = {
  id: string;
};
export type SubmitAdditionalInformationQuery$data = {
  readonly layoffClaimInformationRequests: ReadonlyArray<{
    readonly claimId: string;
    readonly detail: string | null;
    readonly id: string | null;
    readonly requestedAt: {
      readonly formatted: string;
    };
    readonly status: LayoffClaimInformationRequestStatus;
    readonly title: string;
    readonly type: LayoffClaimInformationRequestType;
  }>;
};
export type SubmitAdditionalInformationQuery = {
  response: SubmitAdditionalInformationQuery$data;
  variables: SubmitAdditionalInformationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "claimId",
        "variableName": "id"
      },
      {
        "kind": "Literal",
        "name": "filter",
        "value": {
          "status": "Open"
        }
      }
    ],
    "concreteType": "LayoffClaimInformationRequest",
    "kind": "LinkedField",
    "name": "layoffClaimInformationRequests",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "claimId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "detail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Instant",
        "kind": "LinkedField",
        "name": "requestedAt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "formatted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitAdditionalInformationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmitAdditionalInformationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c8fb19ef728e27430bb2bf3605f5a167",
    "id": null,
    "metadata": {},
    "name": "SubmitAdditionalInformationQuery",
    "operationKind": "query",
    "text": "query SubmitAdditionalInformationQuery(\n  $id: ID!\n) {\n  layoffClaimInformationRequests(claimId: $id, filter: {status: Open}) {\n    id\n    claimId\n    status\n    type\n    title\n    detail\n    requestedAt {\n      formatted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0be95133636fea0ea9d51a39c196d0d";

export default node;
