import React from 'react'
import styled from 'styled-components'
import { Locked, Unlocked } from '../../icons'
import { fromTheme } from '../../theme'
import IconButton from '../IconButton'

const StyledIconButton = styled(IconButton)`
  width: 6rem;
  height: 4rem;

  > svg {
    fill: ${fromTheme((theme) => theme.colors.foregroundPrimary.raw)};
  }
`

type Props = {
  'data-testid': string
  isLocked: boolean
  onClick: (e: React.MouseEvent) => void
}

const Container = styled.div`
  text-align: center;
`

function LockButton(props: Props) {
  const icon = props.isLocked ? <Unlocked /> : <Locked />
  const text = props.isLocked ? 'Unlock card' : 'Lock card'
  return (
    <Container>
      <StyledIconButton
        onClick={props.onClick}
        data-testid={props['data-testid']}
      >
        {icon}
        <div>{text}</div>
      </StyledIconButton>
    </Container>
  )
}

export default LockButton
