/**
 * @generated SignedSource<<1bf1a80e95962afab56bceeaf640506b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WelcomeQuery$variables = {};
export type WelcomeQuery$data = {
  readonly content: {
    readonly onboardingPage: {
      readonly heading: string | null;
      readonly subheading: string | null;
    } | null;
  };
};
export type WelcomeQuery = {
  response: WelcomeQuery$data;
  variables: WelcomeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "Welcome"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subheading",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WelcomeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Welcome\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WelcomeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Welcome\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f939c7e64a54a319236ada7b8f94c65e",
    "id": null,
    "metadata": {},
    "name": "WelcomeQuery",
    "operationKind": "query",
    "text": "query WelcomeQuery {\n  content {\n    onboardingPage(where: {pageName: \"Welcome\"}) {\n      heading\n      subheading\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "537b73c18affe632f413557aee41e7ac";

export default node;
