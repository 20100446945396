import styled, { css } from 'styled-components'
import { inputAttributeFallbacks } from '../../common/input-attrubute-fallbacks'
import { fromTheme } from '../../theme'
import ErrorBadge from '../ErrorBadge'
import ErrorMessageContainer from '../ErrorMessageContainer'
import ErrorMessageTextValidation from '../ErrorMessageTextValidation'
import { InputLabel } from '../InputLabel'

type Props = {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  error?: string
  label?: string
  name?: string
  id?: string
  'data-testid'?: string
  disabled?: boolean
}

const Container = styled.div`
  position: relative;
  margin: 0 0 1.6rem;
  display: inline-block;
  max-width: 100%;
`

export const InputContainer = styled.div<{
  error: boolean
  disabled?: boolean
}>`
  border: 1px solid
    ${fromTheme((theme, props) =>
      props.error
        ? theme.colors.foregroundDanger.raw
        : theme.colors.foregroundMuted.raw
    )};
  border-radius: 3px;
  display: inline-block;
  font-size: 1.8rem;
  outline: none;
  padding: 1rem;
  max-width: 100%;
  position: relative;

  &:focus-within {
    border: 1px solid
      ${fromTheme((theme, props) =>
        props.error
          ? theme.colors.foregroundDanger.raw
          : theme.colors.foregroundMutedFocused.raw
      )};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

const Textarea = styled.textarea`
  outline: none;
  border: none;
  min-width: 200px;
  min-height: 60px;
  max-width: 100%;
  font-size: 1.8rem;
  background-color: ${fromTheme(({ colors }) => colors.background.raw)};
  color: ${fromTheme(({ colors }) => colors.textPrimary.raw)};
`

export function InputTextarea(props: Props) {
  const { label, name, id } = inputAttributeFallbacks(
    props.label,
    props.name,
    props.id
  )

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    props.onChange(event.target.value)
  }

  return (
    <Container>
      {label && (
        <InputLabel error={!!props.error} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <InputContainer error={!!props.error} disabled={props.disabled}>
        <Textarea
          id={id}
          name={name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          cols={300}
          rows={6}
          data-testid={`${props['data-testid']}_input`}
          disabled={props.disabled}
        />
        <ErrorBadge error={!!props.error} style={{ top: -12 }} />
      </InputContainer>
      <ErrorMessageContainer visible={!!props.error && props.error.length > 0}>
        <ErrorMessageTextValidation
          data-testid={`${props['data-testid']}_error_message`}
        >
          {props.error}
        </ErrorMessageTextValidation>
      </ErrorMessageContainer>
    </Container>
  )
}
