import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { focusRingBase, focusRingFocused } from '../../common/styles/focus-ring'
import { fromTheme } from '../../theme'

export const Container = styled.div`
  margin-bottom: 1.6rem;

  /* hide native checkbox. 'display: none' will skip focus, don't want that. */
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
  }

  label {
    cursor: pointer;
    display: flex;
    font-size: 1.6rem;
    font-weight: 400;
  }

  .display-input {
    border-radius: 0.3rem;
    border: 0.1rem solid
      ${fromTheme(({ colors }) => colors.foregroundMuted.raw)};
    display: inline-block;
    flex-shrink: 0;
    height: 2.5rem;
    margin-right: 1.6rem;
    position: relative;
    top: -0.15rem;
    width: 2.5rem;
  }

  .display-input::before {
    ${focusRingBase}
  }

  input:focus-visible ~ .display-input::before {
    ${focusRingFocused}
  }

  // The "checkmark" (it's actually just a box)
  .display-input::after {
    content: '';
    display: block;
    width: 1.9rem;
    height: 1.9rem;
    border-radius: 0.1rem;
    transform: translate(-50%, -50%) scale(0.9);
    top: 50%;
    left: 50%;
    background: ${fromTheme(({ colors }) => colors.foregroundPrimary.raw)};
    position: absolute;
    transition: transform 0.5s, opacity 0.15s;
    opacity: 0;
  }

  // Show the checkmark when the input is checked
  input:checked ~ .display-input::after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.25s, opacity 0.1s;
  }
`

type CommonProps = {
  checked: boolean
  'data-testid'?: string
  error?: string
  onChange: (checked: boolean) => void
  className?: string
  disabled?: boolean
}

type StringLabelProps = CommonProps & {
  label: string
  name?: string
}

type ElementLabelProps = CommonProps & {
  label: JSX.Element
  name?: string
}

const InputCheckbox: React.FC<StringLabelProps | ElementLabelProps> = (
  props
) => {
  const testId = props['data-testid']
  const containerTestId = testId ? `${testId}_container` : undefined
  const inputTestId = testId ? `${testId}_input` : undefined
  const displayInputTestId = testId ? `${testId}_display_input` : undefined

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    props.onChange(e.target.checked)
  }

  return (
    <Container className={props.className} data-testid={containerTestId}>
      <label>
        <input
          type="checkbox"
          checked={props.checked}
          onChange={handleChange}
          name={props.name}
          disabled={props.disabled}
          data-testid={inputTestId}
        />
        <span className="display-input" data-testid={displayInputTestId} />
        <span>{props.label}</span>
      </label>
    </Container>
  )
}

export default InputCheckbox
