import { PlanLabel } from '@web/Signup/Customize/MyPlan/__generated__/MyPlanQuery.graphql'

export default function getPlanColorScheme(planLabel: PlanLabel) {
  switch (planLabel) {
    case 'affordable':
      return 'kournikova'
    case 'popular':
      return 'anakiwa'
    case 'premium':
      return 'emerald'
  }
}
