import React from 'react'
import { ReactComponent as UserIcon } from '../../icons/user.svg'
import InputAdornment from '../InputAdornment'
import InputText, { Props as InputTextProps } from '../InputText'

type Props = Pick<
  InputTextProps,
  'value' | 'onChange' | 'error' | 'data-testid' | 'disabled' | 'labelEnd'
> & {
  type: 'First' | 'Last'
}

const InputName: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { type, ...props },
  ref
) => {
  return (
    <InputText
      {...props}
      ref={ref}
      startAdornment={
        <InputAdornment>
          <UserIcon />
        </InputAdornment>
      }
      label={`${type} legal name`}
      name={`${type.toLowerCase()}_name`}
      placeholder={`${type} Name`}
      labelEnd={props.labelEnd}
    />
  )
}

export default React.forwardRef(InputName)
