import * as event from '@quil/analytics-events'
import { ButtonPrimary, InlineButtonContainer } from '@quil/ui'
import { resumeClaimPath, resumeClaimStep } from '@web/Claims/common'
import { LayoutInAppTracked } from '@web/common/analytics'
import * as analytics from '@web/common/analytics/analytics-adapter'
import RichText from '@web/common/components/RichText'
import { Navigate, useNavigate } from '@web/common/react-router-wrappers'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { NewClaimIntroQuery } from './__generated__/NewClaimIntroQuery.graphql'

const NewClaimIntro = () => {
  const data = useLazyLoadQuery<NewClaimIntroQuery>(
    graphql`
      query NewClaimIntroQuery {
        currentUser {
          id
          layoffProtectionStatus
        }
        pendingLayoffClaim {
          id
          status
          minimumWeeklyHours
          changedJobsRecently
          terminatedWithoutCause
          receivingStateBenefits
          employerName
          occupation
          employmentStartDate
          employmentEndDate
          terminationReason
          additionalInfo
          employerEmail
          employerPhone
          payoutAccount {
            accountNumberMask
          }
          submittedAt {
            formatted
          }
        }
        content {
          newClaimPage(where: { name: "Intro" }) {
            heading
            body {
              raw
            }
            mainCtaText
          }
        }
      }
    `,
    {}
  )

  const navigate = useNavigate()

  if (data.currentUser.layoffProtectionStatus === 'Waiting') {
    return <Navigate to="/home/claims/new/waiting" replace />
  }

  if (data.currentUser.layoffProtectionStatus === 'Pending') {
    analytics.track(
      event.claimResumed(resumeClaimStep(data.pendingLayoffClaim))
    )
    return <Navigate to={resumeClaimPath(data.pendingLayoffClaim)} replace />
  }

  if (
    ['InReview', 'Approved', 'ContactSupport'].includes(
      data.currentUser.layoffProtectionStatus
    )
  ) {
    return <Navigate to="/home" replace />
  }

  async function handleSubmit() {
    navigate('employment-type')
  }

  return (
    <LayoutInAppTracked
      title={data.content.newClaimPage.heading}
      pageName={'New Claim: Intro'}
      footerContent={
        <InlineButtonContainer>
          <ButtonPrimary data-name="Continue" onClick={handleSubmit}>
            {data.content.newClaimPage.mainCtaText}
          </ButtonPrimary>
        </InlineButtonContainer>
      }
    >
      <RichText content={data.content.newClaimPage.body.raw} />
    </LayoutInAppTracked>
  )
}

export default NewClaimIntro
