import Fuse from 'fuse.js'
import { take } from 'ramda'
import {
  ChangeEvent,
  KeyboardEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { fromTheme } from '../../theme'
import InputText, { Props as InputTextProps } from '../InputText'

const Container = styled.div`
  position: relative;
  overflow: visible;
`

const ListBox = styled.ul<{ positionOnTop: boolean }>`
  position: absolute;
  padding: 1rem;
  ${(props) => (props.positionOnTop ? 'bottom: 100%' : 'top: 100%')};
  border: 1px solid ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  border-radius: 0.8rem;
  background-color: ${fromTheme((theme) => theme.colors.background.raw)};
  width: 100%;
  margin-top: 1.6rem;
  z-index: 1;
`

const ListItem = styled.li<{ highlighted: boolean }>`
  list-style: none;
  font-size: 1.8rem;
  padding: 0.4rem;
  margin: 0.8rem 0;
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  background-color: ${fromTheme((theme, { highlighted }) =>
    highlighted
      ? theme.colors.foregroundPrimary.raw
      : theme.colors.background.raw
  )};
  cursor: pointer;
`

function incrementWraparound(currentIndex: number | null, list: unknown[]) {
  if (currentIndex === null) {
    return 0
  }
  const next = currentIndex + 1
  return next >= list.length ? 0 : next
}

function decrementWraparound(currentIndex: number | null, list: unknown[]) {
  if (currentIndex === null) {
    return 0
  }
  const next = currentIndex - 1
  return next < 0 ? list.length - 1 : next
}

type Props = {
  options: string[]
  onChange: (value: string, event?: ChangeEvent<HTMLInputElement>) => void
  includeValueInSuggestions?: boolean
} & InputTextProps
function InputAutocomplete(props: Props) {
  const searcherRef = useRef<Fuse<string> | null>(null)
  useEffect(() => {
    searcherRef.current = new Fuse(props.options)
  }, [props.options])

  const [visibleOptions, setVisibleOptions] = useState<string[]>([])
  const closeVisibleOptions = () => setVisibleOptions([])
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)
  const visualViewportHeight = useRef(window.visualViewport?.height ?? 0)
  const [isVirtualKeyboardOpen, setIsVirtualKeyboardOpen] = useState(false)

  function handleChange(value: string, event: ChangeEvent<HTMLInputElement>) {
    const results = searcherRef.current?.search(value ?? '')
    const resultCount = props.includeValueInSuggestions ? 5 : 6
    const resultsToShow = take(
      resultCount,
      results?.map((result) => result.item) ?? []
    )
    if (props.includeValueInSuggestions && value) {
      // LD Experiment to add typed value to displayed autocomplete options
      resultsToShow.push(value)
    }
    setVisibleOptions(resultsToShow)
    props.onChange(value, event)
  }

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (visibleOptions.length) {
        switch (event.key) {
          case 'Tab':
            setHighlightedIndex(
              event.shiftKey
                ? decrementWraparound(highlightedIndex, visibleOptions)
                : incrementWraparound(highlightedIndex, visibleOptions)
            )
            event.preventDefault()
            break
          case 'ArrowDown':
            setHighlightedIndex((highlightedIndex) =>
              incrementWraparound(highlightedIndex, visibleOptions)
            )
            event.preventDefault()
            break
          case 'ArrowUp':
            setHighlightedIndex((highlightedIndex) =>
              decrementWraparound(highlightedIndex, visibleOptions)
            )
            event.preventDefault()

            break
          case 'Enter':
            if (highlightedIndex !== null) {
              closeVisibleOptions()
              props.onChange(visibleOptions[highlightedIndex])
              event.stopPropagation()
              event.preventDefault()
            }
            break
          case 'Escape':
            closeVisibleOptions()
        }
      }
    },
    [visibleOptions, highlightedIndex, props]
  )

  // handle viewport resize.
  // when the visualViewport shrinks, we assume that the virtual keyboard was opened,
  // which positions the suggestion list on top of the input
  useEffect(() => {
    if (window.visualViewport) {
      const handler = (event: Event) => {
        const target = event.target as VisualViewport
        // @ts-expect-error experimental feature, not available in all browsers
        const userAgentData = navigator.userAgentData
        const isDesktop =
          typeof userAgentData?.mobile !== 'undefined' && !userAgentData?.mobile
        if (target.height < visualViewportHeight.current && !isDesktop) {
          // when viewport shrinks, it indicates the keyboard is opening
          setIsVirtualKeyboardOpen(true)
        } else if (target.height > visualViewportHeight.current) {
          // only set false when target.height increases; staying the same should be ignored
          setIsVirtualKeyboardOpen(false)
        }
        visualViewportHeight.current = target.height
      }
      window.visualViewport.addEventListener('resize', handler)

      return () => {
        window.visualViewport?.removeEventListener('resize', handler)
      }
    }
  }, [])

  useEffect(() => {
    if (visibleOptions.length === 0) {
      setHighlightedIndex(null)
    }
  }, [visibleOptions])

  useEffect(() => {
    function handler(event: MouseEvent) {
      if (event.defaultPrevented) {
        return
      }
      closeVisibleOptions()
    }
    if (visibleOptions.length) {
      window.addEventListener('click', handler)
      return () => window.removeEventListener('click', handler)
    }
  }, [visibleOptions])

  function createOptionClickHandler(optionText: string): MouseEventHandler {
    return function handleOptionClick(event) {
      closeVisibleOptions()
      props.onChange(optionText)
    }
  }

  function createOptionHoverHandler(index: number) {
    return function handleMouseEnter() {
      setHighlightedIndex(index)
    }
  }

  return (
    <Container>
      <InputText
        {...props}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoComplete={'off'}
      />
      {visibleOptions.length > 0 && (
        <ListBox role="listbox" positionOnTop={isVirtualKeyboardOpen}>
          {visibleOptions.map((option, index) => (
            <ListItem
              key={`${index}_${option}`}
              role="option"
              aria-selected={false}
              highlighted={highlightedIndex === index}
              onClick={createOptionClickHandler(option)}
              onMouseEnter={createOptionHoverHandler(index)}
            >
              {option}
            </ListItem>
          ))}
        </ListBox>
      )}
    </Container>
  )
}

export default InputAutocomplete
