import { graphql, useLazyLoadQuery } from 'react-relay'
import { Navigate, Route, Routes } from 'react-router-dom'
import Benefits from './Benefits'
import CheckoutSuccess from './CheckoutSuccess'
import Claims from './Claims'
import { ActiveUserArea } from './common/components/AccessControl'
import Home from './Home'
import Login from './Login'
import PrefillUser from './PrefillUser'
import Settings from './Settings'
import Signup from './Signup'
import Test from './Test'
import VerifyContact from './VerifyContact'
import Welcome from './Welcome'
import { RoutesQuery } from './__generated__/RoutesQuery.graphql'

// Routes renders the top-level Routes for the app.
// Nested Routes should be defined in the Components themselves
function AppRoutes() {
  const data = useLazyLoadQuery<RoutesQuery>(
    graphql`
      query RoutesQuery {
        currentUser {
          ...AccessControl_user
        }
      }
    `,
    {}
  )
  return (
    <Routes>
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/signup/*"
        element={
          process.env.REACT_APP_KILLSWITCH_ENABLED === 'true' ? (
            <Welcome />
          ) : (
            <Signup />
          )
        }
      />
      <Route path="/verify-device" element={<PrefillUser />} />
      <Route path="/verify-contact" element={<VerifyContact />} />
      <Route path="/checkout-success" element={<CheckoutSuccess />} />
      {process.env.REACT_APP_ENV !== 'production' && (
        <Route path="/test/*" element={<Test />} />
      )}
      <Route
        path="/home/*"
        element={
          <ActiveUserArea user={data.currentUser}>
            <Routes>
              <Route index element={<Home />} />
              <Route path="/settings/*" element={<Settings />} />
              <Route path="/claims/*" element={<Claims />} />
              <Route path="/benefits/*" element={<Benefits />} />

              <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
          </ActiveUserArea>
        }
      ></Route>

      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  )
}

export default AppRoutes
