import { Navigate, Route, Routes } from 'react-router-dom'
import NewClaimIntro from './NewClaimIntro'
import { NewClaimSequence } from './NewClaimSequence'
import Success from './Success'
import Waiting from './Waiting'

export default function New() {
  return (
    <Routes>
      <Route index element={<NewClaimIntro />} />
      <Route path="waiting" element={<Waiting />} />
      <Route path="success" element={<Success />} />

      {/* CreateClaimSequence */}
      {Object.values(NewClaimSequence).map(({ Component, path, ...props }) => (
        <Route key={path} path={path} element={<Component {...props} />} />
      ))}

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}
