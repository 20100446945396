import { fromTheme } from '@quil/ui'
import styled from 'styled-components'

export const HomeHeadline = styled.h2`
  margin: 0;
  font-family: 'Unbounded';
  font-size: 2.1rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
  color: ${fromTheme(({ colors }) => colors.foregroundPrimary.raw)};
`
