import {
  ActionListItem,
  ActionListLink,
  ActionListList,
  AvibraBadge,
  SubheadingAlt,
} from '@quil/ui'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { LayoutInAppTracked } from '../../common/analytics'
import GoBack from '../../common/components/GoBack'
import { Navigate } from '../../common/react-router-wrappers'
import { ProtectionQuery } from './__generated__/ProtectionQuery.graphql'

export default function Protection() {
  const pageName = 'Protection'
  const { vendorBenefits } = useLazyLoadQuery<ProtectionQuery>(
    graphql`
      query ProtectionQuery {
        vendorBenefits {
          protection {
            name
            displayName
            icon
            description
          }
        }
      }
    `,
    {}
  )

  if (vendorBenefits.protection.length === 0) {
    return <Navigate to="/home/benefits" />
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title="Protection"
      headerStart={<GoBack />}
    >
      <ActionListList>
        {vendorBenefits.protection.map((benefit) => (
          <ActionListItem key={benefit.name}>
            <ActionListLink
              // unlike Counseling page, the benefit.name in the Protection category maps directly to our routes, so no custom map is needed
              to={`/home/benefits/protection/${benefit.name}`}
              state={{ from: '/home/benefits/protection' }}
              data-testid={`benefits_button_${benefit.name}`}
              data-name={benefit.displayName}
              data-page-name={pageName}
            >
              <SubheadingAlt>{benefit.displayName}</SubheadingAlt>
              {benefit.description}
              <AvibraBadge />
            </ActionListLink>
          </ActionListItem>
        ))}
      </ActionListList>
    </LayoutInAppTracked>
  )
}
