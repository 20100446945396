import styled from 'styled-components'
import {} from '.'
import { Chat } from '../../icons'
import { fromTheme, hsla } from '../../theme'
import IconButton from '../IconButton'

const Container = styled.div`
  overflow: hidden;
  display: block;
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 50%;

  transition: box-shadow 0.3s;
  /* fix safari quirk with layering during animation */
  transform: translateZ(0);

  box-shadow: 0px 0px 3px ${fromTheme(({ colors }) => hsla(colors.shadow, 0.3))};

  :focus,
  :focus-within,
  :hover {
    box-shadow: 0px 0px 3px
        ${fromTheme(({ colors }) => hsla(colors.shadow, 0.3))},
      0 0 0 2px ${fromTheme((theme) => theme.colors.foregroundPrimary.raw)};
  }

  svg {
    width: 2.3rem;
    margin: 0.45rem 0 0 0.05rem;
  }
`

type Props = {
  onClick: () => void
  'data-test-id'?: string
}

function ChatButton(props: Props) {
  return (
    <Container>
      <IconButton
        onClick={props.onClick}
        data-test-id={props['data-test-id']}
        aria-label="Chat"
        height={3.3}
        width={3.3}
      >
        <Chat />
      </IconButton>
    </Container>
  )
}

export default ChatButton
