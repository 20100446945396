import { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import DropdownSvg from '../../icons/dropdown.svg'
import { fromTheme } from '../../theme'
import ErrorBadge from '../ErrorBadge'
import ErrorMessageContainer from '../ErrorMessageContainer'
import ErrorMessageTextValidation from '../ErrorMessageTextValidation'
import { InputLabel } from '../InputLabel'

export type SelectOption<T extends string> = {
  value: T
  label: string
  disabled?: boolean
}

const Container = styled.div`
  position: relative;
`

const StyledSelect = styled.select<{ error: boolean }>`
  appearance: none;
  background: url(${DropdownSvg}) no-repeat 97% 50%;
  background-color: ${fromTheme((theme) => theme.colors.background.raw)};
  border-radius: 3px;
  // Safari and Dark theme requires explicit color for Select text
  color: ${fromTheme(({ colors }) => colors.textPrimary.raw)};
  width: 100%;
  flex-grow: 1;
  font-size: 1.8rem;
  height: 48px;
  min-width: 0;
  outline: none;
  padding: 0 1.8rem;
  margin-bottom: 1.6rem;
  width: 100%;
  border: 1px solid
    ${fromTheme((theme, props) =>
      props.error
        ? theme.colors.foregroundDanger.raw
        : theme.colors.foregroundMuted.raw
    )};

  /* branded focus ring -
 we can't use brandFocusRing here because select elements don't support pseudo elements. */
  box-shadow: 0 0 0 0px ${fromTheme(({ colors }) => colors.background.raw)},
    0 0 0 0px ${fromTheme(({ colors }) => colors.background.raw)};
  transition: box-shadow 0.1s;

  &:focus-visible {
    box-shadow: 0 0 0 2px ${fromTheme(({ colors }) => colors.background.raw)},
      0 0 0 4px ${fromTheme(({ colors }) => colors.foregroundPrimary.raw)};
    transition: box-shadow 0.2s;
  }
`

type SelectProps<T extends string> = {
  label?: string
  id?: string
  name?: string
  value?: T
  onChange: (value: T) => void
  options: SelectOption<T>[]
  error: string | null
  nullOptionLabel?: string
  'data-testid'?: string
}

export function Select<T extends string>(props: SelectProps<T>) {
  const [hasChanged, setHasChanged] = useState(false)

  const options = props.nullOptionLabel
    ? [
        {
          label: props.nullOptionLabel,
          value: undefined,
          disabled: hasChanged,
        },
        ...props.options,
      ]
    : props.options

  if (!props.name && !props.label) {
    throw new Error('either name or label is required')
  }

  const name = props.name ?? props.label?.split(' ').join('_')
  const id = props.id ?? `select${name}`

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    setHasChanged(true)
    props.onChange(event.target.value as T)
  }

  return (
    <Container>
      {!!props.label && (
        <InputLabel htmlFor={id} error={!!props.error}>
          {props.label}
        </InputLabel>
      )}
      <StyledSelect
        id={id}
        onChange={handleChange}
        required
        error={!!props.error}
        data-testid={props['data-testid'] ?? null}
        value={props.value}
      >
        {options.map((op) => (
          <option
            key={`${props.label}${op.value ?? 'Null'}`}
            value={op.value}
            disabled={op.disabled}
          >
            {op.label}
          </option>
        ))}
      </StyledSelect>
      <ErrorMessageContainer
        visible={props.error !== null && props.error.length > 0}
      >
        <ErrorMessageTextValidation
          data-testid={`${props['data-testid']}_error_message`}
        >
          {props.error}
        </ErrorMessageTextValidation>
      </ErrorMessageContainer>
      <ErrorBadge error={!!props.error} style={{ top: -12 }} />
    </Container>
  )
}
