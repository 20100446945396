import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store,
  Variables,
} from 'relay-runtime'
import { logout } from './logout'

const url = process.env.REACT_APP_GRAPHQL_URL

function sendJson(query: string, variables: Variables, url) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
    credentials: 'include',
  })
}

const fetchQuery: FetchFunction = async (operation, variables) => {
  if (!url) {
    throw new Error('REACT_APP_GRAPHQL_URL is not defined')
  }

  const response = await sendJson(operation.text, variables, url)
  const json = await response.json()

  if (json.errors) {
    // hard refresh the app on the login screen for unauthorized users
    if (
      json.errors?.some((error) => error?.extensions?.code === 'UNAUTHORIZED')
    ) {
      logout()

      return new Promise(() => {
        // never resolve, we don't want to render errors while
        // the browser is navigating.
      })
    }

    // Relay swallows errors in some scenarios when data is not null
    return { data: null, errors: json.errors }
  }

  return json
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
})

export default environment
