/**
 * Defines the known types of File Uploads we expect during the claims process.
 * This is not defined in the FileUpload model because the `type` column is intentionally a string;
 * during Information Requests, the Support team is allowed to request anything they want.
 * This is not defined in the API as an enum because the values of FileUpload.type might not conform
 * to a known enum value for the same reason as above.
 */
export enum FileUploadType {
  PayStub = 'PayStub',
  ProofOfStateBenefitsApplication = 'ProofOfStateBenefitsApplication',
  ProofOfStateBenefitsPayment = 'ProofOfStateBenefitsPayment',
}
