import {
  SpinnerFullScreen,
  standardToastHeadlines,
  useShowToast,
} from '@quil/ui'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useCallback, useEffect } from 'react'
import { graphql, useMutation } from 'react-relay'
import { useDebouncedCallback } from 'use-debounce'
import { useLocation, useNavigate } from '../common/react-router-wrappers'
import { CheckoutSuccessActivateMembershipMutation } from './__generated__/CheckoutSuccessActivateMembershipMutation.graphql'
import { CheckoutSuccessMutation } from './__generated__/CheckoutSuccessMutation.graphql'

export default function () {
  const { search } = useLocation()
  const navigate = useNavigate()
  const showToast = useShowToast()
  const ldClient = useLDClient()

  const [commitCheckoutSuccessMutation] =
    useMutation<CheckoutSuccessMutation>(graphql`
      mutation CheckoutSuccessMutation($input: CompleteCheckoutSessionInput!) {
        completeCheckoutSession(input: $input) {
          id
          status
        }
      }
    `)
  const [commitActivateMutation] =
    useMutation<CheckoutSuccessActivateMembershipMutation>(
      graphql`
        mutation CheckoutSuccessActivateMembershipMutation {
          activateMembership {
            id
            status
          }
        }
      `
    )

  const handleError = useCallback(() => {
    navigate('/signup/verify-personal-info')
    showToast({
      headline: standardToastHeadlines.genericError,
      message: 'Something went wrong. Please try again later.',
      severity: 'Error',
    })
  }, [navigate])

  const handleSuccess = useCallback(async () => {
    navigate('/home')
    showToast({
      headline: standardToastHeadlines.success,
      message: 'Membership setup complete!',
      severity: 'Success',
    })
    await ldClient.track('membership_activated')
  }, [navigate])

  const handleMembershipCompleteSuccess = useCallback(() => {
    commitActivateMutation({
      variables: {},
      onError(error) {
        console.error({ error, mutation: 'activateMembership' })
        handleError()
      },
      onCompleted() {
        handleSuccess()
      },
    })
  }, [commitActivateMutation, handleError, handleSuccess])

  const onMount = useDebouncedCallback(
    () => {
      const sessionId = new URLSearchParams(search).get('sessionId')

      if (!sessionId) {
        handleError()
      }

      commitCheckoutSuccessMutation({
        variables: {
          input: {
            sessionId,
          },
        },
        onCompleted(response) {
          if (response.completeCheckoutSession.status === 'Pending') {
            handleMembershipCompleteSuccess()
          } else {
            navigate('/home/settings')
          }
        },

        onError(error) {
          console.error({ error, mutation: 'completeCheckoutSession' })
          handleError()
        },
      })
    },
    1000,
    {
      leading: true,
      trailing: false,
    }
  )

  useEffect(onMount, [
    onMount,
    commitCheckoutSuccessMutation,
    handleError,
    handleSuccess,
    search,
    navigate,
    handleMembershipCompleteSuccess,
  ])

  return <SpinnerFullScreen />
}
