export type VerifyPersonalInfoState = {
  values: {
    firstName: string
    lastName: string
    phone: string
    dob: string
    email: string
    ssn: string
    addressLineOne: string
    addressLineTwo: string
    city: string
    state: string
    zip: string
    agreedAt: string | null
    subscriptionCoverage: string
  }
  errors: {
    firstName: string
    lastName: string
    phone: string
    dob: string
    email: string
    ssn: string
    addressLineOne: string
    addressLineTwo: string
    city: string
    state: string
    zip: string
    agreedAt: string
    subscriptionCoverage: string
  }
}

export function createInitialState(
  initialValues: Partial<VerifyPersonalInfoState['values']>
): VerifyPersonalInfoState {
  return {
    values: {
      firstName: '',
      lastName: '',
      phone: '',
      dob: '',
      email: '',
      ssn: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      zip: '',
      agreedAt: null,
      // This will *always* be set in initialValues; either by the "suggested" value from the API, or a previously selected plan.
      // Why such a complex value? Both `cadence` and `coverage` must be serialized since we need to set both from a single dropdown.
      subscriptionCoverage: `{"cadence":"Monthly","coverage":{"formatted":"$2,000.00","raw":200000}}`,
      ...initialValues,
    },
    errors: {
      firstName: '',
      lastName: '',
      phone: '',
      dob: '',
      email: '',
      ssn: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      zip: '',
      agreedAt: '',
      subscriptionCoverage: '',
    },
  }
}
