import { ButtonPrimary, ButtonSecondary, Headline, PageFooter } from '@quil/ui'
import { graphql, useFragment } from 'react-relay'

import RichText from '../../common/components/RichText'
import * as testIds from './test-ids'
import { SupportRequestModal_content$key } from './__generated__/SupportRequestModal_content.graphql'

type SupportRequestModalProps = {
  onSupportRequest: () => void
  closeModal: () => void
  content: SupportRequestModal_content$key
}

export default function SupportRequestModal(props: SupportRequestModalProps) {
  const pageName = 'Support Request Modal'

  const data = useFragment(
    graphql`
      fragment SupportRequestModal_content on Content {
        modal(where: { name: "Incorrect Prefill Data" }) {
          heading
          body {
            raw
          }
          mainCtaText
          secondaryCtaText
        }
      }
    `,
    props.content
  )

  return (
    <>
      <Headline>{data.modal.heading}</Headline>
      <RichText content={data.modal.body.raw} />
      <PageFooter>
        <ButtonSecondary
          data-page-name={pageName}
          data-name="That isn't me CTA"
          onClick={props.onSupportRequest}
          data-testid={testIds.supportRequestSecondaryCta}
        >
          {data.modal.secondaryCtaText}
        </ButtonSecondary>

        <ButtonPrimary
          data-page-name={pageName}
          data-name="That's me CTA"
          onClick={props.closeModal}
          data-testid={testIds.supportRequestPrimaryCta}
        >
          {data.modal.mainCtaText}
        </ButtonPrimary>
      </PageFooter>
    </>
  )
}
