/**
 * @generated SignedSource<<b930262c5d3fe03672c672cc1ff20e83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PartnerPlacements_query$data = {
  readonly content: {
    readonly miscellaneous: {
      readonly value: string;
    } | null;
    readonly partnerPlacements: ReadonlyArray<{
      readonly backgroundColor: {
        readonly hex: any;
      } | null;
      readonly body: {
        readonly raw: any;
      } | null;
      readonly ctaText: string | null;
      readonly ctaUrl: string | null;
      readonly heading: string | null;
      readonly image: {
        readonly url: string;
      } | null;
      readonly imageAltText: string | null;
    }>;
  };
  readonly user: {
    readonly id: string | null;
  } | null;
  readonly " $fragmentType": "PartnerPlacements_query";
};
export type PartnerPlacements_query$key = {
  readonly " $data"?: PartnerPlacements_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartnerPlacements_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartnerPlacements_query",
  "selections": [
    {
      "alias": "user",
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Cms_PartnerPlacement",
          "kind": "LinkedField",
          "name": "partnerPlacements",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "heading",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Cms_Asset",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageAltText",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Cms_RichText",
              "kind": "LinkedField",
              "name": "body",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "raw",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Cms_Color",
              "kind": "LinkedField",
              "name": "backgroundColor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hex",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ctaUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ctaText",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "name": "Partner placements section heading"
              }
            }
          ],
          "concreteType": "Cms_Miscellaneous",
          "kind": "LinkedField",
          "name": "miscellaneous",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": "miscellaneous(where:{\"name\":\"Partner placements section heading\"})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9acdc93a1751290458405b6614d8ec5f";

export default node;
