export function inputAttributeFallbacks(
  maybeLabel?: string,
  maybeName?: string,
  maybeId?: string
) {
  if (!maybeName && !maybeLabel) {
    throw new Error('either name or label is required')
  }

  const name = maybeName ?? maybeLabel?.split(' ').join('_')
  const id = maybeId ?? `textInput${name}`
  return {
    label: maybeLabel,
    name,
    id,
  }
}
