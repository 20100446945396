/**
 * @generated SignedSource<<c4f09c26aa7d6e9bc1c2f9653bb0f63f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DeniedQuery$variables = {
  pageName: string;
};
export type DeniedQuery$data = {
  readonly content: {
    readonly onboardingPage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
  };
};
export type DeniedQuery = {
  response: DeniedQuery$data;
  variables: DeniedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageName"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "pageName",
        "variableName": "pageName"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeniedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeniedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aefbab110f70dfc5443785fb06e73ece",
    "id": null,
    "metadata": {},
    "name": "DeniedQuery",
    "operationKind": "query",
    "text": "query DeniedQuery(\n  $pageName: String!\n) {\n  content {\n    onboardingPage(where: {pageName: $pageName}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd00d17b4f7991a11768017cfdcdca0b";

export default node;
