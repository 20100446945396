/**
 * @generated SignedSource<<969709946382aed67820b7fe06516635>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnrecoverableErrorCode = "FileUpload" | "FingerprintVerificationFailure" | "IdentityPrefillError" | "Ineligible" | "NotPaidUp" | "OtherRequestsPending" | "SMSFailure" | "%future added value";
export type UserStatus = "Active" | "Closed" | "Ineligible" | "Pending" | "SecurityLocked" | "%future added value";
export type PrefillUserMutation$variables = {
  fingerprint: string;
};
export type PrefillUserMutation$data = {
  readonly prefillUser: {
    readonly __typename: "UnrecoverableError";
    readonly code: UnrecoverableErrorCode;
    readonly help: string;
  } | {
    readonly __typename: "User";
    readonly dob: any | null;
    readonly email: string | null;
    readonly id: string | null;
    readonly state: string | null;
    readonly status: UserStatus;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type PrefillUserMutation = {
  response: PrefillUserMutation$data;
  variables: PrefillUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fingerprint"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "fingerprint",
    "variableName": "fingerprint"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dob",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "help",
      "storageKey": null
    }
  ],
  "type": "UnrecoverableError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PrefillUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "prefillUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PrefillUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "prefillUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c1a36dc5eb0d6f93053e2bc7fa06364",
    "id": null,
    "metadata": {},
    "name": "PrefillUserMutation",
    "operationKind": "mutation",
    "text": "mutation PrefillUserMutation(\n  $fingerprint: String!\n) {\n  prefillUser(fingerprint: $fingerprint) {\n    __typename\n    ... on User {\n      id\n      status\n      email\n      state\n      dob\n    }\n    ... on UnrecoverableError {\n      code\n      help\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "01d5c3af6bec3516edf7d9a349cfc35c";

export default node;
