import { SpinnerFullScreen, useAlternateTheme } from '@quil/ui'
import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { OnboardingSequence } from '../OnboardingSequence'
import CustomizeIntro from './CustomizeIntro'
import EmployerInfo from './EmployerInfo'
import Hours from './Hours'
import MyPlan from './MyPlan'
import Plans from './Plans'
import Seasonal from './Seasonal'
import W2 from './W2'

function Employment() {
  useAlternateTheme('quilThemeVivid')

  return (
    // `useAlternateTheme` reverts to the default theme on unmount.
    // We use our own suspense boundary to ensure the vivid theme remains active when child pages are loading.
    <Suspense fallback={<SpinnerFullScreen />}>
      <Routes>
        <Route
          index={true}
          element={<CustomizeIntro {...OnboardingSequence.CustomizeIntro} />}
        />
        <Route
          path="seasonal"
          element={<Seasonal {...OnboardingSequence.Seasonal} />}
        />
        <Route path="hours" element={<Hours {...OnboardingSequence.Hours} />} />
        <Route path="w-2" element={<W2 {...OnboardingSequence.W2} />} />
        <Route
          path="employer-info"
          element={<EmployerInfo {...OnboardingSequence.EmployerInfo} />}
        />
        <Route
          path="my-plan"
          element={<MyPlan {...OnboardingSequence.MyPlan} />}
        />
        <Route path="plans" element={<Plans {...OnboardingSequence.Plans} />} />
        <Route index element={<Navigate to="seasonal" replace />} />
      </Routes>
    </Suspense>
  )
}

export default Employment
