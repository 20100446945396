/**
 * @generated SignedSource<<996616782ce587c28747000ad8e4399c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OnboardingStep = "ActivateMembership" | "CreateUser" | "SubmitEmploymentInfo" | "UpdateEmploymentInfo" | "VerifyDevice" | "VerifyPersonalInfo" | "%future added value";
export type UserStatus = "Active" | "Closed" | "Ineligible" | "Pending" | "SecurityLocked" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useContinueOnboarding_user$data = {
  readonly lastCompletedOnboardingStep: OnboardingStep;
  readonly status: UserStatus;
  readonly " $fragmentType": "useContinueOnboarding_user";
};
export type useContinueOnboarding_user$key = {
  readonly " $data"?: useContinueOnboarding_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useContinueOnboarding_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useContinueOnboarding_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastCompletedOnboardingStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "2c0aaeb86fe69e3045843ce463992c59";

export default node;
