import {
  ButtonPrimary,
  InlineButtonContainer,
  InputAutocomplete,
  InputCheckbox,
  InputDate,
  InputEmail,
  InputPhone,
  InputTextarea,
  ProcessingOverlay,
  Select,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import {
  employerNames,
  jobTitles,
} from '@web/Signup/Customize/EmployerInfo/options'
import { LayoutInAppTracked } from '@web/common/analytics'
import Form from '@web/common/components/Form'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import { useNavigate } from '@web/common/react-router-wrappers'
import { ValidationError } from '@web/common/types/ValidationError'
import { useFormReducer } from '@web/common/useFormReducer'
import { pick } from 'ramda'
import { useState } from 'react'
import { NewClaimPageProps } from '../NewClaimPageProps'
import NewClaimProgressTracker from '../NewClaimProgressTracker'
import { useNewClaimFlowQuery$data } from '../__generated__/useNewClaimFlowQuery.graphql'
import useNewClaimFlow from '../useNewClaimFlow'
import * as testIds from './test-ids'

const employmentInfoFields = [
  'terminationReason',
  'employerName',
  'employerEmail',
  'employerPhone',
  'occupation',
  'employmentStartDate',
  'employmentEndDate',
  'additionalInfo',
  'returnedToWorkAtDate',
] as const

type EmploymentInfoField = typeof employmentInfoFields[number]
type PendingLayoffClaim = ReturnType<
  typeof useNewClaimFlow
>[0]['pendingLayoffClaim']
type FormState = Pick<PendingLayoffClaim, EmploymentInfoField> & {
  returnedToWork: boolean
}

const requiredFields: (keyof FormState)[] = [
  'terminationReason',
  'employerName',
  'occupation',
  'employmentStartDate',
  'employmentEndDate',
]

function validate(values: FormState) {
  const errors: ValidationError<keyof FormState>[] = []

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors.push({
        field,
        help: 'This field is required',
      })
    }
  })

  if (!values.employerEmail && !values.employerPhone) {
    errors.push({
      field: 'employerEmail',
      help: 'Please provide either an email or phone number',
    })

    errors.push({
      field: 'employerPhone',
      help: 'Please provide either an email or phone number',
    })
  }

  if (values.returnedToWork && !values.returnedToWorkAtDate) {
    errors.push({
      field: 'returnedToWorkAtDate',
      help: 'Please provide the date you returned to work',
    })
  }

  return errors
}

function initialState(data: useNewClaimFlowQuery$data) {
  const knownEmploymentInfo: {
    employerName?: string
    occupation?: string
  } = {}
  if (
    !data.pendingLayoffClaim.changedJobsRecently &&
    !data.pendingLayoffClaim.employerName &&
    data.currentUser.employmentInfo?.employerName
  ) {
    knownEmploymentInfo.employerName =
      data.currentUser.employmentInfo.employerName
  }

  if (
    !data.pendingLayoffClaim.changedJobsRecently &&
    !data.pendingLayoffClaim.occupation &&
    data.currentUser.employmentInfo?.jobTitle
  ) {
    knownEmploymentInfo.occupation = data.currentUser.employmentInfo.jobTitle
  }

  return {
    // strip out field that we aren't editing on this page
    values: {
      ...pick(employmentInfoFields, data.pendingLayoffClaim),
      ...knownEmploymentInfo,
      returnedToWork: data.pendingLayoffClaim.returnedToWorkAtDate !== null,
    },
    errors: {
      terminationReason: '',
      employerName: '',
      employerEmail: '',
      employerPhone: '',
      occupation: '',
      employmentStartDate: '',
      employmentEndDate: '',
      returnedToWorkAtDate: '',
      additionalInfo: '',
    },
  }
}

function EmploymentInfo(props: NewClaimPageProps) {
  const [submitting, setSubmitting] = useState(false)
  const [data, commitMutation, mutationInFlight] =
    useNewClaimFlow('Employment Info')

  const navigate = useNavigate()
  const [{ values, errors }, actions] = useFormReducer(initialState(data))
  const showToast = useShowToast()

  const optionalFields: (keyof typeof values)[] = [
    'additionalInfo',
    'returnedToWorkAtDate',

    // one of these must be provided but we will validate that after allowing the user
    // to attempt to submit so they can get some guidance via validation errors
    'employerEmail',
    'employerPhone',
  ]

  const buttonDisabled =
    Object.entries(values).some(
      ([key, val]) =>
        !optionalFields.includes(key as keyof typeof values) &&
        typeof val !== 'boolean' &&
        !val
    ) || submitting

  function handleError(error) {
    console.error(error)
    setSubmitting(false)
    showToast({
      severity: 'Error',
      headline: 'Something went wrong',
      message: 'Please try again',
      linkText: 'Retry',
      onClick: handleSubmit,
      testId: testIds.errorToast,
    })
  }

  async function handleSubmit() {
    if (!mutationInFlight) {
      const frontendValidationErrors = validate(values)

      if (frontendValidationErrors.length) {
        actions.receiveValidationErrors(frontendValidationErrors)
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.validationErrors,
          message: standardToastMessages.validationErrors,
        })

        return
      }

      setSubmitting(true)
      actions.clearValidationErrors()

      // Don't send returnedToWork, that's jus frontend state
      const { returnedToWork, ...valuesToSend } = values

      commitMutation({
        variables: {
          input: {
            id: data.pendingLayoffClaim?.id,

            ...valuesToSend,

            // if the use has unchecked the returnedToWork checkbox, we need to
            // null out any date they may have previously entered
            returnedToWorkAtDate: returnedToWork
              ? values.returnedToWorkAtDate
              : '',
          },
        },
        onCompleted({ upsertLayoffClaim }) {
          switch (upsertLayoffClaim.__typename) {
            case 'LayoffClaim':
              navigate(props.nextPath)
              break
            case 'ValidationErrors':
              setSubmitting(false)
              showToast({
                severity: 'Error',
                headline: `Sorry, we couldn't process that`,
                message: 'Please review the information you are submitting',
                testId: testIds.validationErrorsToast,
              })
              actions.receiveValidationErrors(upsertLayoffClaim.messages)
              break
            default:
              handleError({
                message: 'Unknown response type',
                upsertLayoffClaim,
              })
          }
        },
        onError(error) {
          handleError(error)
        },
      })
    }
  }

  return (
    <LayoutInAppTracked
      pageName="New Claim: Employer Info"
      title={data.content.newClaimPage.heading}
      headerStart={<GoBack to={props.previousPath} />}
      headerEnd={<NewClaimProgressTracker step={props.step} />}
      footerContent={
        <InlineButtonContainer>
          <ButtonPrimary
            disabled={buttonDisabled}
            data-name="Continue"
            onClick={handleSubmit}
            data-testid={testIds.primaryCta}
          >
            {data.content.newClaimPage.mainCtaText}
          </ButtonPrimary>
        </InlineButtonContainer>
      }
    >
      <RichText content={data.content.newClaimPage.body.raw} />
      <Form onSubmit={handleSubmit}>
        <Select
          nullOptionLabel="Please select a reason"
          error={errors.terminationReason}
          label="Reason for termination"
          onChange={actions.createFieldUpdater('terminationReason')}
          value={values.terminationReason}
          options={[
            { label: 'Business shut down', value: 'BusinessShutDown' },
            { label: 'COVID layoffs', value: 'CovidLayoffs' },
            { label: 'Lack of work', value: 'LackOfWork' },
            { label: 'Strike or lockout', value: 'StrikeOrLockout' },
            { label: 'Other', value: 'Other' },
          ]}
          data-testid={testIds.terminationReason}
        />
        <InputEmail
          error={errors.employerEmail}
          value={values.employerEmail}
          label="Employer email"
          onChange={actions.createFieldUpdater('employerEmail')}
          data-testid={testIds.employerEmail}
        />
        <InputPhone
          error={errors.employerPhone}
          value={values.employerPhone}
          label="Employer phone"
          onChange={actions.createFieldUpdater('employerPhone')}
          data-testid={testIds.employerPhone}
        />
        <InputAutocomplete
          error={errors.employerName}
          value={values.employerName}
          label="Name of employer"
          onChange={actions.createFieldUpdater('employerName')}
          placeholder="Enter employer name"
          options={employerNames}
          data-testid={testIds.employerName}
        />
        <InputAutocomplete
          error={errors.occupation}
          value={values.occupation}
          label="Occupation"
          onChange={actions.createFieldUpdater('occupation')}
          placeholder="Enter occupation"
          options={jobTitles}
          data-testid={testIds.occupation}
        />
        <InputDate
          error={errors.employmentStartDate}
          label="Start date"
          value={values.employmentStartDate}
          onChange={actions.createFieldUpdater('employmentStartDate')}
          data-testid={testIds.employmentStartDate}
        />
        <InputDate
          error={errors.employmentEndDate}
          label="End date"
          value={values.employmentEndDate}
          onChange={actions.createFieldUpdater('employmentEndDate')}
          data-testid={testIds.employmentEndDate}
        />
        <InputCheckbox
          label="Check this box if you have returned to work"
          checked={values.returnedToWork}
          onChange={actions.createFieldUpdater('returnedToWork')}
          data-testid={testIds.returnedToWork}
        />
        {values.returnedToWork && (
          <InputDate
            error={errors.returnedToWorkAtDate}
            label="When did you return to work?"
            value={values.returnedToWorkAtDate}
            onChange={actions.createFieldUpdater('returnedToWorkAtDate')}
            data-testid={testIds.returnedToWorkAtDate}
          />
        )}

        <InputTextarea
          error={errors.additionalInfo}
          label="Additional information"
          value={values.additionalInfo}
          onChange={actions.createFieldUpdater('additionalInfo')}
          data-testid={testIds.additionalInfo}
        />
      </Form>
      <ProcessingOverlay processing={submitting} />
    </LayoutInAppTracked>
  )
}

export default EmploymentInfo
