import {
  ButtonPrimary,
  standardToastHeadlines,
  standardToastMessages,
  Subheading,
  useShowToast,
} from '@quil/ui'
import { useState } from 'react'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import { graphql } from 'relay-runtime'
import styled from 'styled-components'
import { LayoutSignupTracked } from '../../common/analytics'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import useContinueOnboarding from '../../common/components/useContinueOnboarding'
import { formatPhone } from '../../common/formatPhone'
import { useInterval } from '../../common/useInterval'
import { SignupPageProps } from '../SignupPageProps'
import { CheckYourPhoneMutation } from './__generated__/CheckYourPhoneMutation.graphql'
import { CheckYourPhoneQuery } from './__generated__/CheckYourPhoneQuery.graphql'
import * as testIds from './test-ids'

// do not place multiple H1s on the screen, despite similar font size
const LargeSubheading = styled(Subheading)`
  font-size: 3.6rem;
`

function CheckYourPhone({
  previousPath,
  nextPath,
  allowedLastOnboardingSteps,
}: SignupPageProps) {
  const pageName = 'Check Your Phone'
  const showToast = useShowToast()
  const [commitMutation, mutationInFlight] =
    useMutation<CheckYourPhoneMutation>(graphql`
      mutation CheckYourPhoneMutation($targetUrl: String!) {
        sendIdentitySms(targetUrl: $targetUrl) {
          success
        }
      }
    `)
  const data = useLazyLoadQuery<CheckYourPhoneQuery>(
    graphql`
      query CheckYourPhoneQuery {
        currentUser {
          phone
          email
          ...useContinueOnboarding_user
        }
        content {
          onboardingPage(where: { pageName: "Check Your Phone" }) {
            heading
            body {
              raw
            }
            mainCtaText
          }
        }
      }
    `,
    {}
  )

  const [retrySeconds, setRetrySeconds] = useState(180)
  const targetUrl = new URL(
    `verify-device?redirect_to=${nextPath}`,
    window.location.origin
  ).toString()

  function sendSms() {
    setRetrySeconds(180)
    if (!mutationInFlight) {
      commitMutation({
        variables: {
          targetUrl,
        },
        onCompleted(response) {
          if (!response.sendIdentitySms?.success) {
            // allow retrying immediately on failure
            setRetrySeconds(0)
            showToast({
              severity: 'Error',
              headline: standardToastHeadlines.genericError,
              message: standardToastMessages.retry,
              onClick: sendSms,
              testId: testIds.errorMessage,
            })
          }
        },
        onError(error) {
          // allow retrying immediately on failure
          setRetrySeconds(0)
          console.error({ error, mutation: 'sendIdentitySms' })
          showToast({
            severity: 'Error',
            headline: standardToastHeadlines.genericError,
            message: standardToastMessages.retry,
            onClick: sendSms,
            testId: testIds.errorMessage,
          })
        },
      })
    }
  }

  useInterval(
    () => {
      setRetrySeconds((value) => value - 1)
    },
    retrySeconds > 0 ? 1000 : null
  )

  useContinueOnboarding(data.currentUser, allowedLastOnboardingSteps)

  return (
    <LayoutSignupTracked
      pageName={pageName}
      title={data.content.onboardingPage.heading}
      headerStart={previousPath && <GoBack />}
      footerContent={
        retrySeconds > 0 ? (
          <>
            <Subheading>Didn't receive an email? You can resend in</Subheading>
            <LargeSubheading>{retrySeconds}</LargeSubheading>
          </>
        ) : (
          <ButtonPrimary
            data-page-name={pageName}
            data-name="Primary CTA"
            onClick={sendSms}
            data-testid={testIds.buttonPrimary}
          >
            {data.content.onboardingPage.mainCtaText}
          </ButtonPrimary>
        )
      }
      data-testid={testIds.layout}
    >
      <RichText
        content={data.content.onboardingPage.body.raw}
        data={{
          phone: formatPhone(data.currentUser.phone),
          email: data.currentUser.email,
        }}
      />
    </LayoutSignupTracked>
  )
}

export default CheckYourPhone
