import React from 'react'
import { SyncLoader } from 'react-spinners'
import styled from 'styled-components'
import { useQuilTheme } from '../../theme'

const Container = styled.div<{ padding?: number }>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: ${(props) => `${props.padding ?? 0}rem`};
`

type Props = {
  'data-testid'?: string
  padding?: number
}

const Spinner: React.FC<Props> = (props) => {
  const { colors } = useQuilTheme()

  return (
    <Container data-testid={props['data-testid']} padding={props.padding}>
      <SyncLoader color={colors.foregroundPrimary.raw} />
    </Container>
  )
}

export default Spinner
