import React from 'react'
import { HomeSolid as HomeIcon } from '../../icons'
import InputAdornment from '../InputAdornment'
import InputText, { Props as InputTextProps } from '../InputText'

type Props = Pick<
  InputTextProps,
  'value' | 'onChange' | 'error' | 'data-testid'
> & {
  label?: string
}

const InputAddressStreet: React.ForwardRefRenderFunction<
  HTMLInputElement,
  Props
> = (props, ref) => {
  return (
    <InputText
      {...props}
      ref={ref}
      startAdornment={
        <InputAdornment>
          <HomeIcon />
        </InputAdornment>
      }
      label={props.label ?? 'Home address'}
      name="address_street"
      placeholder="0120 E St Name"
      autoComplete="address-line1"
    />
  )
}

export default React.forwardRef(InputAddressStreet)
