import styled from 'styled-components'
import {
  Clock,
  ToastError,
  ToastInformational,
  ToastSuccess,
} from '../../icons'
import { useQuilTheme } from '../../theme'

type Props = {
  type: 'Pending' | 'InReview' | 'Approved' | 'Denied'
}

const Container = styled.div`
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  font-size: 1rem;
  margin: 0.6rem 0 0.7rem 0;
`

const Pill = styled.div<{ background: string; textColor: string }>`
  display: flex;
  align-items: center;
  background: ${(props) => props.background};
  border-radius: 1.6rem;
  padding: 0.1rem 0.6rem 0.3rem 0.4rem;
  font-size: 1.2rem;
  color: ${(props) => props.textColor};
  font-weight: 700;

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.4rem;
    margin-top: 0.1rem;
  }
`

type VariationProperties = {
  text: string
  background: string
  Icon: React.ComponentType
  textColor: string
}

export default function ClaimBadge(props: Props) {
  const { colors } = useQuilTheme()

  const propertyMap: Record<Props['type'], VariationProperties> = {
    Denied: {
      text: 'Denied',
      background: colors.palette.torch.alt2.raw,
      Icon: ToastError,
      textColor: colors.palette.torch.main.raw,
    },
    Approved: {
      text: 'Approved',
      background: colors.palette.emerald.alt2.raw,
      Icon: ToastSuccess,
      textColor: colors.palette.emerald.main.raw,
    },
    InReview: {
      text: 'In Review',
      background: colors.palette.anakiwa.alt1.raw,
      Icon: ToastInformational,
      textColor: colors.palette.minsk.main.raw,
    },
    Pending: {
      text: 'Pending',
      background: colors.palette.minsk.alt1.raw,
      Icon: Clock,
      textColor: colors.palette.minsk.main.raw,
    },
  }
  const { Icon, text, ...colorProps } = propertyMap[props.type]

  return (
    <Container>
      <Pill {...colorProps}>
        <Icon />
        {text}
      </Pill>
    </Container>
  )
}
