import { motion } from 'framer-motion'
import { useId } from 'react'
import styled from 'styled-components'
import { fromTheme } from '../../theme'

type Props<T extends string> = {
  options: { label: string; value: T }[]
  value: T
  onChange: (value: T) => void
  className?: string
  'data-testid': string
}

const Container = styled.div`
  font-size: 1.4rem;
  display: inline-flex;
  border-radius: 4rem;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2.4rem;
  padding: 0.4rem;

  .toggle-item {
    padding: 1.4rem 0;
    text-align: center;
    position: relative;
    flex: 1;

    :not([data-selected='true']) {
      cursor: pointer;
    }
  }

  .toggle-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4rem;
    background-color: ${fromTheme(
      ({ colors }) => colors.foregroundPrimary.raw
    )};
  }

  .toggle-item-label {
    z-index: 1;
    position: relative;
  }
`

export const Toggle = <T extends string>(props: Props<T>) => {
  const layoutId = useId()

  return (
    <Container className={props.className}>
      {props.options.map((option) => {
        const selected = props.value === option.value

        return (
          <div
            key={option.value}
            onClick={() => props.onChange(option.value)}
            className="toggle-item"
            data-selected={selected}
            data-testid={`${props['data-testid']}_${option.value}`}
          >
            {selected && (
              <motion.div className="toggle-indicator" layoutId={layoutId} />
            )}
            <span className="toggle-item-label">{option.label}</span>
          </div>
        )
      })}
    </Container>
  )
}
