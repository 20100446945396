/**
 * @generated SignedSource<<7ba4200bf5d1e657ddb3ef010a8fb149>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useEmploymentQuestionCopy_onboardingPage$data = {
  readonly body: {
    readonly raw: any;
  } | null;
  readonly heading: string | null;
  readonly mainCtaText: string | null;
  readonly secondaryCtaText: string | null;
  readonly subheading: string | null;
  readonly " $fragmentType": "useEmploymentQuestionCopy_onboardingPage";
};
export type useEmploymentQuestionCopy_onboardingPage$key = {
  readonly " $data"?: useEmploymentQuestionCopy_onboardingPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEmploymentQuestionCopy_onboardingPage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useEmploymentQuestionCopy_onboardingPage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cms_RichText",
      "kind": "LinkedField",
      "name": "body",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "raw",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subheading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainCtaText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondaryCtaText",
      "storageKey": null
    }
  ],
  "type": "Cms_OnboardingPage",
  "abstractKey": null
};

(node as any).hash = "7c9d6f5a7c37f4b6f010ccd19b5eddf3";

export default node;
