import * as event from '@quil/analytics-events'
import {
  ActionListItem,
  ActionListLink,
  ActionListList,
  BodyText,
  fromTheme,
  Headline,
  SpinnerFullScreen,
  SubheadingAlt,
  TextLinkPrimary,
} from '@quil/ui'
import { graphql, useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components'
import { LayoutInAppTracked } from '../../common/analytics'
import * as analytics from '../../common/analytics/analytics-adapter'
import GoBack from '../../common/components/GoBack'
import { useLogout } from '../../common/components/useLogout'
import { SettingsOverviewQuery } from './__generated__/SettingsOverviewQuery.graphql'
import { EmailText } from './EmailText'
import { PaymentInformationLink } from './PaymentInformationLink'
import { PhoneText } from './PhoneText'
import * as testIds from './test-ids'

const AppHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.5rem;
  cursor: pointer;

  ${TextLinkPrimary}, ${BodyText} {
    color: ${fromTheme((theme) => theme.colors.textLink.raw)};
    font-weight: 700;
  }

  ${TextLinkPrimary}:hover {
    box-shadow: none;
    text-decoration-line: underline;
  }
`

const query = graphql`
  query SettingsOverviewQuery {
    currentUser {
      ...UserSummary_user
      ...PhoneText_user
      ...EmailText_user
      ...AddressText_user
    }
    payoutAccount {
      id
    }
  }
`

export default function ProfileOverview() {
  const pageName = 'Settings'
  const data = useLazyLoadQuery<SettingsOverviewQuery>(query, {})
  const [loggingOut, handleLogoutClick] = useLogout()

  function handleLegalLinkClick() {
    analytics.track(event.legalLinkClicked())
  }

  if (loggingOut) {
    return <SpinnerFullScreen />
  }

  return (
    <LayoutInAppTracked pageName={pageName} headerStart={<GoBack />}>
      <AppHeaderContainer>
        <Headline> Settings</Headline>
        <TextLinkPrimary
          onClick={handleLogoutClick}
          data-name="Log out"
          data-page-name={pageName}
          bold
        >
          Log out
        </TextLinkPrimary>
      </AppHeaderContainer>
      <ActionListList>
        <ActionListItem>
          <ActionListLink
            to="update-phone"
            data-name="Update Phone"
            data-page-name={pageName}
            data-testid={testIds.phoneLink}
          >
            <SubheadingAlt> Phone</SubheadingAlt>
            <PhoneText user={data.currentUser} />
          </ActionListLink>
        </ActionListItem>
        <ActionListItem>
          <ActionListLink
            to="update-email"
            data-name="Update Email"
            data-page-name={pageName}
            data-testid={testIds.emailLink}
          >
            <EmailText user={data.currentUser} />
          </ActionListLink>
        </ActionListItem>
        <PaymentInformationLink />
        {data.payoutAccount && (
          <ActionListItem>
            <ActionListLink
              to="payout-options"
              data-name="Payout Options"
              data-page-name={pageName}
            >
              <SubheadingAlt>Payout Options</SubheadingAlt>
              Set your preferences for claim payouts
            </ActionListLink>
          </ActionListItem>
        )}
        <ActionListItem>
          <ActionListLink
            to="legal"
            data-testid={testIds.legalLink}
            onClick={handleLegalLinkClick}
            data-name="Legal"
            data-page-name={pageName}
          >
            <SubheadingAlt> Documents</SubheadingAlt>
            View your policies and agreements
          </ActionListLink>
        </ActionListItem>
        <ActionListItem>
          <ActionListLink
            to="help"
            data-name="Help"
            data-page-name={pageName}
            data-testid={testIds.closeMembershipLink}
          >
            <SubheadingAlt> Help</SubheadingAlt>
            Get in touch or close your account
          </ActionListLink>
        </ActionListItem>
      </ActionListList>
    </LayoutInAppTracked>
  )
}
