import {
  Alert,
  ClaimBadge,
  File,
  Headline,
  IntroBlock,
  Subheading,
} from '@quil/ui'
import { booleanToHuman, pascalToTitleCase } from '@quil/utils'
import { LayoutInAppTracked } from '@web/common/analytics'
import GoBack from '@web/common/components/GoBack'
import { constructFile } from '@web/common/file-utils'
import { formatPhone } from '@web/common/formatPhone'
import { Navigate, useNavigate } from '@web/common/react-router-wrappers'
import { groupBy, prop, propEq } from 'ramda'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { formatClaimId } from '../format-claim-id'
import {
  DetailQuery,
  LayoffClaimStatus,
} from './__generated__/DetailQuery.graphql'

// TODO: identical to apps/web-frontend/src/Claims/New/Review/index.tsx; should consolidate
const Container = styled.div`
  .text-review-label {
    font-size: 1.2rem;
  }

  .text-review-value {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  .file-review-label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .disclaimer-label {
    font-size: 1.2rem;
    margin-top: -0.5rem;
  }

  .documents-section {
    margin-bottom: 2.8rem;
  }

  .disclaimers-section {
    margin-bottom: 2.8rem;
  }
`

export default function ClaimDetail() {
  const pageName = 'Layoff Claim Detail'
  const { id } = useParams()
  const navigate = useNavigate()
  // None of the `layoffClaimInformationRequests` fields are required on this page,
  // but it will load it in the cache for the "SubmitAdditionalInformation" page
  const data = useLazyLoadQuery<DetailQuery>(
    graphql`
      query DetailQuery($id: ID!) {
        layoffClaim(id: $id) {
          id
          additionalInfo
          changedJobsRecently
          employerEmail
          employerName
          employerPhone
          employmentEndDate
          employmentStartDate
          minimumWeeklyHours
          occupation
          receivingStateBenefits
          returnedToWorkAtDate
          status
          terminatedWithoutCause
          terminationReason
          fileUploads {
            id
            size
            extension
            originalFileName
            uri
            type
          }
          submittedAt {
            formatted
          }
          payoutAccount {
            accountNumberMask
            bankName
          }
          informationRequests {
            type
            id
            status
            title
            note
            requestedAt {
              formatted
            }
          }
        }

        terminatedWithoutCause: content {
          newClaimPage(where: { name: "Laid Off" }) {
            heading
          }
        }

        changedJobsRecently: content {
          newClaimPage(where: { name: "Job Change" }) {
            heading
          }
        }

        minimumWeeklyHours: content {
          newClaimPage(where: { name: "Employment Type" }) {
            heading
          }
        }

        receivingStateBenefits: content {
          newClaimPage(where: { name: "State Unemployment" }) {
            heading
          }
        }
      }
    `,
    { id }
  )

  const claim = data.layoffClaim
  if (!claim) {
    return <Navigate to="/home/claims" />
  }

  return (
    <LayoutInAppTracked pageName={pageName} headerStart={<GoBack />}>
      <Headline>Layoff Claim</Headline>
      <IntroBlock>Claim ID: {formatClaimId(claim.id)}</IntroBlock>
      <ClaimBadge
        type={claim.status as Exclude<LayoffClaimStatus, '%future added value'>}
      />
      <Container>
        {claim.informationRequests.filter(propEq('Open', 'status')).length >
        0 ? (
          <Alert
            severity={'Warning'}
            headline="We need more info"
            linkText="Submit information"
            message="We need additional information to continue this review"
            onClick={() =>
              navigate(`/home/claims/${id}/submit-additional-information`)
            }
          />
        ) : null}
        {claim.submittedAt?.formatted && (
          <>
            <div className="text-review-label">Submitted on</div>
            <div className="text-review-value">
              {claim.submittedAt.formatted}
            </div>
          </>
        )}

        <Subheading>Submission</Subheading>
        <div className="text-review-label">Reason for termination</div>
        <div className="text-review-value">
          {pascalToTitleCase(claim.terminationReason ?? '')}
        </div>

        <div className="text-review-label">Employer</div>
        <div className="text-review-value">{claim.employerName}</div>

        <div className="text-review-label">Employer email</div>
        <div className="text-review-value">{claim.employerEmail}</div>

        {claim.employerPhone && (
          <>
            <div className="text-review-label">Employer phone</div>
            <div className="text-review-value">
              {formatPhone(claim.employerPhone)}
            </div>
          </>
        )}

        <div className="text-review-label">Occupation</div>
        <div className="text-review-value">{claim.occupation}</div>

        <div className="text-review-label">Start date</div>
        <div className="text-review-value">{claim.employmentStartDate}</div>

        <div className="text-review-label">End date</div>
        <div className="text-review-value">{claim.employmentEndDate}</div>

        {claim.returnedToWorkAtDate && (
          <>
            <div className="text-review-label">Returned to work on</div>
            <div className="text-review-value">
              {claim.returnedToWorkAtDate}
            </div>
          </>
        )}

        <div className="text-review-label">
          {data.changedJobsRecently.newClaimPage.heading}
        </div>
        <div className="text-review-value">
          {booleanToHuman(claim.changedJobsRecently)}
        </div>

        <div className="text-review-label">
          {data.minimumWeeklyHours.newClaimPage.heading}
        </div>
        <div className="text-review-value">
          {booleanToHuman(claim.minimumWeeklyHours)}
        </div>

        <div className="text-review-label">
          {data.receivingStateBenefits.newClaimPage.heading}
        </div>
        <div className="text-review-value">
          {booleanToHuman(claim.receivingStateBenefits)}
        </div>

        <div className="text-review-label">
          {data.terminatedWithoutCause.newClaimPage.heading}
        </div>
        <div className="text-review-value">
          {booleanToHuman(claim.terminatedWithoutCause)}
        </div>

        {claim.additionalInfo && (
          <>
            <div className="text-review-label">Additional info</div>
            <div className="text-review-value">{claim.additionalInfo}</div>
          </>
        )}

        <div className="text-review-label">Payout account</div>
        {claim.payoutAccount && (
          <div className="text-review-value">
            {claim.payoutAccount.bankName || 'Account ending in'} *
            {claim.payoutAccount.accountNumberMask}
          </div>
        )}

        <div className="documents-section">
          {Object.entries(groupBy(prop('type'), claim.fileUploads)).map(
            ([documentType, files]) => (
              <div key={documentType}>
                <div className="file-review-label">
                  {pascalToTitleCase(documentType)}
                </div>
                {files.map((file) => (
                  <File
                    key={file.originalFileName}
                    file={constructFile(file.originalFileName, file.extension)}
                    size={file.size}
                    state="idle"
                    uri={file.uri}
                  />
                ))}
              </div>
            )
          )}
        </div>

        {/*
  - show "Requested on {i.requestedAt.formatted}" for unfulfilled requests (file or note)
  - show Note for fulfilled Note requests
  - show nothing for fulfilled File requests (they will be shown above in the documents section)
 */}
        {claim.informationRequests
          .filter((i) => i.status === 'Open' || i.type === 'Note')
          .map((i) => (
            <div key={i.id}>
              <div className="text-review-label">{i.title}</div>
              <div className="text-review-value">
                {i.note ?? `Requested on ${i.requestedAt.formatted}`}
              </div>
            </div>
          ))}
      </Container>
    </LayoutInAppTracked>
  )
}
