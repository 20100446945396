/**
 * @generated SignedSource<<09bc6cc83b4c6177d8564a4ef6ab4143>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddPlaidAccountInput = {
  claimId?: string | null;
  linkToken: string;
  publicToken: string;
};
export type PayoutAccountSettingsAddPlaidAccountMutation$variables = {
  input: AddPlaidAccountInput;
};
export type PayoutAccountSettingsAddPlaidAccountMutation$data = {
  readonly addPlaidAccount: {
    readonly accountNumberMask: string;
    readonly bankName: string | null;
    readonly id: string | null;
  } | null;
};
export type PayoutAccountSettingsAddPlaidAccountMutation = {
  response: PayoutAccountSettingsAddPlaidAccountMutation$data;
  variables: PayoutAccountSettingsAddPlaidAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PayoutAccount",
    "kind": "LinkedField",
    "name": "addPlaidAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bankName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountNumberMask",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayoutAccountSettingsAddPlaidAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayoutAccountSettingsAddPlaidAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6288b328914ac91d13865f8f6996d59e",
    "id": null,
    "metadata": {},
    "name": "PayoutAccountSettingsAddPlaidAccountMutation",
    "operationKind": "mutation",
    "text": "mutation PayoutAccountSettingsAddPlaidAccountMutation(\n  $input: AddPlaidAccountInput!\n) {\n  addPlaidAccount(input: $input) {\n    id\n    bankName\n    accountNumberMask\n  }\n}\n"
  }
};
})();

(node as any).hash = "21168dba98abbc4c0e3412f25ad5cb34";

export default node;
