import { ContentCard } from '@quil/ui'
import Mustache from 'mustache'
import { graphql, useFragment } from 'react-relay'
import RichText from '../../../../src/common/components/RichText'
import { HomeHeadline } from '../HomeHeadline'
import Row from '../Row'
import { PartnerPlacements_query$key } from './__generated__/PartnerPlacements_query.graphql'

type Props = {
  pageName: string
  query: PartnerPlacements_query$key
}

export function PartnerPlacements(props: Props) {
  const { content, user } = useFragment(
    graphql`
      fragment PartnerPlacements_query on Query {
        user: currentUser {
          id
        }
        content {
          partnerPlacements {
            heading
            image {
              url
            }
            imageAltText
            body {
              raw
            }
            backgroundColor {
              hex
            }
            ctaUrl
            ctaText
          }
          miscellaneous(where: { name: "Partner placements section heading" }) {
            value
          }
        }
      }
    `,
    props.query
  )

  return (
    <Row>
      <HomeHeadline>{content.miscellaneous?.value}</HomeHeadline>
      {content.partnerPlacements.map((placement) => {
        const cta = {
          text: placement.ctaText,
          url: transformUrl(placement.ctaUrl, user.id),
        }
        return (
          <Row key={placement.heading}>
            <ContentCard
              title={placement.heading}
              image={{
                url: placement.image.url,
                alt: placement.imageAltText,
              }}
              backgroundColor={placement.backgroundColor.hex}
              body={<RichText content={placement.body.raw} />}
              cta={cta}
              data-page-name={props.pageName}
              data-name={placement.heading}
              data-text={placement.ctaText}
            />
          </Row>
        )
      })}
    </Row>
  )
}

function transformUrl(template: string, userId: string) {
  return Mustache.render(template, { userId })
}
