import { graphql, useFragment } from 'react-relay'
import { useEmploymentQuestionCopy_onboardingPage$key } from './__generated__/useEmploymentQuestionCopy_onboardingPage.graphql'

export function useEmploymentQuestionCopy(
  onboardingPage: useEmploymentQuestionCopy_onboardingPage$key
) {
  return useFragment(
    graphql`
      fragment useEmploymentQuestionCopy_onboardingPage on Cms_OnboardingPage {
        heading
        body {
          raw
        }
        subheading
        mainCtaText
        secondaryCtaText
      }
    `,
    onboardingPage
  )
}
