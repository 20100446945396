/**
 * @generated SignedSource<<4e646c2a8ef4751292cfd8d0e0a8fdf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LayoffClaimStatus = "Approved" | "Denied" | "InReview" | "Pending" | "%future added value";
export type PayoutAccountManualQuery$variables = {};
export type PayoutAccountManualQuery$data = {
  readonly content: {
    readonly profilePage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly header: string | null;
      readonly mainCtaText: string | null;
    } | null;
  };
  readonly currentLayoffClaim: {
    readonly id: string | null;
    readonly status: LayoffClaimStatus;
  } | null;
  readonly payoutAccount: {
    readonly id: string | null;
  } | null;
};
export type PayoutAccountManualQuery = {
  response: PayoutAccountManualQuery$data;
  variables: PayoutAccountManualQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Payout Account Manual"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "LayoffClaim",
  "kind": "LinkedField",
  "name": "currentLayoffClaim",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PayoutAccount",
  "kind": "LinkedField",
  "name": "payoutAccount",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayoutAccountManualQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Payout Account Manual\"})"
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PayoutAccountManualQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Payout Account Manual\"})"
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/)
    ]
  },
  "params": {
    "cacheID": "1ce040cc636a342f98daadc1e985dedc",
    "id": null,
    "metadata": {},
    "name": "PayoutAccountManualQuery",
    "operationKind": "query",
    "text": "query PayoutAccountManualQuery {\n  content {\n    profilePage(where: {name: \"Payout Account Manual\"}) {\n      header\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n  }\n  currentLayoffClaim {\n    id\n    status\n  }\n  payoutAccount {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0deb60f20c774cc991791876e09d3202";

export default node;
