/**
 * @generated SignedSource<<43348ab1305572701f65bafb2fb32715>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LayoffClaimReason = "BusinessShutDown" | "CovidLayoffs" | "LackOfWork" | "Other" | "StrikeOrLockout" | "%future added value";
export type LayoffClaimStatus = "Approved" | "Denied" | "InReview" | "Pending" | "%future added value";
export type useNewClaimFlowQuery$variables = {
  pageName: string;
};
export type useNewClaimFlowQuery$data = {
  readonly content: {
    readonly newClaimPage: {
      readonly body: {
        readonly raw: any;
      };
      readonly heading: string;
      readonly mainCtaText: string | null;
      readonly noButton: string | null;
      readonly secondaryCtaText: string | null;
      readonly yesButton: string | null;
    } | null;
  };
  readonly currentUser: {
    readonly employmentInfo: {
      readonly employerName: string | null;
      readonly jobTitle: string | null;
    } | null;
  } | null;
  readonly payoutAccount: {
    readonly accountNumberMask: string;
    readonly bankName: string | null;
    readonly id: string | null;
  } | null;
  readonly pendingLayoffClaim: {
    readonly additionalInfo: string | null;
    readonly changedJobsRecently: boolean | null;
    readonly employerEmail: string | null;
    readonly employerName: string | null;
    readonly employerPhone: string | null;
    readonly employmentEndDate: string | null;
    readonly employmentStartDate: string | null;
    readonly fileUploads: ReadonlyArray<{
      readonly extension: string;
      readonly id: string | null;
      readonly originalFileName: string;
      readonly size: number;
      readonly type: string;
      readonly uri: string;
    } | null>;
    readonly id: string | null;
    readonly minimumWeeklyHours: boolean | null;
    readonly occupation: string | null;
    readonly payoutAccount: {
      readonly accountNumberMask: string;
      readonly bankName: string | null;
    } | null;
    readonly receivingStateBenefits: boolean | null;
    readonly returnedToWorkAtDate: string | null;
    readonly status: LayoffClaimStatus;
    readonly terminatedWithoutCause: boolean | null;
    readonly terminationReason: LayoffClaimReason | null;
  } | null;
};
export type useNewClaimFlowQuery = {
  response: useNewClaimFlowQuery$data;
  variables: useNewClaimFlowQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageName"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "EmploymentInfo",
  "kind": "LinkedField",
  "name": "employmentInfo",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumberMask",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PayoutAccount",
  "kind": "LinkedField",
  "name": "payoutAccount",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalInfo",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "changedJobsRecently",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerEmail",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerPhone",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentEndDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentStartDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivingStateBenefits",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "returnedToWorkAtDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumWeeklyHours",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "occupation",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminatedWithoutCause",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminationReason",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "FileUpload",
  "kind": "LinkedField",
  "name": "fileUploads",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extension",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalFileName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "pageName"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryCtaText",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yesButton",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noButton",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useNewClaimFlowQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "pendingLayoffClaim",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v1/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v21/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useNewClaimFlowQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "pendingLayoffClaim",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v1/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v21/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b18103b514c166ac16e9828d3f42b56",
    "id": null,
    "metadata": {},
    "name": "useNewClaimFlowQuery",
    "operationKind": "query",
    "text": "query useNewClaimFlowQuery(\n  $pageName: String!\n) {\n  currentUser {\n    employmentInfo {\n      employerName\n      jobTitle\n    }\n    id\n  }\n  payoutAccount {\n    id\n    accountNumberMask\n    bankName\n  }\n  pendingLayoffClaim {\n    id\n    additionalInfo\n    changedJobsRecently\n    employerEmail\n    employerName\n    employerPhone\n    employmentEndDate\n    employmentStartDate\n    receivingStateBenefits\n    returnedToWorkAtDate\n    minimumWeeklyHours\n    occupation\n    payoutAccount {\n      accountNumberMask\n      bankName\n      id\n    }\n    status\n    terminatedWithoutCause\n    terminationReason\n    fileUploads {\n      id\n      size\n      extension\n      originalFileName\n      uri\n      type\n    }\n  }\n  content {\n    newClaimPage(where: {name: $pageName}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      secondaryCtaText\n      yesButton\n      noButton\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "14f0977cbfee702f3c511bde42a96bc7";

export default node;
