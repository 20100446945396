import { useCallback, useReducer, useRef } from 'react'

export enum ActionType {
  UpdateFields,
  ClearValidationErrors,
  ReceiveValidationErrors,
}

type FormState<V, E> = {
  values: V
  errors: E
}

type ValidationError = {
  readonly help: string
  readonly field: string
}

export function useFormReducer<
  V extends Record<string, unknown>,
  E extends Record<string, string | null>
>(initialState: FormState<V, E>) {
  type State = typeof initialState
  type Values = State['values']

  type UpdateFieldValueAction = {
    type: ActionType.UpdateFields
    values: Partial<Values>
  }

  type ReceiveValidationErrorsAction = {
    type: ActionType.ReceiveValidationErrors
    errors: readonly ValidationError[]
  }

  type SubmitAction = {
    type: ActionType.ClearValidationErrors
  }

  type Action =
    | UpdateFieldValueAction
    | SubmitAction
    | ReceiveValidationErrorsAction

  const reducer = useCallback(function reducer(
    previousState: State,
    action: Action
  ): State {
    function throwBadAction(action: never): never
    function throwBadAction(action: Action) {
      throw new Error('Unknown FormAction kind: ' + action.type)
    }

    switch (action.type) {
      case ActionType.UpdateFields: {
        // remove validation errors as fields are changed
        const updatedErrors = Object.keys(action.values).reduce(
          (errors, key) => ({
            ...errors,
            [key]:
              // if value hasn't actually changed, leave old error.
              // this is necessary especially for the address input which
              // fires a single action for the whole address value object
              previousState.values[key] === action.values[key]
                ? previousState.errors[key]
                : null,
          }),
          previousState.errors
        )

        return {
          ...previousState,
          values: {
            ...previousState.values,
            ...action.values,
          },
          errors: {
            ...updatedErrors,
          },
        }
      }

      case ActionType.ClearValidationErrors:
        return {
          ...previousState,
          errors: Object.keys(previousState.errors).reduce(
            (errorsObject, key) => {
              return { ...errorsObject, [key]: null }
            },
            previousState.errors
          ),
        }

      case ActionType.ReceiveValidationErrors:
        return {
          ...previousState,
          errors: action.errors.reduce((errorsObject, { field, help }) => {
            return { ...errorsObject, [field]: help }
          }, previousState.errors),
        }

      default:
        throwBadAction(action)
    }
  },
  [])

  const [state, dispatch] = useReducer(reducer, initialState)

  const updateFields = useCallback(function (values: Partial<Values>) {
    dispatch({
      type: ActionType.UpdateFields,
      values,
    })
  }, [])

  const updateField = useCallback(
    function <K extends keyof Values>(key: K, value: Values[K]) {
      // @ts-expect-error: in reality, this will always be a valid subset of V
      // but I don't know how to express that in TS.
      updateFields({ [key]: value })
    },
    [updateFields]
  )

  const createFieldUpdater = useCallback(
    function <K extends keyof Values>(key: K) {
      return function updater(value: Values[K]) {
        updateField(key, value)
      }
    },
    [updateField]
  )

  const receiveValidationErrors = useCallback(function (
    errors: readonly ValidationError[]
  ) {
    dispatch({ type: ActionType.ReceiveValidationErrors, errors })
  },
  [])

  const clearValidationErrors = useCallback(function () {
    dispatch({ type: ActionType.ClearValidationErrors })
  }, [])

  const actionsRef = useRef({
    updateField,
    updateFields,
    createFieldUpdater,
    receiveValidationErrors,
    clearValidationErrors,
  })

  return [state, actionsRef.current] as const
}
