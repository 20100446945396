/**
 * @generated SignedSource<<0bef381d1a348d735d6247a65ffa697b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocLink_disclosures$data = {
  readonly avibraMembershipAgreement: {
    readonly url: string;
    readonly version: number;
  };
  readonly avibraPrivacyPolicy: {
    readonly url: string;
    readonly version: number;
  };
  readonly avibraTermsOfService: {
    readonly url: string;
    readonly version: number;
  };
  readonly benefitEligibility: {
    readonly url: string;
    readonly version: number;
  };
  readonly benefitsDisclosure: {
    readonly url: string;
    readonly version: number;
  };
  readonly electronicCommunications: {
    readonly url: string;
    readonly version: number;
  };
  readonly mobileNetworkConsent: {
    readonly url: string;
    readonly version: number;
  };
  readonly patriotAct: {
    readonly url: string;
    readonly version: number;
  };
  readonly plateauApplication: {
    readonly url: string;
    readonly version: number;
  };
  readonly privacyPolicy: {
    readonly url: string;
    readonly version: number;
  };
  readonly promotionalTerms: {
    readonly url: string;
    readonly version: number;
  };
  readonly termsOfService: {
    readonly url: string;
    readonly version: number;
  };
  readonly " $fragmentType": "DocLink_disclosures";
};
export type DocLink_disclosures$key = {
  readonly " $data"?: DocLink_disclosures$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocLink_disclosures">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocLink_disclosures",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "electronicCommunications",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "mobileNetworkConsent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "patriotAct",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "avibraPrivacyPolicy",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "avibraTermsOfService",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "avibraMembershipAgreement",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "benefitsDisclosure",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "plateauApplication",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "privacyPolicy",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "termsOfService",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "benefitEligibility",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Disclosure",
      "kind": "LinkedField",
      "name": "promotionalTerms",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Disclosures",
  "abstractKey": null
};
})();

(node as any).hash = "0f52eea479e8f0d3025bcdb1d8dce348";

export default node;
