import { FileUploadType } from '@quil/constants'
import {
  ButtonPrimary,
  FileUpload,
  InlineButtonContainer,
  useShowToast,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import {
  uploadClaimDocument,
  uploadedToEditable,
  useDeleteFile,
} from '@web/common/file-utils'
import { pluck, propEq } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { NewClaimPageProps } from '../NewClaimPageProps'
import NewClaimProgressTracker from '../NewClaimProgressTracker'
import { NewClaimSequence, basePath } from '../NewClaimSequence'
import useNewClaimFlow from '../useNewClaimFlow'
import { uploadsInProgress, useDocumentsReducer } from './state'
import * as testIds from './test-ids'

function Documents(props: NewClaimPageProps) {
  const [data, commitMutation, mutationInFlight] = useNewClaimFlow('Documents')
  const [commitDeleteFile] = useDeleteFile()

  const [state, actions] = useDocumentsReducer({
    // get initial state from the current claim
    payStubs: data.pendingLayoffClaim?.fileUploads
      .filter(propEq(FileUploadType.PayStub, 'type'))
      .map(uploadedToEditable),
    proofOfStateBenefitsApplication: data.pendingLayoffClaim?.fileUploads
      .filter(propEq(FileUploadType.ProofOfStateBenefitsApplication, 'type'))
      .map(uploadedToEditable),
    proofOfStateBenefitsPayment: data.pendingLayoffClaim?.fileUploads
      .filter(propEq(FileUploadType.ProofOfStateBenefitsPayment, 'type'))
      .map(uploadedToEditable),
  })

  const navigate = useNavigate()
  const showToast = useShowToast()

  function handleError(error: unknown) {
    showToast({
      severity: 'Error',
      headline: 'Something went wrong',
      message: 'Please try again',
      linkText: 'Retry',
      onClick: handleSubmit,
    })
  }

  async function handleSubmit() {
    if (!mutationInFlight) {
      commitMutation({
        variables: {
          input: {
            id: data.pendingLayoffClaim?.id,
            // filter(Boolean) to exclude null values (e.g. upload errors that have not been removed)
            payStubs: pluck('id', state.payStubs).filter(Boolean),
            proofOfStateBenefitsApplication: pluck(
              'id',
              state.proofOfStateBenefitsApplication
            ).filter(Boolean),
            proofOfStateBenefitsPayment: pluck(
              'id',
              state.proofOfStateBenefitsPayment
            ).filter(Boolean),
          },
        },
        onCompleted({ upsertLayoffClaim }) {
          switch (upsertLayoffClaim.__typename) {
            case 'LayoffClaim':
              if (data.payoutAccount) {
                navigate(
                  `${basePath}/${NewClaimSequence.PayoutAccountExisting.path}`
                )
                break
              }
              navigate(props.nextPath)
              break
            case 'ValidationErrors':
              for (const message of upsertLayoffClaim.messages) {
                showToast({
                  severity: 'Error',
                  headline: message.help,
                  message: 'Please try again',
                })
              }
              break
            default:
              handleError({
                message: 'Unknown response type',
                upsertLayoffClaim,
              })
          }
        },
        onError(error) {
          handleError(error)
        },
      })
    }
  }

  function createAddHandler(key: keyof typeof state, type: string) {
    return function (files: File[]) {
      files.forEach(async (file, index) => {
        const handle = `${Date.now()}_${index}`

        actions.addFile(key, {
          file,
          handle,
          state: 'pending',
          uri: URL.createObjectURL(file),
          error: null,
        })

        try {
          const res = await uploadClaimDocument({
            claimId: data.pendingLayoffClaim?.id,
            file,
            type,
          })

          actions.updateFile(key, handle, {
            id: res.id,
            state: 'complete',
          })
        } catch (e) {
          actions.updateFile(key, handle, {
            state: 'error',
          })

          // display error toast
          handleError({
            message: 'Unknown response type',
            uploadClaimDocument,
          })
        }
      })
    }
  }

  function createRemoveHandler(key: keyof typeof state) {
    return function (handle: string) {
      actions.removeFile(key, handle)
      // Future enhancement: if the UI component was aware of the external `id` prop, it could be passed to the `onRequestToRemove` callback and remove the need to `find` in the list of values
      const id = state[key].find((value) => value.handle === handle)?.id
      if (id) {
        commitDeleteFile({ variables: { id } })
      }
    }
  }

  const disableSubmit =
    state.payStubs.length === 0 || mutationInFlight || uploadsInProgress(state)

  return (
    <LayoutInAppTracked
      title={data.content.newClaimPage.heading}
      pageName="New Claim: Documents"
      headerStart={<GoBack to={props.previousPath} />}
      headerEnd={<NewClaimProgressTracker step={props.step} />}
      footerContent={
        <InlineButtonContainer>
          <ButtonPrimary
            data-name="Continue"
            onClick={handleSubmit}
            disabled={disableSubmit}
            data-testid={testIds.primaryCta}
          >
            {data.content.newClaimPage.mainCtaText}
          </ButtonPrimary>
        </InlineButtonContainer>
      }
    >
      <RichText content={data.content.newClaimPage.body.raw} />
      <FileUpload
        title="Paystubs"
        description="We’ll need proof you’ve worked at least 90 consecutive days. Depending on how often you get paid, you will pobably need to upload multiple stubs to show 90 days of work."
        ctaText="Take Photo or Upload"
        items={state.payStubs}
        onRequestToAdd={createAddHandler('payStubs', FileUploadType.PayStub)}
        onRequestToRemove={createRemoveHandler('payStubs')}
        data-testid={testIds.paystubsUpload}
      />
      <FileUpload
        title="State Unemployment"
        description="Proof of applying for state unemployment or letter of approval"
        ctaText="Take Photo or Upload"
        items={state.proofOfStateBenefitsApplication}
        onRequestToAdd={createAddHandler(
          'proofOfStateBenefitsApplication',
          FileUploadType.ProofOfStateBenefitsApplication
        )}
        onRequestToRemove={createRemoveHandler(
          'proofOfStateBenefitsApplication'
        )}
        data-testid={testIds.proofOfStateBenefitsApplicationUpload}
      />
      <FileUpload
        title="Unemployment Payments"
        description="We need proof of payment received or bank statement showing State Unemployment payments"
        ctaText="Take Photo or Upload"
        items={state.proofOfStateBenefitsPayment}
        onRequestToAdd={createAddHandler(
          'proofOfStateBenefitsPayment',
          FileUploadType.ProofOfStateBenefitsPayment
        )}
        onRequestToRemove={createRemoveHandler('proofOfStateBenefitsPayment')}
        data-testid={testIds.proofOfStateBenefitsPaymentUpload}
      />
    </LayoutInAppTracked>
  )
}

export default Documents
