import styled, { css } from 'styled-components'
import { fromTheme } from '../../theme'

type Props = {
  // in rems
  height?: number
  // in rems
  width?: number
}
const IconButton = styled.button<Props>`
  background: ${fromTheme(({ colors }) => colors.background.raw)};
  border: none;
  color: ${fromTheme((theme) => theme.colors.textLink.raw)};
  cursor: pointer;
  padding: 0;
  position: relative;
  width: ${(props) => props.width ?? 1.8}rem;
  height: ${(props) => props.height ?? 1.8}rem;

  ::before {
    background-color: ${fromTheme((theme) => theme.colors.textLink.raw)};
    border-radius: 50%;
    content: '';
    display: block;
    height: 160%;
    width: 160%;
    position: absolute;
    top: -30%;
    left: -30%;
    opacity: 0;
    transform: scale(0);
    transition: 0.3s all;
    z-index: -1;
  }

  ${(props) =>
    !props.disabled &&
    css`
      &:hover::before,
      &:focus::before {
        opacity: 1;
        transform: scale(1);
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: default;
    `}

  &:focus {
    outline: 0;
  }
`

export default IconButton
