import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Info } from '../../icons'
import { fromTheme } from '../../theme'
import { LegalText } from '../typography'

const Container = styled.div`
  display: inline-block;
  position: relative;
`

const Tooltip = styled(motion.div)`
  // TODO: theme
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 18px;
  left: 50%;
  padding: 2rem;
  position: absolute;
  top: calc(100% + 1.6rem);
  width: 26rem;
  transform: translateX(-50%);
  border-radius: 4px;
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  z-index: 1;
  background-color: ${fromTheme((theme) => theme.colors.background.raw)};
`

const InfoIcon = styled(Info)`
  cursor: pointer;
  height: 1.8rem;
  width: 1.8rem;
  margin-left: 1rem;
`

const CustomIconContainer = styled.span`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
`

type Props = {
  className?: string
  icon?: React.ReactNode
  children: React.ReactNode
}

export const InfoTooltip: React.FC<Props> = ({ children, className, icon }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  function handleIconClick(event: React.MouseEvent) {
    setTooltipOpen((tooltipOpen) => !tooltipOpen)
  }

  function handleClickOutside() {
    setTooltipOpen(false)
  }

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !containerRef.current ||
        containerRef.current.contains(event.target as Node)
      ) {
        return
      }
      handleClickOutside()
    }

    document.addEventListener('click', listener)
    window.addEventListener('resize', handleClickOutside)
    return () => {
      document.removeEventListener('click', listener)
      window.removeEventListener('resize', handleClickOutside)
    }
  })

  const tooltipWidth = 260
  const halfTooltip = tooltipWidth / 2
  const margin = 24

  // default x: half the width of the tooltip to the left
  let x = halfTooltip * -1

  if (containerRef.current) {
    const windowWidth = document.body.clientWidth
    const iconLeft =
      containerRef.current.offsetLeft + containerRef.current.clientWidth / 2

    const tooltipLeft = iconLeft - halfTooltip
    const tooltipRight = iconLeft + halfTooltip

    const rightThreshold = margin
    const leftThreshold = windowWidth - margin

    // prevent tooltip going off the left side of the screen
    if (tooltipLeft < rightThreshold) {
      const offset = rightThreshold - tooltipLeft
      x = x + offset
    }

    // prevent tooltip going off the right side of the screen
    if (tooltipRight > leftThreshold) {
      const offset = tooltipRight - leftThreshold
      x = x - offset
    }
  }

  return (
    <Container ref={containerRef} className={className}>
      {icon ? (
        <CustomIconContainer onClick={handleIconClick}>
          {icon}
        </CustomIconContainer>
      ) : (
        <InfoIcon onClick={handleIconClick} />
      )}
      <AnimatePresence>
        {tooltipOpen && (
          <Tooltip
            initial={{ opacity: 0, y: 8, x }}
            animate={{ opacity: 1, y: 0, x }}
            exit={{ opacity: 0, y: 8, x }}
          >
            <LegalText noMargin>{children}</LegalText>
          </Tooltip>
        )}
      </AnimatePresence>
    </Container>
  )
}
