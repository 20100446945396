import { plaidLinkSessionEnded } from '@quil/analytics-events'
import {
  ButtonPrimary,
  ProcessingOverlay,
  standardToastHeadlines,
  useShowToast,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import * as analytics from '@web/common/analytics/analytics-adapter'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import { useNavigate } from '@web/common/react-router-wrappers'
import usePlaidLinkToken from '@web/common/usePlaidLinkToken'
import { useCallback, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { graphql, useMutation } from 'react-relay'
import { NewClaimPageProps } from '../NewClaimPageProps'
import NewClaimProgressTracker from '../NewClaimProgressTracker'
import { NewClaimSequence, basePath } from '../NewClaimSequence'
import useNewClaimFlow from '../useNewClaimFlow'
import { PayoutAccountAddPlaidAccountMutation } from './__generated__/PayoutAccountAddPlaidAccountMutation.graphql'

const PayoutAccount = (props: NewClaimPageProps) => {
  const showToast = useShowToast()
  const navigate = useNavigate()

  const goToManualLinkingStep = useCallback(() => {
    navigate(`${basePath}/${NewClaimSequence.PayoutAccountManual.path}`)
  }, [navigate])
  // Manual control for primary CTA disabled state.
  // After Plaid Link exits, there is a brief period before navigation where the primary CTA is incorrectly enabled
  // https://quin-inc.monday.com/boards/1528612958/pulses/4606157263
  const [plaidLinkOpen, setPlaidLinkOpen] = useState(false)

  const [linkToken, linkTokenLoading, linkTokenError] = usePlaidLinkToken()
  useEffect(() => {
    if (linkTokenError) {
      console.error(linkTokenError)
      goToManualLinkingStep()
    }
  }, [linkTokenError, goToManualLinkingStep])

  const { open, ready, error } = usePlaidLink({
    token: linkToken,
    onSuccess(publicToken, metadata) {
      analytics.track(
        plaidLinkSessionEnded('success', linkToken, { ...metadata })
      )
      createPayoutAccount({
        variables: {
          input: {
            claimId: data.pendingLayoffClaim.id,
            publicToken,
            linkToken,
          },
        },
        onCompleted(response) {
          navigate(props.nextPath)
        },
        onError() {
          setPlaidLinkOpen(false)
          showToast({
            severity: 'Error',
            headline: standardToastHeadlines.genericError,
            message: 'Click to retry',
            onClick() {
              navigate(0)
            },
          })
        },
        updater(store, payload) {
          const payoutAccount = store.get(payload.addPlaidAccount.id)
          store
            .getRoot()
            .getLinkedRecord('pendingLayoffClaim')
            .setLinkedRecord(payoutAccount, 'payoutAccount')
        },
      })
    },
    onExit(error, metadata) {
      analytics.track(
        plaidLinkSessionEnded('failure', linkToken, { ...error, ...metadata })
      )
      goToManualLinkingStep()
    },
  })

  useEffect(() => {
    if (error) {
      console.error(error)
      goToManualLinkingStep()
    }
  }, [error, goToManualLinkingStep])

  function handleClick() {
    setPlaidLinkOpen(true)
    open()
  }

  const [data] = useNewClaimFlow('Payout Account Plaid')

  const [createPayoutAccount, createPayoutAccountInFlight] =
    useMutation<PayoutAccountAddPlaidAccountMutation>(graphql`
      mutation PayoutAccountAddPlaidAccountMutation(
        $input: AddPlaidAccountInput!
      ) {
        addPlaidAccount(input: $input) {
          id
          bankName
          accountNumberMask
        }
      }
    `)

  return (
    <LayoutInAppTracked
      title={data.content.newClaimPage.heading}
      pageName="New Claim: Payout Account Plaid"
      headerEnd={<NewClaimProgressTracker step={props.step} />}
      headerStart={<GoBack to={props.previousPath} />}
      footerContent={
        <ButtonPrimary
          onClick={handleClick}
          disabled={linkTokenLoading || !ready || plaidLinkOpen}
          data-name="Link Payout Account"
        >
          {data.content.newClaimPage.mainCtaText}
        </ButtonPrimary>
      }
    >
      <RichText content={data.content.newClaimPage.body.raw} />
      <ProcessingOverlay processing={createPayoutAccountInFlight} />
    </LayoutInAppTracked>
  )
}

export default PayoutAccount
