import styled, { css } from 'styled-components'
import {
  CenterableTextProps,
  handleCenteredText,
} from '../../common/styles/center'
import { fromTheme } from '../../theme'

const standardMargin = `1.6rem`

type MarginConfigProps = {
  noMargin?: boolean
}

function handleMargin(marginBottom: string) {
  return function (props: MarginConfigProps) {
    return css`
      margin: 0 0 ${props.noMargin ? 0 : marginBottom};
    `
  }
}

type TypographyProps = CenterableTextProps & MarginConfigProps

type HeadingProps = {
  larger?: boolean
  color?: string
} & TypographyProps

export const Headline = styled.h1<HeadingProps>`
  font-family: 'Unbounded';
  color: ${fromTheme(
    (theme, props) => props.color ?? theme.colors.textPrimary.raw
  )};
  font-size: ${(props) => (props.larger ? '4.8rem' : '3.0rem')};
  font-weight: 400;
  line-height: 1.2;

  ${handleMargin(standardMargin)}
  ${handleCenteredText}
`

export const ModalHeadline = styled(Headline)`
  font-size: 2.4rem;
`

export const Subheading = styled.h2<TypographyProps>`
  font-family: 'Unbounded';
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;

  ${handleMargin('2.4rem')}
  ${handleCenteredText}

  strong {
    font-family: 'Unbounded';
    color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
    font-weight: 700;
  }
`

export const SubheadingAlt = styled.h2<TypographyProps>`
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;

  ${handleMargin('1.2rem')}
  ${handleCenteredText}
`

export const SectionHeading = styled.h2<TypographyProps>`
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 4rem;

  ${handleCenteredText}
`

export const IntroBlock = styled.p<TypographyProps>`
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.3;
  font-style: normal;

  ${handleMargin(standardMargin)}
  ${handleCenteredText}
`

export const BodyText = styled.p<TypographyProps>`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;

  ${handleMargin(standardMargin)}
  ${handleCenteredText}
`

export const ThemedBodyText = styled(BodyText)`
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
`

export const LegalText = styled.p<TypographyProps>`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.3;

  ${handleMargin('1.2rem')}
  ${handleCenteredText}
`

export const Ul = styled.ul`
  font-size: 1.6rem;
  padding-left: 3rem;

  li {
    margin-bottom: 1.6rem;
  }
`
