import styled from 'styled-components'
import { brandFocusRing } from '../../common/styles/focus-ring'
import { fromTheme } from '../../theme'

export type Props = {
  disabled?: boolean
  'data-name': string
  size?: 'small'
}

function fontSize({ size }: Props) {
  switch (size) {
    case 'small':
      return '1.4rem'
    default:
      return '2rem'
  }
}

const ButtonPrimary = styled.button<Props>`
  background: ${fromTheme(({ colors }) => colors.foregroundPrimary.raw)};
  color: ${fromTheme(({ colors }) => colors.buttonPrimaryText.raw)};
  border: none;
  appearance: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: ${fontSize};
  font-style: normal;
  font-weight: 600;
  padding: 1rem 2.4rem;

  ${brandFocusRing}

  a& {
    text-align: center;
    text-decoration: none;
  }

  &[disabled] {
    opacity: 0.5;
  }
`
export default ButtonPrimary
