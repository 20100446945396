import {
  BodyText,
  ButtonPrimary,
  fromTheme,
  InputTextarea,
  Modal,
  ModalHeadline,
  PageFooter,
  Select,
  SpinnerFullScreen,
  standardToastHeadlines,
  standardToastMessages,
  TextLinkPrimary,
  useShowToast,
} from '@quil/ui'
import { useEffect, useState } from 'react'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { LayoutInAppTracked } from '../../common/analytics'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import { useLogout } from '../../common/components/useLogout'
import { useFormReducer } from '../../common/useFormReducer'
import { initialState } from './state'
import {
  CloseMembershipMutation,
  ClosureReason,
} from './__generated__/CloseMembershipMutation.graphql'
import {
  CloseMembershipQuery,
  CloseMembershipQuery$data,
} from './__generated__/CloseMembershipQuery.graphql'

const pageName = 'Close Membership'

const query = graphql`
  query CloseMembershipQuery {
    content {
      profilePage(where: { name: "Close Membership" }) {
        header
        body {
          raw
        }
        mainCtaText
      }
      confirmationModal: modal(
        where: { name: "Close Membership Confirmation" }
      ) {
        heading
        body {
          raw
        }
        mainCtaText
        secondaryCtaText
      }
      logOutModal: modal(where: { name: "Account Closed" }) {
        heading
        body {
          raw
        }
        mainCtaText
      }
      setToCloseModal: modal(where: { name: "Account Set To Close" }) {
        heading
        body {
          raw
        }
        mainCtaText
      }
    }
  }
`

const mutation = graphql`
  mutation CloseMembershipMutation($reason: ClosureReason!, $detail: String) {
    closeMembership(reason: $reason, detail: $detail) {
      __typename
      ... on SubscriptionChoice {
        status
        endDate {
          formatted
        }
      }
      ... on ValidationErrors {
        messages {
          field
          help
        }
      }
    }
  }
`

const SectionHeading = styled(BodyText)`
  font-weight: 500;
  display: block;
`
export const BigUl = styled.ul`
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  font-style: normal;
  margin: 0;
`

const StyledTrackedButton = styled(ButtonPrimary)`
  background: ${fromTheme((theme) => theme.colors.foregroundDanger.raw)};
  color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
`

const cancellationReasonOptions: {
  value: ClosureReason
  label: string
}[] = [
  {
    value: 'DidNotUnderstandProduct',
    label: 'Did not understand the product',
  },
  {
    value: 'CostRelated',
    label: 'Not in the budget right now',
  },
  {
    value: 'ImprovedFinancialSituation',
    label: 'My financial situation improved',
  },
  {
    value: 'MoreConfidentWithEmploymentStatus',
    label: 'More confident with my employment status',
  },
  {
    value: 'NotEnoughCoverage',
    label: 'Not enough Layoff Protection coverage',
  },
  {
    value: 'ReductionOfMonthlyExpenses',
    label: 'Reducing my monthly expenses',
  },
]

type ConfirmationModalProps = {
  onCloseModal: () => void
  onConfirm: () => void
  isOpen: boolean
  content: CloseMembershipQuery$data['content']['confirmationModal']
}

type SuccessModalProps = {
  onConfirm: () => void
  isOpen: boolean
  content: CloseMembershipQuery$data['content']['logOutModal']
}

type SetToCloseModalProps = {
  onConfirm: () => void
  isOpen: boolean
  content: CloseMembershipQuery$data['content']['setToCloseModal']
  closureDate: string
}

function ConfirmationModal(props: ConfirmationModalProps) {
  return (
    <Modal isOpen={props.isOpen} onBackdropClick={props.onCloseModal}>
      <ModalHeadline>{props.content.heading}</ModalHeadline>
      <RichText content={props.content.body.raw} />
      <PageFooter>
        <StyledTrackedButton
          data-page-name={pageName}
          data-name="Confirm"
          onClick={props.onConfirm}
        >
          {props.content.mainCtaText}
        </StyledTrackedButton>
        <TextLinkPrimary
          data-page-name={pageName}
          data-name="Override retry"
          onClick={props.onCloseModal}
        >
          {props.content.secondaryCtaText}
        </TextLinkPrimary>
      </PageFooter>
    </Modal>
  )
}

function SuccessModal(props: SuccessModalProps) {
  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeadline>{props.content.heading}</ModalHeadline>
      <RichText content={props.content.body.raw} />
      <PageFooter>
        <ButtonPrimary
          data-page-name={pageName}
          data-name="Log out"
          onClick={props.onConfirm}
        >
          {props.content.mainCtaText}
        </ButtonPrimary>
      </PageFooter>
    </Modal>
  )
}

function SetToCloseModal(props: SetToCloseModalProps) {
  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeadline>{props.content.heading}</ModalHeadline>
      <RichText
        content={props.content.body.raw}
        data={{ date: props.closureDate }}
      />
      <PageFooter>
        <ButtonPrimary
          data-page-name={pageName}
          data-name="Go Home"
          onClick={props.onConfirm}
        >
          {props.content.mainCtaText}
        </ButtonPrimary>
      </PageFooter>
    </Modal>
  )
}

export default function CloseMembership() {
  const [{ values, errors }, actions] = useFormReducer(initialState)
  const [closureDate, setClosureDate] = useState(null)
  const [commitMutation, mutationInFlight] =
    useMutation<CloseMembershipMutation>(mutation)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [setToCloseModalOpen, setSetToCloseModalOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const data = useLazyLoadQuery<CloseMembershipQuery>(query, {})
  const navigate = useNavigate()

  // Temporarly sorting for experiment
  const [closureReasons, setClosureReasons] = useState(
    cancellationReasonOptions.sort(() => Math.random() - 0.5)
  )

  // Adding after sort to make sure it stay last option as part of experiment
  useEffect(() => {
    setClosureReasons([
      ...closureReasons,
      {
        value: 'Other',
        label: 'Other',
      },
    ])
  }, [])

  const [loggingOut, handleLogoutClick] = useLogout()
  const showToast = useShowToast()
  function handleClosureScheduledClick() {
    navigate('/home')
  }

  function handleUnknownError() {
    showToast({
      severity: 'Error',
      onClick: handleSubmit,
      headline: standardToastHeadlines.genericError,
      message: standardToastMessages.retry,
    })
  }
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false)
  const handleOpenConfirmationModal = () => setConfirmationModalOpen(true)

  function handleSubmit() {
    handleCloseConfirmationModal()
    commitMutation({
      variables: values,
      onError: handleUnknownError,
      onCompleted(response) {
        switch (response.closeMembership.__typename) {
          case 'ValidationErrors':
            showToast({
              severity: 'Error',
              headline: standardToastHeadlines.validationErrors,
              message: standardToastMessages.validationErrors,
            })
            actions.receiveValidationErrors(response.closeMembership.messages)
            break
          case 'SubscriptionChoice':
            if (response.closeMembership.status === 'PendingClosure') {
              setClosureDate(response.closeMembership.endDate.formatted)
              setSetToCloseModalOpen(true)
            } else {
              setSuccessModalOpen(true)
            }
            break
          default:
            handleUnknownError()
        }
      },
    })
  }

  if (loggingOut || mutationInFlight) {
    return <SpinnerFullScreen />
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title={data.content.profilePage.header}
      headerStart={<GoBack />}
    >
      <RichText content={data.content.profilePage.body.raw} />
      <SectionHeading as="label" htmlFor="cancellationReason">
        Reason
      </SectionHeading>
      <Select
        id="cancellationReason"
        name="cancellation_reason"
        nullOptionLabel="Cancellation reason"
        options={closureReasons}
        error={errors.reason}
        onChange={actions.createFieldUpdater('reason')}
      />

      <>
        <SectionHeading as="label" htmlFor="cancellationDetail">
          Details
        </SectionHeading>
        <InputTextarea
          id="cancellationDetail"
          name="cancellation_detail"
          value={values.detail}
          error={errors.detail}
          onChange={actions.createFieldUpdater('detail')}
          placeholder="Please provide more details"
        />
      </>
      <ButtonPrimary
        data-page-name={pageName}
        data-name="Primary CTA"
        onClick={handleOpenConfirmationModal}
        disabled={mutationInFlight || !values.reason}
      >
        {data.content.profilePage.mainCtaText}
      </ButtonPrimary>
      <ConfirmationModal
        onCloseModal={handleCloseConfirmationModal}
        content={data.content.confirmationModal}
        onConfirm={handleSubmit}
        isOpen={confirmationModalOpen}
      />
      <SuccessModal
        isOpen={successModalOpen}
        content={data.content.logOutModal}
        onConfirm={handleLogoutClick}
      />
      <SetToCloseModal
        isOpen={setToCloseModalOpen}
        content={data.content.setToCloseModal}
        onConfirm={handleClosureScheduledClick}
        closureDate={closureDate}
      />
    </LayoutInAppTracked>
  )
}
