import { Navigate, Route, Routes } from 'react-router-dom'
import { useDocumentTitle } from '../common/useDocumentTitle'
import AvibraBenefitPage from './AvibraBenefitPage'
import BenefitsOverview from './BenefitsOverview'
import Counseling from './Counseling'
import LayoffInsurance from './LayoffInsurance'
import Protection from './Protection'

export default function Benefits() {
  useDocumentTitle('Benefits')

  return (
    <Routes>
      <Route index element={<BenefitsOverview />} />
      <Route path="/layoff-insurance" element={<LayoffInsurance />} />
      <Route path="/protection" element={<Protection />} />

      <Route
        path="/protection/life"
        element={<AvibraBenefitPage pageName="Life Insurance" />}
      />
      <Route
        path="/protection/adnd"
        element={<AvibraBenefitPage pageName="AD&D" />}
      />
      <Route path="/counseling" element={<Counseling />} />
      <Route
        path="/counseling/esg-advisor"
        element={<AvibraBenefitPage pageName="ESG Advisor" />}
      />
      <Route
        path="/counseling/well-being"
        element={<AvibraBenefitPage pageName="Well-Being Advisor" />}
      />
      <Route
        path="/counseling/credit-counseling"
        element={<AvibraBenefitPage pageName="Credit Counseling" />}
      />
      <Route
        path="/counseling/debt-counseling"
        element={<AvibraBenefitPage pageName="Debt Counseling" />}
      />
      <Route
        path="/counseling/housing-counseling"
        element={<AvibraBenefitPage pageName="Housing Counseling" />}
      />

      <Route path="*" element={<Navigate to="/home/benefits" replace />} />
    </Routes>
  )
}
