/**
 * @generated SignedSource<<134adb9fe64d116127555cb44d98ce80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginFailureType = "Closed" | "Error" | "IncorrectOtp" | "Ineligible" | "KillSwitch" | "Locked" | "NotFound" | "%future added value";
export type OnboardingStep = "ActivateMembership" | "CreateUser" | "SubmitEmploymentInfo" | "UpdateEmploymentInfo" | "VerifyDevice" | "VerifyPersonalInfo" | "%future added value";
export type UserStatus = "Active" | "Closed" | "Ineligible" | "Pending" | "SecurityLocked" | "%future added value";
export type LoginMutation$variables = {
  email?: string | null;
  emailOTP?: string | null;
  phone?: string | null;
};
export type LoginMutation$data = {
  readonly login: {
    readonly __typename: "LoginFailure";
    readonly message: string | null;
    readonly type: LoginFailureType | null;
  } | {
    readonly __typename: "LoginRequiresEmailOTP";
    readonly email: string;
    readonly emailSent: boolean | null;
  } | {
    readonly __typename: "LoginSuccess";
    readonly user: {
      readonly dob: any | null;
      readonly email: string | null;
      readonly firstName: string | null;
      readonly id: string | null;
      readonly lastCompletedOnboardingStep: OnboardingStep;
      readonly lastName: string | null;
      readonly state: string | null;
      readonly status: UserStatus;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type LoginMutation = {
  response: LoginMutation$data;
  variables: LoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "emailOTP"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phone"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "emailOTP",
        "variableName": "emailOTP"
      },
      {
        "kind": "Variable",
        "name": "phone",
        "variableName": "phone"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "login",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "type": "LoginFailure",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailSent",
            "storageKey": null
          }
        ],
        "type": "LoginRequiresEmailOTP",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dob",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastCompletedOnboardingStep",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "LoginSuccess",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoginMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "9cc21cf6298053b1b7332ddc6c5a89fd",
    "id": null,
    "metadata": {},
    "name": "LoginMutation",
    "operationKind": "mutation",
    "text": "mutation LoginMutation(\n  $phone: String\n  $email: String\n  $emailOTP: String\n) {\n  login(phone: $phone, email: $email, emailOTP: $emailOTP) {\n    __typename\n    ... on LoginFailure {\n      message\n      type\n    }\n    ... on LoginRequiresEmailOTP {\n      email\n      emailSent\n    }\n    ... on LoginSuccess {\n      user {\n        id\n        firstName\n        lastName\n        status\n        state\n        dob\n        email\n        lastCompletedOnboardingStep\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b1526af081654943dabb549e2a55768";

export default node;
