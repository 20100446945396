import * as event from '@quil/analytics-events'
import { TextLinkInline, TextLinkPrimary } from '@quil/ui'
import React from 'react'
import { graphql, useFragment } from 'react-relay'
import * as analytics from '../../common/analytics/analytics-adapter'
import {
  DocLink_disclosures$data,
  DocLink_disclosures$key,
} from './__generated__/DocLink_disclosures.graphql'

export type DocKind = keyof DocLink_disclosures$data

type Props = {
  kind: DocKind | 'faq'
  className?: string
  disclosures: DocLink_disclosures$key
  children: React.ReactNode
}

const asDocLink =
  (Component): React.FC<Props> =>
  (props) => {
    const disclosures = useFragment(
      graphql`
        fragment DocLink_disclosures on Disclosures {
          electronicCommunications {
            url
            version
          }
          mobileNetworkConsent {
            url
            version
          }
          patriotAct {
            url
            version
          }
          avibraPrivacyPolicy {
            url
            version
          }
          avibraTermsOfService {
            url
            version
          }
          avibraMembershipAgreement {
            url
            version
          }
          benefitsDisclosure {
            url
            version
          }
          plateauApplication {
            url
            version
          }
          privacyPolicy {
            url
            version
          }
          termsOfService {
            url
            version
          }
          benefitEligibility {
            url
            version
          }
          promotionalTerms {
            url
            version
          }
        }
      `,
      props.disclosures
    )

    const docUrls = {
      faq: {
        url: `https://getquil.zendesk.com/hc/en-us`,
      },
      ...disclosures,
    }

    function handleClick() {
      analytics.track(event.documentLinkClicked(props.kind))
    }

    return (
      <Component
        as="a"
        onClick={handleClick}
        className={props.className}
        // using [] indexing to get around type checking issue with fragment
        href={docUrls[props.kind]['url']}
        target="_blank"
        rel="noreferrer"
      >
        {props.children}
      </Component>
    )
  }

export default asDocLink(TextLinkInline)
export const DocLinkPrimary = asDocLink(TextLinkPrimary)
