import { FileUploadItem } from '@quil/ui'
import { graphql, useMutation } from 'react-relay'
import { fileUtilsDeleteFileMutation } from './__generated__/fileUtilsDeleteFileMutation.graphql'

// we accept 'image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
export function extensionToMime(extension: string) {
  switch (extension) {
    case 'pdf':
      return 'application/pdf'
    case 'doc':
      return 'application/msword'
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'xml':
      return 'application/xml'
    default:
      return `image/${extension}`
  }
}

export function constructFile(name: string, extension: string) {
  return new File(new Array(0), name, {
    type: extensionToMime(extension),
  })
}

export type FileEntity = FileUploadItem<string> & { id?: string }
export function uploadedToEditable(file: {
  id: string
  originalFileName: string
  extension: string
  size: number
  uri: string
}) {
  return {
    handle: file.id,
    state: 'complete' as const,
    file: constructFile(file.originalFileName, file.extension),
    size: file.size,
    uri: file.uri,
    error: null,
    id: file.id,
  }
}
type UploadConfiguration = {
  type: string
  claimId: string
  file: File
}

type Document = {
  id: string
  itemId: string
  type: string
  bucket: string
  key: string
  originalFileName: string
  extension: string
  size: number
  updatedAt: string
  createdAt: string
}

export async function uploadClaimDocument({
  type,
  claimId,
  file,
}: UploadConfiguration): Promise<Document> {
  const formData = new FormData()
  formData.append('type', type)
  formData.append('file', file)

  const request = await fetch(
    `${process.env.REACT_APP_UPLOADS_URL}/claims/${claimId}/documents`,
    {
      method: 'POST',
      body: formData,
      credentials: 'include',
    }
  )

  if (!request.ok) {
    throw new Error(await request.text())
  }

  return request.json()
}

/**
 * This mutation will never throw, and does not need to be awaited. Simply call and forget!
 */
export function useDeleteFile() {
  return useMutation<fileUtilsDeleteFileMutation>(graphql`
    mutation fileUtilsDeleteFileMutation($id: ID!) {
      deleteFile(id: $id) {
        id
      }
    }
  `)
}
