import { ButtonPrimary } from '@quil/ui'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { LayoutSignupTracked } from '../../../common/analytics'
import RichText from '../../../common/components/RichText'
import useContinueOnboarding from '../../../common/components/useContinueOnboarding'
import { useNavigate } from '../../../common/react-router-wrappers'
import { SignupPageProps } from '../../SignupPageProps'
import { SeeYouLaterQuery } from './__generated__/SeeYouLaterQuery.graphql'

const query = graphql`
  query SeeYouLaterQuery {
    currentUser {
      ...useContinueOnboarding_user
    }
    content {
      onboardingPage(where: { pageName: "See You Later" }) {
        heading
        body {
          raw
        }
        mainCtaText
      }
    }
  }
`

export default function SeeYouLater({
  allowedLastOnboardingSteps,
  nextPath,
}: SignupPageProps) {
  const pageName = 'See You Later'
  const navigate = useNavigate()
  const data = useLazyLoadQuery<SeeYouLaterQuery>(query, {})

  function handleContinueClick() {
    navigate(nextPath)
  }

  useContinueOnboarding(data.currentUser, allowedLastOnboardingSteps)

  return (
    <LayoutSignupTracked
      pageName={pageName}
      title={data.content.onboardingPage.heading}
      footerContent={
        <ButtonPrimary
          data-page-name={pageName}
          data-name="Primary CTA"
          onClick={handleContinueClick}
        >
          {data.content.onboardingPage.mainCtaText}
        </ButtonPrimary>
      }
    >
      <RichText content={data.content.onboardingPage.body.raw} />
    </LayoutSignupTracked>
  )
}
