import {
  ActionCard,
  BodyText,
  PlanCard,
  PlanCardUsAlliance,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  Toggle,
  useShowToast,
} from '@quil/ui'
import DocLink from '@web/common/components/DocLink'
import RichText from '@web/common/components/RichText'
import useContinueOnboarding from '@web/common/components/useContinueOnboarding'
import getPlanColorScheme from '@web/common/getPlanColorScheme'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { tap } from 'ramda'
import { useState } from 'react'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import styled from 'styled-components'
import { LayoutSignupTracked } from '../../../common/analytics'
import GoBack from '../../../common/components/GoBack'
import { useNavigate } from '../../../common/react-router-wrappers'
import { SignupPageProps } from '../../SignupPageProps'
import SignupProgressTracker from '../../SignupProgressTracker'
import * as testIds from './test-ids'
import { MyPlanMutation } from './__generated__/MyPlanMutation.graphql'
import {
  BillingCadence,
  MyPlanQuery,
} from './__generated__/MyPlanQuery.graphql'
import { MyPlanSmsMutation } from './__generated__/MyPlanSmsMutation.graphql'

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const TermsText = styled(BodyText)`
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
`

const StyledToggle = styled(Toggle)`
  max-width: 40rem;
  margin: 2rem auto 0;
  width: 100%;
`

type BillingCycleOption = { label: string; value: BillingCadence }
const billingCycleOptions: BillingCycleOption[] = [
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Annual', value: 'Yearly' },
]

const MyPlan: React.FC<SignupPageProps> = ({
  step,
  nextPath,
  previousPath,
  allowedLastOnboardingSteps,
}) => {
  const pageName = 'My Plan'
  const navigate = useNavigate()
  const showToast = useShowToast()
  const { showPhoneDobLaterInOnboarding } = useFlags()

  const targetUrl = new URL(
    `verify-device?redirect_to=${nextPath}`,
    window.location.origin
  ).toString()

  const data = useLazyLoadQuery<MyPlanQuery>(
    graphql`
      query MyPlanQuery {
        content {
          myPlan: onboardingPage(where: { pageName: "My Plan" }) {
            mainCtaText
            heading
            body {
              raw
            }
          }
          myPlanPreselected: onboardingPage(
            where: { pageName: "My Plan Preselected" }
          ) {
            mainCtaText
            heading
            body {
              raw
            }
          }
          myPlanPromotion: onboardingPage(
            where: { pageName: "My Plan Promo" }
          ) {
            mainCtaText
            heading
            body {
              raw
            }
          }
        }
        currentUser {
          ...useContinueOnboarding_user
          promotion {
            promotionId
          }
        }
        currentSubscription {
          coverage {
            formatted
            raw
          }
          cadence
        }
        availableSubscriptionPlans {
          monthlyPrice {
            dollars
            cents
          }
          monthlyListPrice {
            dollars
            cents
          }
          yearlyPrice {
            formatted
            dollars
            cents
          }
          yearlyListPrice {
            dollars
            cents
          }
          coverage {
            formatted
            raw
          }
          label
          cadence
          suggested
        }
        disclosures {
          ...DocLink_disclosures
        }
        features {
          launchPromoPrice
        }
      }
    `,
    {}
  )

  useContinueOnboarding(data.currentUser, allowedLastOnboardingSteps)

  const usAlliance = data.currentUser.promotion?.promotionId === 'usalliance'
  const [billingCycle, setBillingCycle] = useState<BillingCadence>(
    usAlliance ? 'Yearly' : 'Monthly'
  )
  const preselectedPlan = data.availableSubscriptionPlans.find(
    (plan) =>
      plan.cadence === billingCycle &&
      plan.coverage.raw === data.currentSubscription?.coverage.raw
  )

  const highlightedPlan =
    preselectedPlan ??
    data.availableSubscriptionPlans.find((plan) => plan.suggested) ??
    // nullish coalescing is lazily evaluated, the log is only executed if we reach this level; we log here because it indicates a bug in the API
    tap(
      () =>
        console.error({
          availableSubscriptionPlans: data.availableSubscriptionPlans,
          msg: 'No suggested plan',
        }),
      data.availableSubscriptionPlans[1]
    ) ??
    tap(
      () =>
        console.error({
          availableSubscriptionPlans: data.availableSubscriptionPlans,
          msg: 'Nothing at index 1',
        }),
      data.availableSubscriptionPlans[0]
    )

  const [commitMyPlanMutation, myPlanMutationInFlight] =
    useMutation<MyPlanMutation>(graphql`
      mutation MyPlanMutation(
        $subscriptionChoiceInput: SubscriptionChoiceInput!
      ) {
        saveSubscriptionChoice(input: $subscriptionChoiceInput) {
          __typename
        }
      }
    `)

  const [commitSmsMutation, smsMutationInFlight] =
    useMutation<MyPlanSmsMutation>(graphql`
      mutation MyPlanSmsMutation($targetUrl: String!) {
        sendIdentitySms(targetUrl: $targetUrl) {
          success
        }
      }
    `)

  function handleSubmit() {
    if (!myPlanMutationInFlight && !smsMutationInFlight) {
      commitMyPlanMutation({
        variables: {
          subscriptionChoiceInput: {
            coverage: highlightedPlan.coverage.raw,
            cadence: highlightedPlan.cadence,
          },
        },
        onCompleted({ saveSubscriptionChoice }) {
          if (saveSubscriptionChoice.__typename) {
            if (showPhoneDobLaterInOnboarding) {
              // if in experiment group we don't have phone yet so next exp page
              navigate('/signup/phone-dob')
            } else {
              // if not in experiment group we already have phone so send sms
              commitSmsMutation({
                variables: {
                  targetUrl,
                },
                onCompleted({ sendIdentitySms }) {
                  if (sendIdentitySms.success) {
                    navigate(nextPath)
                  } else {
                    showToast({
                      severity: 'Error',
                      headline: standardToastHeadlines.genericError,
                      message: standardToastMessages.retry,
                      onClick: handleSubmit,
                    })
                  }
                },
                onError(error) {
                  console.error({
                    mutation: 'MyPlanSmsMutation',
                    error,
                  })
                  showToast({
                    severity: 'Error',
                    headline: standardToastHeadlines.genericError,
                    message: standardToastMessages.retry,
                    onClick: handleSubmit,
                  })
                },
              })
            }
          } else {
            showToast({
              severity: 'Error',
              headline: standardToastHeadlines.genericError,
              message: standardToastMessages.retry,
              onClick: handleSubmit,
            })
          }
        },
        onError(error) {
          console.error({
            mutation: 'MyPlanMutation',
            error,
          })
          showToast({
            severity: 'Error',
            headline: standardToastHeadlines.genericError,
            message: standardToastMessages.retry,
            onClick: handleSubmit,
          })
        },
      })
    }
  }

  const pageCopy = (() => {
    if (usAlliance && data.content.myPlanPromotion) {
      return data.content.myPlanPromotion
    }

    return preselectedPlan
      ? data.content.myPlanPreselected
      : data.content.myPlan
  })()

  const ctaText = pageCopy.mainCtaText

  return (
    <>
      <LayoutSignupTracked
        pageName={pageName}
        title={pageCopy.heading}
        headerStart={previousPath && <GoBack />}
        headerEnd={<SignupProgressTracker step={step} />}
      >
        <FlexColumn>
          <RichText content={pageCopy.body.raw} />
          {usAlliance && (
            <StyledToggle
              options={billingCycleOptions}
              // @ts-expect-error TODO: figure out why BillingCycleType is getting inferred as string
              onChange={setBillingCycle}
              value={billingCycle}
              data-testid={testIds.billingCycleToggle}
            />
          )}
          {usAlliance ? (
            <PlanCardUsAlliance
              price={
                billingCycle === 'Yearly'
                  ? highlightedPlan.yearlyPrice
                  : highlightedPlan.monthlyPrice
              }
              ctaText={ctaText}
              benefitAmount={highlightedPlan.coverage.formatted}
              colorScheme={getPlanColorScheme(highlightedPlan.label)}
              onClick={handleSubmit}
              disabled={myPlanMutationInFlight || smsMutationInFlight}
              listPrice={
                billingCycle === 'Yearly'
                  ? highlightedPlan.yearlyListPrice
                  : highlightedPlan.monthlyListPrice
              }
              cadence={billingCycle}
            />
          ) : (
            <PlanCard
              price={{
                dollars: highlightedPlan.monthlyPrice.dollars,
                cents: highlightedPlan.monthlyPrice.cents,
              }}
              ctaText={ctaText}
              benefitAmount={highlightedPlan.coverage.formatted}
              colorScheme={getPlanColorScheme(highlightedPlan.label)}
              onClick={handleSubmit}
              disabled={myPlanMutationInFlight || smsMutationInFlight}
              listPrice={highlightedPlan.monthlyListPrice}
            />
          )}
          <TermsText centered>
            7-day money-back guarantee
            <br />
            <DocLink
              disclosures={data.disclosures}
              kind={
                data.features.launchPromoPrice
                  ? 'promotionalTerms'
                  : 'termsOfService'
              }
            >
              Terms and conditions apply
            </DocLink>
          </TermsText>
          {preselectedPlan ? (
            <ActionCard
              onClick={() => navigate('/signup/customize/plans')}
              problemText="Need something different?"
              solutionText="Customize"
            />
          ) : (
            <>
              <ActionCard
                onClick={() =>
                  navigate('/signup/customize/plans', {
                    state: { plan: 'premium' },
                  })
                }
                problemText="Need more coverage?"
                solutionText="Customize"
              />
              <ActionCard
                onClick={() =>
                  navigate('/signup/customize/plans', {
                    state: { plan: 'affordable' },
                  })
                }
                problemText="Need something cheaper?"
                solutionText="Customize"
              />
            </>
          )}
        </FlexColumn>
      </LayoutSignupTracked>
      <ProcessingOverlay
        processing={myPlanMutationInFlight || smsMutationInFlight}
      />
    </>
  )
}

export default MyPlan
