import { graphql, useFragment } from 'react-relay'
import { Navigate } from '../../react-router-wrappers'
import { getNextOnboardingStepPath } from '../useResumeOnboarding'
import {
  AccessControl_user$data,
  AccessControl_user$key,
  UserStatus,
} from './__generated__/AccessControl_user.graphql'

type AccessControlProps = {
  children: React.ReactElement
  allowedStatuses: UserStatus[]
  redirectTo: string | ((data: AccessControl_user$data | null) => string)
  user: AccessControl_user$key
}

export function AccessControl(props: AccessControlProps) {
  const data = useFragment(
    graphql`
      fragment AccessControl_user on User {
        status
        lastCompletedOnboardingStep
      }
    `,
    props.user
  )

  const redirectTo =
    typeof props.redirectTo === 'string'
      ? props.redirectTo
      : props.redirectTo(data)

  if (data === null || !props.allowedStatuses.includes(data.status)) {
    return <Navigate to={redirectTo} />
  }

  return props.children
}

type ActiveUserAreaProps = {
  children: React.ReactElement
  user: AccessControl_user$key
}
export function ActiveUserArea(props: ActiveUserAreaProps) {
  return (
    <AccessControl
      allowedStatuses={['Active']}
      redirectTo={(user) =>
        user?.status === 'Pending'
          ? getNextOnboardingStepPath(user.lastCompletedOnboardingStep)
          : '/welcome'
      }
      user={props.user}
    >
      {props.children}
    </AccessControl>
  )
}
