import React, { ChangeEvent, useImperativeHandle, useRef } from 'react'
import { PatternFormat, PatternFormatProps } from 'react-number-format'
import styled, { css } from 'styled-components'
import { inputAttributeFallbacks } from '../../common/input-attrubute-fallbacks'
import { fromTheme } from '../../theme'
import ErrorBadge from '../ErrorBadge'
import ErrorMessageContainer from '../ErrorMessageContainer'
import ErrorMessageTextValidation from '../ErrorMessageTextValidation'
import { InputLabel } from '../InputLabel'

const Container = styled.div`
  position: relative;
  margin: 0 0 1.6rem;
`

export const InputContainer = styled.div<{
  error: boolean
  disabled?: boolean
}>`
  border: 1px solid
    ${fromTheme((theme, props) =>
      props.error
        ? theme.colors.foregroundDanger.raw
        : theme.colors.foregroundMuted.raw
    )};
  border-radius: 3px;
  display: flex;
  font-size: 1.8rem;
  height: 4.8rem;
  outline: none;
  overflow: hidden;
  padding: 0 1rem;

  &:focus-within {
    border: 1px solid
      ${fromTheme((theme, props) =>
        props.error
          ? theme.colors.foregroundDanger.raw
          : theme.colors.foregroundMutedFocused.raw
      )};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

export const Input = styled.input`
  background-color: ${fromTheme(({ colors }) => colors.background.raw)};
  color: ${fromTheme(({ colors }) => colors.textPrimary.raw)};
  border: none;
  font-size: 1.8rem;
  height: 100%;
  outline: none;
  flex-grow: 1;
  min-width: 0;
  padding: 0;

  &::placeholder {
    color: ${fromTheme((theme) => theme.colors.foregroundMuted.raw)};
  }
`

const InputMask = Input.withComponent(PatternFormat)

type InputProps = {
  value: string | null
  onChange: (value: string, event: ChangeEvent<HTMLInputElement>) => void

  // optional props
  label?: string
  labelEnd?: string | JSX.Element
  id?: string
  name?: string
  error?: string | null
  placeholder?: string
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  'data-testid'?: string
  type?: 'tel' | 'text' | 'password'
  autoComplete?: string
  maxLength?: number
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
  disabled?: boolean
}

// PatternFormatProps includes a `defaultValue` prop definition which conflicts with the native HTMLElement props.
// We don't use it anywhere so omitting for convenience
type MaskedInputProps = InputProps & Omit<PatternFormatProps, 'defaultValue'>
export type Props = InputProps | MaskedInputProps

function hasMaskedInputProps(
  props: React.InputHTMLAttributes<HTMLInputElement> | PatternFormatProps
): props is MaskedInputProps {
  return 'format' in props
}

const InputText: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    labelEnd,
    startAdornment,
    endAdornment,
    error,
    'data-testid': testId,
    ...inputProps
  },
  forwardRef
) => {
  const { label, name, id } = inputAttributeFallbacks(
    inputProps.label,
    inputProps.name,
    inputProps.id
  )

  const inputRef = useRef<HTMLInputElement>(null)
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    forwardRef,
    () => inputRef.current
  )

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    inputProps.onChange(event.target.value, event)
  }

  const modifiedInputProps = {
    ...inputProps,
    id,
    name,
    type: inputProps.type ?? 'text',
    'data-testid': `${testId}_input`,
    onChange: handleChange,
    value: inputProps.value ?? '',
  }

  function handleInputContainerClick() {
    inputRef.current?.focus()
  }

  return (
    <Container>
      {!!label && (
        <InputLabel
          htmlFor={id}
          error={!!error}
          endContent={labelEnd}
          disabled={inputProps.disabled}
        >
          {label}
        </InputLabel>
      )}
      <InputContainer
        error={!!error}
        onClick={handleInputContainerClick}
        disabled={inputProps.disabled}
      >
        {startAdornment}
        {hasMaskedInputProps(modifiedInputProps) ? (
          <InputMask
            {...modifiedInputProps}
            // @ts-expect-error: type definition for InputMask is not including ref
            ref={inputRef}
            disabled={inputProps.disabled}
          />
        ) : (
          <Input
            {...modifiedInputProps}
            ref={inputRef}
            disabled={inputProps.disabled}
          />
        )}
        {endAdornment}
      </InputContainer>
      <ErrorMessageContainer visible={!!error && error.length > 0}>
        <ErrorMessageTextValidation data-testid={`${testId}_error_message`}>
          {error}
        </ErrorMessageTextValidation>
      </ErrorMessageContainer>
      {/* Without a label, the positioning of this badge will be different */}
      <ErrorBadge error={!!error} style={!label ? { top: -12 } : null} />
    </Container>
  )
}

export default React.forwardRef(InputText)
