import { ChangeEvent, useRef } from 'react'
import styled from 'styled-components'
import { fromTheme } from '../../theme'
import ButtonPrimary from '../ButtonPrimary'
import ErrorMessageContainer from '../ErrorMessageContainer'
import ErrorMessageTextValidation from '../ErrorMessageTextValidation'
import { File as FileComponent } from '../File'
import FileState from '../File/FileState'

export type FileUploadItem<T> = {
  // UI-only handle for managing lists of items
  handle: T
  file: File
  size?: number
  state: FileState
  uri: string
  error: string | null
}

type Props<T> = {
  title: string | JSX.Element
  description: string | JSX.Element
  ctaText: string
  items: FileUploadItem<T>[]
  accept?: string
  multiple?: boolean
  disabled?: boolean
  onRequestToAdd: (file: File[]) => void
  onRequestToRemove: (handle: T) => void
  'data-testid'?: string
  error?: string
}

const Container = styled.div`
  .title {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }

  .description {
    font-size: 1.2rem;
    color: ${fromTheme(({ colors }) => colors.foregroundMuted.raw)};
    margin-bottom: 1.6rem;
  }

  button {
    margin-bottom: 2rem;
  }
`

export function FileUpload<T>(props: Props<T>) {
  const {
    accept = 'image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  } = props
  const inputRef = useRef<HTMLInputElement>(null)
  function handleButtonClick() {
    inputRef.current?.click()
  }

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files
    if (files) {
      props.onRequestToAdd(Array.from(files))
    }

    // clear the state of the input. Since we are delegating all
    // of the state management to the caller, we want a fresh input
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const { multiple = true } = props

  return (
    <Container>
      {/* if JSX.Element is passed for `title` or `description, do not use default styles */}
      {typeof props.title === 'string' ? (
        <div className="title">{props.title}</div>
      ) : (
        props.title
      )}
      {typeof props.description === 'string' ? (
        <p className="description">{props.description}</p>
      ) : (
        props.description
      )}
      <input
        hidden
        multiple={multiple}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        data-testid={props['data-testid'] ?? ''}
        accept={accept}
      />
      <ButtonPrimary
        data-name="file-upload"
        disabled={props.disabled}
        size="small"
        onClick={handleButtonClick}
      >
        {props.ctaText}
      </ButtonPrimary>
      {props.items.map((item) => (
        <FileComponent
          uri={item.uri}
          key={JSON.stringify(item.handle)}
          file={item.file}
          size={item.size}
          state={item.state}
          onRequestToRemove={props.onRequestToRemove}
          handle={item.handle}
          error={item.error}
        />
      ))}
      <ErrorMessageContainer visible={!!props.error && props.error.length > 0}>
        <ErrorMessageTextValidation
          data-testid={`${props['data-testid']}_error_message`}
        >
          {props.error}
        </ErrorMessageTextValidation>
      </ErrorMessageContainer>
    </Container>
  )
}
