/**
 * @generated SignedSource<<71867846d8bed3907776047871a1c10a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCadence = "Monthly" | "Yearly" | "%future added value";
export type PlanLabel = "affordable" | "popular" | "premium" | "%future added value";
export type PlansQuery$variables = {
  pageName: string;
};
export type PlansQuery$data = {
  readonly availableSubscriptionPlans: ReadonlyArray<{
    readonly cadence: BillingCadence;
    readonly coverage: {
      readonly formatted: string;
      readonly raw: number;
    };
    readonly label: PlanLabel;
    readonly monthlyListPrice: {
      readonly cents: number;
      readonly dollars: number;
    } | null;
    readonly monthlyPrice: {
      readonly cents: number;
      readonly dollars: number;
    };
    readonly suggested: boolean;
    readonly yearlyListPrice: {
      readonly cents: number;
      readonly dollars: number;
    } | null;
    readonly yearlyPrice: {
      readonly cents: number;
      readonly dollars: number;
    } | null;
  }>;
  readonly content: {
    readonly onboardingPage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
  };
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"useContinueOnboarding_user">;
  } | null;
  readonly disclosures: {
    readonly " $fragmentSpreads": FragmentRefs<"DocLink_disclosures">;
  } | null;
  readonly features: {
    readonly launchPromoPrice: boolean;
  } | null;
};
export type PlansQuery = {
  response: PlansQuery$data;
  variables: PlansQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageName"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "pageName",
        "variableName": "pageName"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "raw",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "FeatureSet",
  "kind": "LinkedField",
  "name": "features",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "launchPromoPrice",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dollars",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cents",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "includePromotional",
      "value": false
    }
  ],
  "concreteType": "SubscriptionPlan",
  "kind": "LinkedField",
  "name": "availableSubscriptionPlans",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "monthlyPrice",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "monthlyListPrice",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "yearlyPrice",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "yearlyListPrice",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "coverage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formatted",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cadence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suggested",
      "storageKey": null
    }
  ],
  "storageKey": "availableSubscriptionPlans(includePromotional:false)"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlansQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useContinueOnboarding_user"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocLink_disclosures"
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlansQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastCompletedOnboardingStep",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "electronicCommunications",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "mobileNetworkConsent",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "patriotAct",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraPrivacyPolicy",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraTermsOfService",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraMembershipAgreement",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitsDisclosure",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "plateauApplication",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "privacyPolicy",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "termsOfService",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitEligibility",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "promotionalTerms",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "b6881b549ecada562cdadc5ad1d0f229",
    "id": null,
    "metadata": {},
    "name": "PlansQuery",
    "operationKind": "query",
    "text": "query PlansQuery(\n  $pageName: String!\n) {\n  content {\n    onboardingPage(where: {pageName: $pageName}) {\n      heading\n      mainCtaText\n      body {\n        raw\n      }\n      id\n    }\n  }\n  currentUser {\n    ...useContinueOnboarding_user\n    id\n  }\n  disclosures {\n    ...DocLink_disclosures\n  }\n  features {\n    launchPromoPrice\n  }\n  availableSubscriptionPlans(includePromotional: false) {\n    monthlyPrice {\n      dollars\n      cents\n    }\n    monthlyListPrice {\n      dollars\n      cents\n    }\n    yearlyPrice {\n      dollars\n      cents\n    }\n    yearlyListPrice {\n      dollars\n      cents\n    }\n    coverage {\n      formatted\n      raw\n    }\n    label\n    cadence\n    suggested\n  }\n}\n\nfragment DocLink_disclosures on Disclosures {\n  electronicCommunications {\n    url\n    version\n  }\n  mobileNetworkConsent {\n    url\n    version\n  }\n  patriotAct {\n    url\n    version\n  }\n  avibraPrivacyPolicy {\n    url\n    version\n  }\n  avibraTermsOfService {\n    url\n    version\n  }\n  avibraMembershipAgreement {\n    url\n    version\n  }\n  benefitsDisclosure {\n    url\n    version\n  }\n  plateauApplication {\n    url\n    version\n  }\n  privacyPolicy {\n    url\n    version\n  }\n  termsOfService {\n    url\n    version\n  }\n  benefitEligibility {\n    url\n    version\n  }\n  promotionalTerms {\n    url\n    version\n  }\n}\n\nfragment useContinueOnboarding_user on User {\n  lastCompletedOnboardingStep\n  status\n}\n"
  }
};
})();

(node as any).hash = "e0d76f8c8fbc7852d451c3679cd1baa2";

export default node;
