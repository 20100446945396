/**
 * @generated SignedSource<<886594f4c9e2d9dbd87853b21b9fe096>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LayoffClaimReason = "BusinessShutDown" | "CovidLayoffs" | "LackOfWork" | "Other" | "StrikeOrLockout" | "%future added value";
export type LayoffClaimStatus = "Approved" | "Denied" | "InReview" | "Pending" | "%future added value";
export type UpsertLayoffClaimInput = {
  additionalInfo?: string | null;
  authorizedAt?: any | null;
  changedJobsRecently?: boolean | null;
  employerEmail?: string | null;
  employerName?: string | null;
  employerPhone?: string | null;
  employmentEndDate?: string | null;
  employmentStartDate?: string | null;
  id?: string | null;
  minimumWeeklyHours?: boolean | null;
  occupation?: string | null;
  payStubs?: ReadonlyArray<string | null> | null;
  proofOfStateBenefitsApplication?: ReadonlyArray<string | null> | null;
  proofOfStateBenefitsPayment?: ReadonlyArray<string | null> | null;
  receivingStateBenefits?: boolean | null;
  returnedToWorkAtDate?: string | null;
  terminatedWithoutCause?: boolean | null;
  terminationReason?: LayoffClaimReason | null;
};
export type useNewClaimFlowMutation$variables = {
  input: UpsertLayoffClaimInput;
};
export type useNewClaimFlowMutation$data = {
  readonly upsertLayoffClaim: {
    readonly __typename: "LayoffClaim";
    readonly additionalInfo: string | null;
    readonly changedJobsRecently: boolean | null;
    readonly employerEmail: string | null;
    readonly employerName: string | null;
    readonly employerPhone: string | null;
    readonly employmentEndDate: string | null;
    readonly employmentStartDate: string | null;
    readonly fileUploads: ReadonlyArray<{
      readonly extension: string;
      readonly id: string | null;
      readonly originalFileName: string;
      readonly size: number;
      readonly type: string;
      readonly uri: string;
    } | null>;
    readonly id: string | null;
    readonly minimumWeeklyHours: boolean | null;
    readonly occupation: string | null;
    readonly payoutAccount: {
      readonly accountNumberMask: string;
      readonly bankName: string | null;
    } | null;
    readonly returnedToWorkAtDate: string | null;
    readonly status: LayoffClaimStatus;
    readonly terminatedWithoutCause: boolean | null;
    readonly terminationReason: LayoffClaimReason | null;
  } | {
    readonly __typename: "ValidationErrors";
    readonly messages: ReadonlyArray<{
      readonly field: string;
      readonly help: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useNewClaimFlowMutation = {
  response: useNewClaimFlowMutation$data;
  variables: useNewClaimFlowMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ValidationError",
      "kind": "LinkedField",
      "name": "messages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "field",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "help",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ValidationErrors",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalInfo",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "changedJobsRecently",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerEmail",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerPhone",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentEndDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentStartDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "returnedToWorkAtDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumWeeklyHours",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "occupation",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumberMask",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminatedWithoutCause",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminationReason",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "FileUpload",
  "kind": "LinkedField",
  "name": "fileUploads",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extension",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalFileName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useNewClaimFlowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "upsertLayoffClaim",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PayoutAccount",
                "kind": "LinkedField",
                "name": "payoutAccount",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "type": "LayoffClaim",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useNewClaimFlowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "upsertLayoffClaim",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PayoutAccount",
                "kind": "LinkedField",
                "name": "payoutAccount",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "type": "LayoffClaim",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "32b46e4df95c9f48071dc81b32874515",
    "id": null,
    "metadata": {},
    "name": "useNewClaimFlowMutation",
    "operationKind": "mutation",
    "text": "mutation useNewClaimFlowMutation(\n  $input: UpsertLayoffClaimInput!\n) {\n  upsertLayoffClaim(input: $input) {\n    __typename\n    ... on ValidationErrors {\n      messages {\n        field\n        help\n      }\n    }\n    ... on LayoffClaim {\n      id\n      additionalInfo\n      changedJobsRecently\n      employerEmail\n      employerName\n      employerPhone\n      employmentEndDate\n      employmentStartDate\n      returnedToWorkAtDate\n      minimumWeeklyHours\n      occupation\n      status\n      payoutAccount {\n        accountNumberMask\n        bankName\n        id\n      }\n      terminatedWithoutCause\n      terminationReason\n      fileUploads {\n        id\n        size\n        extension\n        originalFileName\n        uri\n        type\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "03b311ecf9e3f1cee73202cb0647b269";

export default node;
