import { SignupPageProps } from './SignupPageProps'

export const OnboardingSequence: Record<string, SignupPageProps> = {
  TheBasics: {
    step: 1,
    allowedLastOnboardingSteps: null,
    nextPath: '/signup/customize',
    previousPath: '/',
  },
  CustomizeIntro: {
    step: 2,
    allowedLastOnboardingSteps: ['CreateUser'],
    nextPath: '/signup/customize/seasonal',
    previousPath: null,
  },
  Seasonal: {
    step: 3,
    allowedLastOnboardingSteps: ['UpdateEmploymentInfo'],
    nextPath: '/signup/customize/hours',
    previousPath: '/signup/customize',
  },
  Hours: {
    step: 4,
    allowedLastOnboardingSteps: ['UpdateEmploymentInfo'],
    nextPath: '/signup/customize/w-2',
    previousPath: '/signup/customize/seasonal',
  },
  W2: {
    step: 5,
    allowedLastOnboardingSteps: ['UpdateEmploymentInfo'],
    nextPath: '/signup/customize/employer-info',
    previousPath: '/signup/customize/hours',
  },
  EmployerInfo: {
    step: 6,
    allowedLastOnboardingSteps: ['UpdateEmploymentInfo'],
    nextPath: '/signup/customize/my-plan',
    previousPath: '/signup/customize/w-2',
  },
  MyPlan: {
    step: 7,
    allowedLastOnboardingSteps: ['SubmitEmploymentInfo'],
    nextPath: '/signup/check-your-phone',
    previousPath: '/signup/customize/employer-info',
  },
  Plans: {
    step: 7,
    allowedLastOnboardingSteps: ['SubmitEmploymentInfo'],
    nextPath: '/signup/check-your-phone',
    previousPath: '/signup/customize/my-plan',
  },
  // Experiment step
  TheBasicsPhoneDob: {
    step: 8,
    allowedLastOnboardingSteps: ['SubmitEmploymentInfo'],
    nextPath: '/signup/check-your-phone',
    previousPath: '/signup/customize/my-plan',
  },
  CheckYourPhone: {
    step: 8,
    allowedLastOnboardingSteps: ['SubmitEmploymentInfo'],
    nextPath: '/signup/verify-personal-info',
    previousPath: '/signup/customize/employer-info',
  },
  VerifyPersonalInfo: {
    step: 9,
    allowedLastOnboardingSteps: ['VerifyDevice', 'VerifyPersonalInfo'],
    nextPath: '/signup/verify-personal-info',
    previousPath: null,
  },
  UpgradeAvibraPlan: {
    step: 9,
    allowedLastOnboardingSteps: ['VerifyPersonalInfo'],
    nextPath: null,
    previousPath: '/signup/verify-personal-info',
  },
  SeeYouLater: {
    step: 9,
    allowedLastOnboardingSteps: ['VerifyPersonalInfo'],
    nextPath: '/signup/upgrade-plan',
    previousPath: '/signup/upgrade-plan',
  },
} as const
