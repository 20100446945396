import {
  Link as L,
  Navigate as N,
  To,
  useLocation as useLoc,
  useNavigate as useNav,
} from 'react-router-dom'

export enum LocationStateError {
  PlaidAccountLinkingFailure,
}

type QuilLocationState = {
  error?: LocationStateError

  // for success/failure callbacks when there is more that one path
  // to a given page.
  redirectPath?: QuilLocationDescriptor
}

export type QuilLocationDescriptor =
  | string
  | { pathname: string; state: QuilLocationState }

export interface QuilNavigateFunction<CustomLocationState> {
  (to: To, options?: QuilNavigateOptions<CustomLocationState>): void
  (delta: number): void
}
export interface QuilNavigateOptions<CustomLocationState> {
  replace?: boolean
  state?: QuilLocationState & CustomLocationState
}

export function useNavigate<
  CustomLocationState extends Record<string, unknown>
>() {
  return useNav() as QuilNavigateFunction<CustomLocationState>
}

type QuilLocation<CustomLocationState> = ReturnType<typeof useLoc> & {
  state?: QuilLocationState & CustomLocationState
}

export function useLocation<
  CustomLocationState extends Record<string, unknown>
>() {
  return useLoc() as QuilLocation<CustomLocationState>
}

type LinkProps = Parameters<typeof L>[0] & {
  state?: QuilLocationState
}
export function Link(props: LinkProps) {
  return <L {...props} />
}

type NavigateProps = Parameters<typeof N>[0] & {
  state?: QuilLocationState
}
export function Navigate(props: NavigateProps) {
  return <N {...props} />
}
