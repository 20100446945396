/**
 * This is starting as a copy of the PlanCard component
 *
 * There is currently a lot of flux around how we want to display
 * the pricing and benefits of the US Alliance plans. This component
 * contains a lot of duplication from the PlanCard component, but
 * I want to keep them separate for now so that we can iterate quickly
 * on it without affecting the existing plans.
 *
 * TODO: Integrate this component with the existing PlanCard component
 * once we have a better idea of what the final design should look like.
 */

import styled from 'styled-components'
import { ShieldIllustration } from '../../illustrations'
import ButtonPrimary from '../ButtonPrimary'
import { InlineButtonContainer } from '../InlineButtonContainer'
import type { PlanCardColorScheme } from '../PlanCard'
import { PlanCardContainer } from '../PlanCard'

const Container = styled(PlanCardContainer)`
  svg {
    margin: 0 auto 1.6rem;
  }

  .price .cycle.year {
    right: 2rem;
  }
`

type Props = {
  price: {
    dollars: number
    cents: number
  }
  ctaText: string
  onClick: () => void
  benefitAmount: string
  colorScheme?: PlanCardColorScheme
  tagText?: string
  disabled?: boolean
  listPrice?: {
    dollars: number
    cents: number
  }
  cadence: string
}

export function PlanCardUsAlliance(props: Props) {
  const isDiscounted = 'listPrice' in props && !!props.listPrice
  const colorScheme = props.colorScheme || 'anakiwa'
  const listCents = `${props.listPrice?.cents}`.padStart(2, '0')
  const priceCents = `${props.price?.cents}`.padStart(2, '0')
  const cadenceLabel = props.cadence === 'Yearly' ? 'year' : 'month'

  return (
    <Container colorScheme={colorScheme}>
      <div className="card">
        {props.tagText && <div className="tag">{props.tagText}</div>}
        <ShieldIllustration />
        {isDiscounted && (
          <div className="list-price-container">
            <div className="price list-price">
              <span className="fade">
                ${props.listPrice?.dollars}
                <sup>{listCents}</sup>
                <span className={`cycle ${cadenceLabel}`}>/{cadenceLabel}</span>
              </span>
            </div>
          </div>
        )}
        <div className="price-container" data-discounted={isDiscounted}>
          <div className="price">
            ${props.price.dollars}
            <sup>{priceCents}</sup>
            <span className={`cycle ${cadenceLabel}`}>/{cadenceLabel}</span>
          </div>
        </div>
        <div className="benefit-highlight">
          <strong>includes {props.benefitAmount}</strong>
          <br />
          of Layoff Insurance
        </div>
        <InlineButtonContainer>
          <ButtonPrimary
            data-name="Plan Card Button"
            onClick={props.onClick}
            disabled={props.disabled}
          >
            {props.ctaText}
          </ButtonPrimary>
        </InlineButtonContainer>
      </div>
    </Container>
  )
}
