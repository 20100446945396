import { SpinnerFullScreen } from '@quil/ui'
import React from 'react'
import { useLocation } from '../../react-router-wrappers'
import { SuspendableErrorBoundary } from '../ErrorBoundary'
import { UnhandledError } from '../UnhandledError'

export const AppSuspendableErrorBoundary: React.FC<{
  children: React.ReactNode
}> = (props) => {
  const location = useLocation()
  return (
    <SuspendableErrorBoundary
      key={location.key}
      errorState={<UnhandledError />}
      fallback={<SpinnerFullScreen />}
    >
      {props.children}
    </SuspendableErrorBoundary>
  )
}
