import { css } from 'styled-components'

export type CenterableTextProps = {
  centered?: boolean
}

export function handleCenteredText(props: CenterableTextProps) {
  return (
    props.centered &&
    css`
      text-align: center;
    `
  )
}
