import { CloseMembershipMutation$variables } from './__generated__/CloseMembershipMutation.graphql'

type State = {
  values: Required<CloseMembershipMutation$variables>
  errors: Record<keyof CloseMembershipMutation$variables, string | null>
}

export const initialState: State = {
  values: {
    reason: null,
    detail: null,
  },
  errors: {
    reason: null,
    detail: null,
  },
}
