import * as event from '@quil/analytics-events'
import {
  BodyText,
  ButtonPrimary,
  Headline,
  InputEmail,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  TextLinkPrimary,
  useShowToast,
} from '@quil/ui'
import { useEffect, useRef, useState } from 'react'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import { graphql } from 'relay-runtime'
import styled from 'styled-components'
import { LayoutInAppTracked } from '../../common/analytics'
import * as analytics from '../../common/analytics/analytics-adapter'
import Form from '../../common/components/Form'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import Row from '../../common/components/Row'
import { isTouchDevice } from '../../common/isTouchDevice'
import { UnverifiedLabel } from '../SettingsOverview/UnverifiedLabel'
import { UpdateEmailMutation } from './__generated__/UpdateEmailMutation.graphql'
import { UpdateEmailQuery } from './__generated__/UpdateEmailQuery.graphql'

const EmailHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  align-items: center;
  margin-bottom: 2rem;
`

const UnverifiedEmailContainer = styled.div`
  margin-top: 1.2rem;

  ${TextLinkPrimary} {
    padding-bottom: 1rem;
    cursor: pointer;
    font-size: 1.6rem;
  }
`

const mutation = graphql`
  mutation UpdateEmailMutation($email: String!) {
    updateEmail(email: $email) {
      __typename
      ... on User {
        unverifiedEmail
      }
      ... on ValidationErrors {
        messages {
          field
          help
        }
      }
    }
  }
`

const query = graphql`
  query UpdateEmailQuery {
    content {
      profilePage(where: { name: "Update Email" }) {
        header
        body {
          raw
        }
        secondaryBody {
          raw
        }
        mainCtaText
        secondaryCtaText
      }
    }
    currentUser {
      email
      unverifiedEmail
    }
  }
`

export default function UpdateEmail() {
  const pageName = 'Update Email'
  const { currentUser, content } = useLazyLoadQuery<UpdateEmailQuery>(query, {})
  const [email, setEmail] = useState(currentUser.email)
  const [userUnverifiedEmail, setUserUnverifiedEmail] = useState(
    currentUser.unverifiedEmail
  )
  const [validationError, setValidationError] = useState('')
  const showToast = useShowToast()
  const [commitMutation, mutationInFlight] =
    useMutation<UpdateEmailMutation>(mutation)

  // autofocus on email on mount (on non-touch devices)
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!isTouchDevice()) {
      inputRef.current?.focus()
    }
  }, [])

  function handleResendUpdate() {
    if (mutationInFlight) {
      return
    }
    sendVerificationEmail(userUnverifiedEmail)
  }

  function handleSubmit() {
    if (mutationInFlight || email === currentUser.email) {
      return
    }
    setUserUnverifiedEmail(email)
    sendVerificationEmail(email)
  }

  function sendVerificationEmail(emailToVerify) {
    commitMutation({
      variables: { email: emailToVerify },
      onCompleted(response) {
        switch (response.updateEmail.__typename) {
          case 'User':
            showToast({
              severity: 'Success',
              headline: standardToastHeadlines.success,
              message: 'verification email sent',
            })
            analytics.track(event.userAccountUpdated('email'))
            break
          case 'ValidationErrors': {
            const emailError = response.updateEmail.messages.find(
              (message) => message.field === 'email'
            )

            if (!emailError) {
              showGenericError()
              analytics.track(event.userAccountUpdateFailed('email'))
            } else {
              showToast({
                severity: 'Error',
                headline: standardToastHeadlines.validationErrors,
                message: standardToastMessages.validationErrors,
              })
              setValidationError(emailError.help)
              analytics.track(
                event.userAccountUpdateFailed(
                  'email',
                  'invalidInput',
                  emailError.help
                )
              )
            }
          }
        }
      },
      onError: () => {
        showGenericError()
        analytics.track(event.userAccountUpdateFailed('email'))
      },
    })
  }

  function showGenericError() {
    showToast({
      severity: 'Error',
      headline: standardToastHeadlines.genericError,
      message: standardToastMessages.retry,
      onClick: handleSubmit,
    })
  }

  return (
    <>
      <LayoutInAppTracked pageName={pageName} headerStart={<GoBack />}>
        <EmailHeaderContainer>
          <Headline noMargin>{content.profilePage.header}</Headline>
          {!!userUnverifiedEmail && <UnverifiedLabel />}
        </EmailHeaderContainer>

        <RichText
          content={content.profilePage.body.raw}
          renderers={{ p: ({ children }) => <BodyText>{children}</BodyText> }}
        />
        <Form onSubmit={handleSubmit}>
          <Row>
            <InputEmail
              ref={inputRef}
              value={email}
              onChange={setEmail}
              error={validationError}
            />
          </Row>
        </Form>
        <ButtonPrimary
          data-page-name={pageName}
          data-name="Primary CTA"
          onClick={handleSubmit}
        >
          {content.profilePage.mainCtaText}
        </ButtonPrimary>
        {userUnverifiedEmail && (
          <UnverifiedEmailContainer>
            <div>
              <TextLinkPrimary
                onClick={handleResendUpdate}
                data-name="Resend email verification"
                data-page-name={pageName}
              >
                {content.profilePage.secondaryCtaText}
              </TextLinkPrimary>
            </div>
            {userUnverifiedEmail && !!currentUser.email && (
              <RichText
                content={content.profilePage.secondaryBody.raw}
                data={{ unverifiedEmail: userUnverifiedEmail }}
                renderers={{
                  p: ({ children }) => <BodyText>{children}</BodyText>,
                }}
              />
            )}
          </UnverifiedEmailContainer>
        )}
      </LayoutInAppTracked>
      <ProcessingOverlay processing={mutationInFlight} />
    </>
  )
}
