import React from 'react'
import styled, { css } from 'styled-components'
import { fromTheme } from '../../theme'
import { AppHeader, AppHeaderSegment } from '../AppHeader'
import Logo from '../Logo'
import PageFooter from '../PageFooter'
import { Headline } from '../typography'

type Props = {
  title?: React.ReactNode
  headerStart?: React.ReactNode
  headerEnd?: React.ReactNode
  footerContent?: React.ReactNode
  legalContent?: React.ReactNode
  'data-testid'?: string
  children: React.ReactNode
  belowFooter?: React.ReactNode
  className?: string
}

const MainContainer = styled.main<{ collapseOnDesktop?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${(props) =>
    props.collapseOnDesktop
      ? css`
          @media (min-width: ${fromTheme(
              ({ breakpoints }) => breakpoints.medium
            )}px) {
            min-height: 0;
          }
        `
      : null}
`

const InnerContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 60rem;
  width: 100%;
`

const LegalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Body = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 ${fromTheme(({ spacing }) => spacing.horizontalPadding)};
`

const StyledHeadline = styled(Headline)`
  margin-bottom: 1.6rem;
`

const ChildrenContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const LayoutSignupSingleColumn: React.FC<Props> = (props) => (
  <OuterContainer className={props.className}>
    <MainContainer
      data-testid={`${props['data-testid']}_container`}
      collapseOnDesktop={!!props.belowFooter}
    >
      <AppHeader>
        <AppHeaderSegment>{props.headerStart}</AppHeaderSegment>
        <AppHeaderSegment>
          <Logo />
        </AppHeaderSegment>
        <AppHeaderSegment>{props.headerEnd}</AppHeaderSegment>
      </AppHeader>
      <InnerContainer>
        <Body>
          {props.title ? <StyledHeadline>{props.title}</StyledHeadline> : null}
          <ChildrenContainer>{props.children}</ChildrenContainer>
          <LegalContainer>
            {props.legalContent ? <div>{props.legalContent}</div> : null}
          </LegalContainer>
        </Body>

        {props.footerContent ? (
          <PageFooter>{props.footerContent}</PageFooter>
        ) : null}
      </InnerContainer>
    </MainContainer>
    {props.belowFooter ? <Body>{props.belowFooter}</Body> : null}
  </OuterContainer>
)

export default LayoutSignupSingleColumn
