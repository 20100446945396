/**
 * @generated SignedSource<<aeb16c26fe89dc0ffe026ee55a71f882>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LayoffClaimReason = "BusinessShutDown" | "CovidLayoffs" | "LackOfWork" | "Other" | "StrikeOrLockout" | "%future added value";
export type LayoffClaimStatus = "Approved" | "Denied" | "InReview" | "Pending" | "%future added value";
export type LayoffProtectionStatus = "Approved" | "ContactSupport" | "Eligible" | "InReview" | "Pending" | "Waiting" | "%future added value";
export type NewClaimIntroQuery$variables = {};
export type NewClaimIntroQuery$data = {
  readonly content: {
    readonly newClaimPage: {
      readonly body: {
        readonly raw: any;
      };
      readonly heading: string;
      readonly mainCtaText: string | null;
    } | null;
  };
  readonly currentUser: {
    readonly id: string | null;
    readonly layoffProtectionStatus: LayoffProtectionStatus;
  } | null;
  readonly pendingLayoffClaim: {
    readonly additionalInfo: string | null;
    readonly changedJobsRecently: boolean | null;
    readonly employerEmail: string | null;
    readonly employerName: string | null;
    readonly employerPhone: string | null;
    readonly employmentEndDate: string | null;
    readonly employmentStartDate: string | null;
    readonly id: string | null;
    readonly minimumWeeklyHours: boolean | null;
    readonly occupation: string | null;
    readonly payoutAccount: {
      readonly accountNumberMask: string;
    } | null;
    readonly receivingStateBenefits: boolean | null;
    readonly status: LayoffClaimStatus;
    readonly submittedAt: {
      readonly formatted: string;
    } | null;
    readonly terminatedWithoutCause: boolean | null;
    readonly terminationReason: LayoffClaimReason | null;
  } | null;
};
export type NewClaimIntroQuery = {
  response: NewClaimIntroQuery$data;
  variables: NewClaimIntroQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "layoffProtectionStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumWeeklyHours",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "changedJobsRecently",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminatedWithoutCause",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivingStateBenefits",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "occupation",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentStartDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentEndDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminationReason",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalInfo",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerEmail",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employerPhone",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumberMask",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Instant",
  "kind": "LinkedField",
  "name": "submittedAt",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formatted",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Intro"
    }
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewClaimIntroQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "pendingLayoffClaim",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v17/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": "newClaimPage(where:{\"name\":\"Intro\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewClaimIntroQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LayoffClaim",
        "kind": "LinkedField",
        "name": "pendingLayoffClaim",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutAccount",
            "kind": "LinkedField",
            "name": "payoutAccount",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v17/*: any*/),
            "concreteType": "Cms_NewClaimPage",
            "kind": "LinkedField",
            "name": "newClaimPage",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": "newClaimPage(where:{\"name\":\"Intro\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f1e44fc515f3b87b17df87fb38ac88a",
    "id": null,
    "metadata": {},
    "name": "NewClaimIntroQuery",
    "operationKind": "query",
    "text": "query NewClaimIntroQuery {\n  currentUser {\n    id\n    layoffProtectionStatus\n  }\n  pendingLayoffClaim {\n    id\n    status\n    minimumWeeklyHours\n    changedJobsRecently\n    terminatedWithoutCause\n    receivingStateBenefits\n    employerName\n    occupation\n    employmentStartDate\n    employmentEndDate\n    terminationReason\n    additionalInfo\n    employerEmail\n    employerPhone\n    payoutAccount {\n      accountNumberMask\n      id\n    }\n    submittedAt {\n      formatted\n    }\n  }\n  content {\n    newClaimPage(where: {name: \"Intro\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "90c0ae55770e944a0d4a913d1bb20ff4";

export default node;
