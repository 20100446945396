import styled from 'styled-components'
import { fromTheme } from '../../theme'
import ButtonPrimary from '../ButtonPrimary'

const PageFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${fromTheme(({ spacing }) => spacing.horizontalPadding)} 4.4rem;

  ${ButtonPrimary} {
    margin-bottom: 1.2rem;
    width: 100%;
  }
`

export default PageFooter
