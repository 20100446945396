import React from 'react'
import styled from 'styled-components'
import { fromTheme } from '../../theme'
import { AppHeader, AppHeaderSegment } from '../AppHeader'
import { InlineButtonContainer } from '../InlineButtonContainer'
import Logo from '../Logo'
import PageFooter from '../PageFooter'
import { Headline } from '../typography'

type Props = {
  title?: React.ReactNode
  headerStart?: React.ReactNode
  headerEnd?: React.ReactNode
  footerContent?: React.ReactNode
  'data-testid'?: string
  children: React.ReactNode
}

const OuterContainer = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  // clearance for fixed nav
  padding-bottom: ${fromTheme(({ spacing }) => spacing.navClearance)};
`

const InnerContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 60rem;
  width: 100%;
  height: 100%;

  /* Push to bottom, and clear bottom-padding */
  ${PageFooter} {
    margin-top: auto;
    padding-bottom: 0;

    ${InlineButtonContainer} {
      margin-bottom: 0;
    }
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${fromTheme(({ spacing }) => spacing.horizontalPadding)};
  flex-grow: 1;
`

const StyledHeadline = styled(Headline)`
  margin-bottom: 1.6rem;
`

const ChildrenContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const LayoutInAppSingleColumn: React.FC<Props> = (props) => (
  <OuterContainer data-testid={`${props['data-testid']}_container`}>
    <AppHeader>
      <AppHeaderSegment>{props.headerStart}</AppHeaderSegment>
      <AppHeaderSegment>
        <Logo />
      </AppHeaderSegment>
      <AppHeaderSegment>{props.headerEnd}</AppHeaderSegment>
    </AppHeader>
    <InnerContainer>
      <Body>
        {props.title ? <StyledHeadline>{props.title}</StyledHeadline> : null}
        <ChildrenContainer>{props.children}</ChildrenContainer>
      </Body>
      {props.footerContent ? (
        <PageFooter>{props.footerContent}</PageFooter>
      ) : null}
    </InnerContainer>
  </OuterContainer>
)

export default LayoutInAppSingleColumn
