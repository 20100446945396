import * as event from '@quil/analytics-events'
import {
  ButtonPrimary,
  InputEmail,
  IntroBlock,
  LegalText,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useRef, useState } from 'react'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LayoutSignupTracked } from '../../common/analytics'
import * as analytics from '../../common/analytics/analytics-adapter'
import { useTrackedState } from '../../common/analytics/useTrackedState'
import DocLink from '../../common/components/DocLink'
import Form from '../../common/components/Form'
import GoBack from '../../common/components/GoBack'
import { getOrCreateGuestID } from '../../common/experimentContext'
import { isTouchDevice } from '../../common/isTouchDevice'
import { SignupPageProps } from '../../Signup/SignupPageProps'
import SignupProgressTracker from '../../Signup/SignupProgressTracker'
import {
  parseUrlParams,
  preSubMutation,
  theBasicsQuery,
  ValidationError,
} from '../../Signup/TheBasics'
import { TheBasicsPreSubMutation } from '../../Signup/TheBasics/__generated__/TheBasicsPreSubMutation.graphql'
import { TheBasicsQuery } from '../../Signup/TheBasics/__generated__/TheBasicsQuery.graphql'
import * as testIds from './test-ids'
import { TheBasicsEmailMutation } from './__generated__/TheBasicsEmailMutation.graphql'

const theBasicsEmailMutation = graphql`
  mutation TheBasicsEmailMutation(
    $data: UserCreateExpInput!
    $attribution: AttributionInput
  ) {
    createUserExp(
      data: $data
      attribution: $attribution
      acceptedAgreements: true
    ) {
      __typename
      ... on User {
        id
        status
        email
      }
      ... on ValidationErrors {
        messages {
          field
          help
        }
      }
      ... on PhoneNumberNotAvailable {
        message
      }
    }
  }
`

function TheBasicsEmail({ step, nextPath, previousPath }: SignupPageProps) {
  const pageName = 'The Basics Email'
  const showToast = useShowToast()
  const ldClient = useLDClient()

  const data = useLazyLoadQuery<TheBasicsQuery>(theBasicsQuery, {})

  const [email, setEmail] = useTrackedState('', (value) =>
    event.inputChanged('Signup: Email', { email: value })
  )

  const [emailValidationError, setEmailValidationError] = useState<
    string | null
  >(null)

  const [searchParams] = useSearchParams()

  // autofocus email input on mount (on non touch-devices)
  const emailInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!isTouchDevice()) {
      emailInputRef.current?.focus()
    }
  }, [])

  function clearValidationErrors() {
    setEmailValidationError(null)
  }

  const navigate = useNavigate()
  const [commitTheBasicsEmailMutation, theBasicsEmailMutationInflight] =
    useMutation<TheBasicsEmailMutation>(theBasicsEmailMutation)

  const [commitPreSubMutation] =
    useMutation<TheBasicsPreSubMutation>(preSubMutation)

  const formFilled = !!email

  function setValidationError(message: ValidationError | null) {
    if (message?.field === 'email') {
      return setEmailValidationError(message.help)
    }
  }

  function handleNextClick() {
    const { attribution, preselectedCoverage, billingCadence } =
      parseUrlParams(searchParams)

    analytics.track(event.formSubmitted('The Basics | Email'))

    clearValidationErrors()

    commitTheBasicsEmailMutation({
      variables: {
        data: {
          email,
        },
        attribution,
      },
      async onCompleted(response) {
        clearValidationErrors()

        switch (response.createUserExp?.__typename) {
          case 'User':
            {
              const { id, status } = response.createUserExp

              // Note that User Account Created is tracked on the server.
              // User is also identified with traits on the server.
              //
              // this identify call is made on the front to link the existing anonymous id with the real user id.
              await analytics.identify(id)

              // identify with LD so correct context is used
              await ldClient.identify({
                kind: 'multi',
                user: {
                  key: id,
                  status,
                },
                guest: {
                  key: getOrCreateGuestID(),
                },
              })
              await ldClient.track('user_created')

              // if preselected coverage was retained from marketing site, set it now that we have a user
              if (preselectedCoverage) {
                commitPreSubMutation({
                  variables: {
                    subscriptionChoiceInput: {
                      // coverage amounts received from marketing site in this way come in dollars
                      // while we need to send cents to our api
                      coverage: preselectedCoverage * 100,
                      cadence: billingCadence,
                    },
                  },
                  async onError(error) {
                    console.error({
                      error,
                      mutation: 'TheBasicsPreSubMutation',
                    })
                  },
                })
              }

              navigate(nextPath)
            }
            break
          case 'ValidationErrors':
            showToast({
              severity: 'Error',
              headline: standardToastHeadlines.validationErrors,
              message: standardToastMessages.validationErrors,
            })
            response.createUserExp.messages?.forEach(setValidationError)
            analytics.track(
              event.userAccountCreationFailed(
                JSON.stringify(response.createUserExp.messages)
              )
            )
            break
          case 'PhoneNumberNotAvailable':
            navigate('/login')
            analytics.track(
              event.userAccountCreationFailed(
                JSON.stringify(response.createUserExp.message)
              )
            )
            break
          default:
            analytics.track(event.userAccountCreationFailed())
        }
      },

      onError(error) {
        console.error({ error, mutation: 'createUserExp' })
        clearValidationErrors()
        analytics.track(event.userAccountCreationFailed())
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.genericError,
          message: standardToastMessages.retry,
          onClick: handleNextClick,
        })
      },

      // associate this user id with Query.currentUser in the relay store
      updater(store, payload) {
        if (payload.createUserExp.__typename === 'User') {
          const currentUser = store.get(payload.createUserExp.id)
          store.getRoot().setLinkedRecord(currentUser, 'currentUser')
        }
      },
    })
  }

  return (
    <>
      <LayoutSignupTracked
        pageName={pageName}
        title={'Welcome to Quil!'}
        data-testid={testIds.page}
        headerStart={previousPath && <GoBack />}
        headerEnd={<SignupProgressTracker step={step} />}
        footerContent={
          <ButtonPrimary
            data-page-name={pageName}
            data-name="Primary CTA"
            onClick={handleNextClick}
            disabled={!formFilled || theBasicsEmailMutationInflight}
            data-testid={testIds.primaryCta}
          >
            {data.content.onboardingPage.mainCtaText}
          </ButtonPrimary>
        }
        legalContent={
          <>
            <LegalText>
              By applying for Quil, you agree to Quil’s{' '}
              <DocLink
                disclosures={data.disclosures}
                kind="electronicCommunications"
              >
                Electronic Communication
              </DocLink>
              ,{' '}
              <DocLink
                disclosures={data.disclosures}
                kind="mobileNetworkConsent"
              >
                Mobile Network Consent
              </DocLink>
              ,{' '}
              <DocLink disclosures={data.disclosures} kind="privacyPolicy">
                Privacy Policy
              </DocLink>{' '}
              and the{' '}
              <DocLink disclosures={data.disclosures} kind="patriotAct">
                Patriot Act
              </DocLink>
              .
            </LegalText>
            <LegalText>We don’t sell your data to any third parties.</LegalText>
            <LegalText>Message and data rates may apply.</LegalText>
          </>
        }
      >
        <IntroBlock>Let's protect your income.</IntroBlock>
        <Form onSubmit={handleNextClick}>
          <InputEmail
            value={email}
            onChange={setEmail}
            error={emailValidationError ?? ''}
            data-testid={testIds.emailField}
            ref={emailInputRef}
          />
        </Form>
      </LayoutSignupTracked>
      <ProcessingOverlay processing={theBasicsEmailMutationInflight} />
    </>
  )
}

export default TheBasicsEmail
