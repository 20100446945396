import React, { useContext, useEffect, useState } from 'react'
import {
  css,
  SimpleInterpolation,
  ThemeProvider as StyledComponentsThemeProvider,
  useTheme,
} from 'styled-components'

type HslColor = {
  hue: string
  saturation: string
  lightness: string
  raw: string
  hex: string
  toString(): string
}

function hsl(h: number, s: number, l: number): HslColor {
  const hue = `${h}deg`
  const saturation = `${s}%`
  const lightness = `${l}%`
  const raw = `hsl(${hue} ${saturation} ${lightness})`
  const hex = hslToHex(h, s, l)

  return { hue, saturation, lightness, raw, hex, toString: () => raw }
}

// from https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
function hslToHex(h: number, s: number, l: number) {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = (n: number) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

const colors = {
  // primary
  violet: {
    main: hsl(264, 74, 7),
  },
  minsk: {
    main: hsl(263, 40, 35),
    alt1: hsl(263, 40, 95),
  },
  topaz: { main: hsl(261, 6, 54) },
  white: { main: hsl(0, 0, 100) },

  // utility
  // colors with multiple variations are a "color family", where main = standard brand color, and alt1 = a lighter variation
  torch: {
    main: hsl(342, 100, 50),
    alt1: hsl(342, 100, 98),
    alt2: hsl(342, 100, 95),
  },
  kournikova: {
    main: hsl(47, 100, 73),
    alt1: hsl(48, 100, 98),
  },
  emerald: {
    main: hsl(153, 51, 59),
    alt1: hsl(156, 45, 98),
    alt2: hsl(153, 51, 95),
  },
  anakiwa: {
    main: hsl(194, 100, 83),
    alt1: hsl(192, 100, 98),
  },
  onahau: { main: hsl(194, 100, 91) },
} as const

const breakpoints = {
  small: 400,
  medium: 600,
  large: 900,
}

const spacing = {
  navClearance: '12rem',
  horizontalPadding: '2rem',
}

export type QuilTheme = {
  breakpoints: typeof breakpoints
  spacing: typeof spacing
  colors: {
    // Some components do not change between themes.
    // For simplicity, these components are allowed to directly access the branded color palette
    palette: typeof colors
    background: HslColor

    foregroundPrimary: HslColor
    foregroundHighlight: HslColor
    foregroundDanger: HslColor
    foregroundMuted: HslColor
    foregroundMutedFocused: HslColor

    textPrimary: HslColor
    textLink: HslColor

    buttonPrimaryText: HslColor
    shadow: HslColor

    avibraBadgeBackground: HslColor
  }
}

export const quilThemeLight: QuilTheme = {
  breakpoints,
  spacing,
  colors: {
    palette: colors,
    background: colors.white.main,

    foregroundPrimary: colors.minsk.main,
    foregroundHighlight: colors.emerald.main,
    foregroundDanger: colors.torch.main,
    foregroundMuted: colors.topaz.main,
    foregroundMutedFocused: colors.minsk.main,

    textPrimary: colors.violet.main,
    textLink: colors.minsk.main,

    buttonPrimaryText: colors.onahau.main,
    shadow: colors.violet.main,

    // This is a one-off color defined by Avibra's styleguide; not included in Quil colors
    avibraBadgeBackground: hsl(253.33, 52.94, 96.67),
  },
}

// user/system selectable dark mode
export const quilThemeDark: QuilTheme = {
  breakpoints,
  spacing,
  colors: {
    palette: colors,
    background: colors.violet.main,

    foregroundPrimary: colors.minsk.main,
    foregroundHighlight: colors.emerald.main,
    foregroundDanger: colors.torch.main,
    foregroundMuted: colors.topaz.main,
    foregroundMutedFocused: colors.white.main,

    textPrimary: colors.white.main,
    textLink: colors.onahau.main,

    buttonPrimaryText: colors.onahau.main,
    shadow: colors.violet.main,

    avibraBadgeBackground: hsl(253.33, 52.94, 96.67),
  },
}

// Not a user/system selectable theme - The app will switch to this theme for pages like the welcome screen and customization flow.
export const quilThemeVivid: QuilTheme = {
  breakpoints,
  spacing,
  colors: {
    palette: colors,
    background: colors.minsk.main,

    foregroundPrimary: colors.violet.main,
    foregroundHighlight: colors.emerald.main,
    foregroundDanger: colors.torch.main,
    foregroundMuted: colors.topaz.main,
    foregroundMutedFocused: colors.white.main,

    textPrimary: colors.white.main,
    textLink: colors.onahau.main,

    buttonPrimaryText: colors.onahau.main,
    shadow: colors.violet.main,

    avibraBadgeBackground: hsl(253.33, 52.94, 96.67),
  },
}

const themes = {
  quilThemeLight,
  quilThemeVivid,
  quilThemeDark,
}

type ThemeName = keyof typeof themes

type Props = {
  children: JSX.Element | JSX.Element[]
  override?: ThemeName
}

type ThemeManager = {
  set(theme: ThemeName): void
}
const ThemeManagerContext = React.createContext<null | ThemeManager>(null)

export function ThemeProvider(props: Props) {
  // future enhancement: use system or user defined light/dark
  const [currentTheme, set] = useState<ThemeName>('quilThemeLight')

  return (
    <ThemeManagerContext.Provider value={{ set }}>
      <StyledComponentsThemeProvider
        theme={themes[props.override ?? currentTheme]}
      >
        {props.children}
      </StyledComponentsThemeProvider>
    </ThemeManagerContext.Provider>
  )
}

type StyledComponentProps = { theme: QuilTheme }

export function hsla(
  { hue, saturation, lightness }: HslColor,
  opacity: number
) {
  return `hsl(${hue} ${saturation} ${lightness} / ${opacity})`
}

export function fromTheme<Props>(
  fn: (
    theme: QuilTheme,
    props: Props & StyledComponentProps
  ) => SimpleInterpolation
) {
  return (props: Props & StyledComponentProps) => fn(props.theme, props)
}

type Breakpoint = keyof typeof breakpoints
export function atBreakpoint<Props>(
  breakpoint: Breakpoint,
  fn: (props: Props & StyledComponentProps) => SimpleInterpolation
) {
  return (props: Props & StyledComponentProps) => css`
    @media (min-width: ${props.theme.breakpoints[breakpoint]}px) {
      ${fn}
    }
  `
}

export const useQuilTheme = useTheme as () => QuilTheme

export function useThemeManager() {
  const manager = useContext(ThemeManagerContext)

  if (!manager) {
    throw new Error(
      'attempted to access theme manager outside of ThemeProvider'
    )
  }

  return manager
}

export function useAlternateTheme(theme: ThemeName) {
  const themeManager = useThemeManager()
  useEffect(() => {
    themeManager.set(theme)

    return () => {
      // future enhancement: use system or user defined light/dark
      themeManager.set('quilThemeLight')
    }
  }, [themeManager, theme])
}
