import { BodyText, LayoutSignupSingleColumn, TextLinkInline } from '@quil/ui'
export function UnhandledError() {
  return (
    <LayoutSignupSingleColumn title="Sorry, something went wrong.">
      <BodyText>
        Try{' '}
        <TextLinkInline
          href="/welcome"
          data-name="Log in"
          data-page-name="Unhandled error"
        >
          logging in
        </TextLinkInline>{' '}
        again.
      </BodyText>
    </LayoutSignupSingleColumn>
  )
}
