/**
 * @generated SignedSource<<145879556c3380eca7ffe32cb3d46570>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmployerInfoQuery$variables = {};
export type EmployerInfoQuery$data = {
  readonly content: {
    readonly onboardingPage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
  };
};
export type EmployerInfoQuery = {
  response: EmployerInfoQuery$data;
  variables: EmployerInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "Employer Info"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmployerInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Employer Info\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EmployerInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Employer Info\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6142453d11844e247d3b1a974cd12ee0",
    "id": null,
    "metadata": {},
    "name": "EmployerInfoQuery",
    "operationKind": "query",
    "text": "query EmployerInfoQuery {\n  content {\n    onboardingPage(where: {pageName: \"Employer Info\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "889620bb22ba04226edfd85918e4da9b";

export default node;
