import styled from 'styled-components'
import { BenefitsIllustration } from '../../illustrations'
import { fromTheme } from '../../theme'
import ButtonPrimary from '../ButtonPrimary'
import { InlineButtonContainer } from '../InlineButtonContainer'

export type PlanCardColorScheme = 'anakiwa' | 'kournikova' | 'emerald'

type StyledProps = {
  colorScheme: PlanCardColorScheme
}

// design has confirmed that these are one-off colors for now
function lightColor(props: StyledProps) {
  switch (props.colorScheme) {
    case 'anakiwa':
      return '#D3F5FF'
    case 'kournikova':
      return '#FFEEB3'
    case 'emerald':
      return '#C5ECDB'
  }
}

export const PlanCardContainer = styled.div<StyledProps>`
  margin: 1.6rem auto 2.4rem;
  max-width: 400px;
  width: 100%;

  .card {
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 3.2rem 1.6rem 1.6rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;

    @media (min-width: ${fromTheme(({ breakpoints }) => breakpoints.small)}px) {
      padding: 1.6rem;
    }
  }

  .tag {
    color: ${fromTheme(({ colors }) => colors.palette.violet.main.raw)};
    font-weight: 700;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: ${fromTheme(
      ({ colors }) => colors.palette.kournikova.main.raw
    )};
    border-radius: 2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 0.4rem 1.4rem;
  }

  svg {
    display: block;
    margin: 0 auto;

    .medium {
      fill: ${fromTheme(
        ({ colors }, { colorScheme }) => colors.palette[colorScheme].main.hex
      )};
    }

    .light {
      fill: ${lightColor};
    }
  }

  .price-container,
  .list-price-container {
    text-align: center;
  }

  .price-container[data-discounted='true'] {
    margin-top: -1rem;
  }

  .price,
  .price sup {
    font-family: 'Unbounded';
    font-weight: 700;
  }

  .price {
    margin-left: -0.6rem;
    font-size: 5.4rem;
    position: relative;
    display: inline-block;

    .cycle {
      font-size: 1.6rem;
      font-weight: 700;
      position: absolute;
      bottom: 0.6rem;
      right: 0rem;
    }
  }

  sup {
    font-size: 3.2rem;
    vertical-align: top;
    position: relative;
    top: 0.8rem;
  }

  .list-price {
    transform: scale(0.7);
    margin-top: -2.4rem;

    .fade {
      font-family: inherit;
      opacity: 0.5;
    }

    ::after {
      content: '';
      display: block;
      width: calc(100% + 1.2rem);
      height: 4px;
      background-color: ${fromTheme(({ colors }) => colors.textPrimary.raw)};
      position: absolute;
      top: 50%;
      left: -0.6rem;
    }
  }

  .benefit-highlight {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  ${InlineButtonContainer} {
    margin: 0;
  }

  ${ButtonPrimary} {
    color: ${fromTheme(({ colors }) => colors.palette.violet.main.raw)};
    background-color: ${fromTheme(
      ({ colors }, { colorScheme }) => colors.palette[colorScheme].main.raw
    )};
  }

  .price-disclaimer {
    padding-top: 2rem;
    font-size: 1.2rem;
    text-align: center;
  }

  .price-disclaimer-asterisk {
    vertical-align: top;
    position: relative;
    top: 0.8rem;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2rem;
  }
`
type Props = {
  price: {
    dollars: number
    cents: number
  }
  ctaText: string
  onClick: () => void
  benefitAmount: string
  colorScheme?: PlanCardColorScheme
  tagText?: string
  disabled?: boolean
  priceDisclaimer?: string
  listPrice?: {
    dollars: number
    cents: number
  }
}

export function PlanCard(props: Props) {
  const isDiscounted = 'listPrice' in props && !!props.listPrice
  const colorScheme = props.colorScheme || 'anakiwa'

  return (
    <PlanCardContainer colorScheme={colorScheme}>
      <div className="card">
        {props.tagText && <div className="tag">{props.tagText}</div>}
        <BenefitsIllustration />
        {isDiscounted && (
          <div className="list-price-container">
            <div className="price list-price">
              <span className="fade">
                ${props.listPrice?.dollars}
                <sup>{props.listPrice?.cents}</sup>
                <span className="cycle">/month</span>
              </span>
            </div>
          </div>
        )}
        <div className="price-container" data-discounted={isDiscounted}>
          <div className="price">
            ${props.price.dollars}
            <sup>{props.price.cents}</sup>
            <span className="cycle">/month</span>
          </div>
          <sup className="price-disclaimer-asterisk">
            {props.priceDisclaimer ? '*' : null}
          </sup>
        </div>
        <div className="benefit-highlight">
          <strong>includes {props.benefitAmount}</strong>
          <br />
          of Layoff Insurance
        </div>
        <InlineButtonContainer>
          <ButtonPrimary
            data-name="Plan Card Button"
            onClick={props.onClick}
            disabled={props.disabled}
          >
            {props.ctaText}
          </ButtonPrimary>
        </InlineButtonContainer>
        {props.priceDisclaimer ? (
          <div className="price-disclaimer">*{props.priceDisclaimer}</div>
        ) : null}
      </div>
    </PlanCardContainer>
  )
}
