/**
 * @generated SignedSource<<86392bfe2e2f93d8dcc4164fc2039fe1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NoticeEventDisplayType = "Alert" | "Modal" | "%future added value";
export type NoticeEventSeverity = "Error" | "Informational" | "Success" | "Warning" | "%future added value";
export type NoticeType = "AllLpBenefitsUsed" | "FailedPayment" | "LpInformationRequested" | "LpRequestApproved" | "LpRequestDenied" | "LpRequestInProgress" | "LpRequestReceived" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Alerts_query$data = {
  readonly noticeEvents: ReadonlyArray<{
    readonly content: string;
    readonly deliveredAt: any | null;
    readonly displayType: NoticeEventDisplayType;
    readonly headline: string;
    readonly id: string | null;
    readonly linkText: string | null;
    readonly noticeType: NoticeType;
    readonly precedence: number;
    readonly severity: NoticeEventSeverity;
  }>;
  readonly " $fragmentType": "Alerts_query";
};
export type Alerts_query$key = {
  readonly " $data"?: Alerts_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"Alerts_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Alerts_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NoticeEvent",
      "kind": "LinkedField",
      "name": "noticeEvents",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveredAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "noticeType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "precedence",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "severity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkText",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b372afd9639e0c064984cf64d98c6141";

export default node;
