import * as event from '@quil/analytics-events'
import {
  BodyText,
  ButtonPrimary,
  InputPhone,
  InputText,
  SpinnerFullScreen,
  standardToastHeadlines,
  useShowToast,
} from '@quil/ui'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useRef, useState } from 'react'
import { graphql, useMutation } from 'react-relay'
import styled from 'styled-components'
import { LayoutSignupTracked } from '../common/analytics'
import * as analytics from '../common/analytics/analytics-adapter'
import Form from '../common/components/Form'
import GoBack from '../common/components/GoBack'
import Row from '../common/components/Row'
import useResumeOnboarding from '../common/components/useResumeOnboarding'
import { getOrCreateGuestID } from '../common/experimentContext'
import { isTouchDevice } from '../common/isTouchDevice'
import { useMountEffect } from '../common/react-hook-wrappers'
import { useLocation, useNavigate } from '../common/react-router-wrappers'
import { useDocumentTitle } from '../common/useDocumentTitle'
import {
  LoginFailureType,
  LoginMutation,
} from './__generated__/LoginMutation.graphql'
import * as testIds from './test-ids'

const BreakWord = styled.span`
  word-break: break-all;
`

const mutation = graphql`
  mutation LoginMutation($phone: String, $email: String, $emailOTP: String) {
    login(phone: $phone, email: $email, emailOTP: $emailOTP) {
      __typename
      ... on LoginFailure {
        message
        type
      }
      ... on LoginRequiresEmailOTP {
        email
        emailSent
      }
      ... on LoginSuccess {
        user {
          id
          firstName
          lastName
          status
          state
          dob
          email
          lastCompletedOnboardingStep
        }
      }
    }
  }
`

type LoginState = {
  phone?: string
}

function Login() {
  const pageName = 'Log In'

  useDocumentTitle('Log In')

  const showToast = useShowToast()
  const inputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const resumeOnboarding = useResumeOnboarding()
  const location = useLocation<LoginState>()
  const ldClient = useLDClient()

  const [phone, setPhone] = useState(location.state?.phone ?? '')
  const [email, setEmail] = useState('')
  const [emailOtp, setEmailOtp] = useState('')
  const [emailOtpError, setEmailOtpError] = useState('')

  // on touch devices, autofocus on each step *change*
  // on non-touch devices, autofocus on each step
  const [step, setStep] = useState<'phoneEntry' | 'emailOtpEntry'>('phoneEntry')
  useEffect(() => {
    if (!(step === 'phoneEntry' && isTouchDevice())) {
      inputRef.current?.focus()
    }
  }, [step])

  const [instructionMessage, setInstructionMessage] =
    useState<React.ReactNode>('')
  const [commitMutation, mutationInFlight] =
    useMutation<LoginMutation>(mutation)

  function displayGenericError() {
    showToast({
      severity: 'Error',
      headline: standardToastHeadlines.genericError,
      message: 'Please check your code and try again.',
      testId: testIds.toastMessage,
    })
  }

  function displayLoginError(message: string, failureType: LoginFailureType) {
    switch (failureType) {
      // locked shows contact support link instead of retry
      case 'Locked':
        showToast({
          severity: 'Error',
          headline: 'Please stand by!',
          message: (
            <>
              We're reviewing your account and will get back to you — look out
              for an email from Quil's support team
            </>
          ),
          testId: testIds.toastMessage,
        })

        break

      // ineligible shows server message but without retry
      case 'Ineligible':
      case 'Closed':
        showToast({
          severity: 'Error',
          headline: 'Your account is closed',
          message,
          testId: testIds.toastMessage,
        })

        break

      // other types show server error
      default:
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.genericError,
          message,
          testId: testIds.toastMessage,
        })
    }
  }

  function clearErrors() {
    setEmailOtpError('')
  }

  function handleSubmit() {
    if (step === 'emailOtpEntry' && emailOtp === '') {
      setEmailOtpError('Please enter the code we sent to your email')
      return
    }

    clearErrors()

    commitMutation({
      variables: { phone, email, emailOTP: emailOtp },
      async onCompleted(data) {
        switch (data.login.__typename) {
          case 'LoginFailure':
            displayLoginError(data.login.message, data.login.type)
            break

          case 'LoginRequiresEmailOTP':
            if (data.login.emailSent && data.login.email) {
              setStep('emailOtpEntry')
              setInstructionMessage(
                <>
                  Please check your email for the code that we sent to{' '}
                  <BreakWord>{data.login.email}</BreakWord>.
                </>
              )
              setEmail(data.login.email)
            } else {
              displayGenericError()
            }
            break

          case 'LoginSuccess':
            analytics.track(event.loginSucceeded())
            // identify so frontend event are correctly correlated to user ID
            analytics.identify(data.login.user.id)
            // identify with LD so correct context is used
            await ldClient.identify({
              kind: 'multi',
              user: {
                key: data.login.user.id,
                status: data.login.user.status,
              },
              guest: {
                key: getOrCreateGuestID(),
              },
            })

            if (data.login.user.status === 'Active') {
              if (location.state?.redirectPath) {
                navigate(location.state?.redirectPath)
              } else {
                navigate('/home')
              }
            } else {
              return resumeOnboarding(
                data.login.user.lastCompletedOnboardingStep
              )
            }
            break
          default:
            console.error({
              typename: data.login.__typename,
              mutation: 'login',
            })
            displayGenericError()
        }
      },
      onError(error) {
        console.error({ error, mutation: 'login' })
        displayGenericError()
      },

      // associate this user id with Query.currentUser in the relay store
      updater(store, payload) {
        if (payload.login.__typename === 'LoginSuccess') {
          const currentUser = store.get(payload.login.user.id)
          store.getRoot().setLinkedRecord(currentUser, 'currentUser')
        }
      },
    })
  }

  useMountEffect(() => {
    if (location.state?.phone) {
      // if phone passed to login skip to submission
      handleSubmit()
    }
  })

  if (mutationInFlight) {
    return <SpinnerFullScreen />
  }

  return (
    <LayoutSignupTracked
      pageName={pageName}
      title="Welcome back"
      headerStart={<GoBack />}
      footerContent={
        <ButtonPrimary
          data-page-name={pageName}
          data-name="Primary CTA"
          onClick={handleSubmit}
          disabled={mutationInFlight}
          data-testid={testIds.submitButton}
        >
          Next
        </ButtonPrimary>
      }
    >
      {!!instructionMessage && (
        <Row>
          <BodyText data-testid={testIds.instructionMessage}>
            {instructionMessage}
          </BodyText>
        </Row>
      )}
      <Row>
        <Form onSubmit={handleSubmit}>
          {step === 'phoneEntry' ? (
            <InputPhone
              ref={inputRef}
              value={phone}
              onChange={setPhone}
              data-testid={testIds.phone}
            />
          ) : step === 'emailOtpEntry' ? (
            <InputText
              ref={inputRef}
              label="Code"
              name={`email_otp${Math.ceil(Math.random() * 999999)}`}
              value={emailOtp}
              onChange={setEmailOtp}
              data-testid={testIds.emailOtp}
              inputMode="tel"
              error={emailOtpError}
            />
          ) : null}
        </Form>
      </Row>
    </LayoutSignupTracked>
  )
}

export default Login
