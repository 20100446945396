/**
 * @generated SignedSource<<5495d96d4bf3e81c5f9c66305fc9e6cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpgradeAvibraPlanQuery$variables = {};
export type UpgradeAvibraPlanQuery$data = {
  readonly content: {
    readonly modal: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
      readonly secondaryCtaText: string | null;
    } | null;
    readonly onboardingPage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
      readonly secondaryCtaText: string | null;
    } | null;
  };
  readonly currentUser: {
    readonly id: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"useContinueOnboarding_user">;
  } | null;
};
export type UpgradeAvibraPlanQuery = {
  response: UpgradeAvibraPlanQuery$data;
  variables: UpgradeAvibraPlanQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "Upgrade Avibra Plan"
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryCtaText",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Decline Upgrade Avibra Plan"
    }
  }
],
v8 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpgradeAvibraPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useContinueOnboarding_user"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": "onboardingPage(where:{\"pageName\":\"Upgrade Avibra Plan\"})"
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Decline Upgrade Avibra Plan\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UpgradeAvibraPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastCompletedOnboardingStep",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "onboardingPage(where:{\"pageName\":\"Upgrade Avibra Plan\"})"
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Decline Upgrade Avibra Plan\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5cd43c56b124ad28185d1b9031cd553d",
    "id": null,
    "metadata": {},
    "name": "UpgradeAvibraPlanQuery",
    "operationKind": "query",
    "text": "query UpgradeAvibraPlanQuery {\n  currentUser {\n    id\n    ...useContinueOnboarding_user\n  }\n  content {\n    onboardingPage(where: {pageName: \"Upgrade Avibra Plan\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      secondaryCtaText\n      id\n    }\n    modal(where: {name: \"Decline Upgrade Avibra Plan\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      secondaryCtaText\n      id\n    }\n  }\n}\n\nfragment useContinueOnboarding_user on User {\n  lastCompletedOnboardingStep\n  status\n}\n"
  }
};
})();

(node as any).hash = "351abcb9a1224c5487ef8fa957a000de";

export default node;
