// import { useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useMountEffect } from './react-hook-wrappers'

export function useExternalScript(
  resourceUrl: string,
  id: string,
  cleanup = false
) {
  function appendScript() {
    // only add if it hasn't been added yet
    // if you want a script to be appended on each render, pass `true` for `cleanup`
    if (document.getElementById(id) === null) {
      const script = document.createElement('script')
      script.src = resourceUrl
      script.async = true
      if (id) {
        script.id = id
      }

      document.body.appendChild(script)

      if (cleanup) {
        return () => {
          document.getElementById(id).remove()
        }
      }
    }
  }

  const debouncedAppendScript = useDebouncedCallback(appendScript, 250, {
    leading: false,
    trailing: true,
  })

  useMountEffect(debouncedAppendScript)
}
