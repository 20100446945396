/**
 * @generated SignedSource<<9039fc6d34be0dd0b442f467b92488f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScrambleMutation$variables = {
  phone?: string | null;
};
export type ScrambleMutation$data = {
  readonly logout: any | null;
  readonly sandboxScramblePhone: any | null;
};
export type ScrambleMutation = {
  response: ScrambleMutation$data;
  variables: ScrambleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "phone"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "phone",
        "variableName": "phone"
      }
    ],
    "kind": "ScalarField",
    "name": "sandboxScramblePhone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logout",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScrambleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScrambleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e11c8be44d5c50b1d8760720a7ce1b27",
    "id": null,
    "metadata": {},
    "name": "ScrambleMutation",
    "operationKind": "mutation",
    "text": "mutation ScrambleMutation(\n  $phone: String\n) {\n  sandboxScramblePhone(phone: $phone)\n  logout\n}\n"
  }
};
})();

(node as any).hash = "01d595dc27214d3cc9e0047390964d86";

export default node;
