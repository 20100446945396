import { Modal } from '@quil/ui'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from './react-router-wrappers'

type ContextValue = {
  openModal(content: JSX.Element): void
  closeModal(): void
}

const ModalContext = createContext<ContextValue | null>(null)

type State = {
  open: boolean
  content: JSX.Element | null
}

export const ModalProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [state, setState] = useState<State>({ open: false, content: null })
  const location = useLocation()

  const openModal = useCallback((content: JSX.Element) => {
    setState({ open: true, content })
  }, [])

  const closeModal = useCallback(() => {
    setState((state) => (state.open ? { open: false, content: null } : state))
  }, [])

  useEffect(() => {
    closeModal()
  }, [location.key, closeModal])

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {props.children}
      <Modal isOpen={state.open} onBackdropClick={closeModal}>
        {state.content}
      </Modal>
    </ModalContext.Provider>
  )
}

export function useModal() {
  const modalContext = useContext(ModalContext)

  if (modalContext === null) {
    throw new Error('useModal cannot be used outside of ModalProvider')
  }

  return modalContext
}
