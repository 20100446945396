import { ChatButton as ChatButtonUI } from '@quil/ui'
import { useState } from 'react'
import { useExternalScript } from '../../useExternalScript'

declare global {
  interface Window {
    zE?: (command: string, action: string, cb?: () => void) => void
  }
}

export function ZendeskChatButton() {
  useExternalScript(process.env.REACT_APP_ZENDESK_WIDGET_URL, 'ze-snippet')

  const [chatOpen, setChatOpen] = useState(false)
  function handleChatButtonClick() {
    // window.zE is attached from the zendesk chat widget script https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/#open
    if (typeof window.zE === 'function') {
      const action = chatOpen ? 'close' : 'open'
      setChatOpen(!chatOpen)
      window.zE('messenger', action)
      // If the user closes the chat widget via the included "close" button,
      // we should update our local state, so the next button click performs the correct action.
      // There is no ":off" method, so this listener might be attached multiple times
      window.zE('messenger:on', 'close', () => setChatOpen(false))
    }
  }

  return (
    <ChatButtonUI onClick={handleChatButtonClick} data-test-id="chat_button" />
  )
}
