/**
 * @generated SignedSource<<0641b5832ea6c985c87812df20448bd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LayoffProtectionStatus = "Approved" | "ContactSupport" | "Eligible" | "InReview" | "Pending" | "Waiting" | "%future added value";
export type AppNavQuery$variables = {};
export type AppNavQuery$data = {
  readonly content: {
    readonly approvedClaim: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
    readonly contactSupport: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
      readonly secondaryCtaText: string | null;
    } | null;
    readonly inReview: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
    readonly pending: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
      readonly secondaryCtaText: string | null;
    } | null;
  };
  readonly currentUser: {
    readonly eligibleForLayoffProtectionAt: {
      readonly formatted: string;
    } | null;
    readonly layoffProtectionStatus: LayoffProtectionStatus;
  } | null;
};
export type AppNavQuery = {
  response: AppNavQuery$data;
  variables: AppNavQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Navbar Approved Claim"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Navbar Claim In Review"
    }
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Navbar Pending Claim"
    }
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryCtaText",
  "storageKey": null
},
v8 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v7/*: any*/)
],
v9 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Navbar Contact Support"
    }
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Instant",
  "kind": "LinkedField",
  "name": "eligibleForLayoffProtectionAt",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formatted",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "layoffProtectionStatus",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v12/*: any*/)
],
v14 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v7/*: any*/),
  (v12/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppNavQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": "approvedClaim",
            "args": (v0/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Navbar Approved Claim\"})"
          },
          {
            "alias": "inReview",
            "args": (v5/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Navbar Claim In Review\"})"
          },
          {
            "alias": "pending",
            "args": (v6/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Navbar Pending Claim\"})"
          },
          {
            "alias": "contactSupport",
            "args": (v9/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Navbar Contact Support\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppNavQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": "approvedClaim",
            "args": (v0/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Navbar Approved Claim\"})"
          },
          {
            "alias": "inReview",
            "args": (v5/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Navbar Claim In Review\"})"
          },
          {
            "alias": "pending",
            "args": (v6/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Navbar Pending Claim\"})"
          },
          {
            "alias": "contactSupport",
            "args": (v9/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Navbar Contact Support\"})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0fc7d5d8c0fba588e5945efee4b45df7",
    "id": null,
    "metadata": {},
    "name": "AppNavQuery",
    "operationKind": "query",
    "text": "query AppNavQuery {\n  content {\n    approvedClaim: modal(where: {name: \"Navbar Approved Claim\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n    inReview: modal(where: {name: \"Navbar Claim In Review\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n    pending: modal(where: {name: \"Navbar Pending Claim\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      secondaryCtaText\n      id\n    }\n    contactSupport: modal(where: {name: \"Navbar Contact Support\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      secondaryCtaText\n      id\n    }\n  }\n  currentUser {\n    eligibleForLayoffProtectionAt {\n      formatted\n    }\n    layoffProtectionStatus\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f355c9c888ecc252d92a0f87f66d741d";

export default node;
