import { TestIds as AddressTestIds } from '../../common/components/AddressInput'

const VerifyPersonalInfo = 'verify_personal_info'

export const primaryCta = `${VerifyPersonalInfo}_primary_cta`
export const firstNameField = `${VerifyPersonalInfo}_firstname_field`
export const lastNameField = `${VerifyPersonalInfo}_lastname_field`
export const phoneField = `${VerifyPersonalInfo}_phone_field`
export const dobField = `${VerifyPersonalInfo}_dob_field`
export const emailField = `${VerifyPersonalInfo}_email_field`
export const socialSecurityNumberField = `${VerifyPersonalInfo}_ssn_field`
export const inManualReviewError = `${VerifyPersonalInfo}_in_manual_review_error`
export const loading = `${VerifyPersonalInfo}_loading`
export const notYouCta = `${VerifyPersonalInfo}_not_you_cta`
export const supportRequestPrimaryCta = `${VerifyPersonalInfo}_support_request_primary_cta`
export const supportRequestSecondaryCta = `${VerifyPersonalInfo}_support_request_secondary_cta`
export const agreementCheckbox = `${VerifyPersonalInfo}_agreement_checkbox`
export const residencyAlert = `${VerifyPersonalInfo}_residency_alert`

export const address: AddressTestIds = {
  addressLineOne: `${VerifyPersonalInfo}_address_street_field`,
  addressLineTwo: `${VerifyPersonalInfo}_address_unit_field`,
  city: `${VerifyPersonalInfo}_address_city_field`,
  state: `${VerifyPersonalInfo}_address_state_field`,
  zip: `${VerifyPersonalInfo}_address_zip_field`,
}
