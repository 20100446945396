/**
 * @generated SignedSource<<5c1a24d62438cf4b804130cf44bec913>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WaitingForNewClaimQuery$variables = {};
export type WaitingForNewClaimQuery$data = {
  readonly content: {
    readonly youtubeVideoId: {
      readonly value: string;
    } | null;
  };
  readonly currentUser: {
    readonly eligibleForLayoffProtectionAt: {
      readonly formatted: string;
    } | null;
    readonly id: string | null;
  } | null;
  readonly disclosures: {
    readonly " $fragmentSpreads": FragmentRefs<"DocLink_disclosures">;
  } | null;
};
export type WaitingForNewClaimQuery = {
  response: WaitingForNewClaimQuery$data;
  variables: WaitingForNewClaimQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Instant",
      "kind": "LinkedField",
      "name": "eligibleForLayoffProtectionAt",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formatted",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "YouTube Waiting Eligibility Video ID"
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WaitingForNewClaimQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocLink_disclosures"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": "youtubeVideoId",
            "args": (v2/*: any*/),
            "concreteType": "Cms_Miscellaneous",
            "kind": "LinkedField",
            "name": "miscellaneous",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": "miscellaneous(where:{\"name\":\"YouTube Waiting Eligibility Video ID\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WaitingForNewClaimQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Disclosures",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "electronicCommunications",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "mobileNetworkConsent",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "patriotAct",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraPrivacyPolicy",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraTermsOfService",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "avibraMembershipAgreement",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitsDisclosure",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "plateauApplication",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "privacyPolicy",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "termsOfService",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "benefitEligibility",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Disclosure",
            "kind": "LinkedField",
            "name": "promotionalTerms",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": "youtubeVideoId",
            "args": (v2/*: any*/),
            "concreteType": "Cms_Miscellaneous",
            "kind": "LinkedField",
            "name": "miscellaneous",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": "miscellaneous(where:{\"name\":\"YouTube Waiting Eligibility Video ID\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "440eef9969c04368984e9c0db38980d2",
    "id": null,
    "metadata": {},
    "name": "WaitingForNewClaimQuery",
    "operationKind": "query",
    "text": "query WaitingForNewClaimQuery {\n  currentUser {\n    id\n    eligibleForLayoffProtectionAt {\n      formatted\n    }\n  }\n  disclosures {\n    ...DocLink_disclosures\n  }\n  content {\n    youtubeVideoId: miscellaneous(where: {name: \"YouTube Waiting Eligibility Video ID\"}) {\n      value\n      id\n    }\n  }\n}\n\nfragment DocLink_disclosures on Disclosures {\n  electronicCommunications {\n    url\n    version\n  }\n  mobileNetworkConsent {\n    url\n    version\n  }\n  patriotAct {\n    url\n    version\n  }\n  avibraPrivacyPolicy {\n    url\n    version\n  }\n  avibraTermsOfService {\n    url\n    version\n  }\n  avibraMembershipAgreement {\n    url\n    version\n  }\n  benefitsDisclosure {\n    url\n    version\n  }\n  plateauApplication {\n    url\n    version\n  }\n  privacyPolicy {\n    url\n    version\n  }\n  termsOfService {\n    url\n    version\n  }\n  benefitEligibility {\n    url\n    version\n  }\n  promotionalTerms {\n    url\n    version\n  }\n}\n"
  }
};
})();

(node as any).hash = "78e199a505febfea08ac20fe0eea835b";

export default node;
