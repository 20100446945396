import { Route, Routes } from 'react-router-dom'
import { Navigate } from '../common/react-router-wrappers'
import ClaimsList from './ClaimsList'
import ClaimDetail from './Detail'
import New from './New'
import ClaimSubmitAdditionalInformation from './SubmitAdditionalInformation'

export default function Claims() {
  return (
    <Routes>
      <Route index element={<ClaimsList />} />
      <Route
        path="/:id/submit-additional-information"
        element={<ClaimSubmitAdditionalInformation />}
      />
      <Route path="/:id" element={<ClaimDetail />} />
      <Route path="new/*" element={<New />} />
      <Route path="*" element={<Navigate to="/home/claims" replace />} />
    </Routes>
  )
}
