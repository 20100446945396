/**
 * @generated SignedSource<<1ac024d6c3a2f97eb3bff521fb8d78ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DisclosureType = "AvibraMembershipAgreement" | "AvibraPrivacyPolicy" | "AvibraTermsOfService" | "BenefitEligibility" | "BenefitsDisclosure" | "ElectronicCommunications" | "MobileNetworkConsent" | "PatriotAct" | "PlateauApplication" | "PlateauPolicyCertificate" | "PrivacyPolicy" | "PromotionalTerms" | "TermsOfService" | "%future added value";
export type LegalDocDetailQuery$variables = {
  type: DisclosureType;
};
export type LegalDocDetailQuery$data = {
  readonly agreement: {
    readonly html: string | null;
    readonly id: string | null;
    readonly url: string;
  } | null;
};
export type LegalDocDetailQuery = {
  response: LegalDocDetailQuery$data;
  variables: LegalDocDetailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Agreement",
    "kind": "LinkedField",
    "name": "agreement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "html",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LegalDocDetailQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LegalDocDetailQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f3507fd49ad0b23338f0015ed746ff3a",
    "id": null,
    "metadata": {},
    "name": "LegalDocDetailQuery",
    "operationKind": "query",
    "text": "query LegalDocDetailQuery(\n  $type: DisclosureType!\n) {\n  agreement(type: $type) {\n    id\n    html\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "2dd0efb6c2192a11c8dce535dca919f8";

export default node;
