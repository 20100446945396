import React, { ChangeEvent, useRef } from 'react'
import styled, { css } from 'styled-components'
import { containsOnlyNumbers } from '../../common/utils'
import ErrorBadge from '../ErrorBadge'
import ErrorMessageContainer from '../ErrorMessageContainer'
import ErrorMessageTextValidation from '../ErrorMessageTextValidation'
import InputAdornment from '../InputAdornment'
import { InputLabel } from '../InputLabel'
import { Input, InputContainer } from '../InputText'

const Container = styled.div<{ disabled?: boolean }>`
  margin: 0 0 2.4rem;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

const StyledErrorMessageContainer = styled(ErrorMessageContainer)`
  width: 100%;
`

const InputGroup = styled.div`
  display: inline-flex;
  gap: 1.6rem;
  position: relative;

  ${InputContainer} {
    display: inline-flex;
  }
  ${InputContainer}:first-child {
  }
`
const MonthInput = styled(Input)`
  text-align: center;
  width: 4rem;
`

const DayInput = styled(Input)`
  text-align: center;
  width: 6.4rem;
`
const YearInput = styled(Input)`
  text-align: center;
  width: 6.4rem;
`

type Props = {
  value: string | null
  onChange: (value: string) => void
  label: string
  startAdornment?: JSX.Element
  error?: string | null
  'data-testid'?: string
  disabled?: boolean
}

const InputDate: React.FC<Props> = (props) => {
  // make public api look like <input type="date" />
  const date = props.value?.length ? props.value : '--'
  const [year, month, day] = date.split('-')

  // element refs for setting focus
  const monthRef = useRef<HTMLInputElement>(null)
  const dayRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  // We will guide the user by focusing on the next input
  // when appropriate, but only once.
  // If we pass a value on mount (say, user is editing a setting)
  const shouldJumpToDayRef = useRef(
    props.value === null || props.value?.length === 0
  )
  const shouldJumpToYearRef = useRef(
    props.value === null || props.value?.length === 0
  )

  function focusOnDay() {
    if (dayRef.current && shouldJumpToDayRef.current) {
      dayRef.current.focus()

      // only once per mount
      shouldJumpToDayRef.current = false
    }
  }

  function focusOnYear() {
    if (yearRef.current && shouldJumpToYearRef.current) {
      yearRef.current.focus()

      // only once per mount
      shouldJumpToYearRef.current = false
    }
  }

  function handleMonthChange({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    // only update on numbers and empty string
    if (value === '' || containsOnlyNumbers(value)) {
      // if the first char is greater than 1, assume that's the month and advance
      if (value.length === 1 && parseInt(value) > 1) {
        value = `0${value}`
        props.onChange(`${year}-${value}-${day}`)
        focusOnDay()
      }

      props.onChange(`${year}-${value}-${day}`)

      // jump to next field when you've typed 2 digits
      if (value.length === 2) {
        focusOnDay()
      }
    }
  }

  function handleDayChange({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    // only update on numbers and empty string
    if (value === '' || containsOnlyNumbers(value)) {
      // if the first char is greater than 3, assume that's the day and advance
      if (value.length === 1 && parseInt(value) > 3) {
        value = `0${value}`
        props.onChange(`${year}-${month}-${value}`)
        focusOnYear()
      }

      props.onChange(`${year}-${month}-${value}`)

      // jump to next field when you've typed 2 digits
      if (value.length === 2) {
        focusOnYear()
      }
    }
  }

  function handleYearChange({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    // only update on numbers and empty string
    if (value === '' || containsOnlyNumbers(value)) {
      props.onChange(`${value}-${month}-${day}`)
    }
  }

  // Containers should be perceived as inputs to the user
  function handleMonthContainerClick() {
    monthRef.current?.focus()
  }

  function handleDayContainerClick() {
    dayRef.current?.focus()
  }

  function handleYearContainerClick() {
    yearRef.current?.focus()
  }

  // so that two date inputs on the page will not clash and all labels will work properly
  const monthInputId = `dateMonthInput${React.useId()}`

  return (
    <div>
      <Container disabled={props.disabled}>
        <InputLabel htmlFor={monthInputId} error={!!props.error}>
          {props.label ?? 'Date of birth'}
        </InputLabel>
        <InputGroup>
          <InputContainer
            error={!!props.error}
            onClick={handleMonthContainerClick}
          >
            {props.startAdornment && (
              <InputAdornment>{props.startAdornment}</InputAdornment>
            )}
            <MonthInput
              id={monthInputId}
              type="text"
              inputMode="tel"
              placeholder={'mm'}
              name="date_of_birth_month"
              ref={monthRef}
              value={month}
              maxLength={2}
              onChange={handleMonthChange}
              aria-label="Date of birth month"
              data-testid={`${props['data-testid']}_month`}
              disabled={props.disabled}
            />
          </InputContainer>
          <InputContainer
            error={!!props.error}
            onClick={handleDayContainerClick}
          >
            <DayInput
              type="text"
              inputMode="tel"
              placeholder={'dd'}
              name="date_of_birth_day"
              ref={dayRef}
              value={day}
              maxLength={2}
              onChange={handleDayChange}
              aria-label="Date of birth day"
              data-testid={`${props['data-testid']}_day`}
              disabled={props.disabled}
            />
          </InputContainer>
          <InputContainer
            error={!!props.error}
            onClick={handleYearContainerClick}
          >
            <YearInput
              type="text"
              inputMode="tel"
              placeholder={'yyyy'}
              name="date_of_birth_year"
              ref={yearRef}
              value={year}
              onChange={handleYearChange}
              maxLength={4}
              aria-label="Date of birth year"
              data-testid={`${props['data-testid']}_year`}
              disabled={props.disabled}
            />
          </InputContainer>
          <ErrorBadge error={!!props.error} style={{ top: -10 }} />
        </InputGroup>
        <StyledErrorMessageContainer visible={!!props.error}>
          <ErrorMessageTextValidation
            data-testid={`${props['data-testid']}_error_message`}
          >
            {props.error}
          </ErrorMessageTextValidation>
        </StyledErrorMessageContainer>
      </Container>
    </div>
  )
}

export default InputDate
