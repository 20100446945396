import React from 'react'
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg'
import InputAdornment from '../InputAdornment'
import InputText, { Props as InputTextProps } from '../InputText'

type Props = Pick<
  InputTextProps,
  'value' | 'onChange' | 'error' | 'data-testid' | 'disabled' | 'label'
>

const InputPhone: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  ref
) => {
  function handleChange(
    value: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    props.onChange(value, event)
  }
  return (
    <InputText
      {...props}
      ref={ref}
      startAdornment={
        <InputAdornment>
          <PhoneIcon />
        </InputAdornment>
      }
      onChange={handleChange}
      label={props.label ?? 'Mobile phone'}
      mask=" "
      format="+1 ### ### ####"
      placeholder="+1 555 555 5555"
      name="phone"
      type="tel"
      inputMode="tel"
    />
  )
}

export default React.forwardRef(InputPhone)
