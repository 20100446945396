import { ButtonPrimary, useAlternateTheme } from '@quil/ui'
import React from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { LayoutSignupTracked } from '../../common/analytics'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import { DeniedQuery } from './__generated__/DeniedQuery.graphql'

type Reason = 'identity' | 'employment' | 'prefill' | 'newYork'
type Props = {
  reason: Reason
}

const Denied: React.FC<Props> = ({ reason }) => {
  const pageName = 'Denied'
  useAlternateTheme('quilThemeVivid')
  const reasonPageNameMap: Record<Reason, string> = {
    employment: 'Employment Ineligible',
    identity: 'Identity Ineligible',
    prefill: 'Prefill Ineligible',
    newYork: 'New York Ineligible',
  }
  const data = useLazyLoadQuery<DeniedQuery>(
    graphql`
      query DeniedQuery($pageName: String!) {
        content {
          onboardingPage(where: { pageName: $pageName }) {
            heading
            body {
              raw
            }
            mainCtaText
          }
        }
      }
    `,
    {
      pageName: reasonPageNameMap[reason],
    }
  )

  return (
    <LayoutSignupTracked
      pageName={pageName}
      pageCallProperties={{ reason }}
      title={data.content.onboardingPage.heading}
      headerStart={<GoBack />}
      footerContent={
        <ButtonPrimary
          as="a"
          href={
            process.env.REACT_APP_ENV === 'production'
              ? 'https://avibra.com/benefits/quil'
              : 'https://avibra.com/benefits-staging/quil'
          }
          data-name="Avibra referral link"
          data-page-name={pageName}
        >
          {data.content.onboardingPage.mainCtaText}
        </ButtonPrimary>
      }
      data-testid="denied"
    >
      <RichText content={data.content.onboardingPage.body.raw} />
    </LayoutSignupTracked>
  )
}

export default Denied
