import { buttonClicked } from '@quil/analytics-events'
import ReactGA from 'react-ga4'

import * as analytics from './analytics-adapter'

export function addAnalyticsListeners() {
  const trackClick = async (event: MouseEvent) => {
    const target = event.target as HTMLAnchorElement | HTMLButtonElement
    if (!target || !['BUTTON', 'A'].includes(target.nodeName)) {
      return
    }

    const dataset = target.dataset
    const track = () =>
      analytics.track(
        buttonClicked(
          dataset['name'] ?? `Unlabeled ${target.nodeName}`,
          // dataset will automatically camelCase keys from kebab-case; if they are already camelCase, it will lowercase
          dataset['pageName'] ?? dataset['pagename'] ?? window.document.title,
          dataset['text'] ?? target.innerText,
          href
        )
      )

    // If the click target is an `a` element and it has an `href` property pointing to an external URL,
    // the navigation must be suspended to allow the analytics tracking to complete before navigating.
    // To improve the UX, we add 2 enhancements:
    // 1. prefetch the destination URL so the content is cached when the navigation occurs
    // 2. set a maximum timeout of 1 second. If the analytics call takes longer than 1 second or fails, the user will still navigate
    // Note: accessing `href` directly via `target.href` will always return a fully-qualified URL. For relative paths, the element's attributes must be used
    const href = target.attributes?.getNamedItem('href')?.value
    if (target.nodeName === 'A' && href?.includes('://')) {
      event.preventDefault()

      const navigate = () => {
        // respect "new tab" behavior
        if (
          event.metaKey ||
          event.ctrlKey ||
          target.attributes.getNamedItem('target')?.value?.includes('blank')
        ) {
          window.open(
            href,
            target.attributes.getNamedItem('target')?.value,
            target.attributes.getNamedItem('rel')?.value
          )
        } else {
          window.location.assign(href)
        }
      }
      const timeout = setTimeout(navigate, 1000)

      // prefetch by injecting link element into DOM
      document.head.appendChild(
        Object.assign(document.createElement('link'), {
          rel: 'prefetch',
          href,
        })
      )

      await track()
      // after track is completed, timeout can be safely canceled
      clearTimeout(timeout)
      return navigate()
    }

    await track()
  }

  window.addEventListener('click', trackClick)
}

export function initializeGA() {
  if (
    process.env.REACT_APP_GOOGLE_ANALYTICS_ID &&
    process.env.REACT_APP_GOOGLE_ANALYTICS_ID !== 'false'
  ) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
      gtagOptions: {
        send_page_view: false,
      },
    })
  }
}
