import styled from 'styled-components'
import { fromTheme } from '../../theme'

export default styled.span`
  align-items: center;
  display: flex;
  font-size: 24px;
  justify-content: center;
  fill: currentcolor;
  color: ${fromTheme((theme) => theme.colors.foregroundMuted.raw)};

  &:first-child {
    padding-right: 8px;
  }

  &:last-child {
    padding-left: 8px;
  }
`
