import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

const ErrorMessageContainer: React.FC<{
  visible: boolean
  children: React.ReactNode
}> = (props) => {
  return (
    <AnimatePresence>
      {!!props.visible && (
        <motion.div
          key="errorContainer"
          initial={{ opacity: 0, y: -2 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          {props.children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ErrorMessageContainer
