/**
 * @generated SignedSource<<3ac97248dc47f9a72dc19ced7008fa94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BenefitsOverviewQuery$variables = {};
export type BenefitsOverviewQuery$data = {
  readonly user: {
    readonly id: string | null;
  } | null;
  readonly vendorBenefits: {
    readonly counseling: ReadonlyArray<{
      readonly description: string;
      readonly displayName: string;
      readonly icon: string;
      readonly name: string;
    } | null>;
    readonly protection: ReadonlyArray<{
      readonly description: string;
      readonly displayName: string;
      readonly icon: string;
      readonly name: string;
    } | null>;
  } | null;
};
export type BenefitsOverviewQuery = {
  response: BenefitsOverviewQuery$data;
  variables: BenefitsOverviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "icon",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": "user",
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "VendorBenefits",
    "kind": "LinkedField",
    "name": "vendorBenefits",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VendorBenefit",
        "kind": "LinkedField",
        "name": "protection",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VendorBenefit",
        "kind": "LinkedField",
        "name": "counseling",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BenefitsOverviewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BenefitsOverviewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d0a07a12d2a07e6c8fbad8ff6c4cd2a6",
    "id": null,
    "metadata": {},
    "name": "BenefitsOverviewQuery",
    "operationKind": "query",
    "text": "query BenefitsOverviewQuery {\n  user: currentUser {\n    id\n  }\n  vendorBenefits {\n    protection {\n      name\n      displayName\n      icon\n      description\n    }\n    counseling {\n      name\n      displayName\n      icon\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7e7a561c9dffa6baff85ce827ddb1b7";

export default node;
