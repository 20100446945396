import React from 'react'
import { ReactComponent as StarIcon } from '../../icons/star.svg'
import InputAdornment from '../InputAdornment'
import InputText, { Props as InputTextProps } from '../InputText'

type Props = Pick<
  InputTextProps,
  'value' | 'onChange' | 'error' | 'data-testid'
>

const InputAddressCity: React.ForwardRefRenderFunction<
  HTMLInputElement,
  Props
> = (props, ref) => {
  return (
    <InputText
      {...props}
      ref={ref}
      startAdornment={
        <InputAdornment>
          <StarIcon />
        </InputAdornment>
      }
      label="State"
      name="address_state"
      placeholder="ST"
      autoComplete="address-level1"
    />
  )
}

export default React.forwardRef(InputAddressCity)
