import {
  ButtonPrimary,
  InputText,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import Form from '@web/common/components/Form'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import { useNavigate } from '@web/common/react-router-wrappers'
import { useFormReducer } from '@web/common/useFormReducer'
import { useEffect } from 'react'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import { PayoutAccountManualMutation } from './__generated__/PayoutAccountManualMutation.graphql'
import { PayoutAccountManualQuery } from './__generated__/PayoutAccountManualQuery.graphql'

const initialState = {
  values: {
    routingNumber: '',
    accountNumber: '',
    accountNumberConfirmation: '',
  },
  errors: {
    routingNumber: '',
    accountNumber: '',
    accountNumberConfirmation: '',
  },
}

export default function PayoutAccountManual() {
  const pageName = 'Payout Account Manual'
  const navigate = useNavigate()
  const showToast = useShowToast()
  const [{ values, errors }, actions] = useFormReducer(initialState)

  useEffect(() => {
    if (!data.payoutAccount) {
      navigate('/home/settings')
    }
  }, [])

  const data = useLazyLoadQuery<PayoutAccountManualQuery>(
    graphql`
      query PayoutAccountManualQuery {
        content {
          profilePage(where: { name: "Payout Account Manual" }) {
            header
            body {
              raw
            }
            mainCtaText
          }
        }
        currentLayoffClaim {
          id
          status
        }
        payoutAccount {
          id
        }
      }
    `,
    {}
  )

  const [changePayoutAccount, changePayoutAccountInFlight] =
    useMutation<PayoutAccountManualMutation>(graphql`
      mutation PayoutAccountManualMutation($input: AddManualAccountInput!) {
        addManualAccount(input: $input) {
          __typename
          ... on PayoutAccount {
            id
            accountNumberMask
            bankName
          }
          ... on ValidationErrors {
            messages {
              field
              help
            }
          }
        }
      }
    `)

  const updateClaim = ['Pending', 'InReview'].includes(
    data.currentLayoffClaim?.status
  )

  function handleSubmit() {
    if (values.accountNumber !== values.accountNumberConfirmation) {
      actions.receiveValidationErrors([
        {
          field: 'accountNumberConfirmation',
          help: 'Account numbers must match',
        },
      ])
      return
    }

    changePayoutAccount({
      variables: {
        input: {
          claimId: updateClaim ? data.currentLayoffClaim.id : null,
          accountNumber: values.accountNumber,
          routingNumber: values.routingNumber,
        },
      },
      onCompleted({ addManualAccount }) {
        switch (addManualAccount.__typename) {
          case 'PayoutAccount':
            navigate('/home/settings/payout-account')
            break
          case 'ValidationErrors':
            actions.receiveValidationErrors(addManualAccount.messages)
            break
          default:
            showToast({
              severity: 'Error',
              headline: 'Something went wrong',
              message: 'Please contact support',
            })
        }
      },
      onError() {
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.genericError,
          message: standardToastMessages.retry,
          onClick() {
            handleSubmit()
          },
        })
      },
      updater(store, payload) {
        if (payload.addManualAccount.__typename === 'PayoutAccount') {
          const payoutAccount = store.get(payload.addManualAccount.id)
          store.getRoot().setLinkedRecord(payoutAccount, 'payoutAccount')
          if (updateClaim) {
            store
              .getRoot()
              .getLinkedRecord('currentLayoffClaim')
              .setLinkedRecord(payoutAccount, 'payoutAccount')
          }
        }
      },
    })
  }

  function createHandler(name: string) {
    return function (value: string | boolean) {
      actions.updateFields({ [name]: value })
    }
  }

  return (
    <LayoutInAppTracked
      title={data.content.profilePage.header}
      pageName="Settings: Payout Account Manual"
      headerStart={<GoBack />}
      footerContent={
        <ButtonPrimary
          onClick={handleSubmit}
          disabled={changePayoutAccountInFlight}
          data-name="Link Manual Payout Account"
        >
          {data.content.profilePage.mainCtaText}
        </ButtonPrimary>
      }
    >
      <RichText content={data.content.profilePage.body.raw} />
      <Form onSubmit={handleSubmit}>
        <InputText
          error={errors.routingNumber}
          label="Routing Number"
          value={values.routingNumber}
          onChange={createHandler('routingNumber')}
        />
        <InputText
          error={errors.accountNumber}
          label="Account Number"
          value={values.accountNumber}
          onChange={createHandler('accountNumber')}
        />
        <InputText
          error={errors.accountNumberConfirmation}
          label="Confirm Account Number"
          value={values.accountNumberConfirmation}
          onChange={createHandler('accountNumberConfirmation')}
        />
      </Form>
      <ProcessingOverlay processing={changePayoutAccountInFlight} />
    </LayoutInAppTracked>
  )
}
