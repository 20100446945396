import styled from 'styled-components'

const Container = styled.div`
  align-items: baseline;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  justify-content: space-between;
  padding: 2.4rem 1.6rem;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto 1.6rem;

  .solution {
    font-weight: 700;
    font-size: 1.4rem;
    text-decoration: underline;
  }
`

type Props = {
  problemText: string
  solutionText: string
  onClick: () => void
}

export function ActionCard(props: Props) {
  return (
    <Container onClick={props.onClick}>
      <div className="problem">{props.problemText}</div>
      <div className="solution">{props.solutionText}</div>
    </Container>
  )
}
