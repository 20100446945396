/**
 * @generated SignedSource<<11eae15696979e49b33f08267917739e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DisclosureType = "AvibraMembershipAgreement" | "AvibraPrivacyPolicy" | "AvibraTermsOfService" | "BenefitEligibility" | "BenefitsDisclosure" | "ElectronicCommunications" | "MobileNetworkConsent" | "PatriotAct" | "PlateauApplication" | "PlateauPolicyCertificate" | "PrivacyPolicy" | "PromotionalTerms" | "TermsOfService" | "%future added value";
export type LegalDocsQuery$variables = {};
export type LegalDocsQuery$data = {
  readonly agreements: {
    readonly benefitEligibility: {
      readonly id: string | null;
      readonly type: DisclosureType;
      readonly url: string;
    } | null;
    readonly benefitsDisclosure: {
      readonly id: string | null;
      readonly type: DisclosureType;
      readonly url: string;
    } | null;
    readonly electronicCommunications: {
      readonly id: string | null;
      readonly type: DisclosureType;
      readonly url: string;
    } | null;
    readonly mobileNetworkConsent: {
      readonly id: string | null;
      readonly type: DisclosureType;
      readonly url: string;
    } | null;
    readonly patriotAct: {
      readonly id: string | null;
      readonly type: DisclosureType;
      readonly url: string;
    } | null;
    readonly plateauApplication: {
      readonly id: string | null;
      readonly type: DisclosureType;
      readonly url: string;
    } | null;
    readonly plateauPolicyCertificate: {
      readonly id: string | null;
      readonly type: DisclosureType;
      readonly url: string;
    } | null;
    readonly privacyPolicy: {
      readonly id: string | null;
      readonly type: DisclosureType;
      readonly url: string;
    } | null;
    readonly termsOfService: {
      readonly id: string | null;
      readonly type: DisclosureType;
      readonly url: string;
    } | null;
  };
  readonly content: {
    readonly profilePage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly header: string | null;
    } | null;
  };
};
export type LegalDocsQuery = {
  response: LegalDocsQuery$data;
  variables: LegalDocsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Legal"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Agreements",
  "kind": "LinkedField",
  "name": "agreements",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Agreement",
      "kind": "LinkedField",
      "name": "termsOfService",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agreement",
      "kind": "LinkedField",
      "name": "benefitEligibility",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agreement",
      "kind": "LinkedField",
      "name": "benefitsDisclosure",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agreement",
      "kind": "LinkedField",
      "name": "privacyPolicy",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agreement",
      "kind": "LinkedField",
      "name": "patriotAct",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agreement",
      "kind": "LinkedField",
      "name": "plateauApplication",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agreement",
      "kind": "LinkedField",
      "name": "plateauPolicyCertificate",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agreement",
      "kind": "LinkedField",
      "name": "electronicCommunications",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agreement",
      "kind": "LinkedField",
      "name": "mobileNetworkConsent",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LegalDocsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Legal\"})"
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LegalDocsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Legal\"})"
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "8f589c9fc577fb3b155138e720b07616",
    "id": null,
    "metadata": {},
    "name": "LegalDocsQuery",
    "operationKind": "query",
    "text": "query LegalDocsQuery {\n  content {\n    profilePage(where: {name: \"Legal\"}) {\n      header\n      body {\n        raw\n      }\n      id\n    }\n  }\n  agreements {\n    termsOfService {\n      id\n      url\n      type\n    }\n    benefitEligibility {\n      id\n      url\n      type\n    }\n    benefitsDisclosure {\n      id\n      url\n      type\n    }\n    privacyPolicy {\n      id\n      url\n      type\n    }\n    patriotAct {\n      id\n      url\n      type\n    }\n    plateauApplication {\n      id\n      url\n      type\n    }\n    plateauPolicyCertificate {\n      id\n      url\n      type\n    }\n    electronicCommunications {\n      id\n      url\n      type\n    }\n    mobileNetworkConsent {\n      id\n      url\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "09cec4cebdc0c7d891b7966544baafd7";

export default node;
