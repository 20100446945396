const Page = 'employment_info'

export const primaryCta = `${Page}_primary_cta`
export const terminationReason = `${Page}_termination_reason`
export const employerName = `${Page}_employer_name`
export const employerEmail = `${Page}_employer_email`
export const employerPhone = `${Page}_employer_phone`
export const occupation = `${Page}_occupation`
export const employmentStartDate = `${Page}_employment_start_date`
export const employmentEndDate = `${Page}_employment_end_date`
export const returnedToWork = `${Page}_returned_to_work`
export const returnedToWorkAtDate = `${Page}_returned_to_work_at_date`
export const additionalInfo = `${Page}_additional_info`
export const validationErrorsToast = `${Page}_validation_errors_toast`
export const errorToast = `${Page}_error_toast`
