import {
  ActionListItem,
  ActionListLink,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  SubheadingAlt,
  useShowToast,
} from '@quil/ui'
import React, { useState } from 'react'
import { graphql, useMutation } from 'react-relay'
import * as testIds from './test-ids'
import { PaymentInformationLinkAccessStripeMutation } from './__generated__/PaymentInformationLinkAccessStripeMutation.graphql'

export const PaymentInformationLink: React.FC = () => {
  // manually manage loading state to ensure spinner stays on screen until browser navigation is complete
  const [loading, setLoading] = useState(false)
  const showToast = useShowToast()
  const [commitAccessStripeBillingProfileMutation] =
    useMutation<PaymentInformationLinkAccessStripeMutation>(graphql`
      mutation PaymentInformationLinkAccessStripeMutation(
        $input: AccessStripeBillingProfileInput!
      ) {
        accessStripeBillingProfile(input: $input) {
          url
        }
      }
    `)

  async function handleUpdateBilling() {
    setLoading(true)
    commitAccessStripeBillingProfileMutation({
      variables: {
        input: {
          returnUrl: `${window.location.protocol}//${window.location.host}/home/settings`,
        },
      },
      onCompleted(response) {
        window.location.href = response.accessStripeBillingProfile.url
      },
      onError(error) {
        console.error({ error, mutation: 'accessStripeBillingProfile' })
        setLoading(false)
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.genericError,
          message: standardToastMessages.retry,
          onClick: handleUpdateBilling,
        })
      },
    })
  }

  return (
    <>
      <ActionListItem>
        <ActionListLink
          to="#"
          onClick={handleUpdateBilling}
          data-testid={testIds.paymentInformationLink}
          data-name="Billing"
          data-page-name="Settings"
        >
          <SubheadingAlt> Billing</SubheadingAlt>
          Update billing information
        </ActionListLink>
      </ActionListItem>
      <ProcessingOverlay processing={loading} delayedTimeout={0} />
    </>
  )
}
