import {
  BodyText,
  ChevronLeft,
  fromTheme,
  PlanCard,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  Subheading,
  useShowToast,
} from '@quil/ui'
import { LayoutSignupTracked } from '@web/common/analytics'
import DocLink from '@web/common/components/DocLink'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import useContinueOnboarding from '@web/common/components/useContinueOnboarding'
import getPlanColorScheme from '@web/common/getPlanColorScheme'
import { useLocation, useNavigate } from '@web/common/react-router-wrappers'
import { SignupPageProps } from '@web/Signup/SignupPageProps'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { propEq } from 'ramda'
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay'
import styled from 'styled-components'
import {
  BillingCadence,
  PlansMutation,
} from './__generated__/PlansMutation.graphql'
import { PlanLabel, PlansQuery } from './__generated__/PlansQuery.graphql'
import { PlansSmsMutation } from './__generated__/PlansSmsMutation.graphql'

const Container = styled.div`
  footer {
    @media (min-width: ${fromTheme(
        ({ breakpoints }) => breakpoints.medium
      )}px) {
      padding-bottom: 0;
    }
  }
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const AllPlansSubheading = styled(Subheading)`
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 0;
`

const StyledChevron = styled(ChevronLeft)`
  transform: rotate(-90deg);
  fill: ${fromTheme(({ colors }) => colors.textPrimary.raw)};
  width: 100%;
  height: 2rem;
  text-align: center;
`

function getPageName(plan?: PlanLabel) {
  switch (plan) {
    case 'affordable':
      return 'Plans Affordable'
    case 'premium':
      return 'Plans Premium'
    default:
      return 'Plans'
  }
}

const Plans: React.FC<SignupPageProps> = ({
  nextPath,
  allowedLastOnboardingSteps,
}) => {
  const navigate = useNavigate()
  const showToast = useShowToast()
  const { showPhoneDobLaterInOnboarding } = useFlags()

  const targetUrl = new URL(
    `verify-device?redirect_to=${nextPath}`,
    window.location.origin
  ).toString()

  const selectedPlan = useLocation().state?.plan

  const data = useLazyLoadQuery<PlansQuery>(
    graphql`
      query PlansQuery($pageName: String!) {
        content {
          onboardingPage(where: { pageName: $pageName }) {
            heading
            mainCtaText
            body {
              raw
            }
          }
        }
        currentUser {
          ...useContinueOnboarding_user
        }
        disclosures {
          ...DocLink_disclosures
        }
        features {
          launchPromoPrice
        }
        availableSubscriptionPlans(includePromotional: false) {
          monthlyPrice {
            dollars
            cents
          }
          monthlyListPrice {
            dollars
            cents
          }
          yearlyPrice {
            dollars
            cents
          }
          yearlyListPrice {
            dollars
            cents
          }
          coverage {
            formatted
            raw
          }
          label
          cadence
          suggested
        }
      }
    `,
    { pageName: getPageName(selectedPlan) }
  )

  useContinueOnboarding(data.currentUser, allowedLastOnboardingSteps)

  const [commitPlansMutation, plansMutationInFlight] =
    useMutation<PlansMutation>(graphql`
      mutation PlansMutation(
        $subscriptionChoiceInput: SubscriptionChoiceInput!
      ) {
        saveSubscriptionChoice(input: $subscriptionChoiceInput) {
          __typename
        }
      }
    `)

  const [commitSmsMutation, smsMutationInFlight] =
    useMutation<PlansSmsMutation>(graphql`
      mutation PlansSmsMutation($targetUrl: String!) {
        sendIdentitySms(targetUrl: $targetUrl) {
          success
        }
      }
    `)

  function createPlanSelectHandler(
    selectedPlanCoverage: number,
    cadence: BillingCadence
  ) {
    return function planSelectHandler() {
      if (!plansMutationInFlight && !smsMutationInFlight) {
        commitPlansMutation({
          variables: {
            subscriptionChoiceInput: {
              coverage: selectedPlanCoverage,
              cadence,
            },
          },
          onCompleted({ saveSubscriptionChoice }) {
            if (saveSubscriptionChoice.__typename) {
              if (showPhoneDobLaterInOnboarding) {
                // if in experiment group we don't have phone yet so next exp page
                navigate('/signup/phone-dob')
              } else {
                // if not in experiment group we already have phone so send sms
                commitSmsMutation({
                  variables: {
                    targetUrl,
                  },
                  onCompleted({ sendIdentitySms }) {
                    if (sendIdentitySms.success) {
                      navigate(nextPath)
                    } else {
                      showToast({
                        severity: 'Error',
                        headline: standardToastHeadlines.genericError,
                        message: standardToastMessages.retry,
                        onClick: planSelectHandler,
                      })
                    }
                  },
                  onError(error) {
                    console.error({
                      mutation: 'PlansSmsMutation',
                      error,
                    })
                    showToast({
                      severity: 'Error',
                      headline: standardToastHeadlines.genericError,
                      message: standardToastMessages.retry,
                      onClick: planSelectHandler,
                    })
                  },
                })
              }
            } else {
              showToast({
                severity: 'Error',
                headline: standardToastHeadlines.genericError,
                message: standardToastMessages.retry,
                onClick: planSelectHandler,
              })
            }
          },
          onError(error) {
            console.error({
              mutation: 'PlansMutation',
              error,
            })
            showToast({
              severity: 'Error',
              headline: standardToastHeadlines.genericError,
              message: standardToastMessages.retry,
              onClick: planSelectHandler,
            })
          },
        })
      }
    }
  }

  const plansToRender =
    typeof selectedPlan === 'string'
      ? [
          [data.availableSubscriptionPlans.find(propEq(selectedPlan, 'label'))],
          data.availableSubscriptionPlans.filter(
            (plan) => plan.label !== selectedPlan
          ),
        ]
      : [data.availableSubscriptionPlans, []]

  const [highlightedPlans, otherPlans] = plansToRender

  return (
    <Container>
      <LayoutSignupTracked
        pageName="Plans"
        title={data.content.onboardingPage.heading}
        headerStart={<GoBack />}
        footerContent={
          <b>
            {otherPlans.length > 0 && (
              <>
                <AllPlansSubheading centered>All Plans</AllPlansSubheading>
                <StyledChevron />
              </>
            )}
          </b>
        }
        belowFooter={
          otherPlans.length > 0 && (
            <>
              {otherPlans.map((plan) => (
                <PlanCard
                  key={plan.coverage.formatted}
                  price={{
                    dollars: plan.monthlyPrice.dollars,
                    cents: plan.monthlyPrice.cents,
                  }}
                  ctaText={data.content.onboardingPage.mainCtaText}
                  benefitAmount={plan.coverage.formatted}
                  colorScheme={getPlanColorScheme(plan.label)}
                  onClick={createPlanSelectHandler(
                    plan.coverage.raw,
                    plan.cadence
                  )}
                  disabled={plansMutationInFlight || smsMutationInFlight}
                />
              ))}
            </>
          )
        }
      >
        <FlexColumn>
          <RichText content={data.content.onboardingPage.body.raw} />

          {highlightedPlans.map((plan) => (
            <PlanCard
              key={plan.coverage.formatted}
              price={{
                dollars: plan.monthlyPrice.dollars,
                cents: plan.monthlyPrice.cents,
              }}
              ctaText={data.content.onboardingPage.mainCtaText}
              benefitAmount={plan.coverage.formatted}
              colorScheme={getPlanColorScheme(plan.label)}
              onClick={createPlanSelectHandler(plan.coverage.raw, plan.cadence)}
              disabled={plansMutationInFlight || smsMutationInFlight}
            />
          ))}
          <BodyText centered>
            7-day money-back guarantee
            <br />
            <DocLink
              disclosures={data.disclosures}
              kind={
                data.features.launchPromoPrice
                  ? 'promotionalTerms'
                  : 'termsOfService'
              }
            >
              Terms and conditions apply
            </DocLink>
          </BodyText>
        </FlexColumn>
        <ProcessingOverlay
          processing={plansMutationInFlight || smsMutationInFlight}
        />
      </LayoutSignupTracked>
    </Container>
  )
}

export default Plans
