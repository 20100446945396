import {
  NodeRendererType,
  RichText as ReactRichText,
  RichTextProps,
} from '@graphcms/rich-text-react-renderer'
import { ElementNode, isText, Text } from '@graphcms/rich-text-types'
import { IntroBlock, TextLinkInline, Ul } from '@quil/ui'
import Mustache from 'mustache'

type Props = {
  content: RichTextProps['content']
  data?: Record<string, string | number>
  renderers?: NodeRendererType
}

function transformNodeWith(transform: (text: string) => string) {
  return function transformNode(node: ElementNode | Text) {
    if (isText(node)) {
      return {
        ...node,
        text: transform(node.text),
      }
    } else {
      return {
        ...node,
        children: node.children.map(transformNodeWith(transform)),
      }
    }
  }
}

export function transformRichText(
  transform: (text: string) => string,
  content: RichTextProps['content']
) {
  if ('children' in content) {
    return {
      ...content,
      children: content.children.map(transformNodeWith(transform)),
    }
  } else {
    return content.map(transformNodeWith(transform))
  }
}

// Maps some rich text elements to Quil UI components.
//
// Ideally, we keep this minimal so that the app continues to be in control of
// presentation. Opt for adding a new field to the Hygraph model rather than
// adding mappings here.
//
// If `data` prop is present, text will be processed as a handlebars template
export default function RichText(props: Props) {
  function transform(text: string) {
    return Mustache.render(text, props.data)
  }

  const content = props.data
    ? transformRichText(transform, props.content)
    : props.content

  return (
    <ReactRichText
      content={content}
      renderers={{
        p: ({ children }) => <IntroBlock>{children}</IntroBlock>,
        ul: ({ children }) => <Ul>{children}</Ul>,
        a: ({ children, href }) => (
          <TextLinkInline data-name="CMS link" href={href}>
            {children}
          </TextLinkInline>
        ),
        ...props.renderers,
      }}
    />
  )
}
