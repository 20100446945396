/**
 * @generated SignedSource<<0cf12f547508b5c30577be3523239a94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PayoutOptionsQuery$variables = {};
export type PayoutOptionsQuery$data = {
  readonly content: {
    readonly profilePage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly header: string | null;
    } | null;
  };
  readonly payoutAccount: {
    readonly id: string | null;
  } | null;
};
export type PayoutOptionsQuery = {
  response: PayoutOptionsQuery$data;
  variables: PayoutOptionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Payout Options"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PayoutAccount",
  "kind": "LinkedField",
  "name": "payoutAccount",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayoutOptionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Payout Options\"})"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PayoutOptionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Payout Options\"})"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "0e80da708c32a4be8f4de9a05bce5f17",
    "id": null,
    "metadata": {},
    "name": "PayoutOptionsQuery",
    "operationKind": "query",
    "text": "query PayoutOptionsQuery {\n  content {\n    profilePage(where: {name: \"Payout Options\"}) {\n      header\n      body {\n        raw\n      }\n      id\n    }\n  }\n  payoutAccount {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f710dd70ca139ffc8336c9b17de9a7a3";

export default node;
