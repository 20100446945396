/**
 * @generated SignedSource<<5a184a7d8988d8ba2ea2c9f7c41bdb90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsOverviewQuery$variables = {};
export type SettingsOverviewQuery$data = {
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"AddressText_user" | "EmailText_user" | "PhoneText_user" | "UserSummary_user">;
  } | null;
  readonly payoutAccount: {
    readonly id: string | null;
  } | null;
};
export type SettingsOverviewQuery = {
  response: SettingsOverviewQuery$data;
  variables: SettingsOverviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "PayoutAccount",
  "kind": "LinkedField",
  "name": "payoutAccount",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSummary_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PhoneText_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EmailText_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AddressText_user"
          }
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "membershipActivatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unverifiedEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addressLineOne",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ]
  },
  "params": {
    "cacheID": "e90c20d40fba932dea5644b6f8df1d74",
    "id": null,
    "metadata": {},
    "name": "SettingsOverviewQuery",
    "operationKind": "query",
    "text": "query SettingsOverviewQuery {\n  currentUser {\n    ...UserSummary_user\n    ...PhoneText_user\n    ...EmailText_user\n    ...AddressText_user\n    id\n  }\n  payoutAccount {\n    id\n  }\n}\n\nfragment AddressText_user on User {\n  addressLineOne\n}\n\nfragment EmailText_user on User {\n  email\n  unverifiedEmail\n}\n\nfragment PhoneText_user on User {\n  phone\n}\n\nfragment UserSummary_user on User {\n  firstName\n  lastName\n  membershipActivatedAt\n}\n"
  }
};
})();

(node as any).hash = "42bde0af976ce234e0ad55a743c8ece9";

export default node;
