import * as event from '@quil/analytics-events'
import { BodyText, ButtonPrimary } from '@quil/ui'
import { formatPhone } from '@web/common/formatPhone'
import { useEffect, useRef } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { LayoutInAppTracked } from '../../common/analytics'
import * as analytics from '../../common/analytics/analytics-adapter'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import { ZendeskChatButton } from '../../common/components/ZendeskChatButton'
import createSupportEmailUri from '../../common/createSupportEmailUri'
import { isTouchDevice } from '../../common/isTouchDevice'
import { UpdatePhoneQuery } from './__generated__/UpdatePhoneQuery.graphql'

const query = graphql`
  query UpdatePhoneQuery {
    content {
      profilePage(where: { name: "Update Phone" }) {
        header
        body {
          raw
        }
        mainCtaText
      }
    }
    currentUser {
      phone
    }
  }
`

export default function UpdatePhone() {
  const pageName = 'Update Phone'

  const data = useLazyLoadQuery<UpdatePhoneQuery>(query, {})

  // autofocus on phone on mount (on non-touch devices)
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!isTouchDevice()) {
      inputRef.current?.focus()
    }
  }, [])

  function handleSubmit() {
    analytics.track(event.supportLinkClicked('Phone Number Change'))
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title={data.content.profilePage.header}
      headerStart={<GoBack />}
      headerEnd={<ZendeskChatButton />}
    >
      <RichText
        content={data.content.profilePage.body.raw}
        data={{ phone: formatPhone(data.currentUser.phone) }}
        renderers={{ p: ({ children }) => <BodyText>{children}</BodyText> }}
      />
      <ButtonPrimary
        as={'a'}
        href={createSupportEmailUri('Update phone number')}
        onClick={handleSubmit}
        data-name="Update phone number"
        data-page-name={pageName}
      >
        {data.content.profilePage.mainCtaText}
      </ButtonPrimary>
    </LayoutInAppTracked>
  )
}
