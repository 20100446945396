import React, { useRef } from 'react'
import styled from 'styled-components'
import { fromTheme } from '../../theme'
import ErrorMessageContainer from '../ErrorMessageContainer'
import ErrorMessageText from '../ErrorMessageText'
import InputCheckbox from '../InputCheckbox'
import { UploadedFile } from './UploadedFile'

const acceptType = {
  // any image, pdf, or file that "smells like an MS Word document"
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#limiting_accepted_file_types
  document:
    'image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

type FileKind = keyof typeof acceptType

type InputFileProps = {
  label: string
  value: File[] | null
  onChange(value: File[]): void
  onDeleteFile(file: File): void
  kind?: FileKind
  multiple?: boolean
  error?: string | null
  testId?: string
}

type WithSizeValidationProps = InputFileProps & {
  maxSize: number
  onInvalidSize(file: File): void
}

type Props = InputFileProps | WithSizeValidationProps

const Container = styled.div`
  margin-bottom: 2.4rem;
`

const Input = styled.input`
  display: none;
`

const DisplayLabel = styled.span`
  color: ${fromTheme((theme) => theme.colors.foregroundPrimary.raw)};
  text-decoration: underline;
`

const StyledErrorMessageText = styled(ErrorMessageText)`
  padding: 0.4rem 0;
`

function hasSizeValidation(props: Props): props is WithSizeValidationProps {
  return 'maxSize' in props
}

function validateFileSize(size: number, onInvalid: (file: File) => void) {
  return function validate(file: File) {
    if (file.size > size) {
      onInvalid(file)
    }
  }
}

export function InputFile(props: Props) {
  const inputRef = useRef<HTMLInputElement>(null)
  const checked = Boolean(props.value?.length)
  const id = `fileInput${props.label}`

  function openFileBrowser() {
    inputRef.current?.click()
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files)
      props.onChange(fileArray)

      // clear the state of the input. Since we are delegating all
      // of the state management to the caller, we want a fresh input
      if (inputRef.current) {
        inputRef.current.value = ''
      }

      if (hasSizeValidation(props)) {
        fileArray.forEach(validateFileSize(props.maxSize, props.onInvalidSize))
      }
    }
  }

  return (
    <div>
      <Container>
        <InputCheckbox
          label={<DisplayLabel>{props.label}</DisplayLabel>}
          name={props.label}
          onChange={openFileBrowser}
          aria-hidden={true}
          checked={checked}
        />
        <Input
          ref={inputRef}
          id={id}
          type="file"
          onChange={handleChange}
          multiple={props.multiple}
          accept={acceptType[props.kind ?? 'document']}
          max={5e6}
        />

        <ErrorMessageContainer
          visible={!!props.error && props.error.length > 0}
        >
          <StyledErrorMessageText data-testid={`${props.testId}_error_message`}>
            {props.error}
          </StyledErrorMessageText>
        </ErrorMessageContainer>
      </Container>

      {props.value?.map((file) => (
        <UploadedFile
          key={file.name}
          onDeleteClick={props.onDeleteFile}
          file={file}
        />
      ))}
    </div>
  )
}
