import React from 'react'
import { ReactComponent as MailIcon } from '../../icons/mail.svg'
import InputAdornment from '../InputAdornment'
import InputText, { Props as InputTextProps } from '../InputText'

type Props = Pick<
  InputTextProps,
  'value' | 'onChange' | 'error' | 'data-testid' | 'label'
>

const InputEmail: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  ref
) => {
  return (
    <InputText
      {...props}
      ref={ref}
      startAdornment={
        <InputAdornment>
          <MailIcon />
        </InputAdornment>
      }
      label={props.label ?? 'Email'}
      name="email"
      placeholder="email@email.com"
      inputMode="email"
      autoComplete="email"
      autoCapitalize="none"
    />
  )
}

export default React.forwardRef(InputEmail)
