/**
 * @generated SignedSource<<41925460d8ccd348f13304c86c1cd859>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LayoffClaimInformationRequestStatus = "Fulfilled" | "Open" | "%future added value";
export type LayoffClaimInformationRequestType = "FileUpload" | "Note" | "%future added value";
export type FulfillInformationRequestInput = {
  fileUploadId?: string | null;
  layoffclaimInformationRequestId: string;
  note?: string | null;
};
export type SubmitAdditionalInformationFulfillMutation$variables = {
  input: ReadonlyArray<FulfillInformationRequestInput>;
};
export type SubmitAdditionalInformationFulfillMutation$data = {
  readonly fulfillLayoffClaimInformationRequests: {
    readonly __typename: "FulfillLayoffClaimInformationRequestsSuccess";
    readonly informationRequests: ReadonlyArray<{
      readonly detail: string | null;
      readonly id: string | null;
      readonly requestedAt: {
        readonly formatted: string;
      };
      readonly status: LayoffClaimInformationRequestStatus;
      readonly title: string;
      readonly type: LayoffClaimInformationRequestType;
    }>;
  } | {
    readonly __typename: "ValidationErrors";
    readonly messages: ReadonlyArray<{
      readonly field: string;
      readonly help: string;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type SubmitAdditionalInformationFulfillMutation = {
  response: SubmitAdditionalInformationFulfillMutation$data;
  variables: SubmitAdditionalInformationFulfillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "fulfillLayoffClaimInformationRequests",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LayoffClaimInformationRequest",
            "kind": "LinkedField",
            "name": "informationRequests",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Instant",
                "kind": "LinkedField",
                "name": "requestedAt",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "formatted",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "FulfillLayoffClaimInformationRequestsSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidationError",
            "kind": "LinkedField",
            "name": "messages",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "field",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "help",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ValidationErrors",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitAdditionalInformationFulfillMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmitAdditionalInformationFulfillMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1e1d8c8ed62cbeefd2e86aad012b56cd",
    "id": null,
    "metadata": {},
    "name": "SubmitAdditionalInformationFulfillMutation",
    "operationKind": "mutation",
    "text": "mutation SubmitAdditionalInformationFulfillMutation(\n  $input: [FulfillInformationRequestInput!]!\n) {\n  fulfillLayoffClaimInformationRequests(input: $input) {\n    __typename\n    ... on FulfillLayoffClaimInformationRequestsSuccess {\n      informationRequests {\n        id\n        status\n        type\n        title\n        detail\n        requestedAt {\n          formatted\n        }\n      }\n    }\n    ... on ValidationErrors {\n      messages {\n        field\n        help\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3bbbc80a0f5a3eb694c6899ea28d0eac";

export default node;
