/**
 * @generated SignedSource<<4142cc5f4d6cc1f86c11c66b45ad7cd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UpdateAddressQuery$variables = {};
export type UpdateAddressQuery$data = {
  readonly content: {
    readonly profilePage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly header: string | null;
      readonly mainCtaText: string | null;
    } | null;
  };
  readonly currentUser: {
    readonly addressLineOne: string | null;
    readonly addressLineTwo: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly zip: string | null;
  } | null;
};
export type UpdateAddressQuery = {
  response: UpdateAddressQuery$data;
  variables: UpdateAddressQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressLineOne",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressLineTwo",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zip",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Update Address"
    }
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAddressQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Update Address\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UpdateAddressQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Update Address\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de0d81dec6c637a05b849a92d3e6e8f9",
    "id": null,
    "metadata": {},
    "name": "UpdateAddressQuery",
    "operationKind": "query",
    "text": "query UpdateAddressQuery {\n  currentUser {\n    addressLineOne\n    addressLineTwo\n    city\n    state\n    zip\n    id\n  }\n  content {\n    profilePage(where: {name: \"Update Address\"}) {\n      header\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f68a1df3d678c01da3fbe675a292fc10";

export default node;
