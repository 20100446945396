import React from 'react'
import styled, { css } from 'styled-components'
import { NewClaimSequence, basePath } from './New/NewClaimSequence'

export const styledIcon = (Component: React.ComponentType) => styled(Component)`
  margin: 0 0 2.4em;
`

export const Centered = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
`

export const Center = styled.div`
  ${Centered}
`

type ResumableClaim = {
  minimumWeeklyHours: boolean | null
  changedJobsRecently: boolean | null
  terminatedWithoutCause: boolean | null
  receivingStateBenefits: boolean | null
  terminationReason: string | null
  employerName: string | null
  occupation: string | null
  employmentStartDate: string | null
  employmentEndDate: string | null
  employerEmail: string | null
  employerPhone: string | null
  payoutAccount: { accountNumberMask: string } | null
}

export function resumeClaimPath(claim: ResumableClaim) {
  return `${basePath}/${NewClaimSequence[resumeClaimStep(claim)].path}`
}

export function resumeClaimStep(
  claim: ResumableClaim
): keyof typeof NewClaimSequence {
  if (
    claim.minimumWeeklyHours === null ||
    claim.changedJobsRecently === null ||
    claim.terminatedWithoutCause === null ||
    claim.receivingStateBenefits === null
  ) {
    return 'EmploymentType'
  }
  if (
    !claim.terminationReason ||
    !claim.employerName ||
    !claim.occupation ||
    !claim.employmentStartDate ||
    !claim.employmentEndDate ||
    (!claim.employerEmail && !claim.employerPhone)
  ) {
    return 'EmploymentInfo'
  }
  if (claim.payoutAccount?.accountNumberMask) {
    return 'PayoutAccountExisting'
  }
  return 'Documents'
}
