export function formatPhone(phone: string) {
  const match = phone
    ?.replace(/^\+1/, '')
    ?.replace(/\D/g, '')
    .match(/^(\d{3})(\d{3})(\d+)$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return phone
}
