import styled from 'styled-components'
import { brandFocusRing } from '../../common/styles/focus-ring'
import { fromTheme } from '../../theme'

type Props = {
  'data-name': string
}

const TextLinkInline = styled.a<Props>`
  position: relative;
  display: inline-block;

  ${brandFocusRing}

  &,
  &:visited {
    color: ${fromTheme((theme) => theme.colors.textLink.raw)};
  }
`

export default TextLinkInline
