import { Headline } from '@quil/ui'
import React, { useEffect, useState } from 'react'
import { graphql, useMutation } from 'react-relay'
import { useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { logout } from '../../common/logout'

export const Scramble: React.FC = () => {
  const { phone } = useParams()
  const [message, setMessage] = useState('Scrambling...')
  const [commit] = useMutation(graphql`
    mutation ScrambleMutation($phone: String) {
      sandboxScramblePhone(phone: $phone)
      logout
    }
  `)
  // Debouncing prevents duplicate side effects caused by multiple mounts (e.g. in the case of React.StrictMode in development environment)
  const commitMutation = useDebouncedCallback(commit, 1000, {
    leading: true,
    trailing: false,
  })
  useEffect(() => {
    commitMutation({
      variables: {
        phone,
      },
      onCompleted() {
        logout()
      },
      onError(error) {
        console.error(error)
        setMessage('Error, check console')
      },
    })
  }, [setMessage, logout, phone, commitMutation, console.error])
  return <Headline>{message}</Headline>
}
