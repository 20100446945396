/**
 * @generated SignedSource<<e920138c7e5b79c4ec2fbe512dadb9c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OnboardingStep = "ActivateMembership" | "CreateUser" | "SubmitEmploymentInfo" | "UpdateEmploymentInfo" | "VerifyDevice" | "VerifyPersonalInfo" | "%future added value";
export type UserStatus = "Active" | "Closed" | "Ineligible" | "Pending" | "SecurityLocked" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccessControl_user$data = {
  readonly lastCompletedOnboardingStep: OnboardingStep;
  readonly status: UserStatus;
  readonly " $fragmentType": "AccessControl_user";
};
export type AccessControl_user$key = {
  readonly " $data"?: AccessControl_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessControl_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessControl_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastCompletedOnboardingStep",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "782a1b6e68af0d8799e4ac1eea0cd84a";

export default node;
