/**
 * @generated SignedSource<<de8853425d9107106b9e6414db737bf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateEmploymentInfoInput = {
  employerName?: string | null;
  jobTitle?: string | null;
  minimumWeeklyHours?: boolean | null;
  seasonal?: boolean | null;
  w2?: boolean | null;
};
export type useUpdateEmploymentInfoMutation$variables = {
  input?: UpdateEmploymentInfoInput | null;
};
export type useUpdateEmploymentInfoMutation$data = {
  readonly updateEmploymentInfo: {
    readonly __typename: "User";
    readonly employmentInfo: {
      readonly employerName: string | null;
      readonly jobTitle: string | null;
      readonly minimumWeeklyHours: boolean | null;
      readonly seasonal: boolean | null;
      readonly w2: boolean | null;
    } | null;
    readonly id: string | null;
  } | null;
};
export type useUpdateEmploymentInfoMutation = {
  response: useUpdateEmploymentInfoMutation$data;
  variables: useUpdateEmploymentInfoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "updateEmploymentInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentInfo",
        "kind": "LinkedField",
        "name": "employmentInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employerName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minimumWeeklyHours",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "seasonal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "w2",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateEmploymentInfoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateEmploymentInfoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d9f5c4512e930c8a2272dac03a73b1e7",
    "id": null,
    "metadata": {},
    "name": "useUpdateEmploymentInfoMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateEmploymentInfoMutation(\n  $input: UpdateEmploymentInfoInput\n) {\n  updateEmploymentInfo(input: $input) {\n    __typename\n    id\n    employmentInfo {\n      employerName\n      jobTitle\n      minimumWeeklyHours\n      seasonal\n      w2\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6c38721494d1652cf6c58922288d77b";

export default node;
