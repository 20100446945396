/**
 * @generated SignedSource<<89ae7bf0aca2c2dab5a81c95df282fff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroImage_query$data = {
  readonly content: {
    readonly heroImages: ReadonlyArray<{
      readonly altText: string | null;
      readonly color: {
        readonly hex: any;
      } | null;
      readonly endDateTime: any | null;
      readonly image: {
        readonly mimeType: string | null;
        readonly url: string;
      };
      readonly startDateTime: any | null;
    }>;
  };
  readonly " $fragmentType": "HeroImage_query";
};
export type HeroImage_query$key = {
  readonly " $data"?: HeroImage_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroImage_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroImage_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Cms_HeroImage",
          "kind": "LinkedField",
          "name": "heroImages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Cms_Asset",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mimeType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Cms_Color",
              "kind": "LinkedField",
              "name": "color",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hex",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "altText",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDateTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDateTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ffe0e2b8b683aa36f2a30fd627659e04";

export default node;
