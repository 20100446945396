import { formatPhone } from '@web/common/formatPhone'
import React from 'react'
import { graphql, useFragment } from 'react-relay'
import { PhoneText_user$key } from './__generated__/PhoneText_user.graphql'

type Props = {
  user: PhoneText_user$key
}

export const PhoneText: React.FC<Props> = (props) => {
  const data = useFragment(
    graphql`
      fragment PhoneText_user on User {
        phone
      }
    `,
    props.user
  )

  return <div>{formatPhone(data.phone)}</div>
}
