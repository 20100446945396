import {
  ButtonPrimary,
  InputText,
  ProcessingOverlay,
  standardToastHeadlines,
  standardToastMessages,
  useShowToast,
} from '@quil/ui'
import { LayoutInAppTracked } from '@web/common/analytics'
import Form from '@web/common/components/Form'
import GoBack from '@web/common/components/GoBack'
import RichText from '@web/common/components/RichText'
import { useNavigate } from '@web/common/react-router-wrappers'
import { useFormReducer } from '@web/common/useFormReducer'
import { graphql, useMutation } from 'react-relay'
import { NewClaimPageProps } from '../NewClaimPageProps'
import NewClaimProgressTracker from '../NewClaimProgressTracker'
import useNewClaimFlow from '../useNewClaimFlow'
import { PayoutAccountManualAddAccountMutation } from './__generated__/PayoutAccountManualAddAccountMutation.graphql'

const initialState = {
  values: {
    routingNumber: '',
    accountNumber: '',
    accountNumberConfirmation: '',
  },
  errors: {
    routingNumber: '',
    accountNumber: '',
    accountNumberConfirmation: '',
  },
}

const PayoutAccountManual = (props: NewClaimPageProps) => {
  const showToast = useShowToast()
  const navigate = useNavigate()

  const [{ values, errors }, actions] = useFormReducer(initialState)

  function handleSubmit() {
    if (values.accountNumber !== values.accountNumberConfirmation) {
      actions.receiveValidationErrors([
        {
          field: 'accountNumberConfirmation',
          help: 'Account numbers must match',
        },
      ])
      return
    }

    createPayoutAccount({
      variables: {
        input: {
          claimId: data.pendingLayoffClaim.id,
          accountNumber: values.accountNumber,
          routingNumber: values.routingNumber,
        },
      },
      onCompleted({ addManualAccount }) {
        switch (addManualAccount.__typename) {
          case 'PayoutAccount':
            navigate(props.nextPath)
            break
          case 'ValidationErrors':
            actions.receiveValidationErrors(addManualAccount.messages)
            break
          default:
            showToast({
              severity: 'Error',
              headline: 'Something went wrong',
              message: 'Please contact support',
            })
        }
      },
      updater(store, payload) {
        if (payload.addManualAccount.__typename === 'PayoutAccount') {
          const payoutAccount = store.get(payload.addManualAccount.id)
          store
            .getRoot()
            .getLinkedRecord('pendingLayoffClaim')
            .setLinkedRecord(payoutAccount, 'payoutAccount')
        }
      },
      onError() {
        showToast({
          severity: 'Error',
          headline: standardToastHeadlines.genericError,
          message: standardToastMessages.retry,
          onClick() {
            handleSubmit()
          },
        })
      },
    })
  }

  const [data] = useNewClaimFlow('Payout Account Manual')

  const [createPayoutAccount, createPayoutAccountInFlight] =
    useMutation<PayoutAccountManualAddAccountMutation>(graphql`
      mutation PayoutAccountManualAddAccountMutation(
        $input: AddManualAccountInput!
      ) {
        addManualAccount(input: $input) {
          __typename
          ... on PayoutAccount {
            id
            accountNumberMask
            bankName
          }
          ... on ValidationErrors {
            messages {
              field
              help
            }
          }
        }
      }
    `)

  function createHandler(name: string) {
    return function (value: string | boolean) {
      actions.updateFields({ [name]: value })
    }
  }

  return (
    <LayoutInAppTracked
      title={data.content.newClaimPage.heading}
      pageName="New Claim: Payout Account Manual"
      headerStart={<GoBack to={props.previousPath} />}
      headerEnd={<NewClaimProgressTracker step={props.step} />}
      footerContent={
        <ButtonPrimary
          onClick={handleSubmit}
          disabled={createPayoutAccountInFlight}
          data-name="Link Manual Payout Account"
        >
          {data.content.newClaimPage.mainCtaText}
        </ButtonPrimary>
      }
    >
      <RichText content={data.content.newClaimPage.body.raw} />
      <Form onSubmit={handleSubmit}>
        <InputText
          error={errors.routingNumber}
          label="Routing Number"
          value={values.routingNumber}
          onChange={createHandler('routingNumber')}
        />
        <InputText
          error={errors.accountNumber}
          label="Account Number"
          value={values.accountNumber}
          onChange={createHandler('accountNumber')}
        />
        <InputText
          error={errors.accountNumberConfirmation}
          label="Confirm Account Number"
          value={values.accountNumberConfirmation}
          onChange={createHandler('accountNumberConfirmation')}
        />
      </Form>
      <ProcessingOverlay processing={createPayoutAccountInFlight} />
    </LayoutInAppTracked>
  )
}

export default PayoutAccountManual
