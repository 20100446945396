import React, { ChangeEvent } from 'react'
import InputAdornment from '../InputAdornment'
import InputText, { Props as InputTextProps } from '../InputText'

type Props = Pick<
  InputTextProps,
  'value' | 'onChange' | 'error' | 'data-testid'
>

const InputMoney: React.FC<Props> = (props) => {
  function setNumericScale(value: string) {
    return (
      value
        .replace(/[^\d.]/g, '')
        // remove all but the last decimal point
        .replace(/(\.)(.*)(\.)/g, '$2$3')
        // limit to 2 decimal places
        .replace(/(\.\d{2})(.*)/, '$1')
    )
  }
  function handleChange(value: string, event: ChangeEvent<HTMLInputElement>) {
    props.onChange(setNumericScale(value), event)
  }

  return (
    <InputText
      {...props}
      onChange={handleChange}
      startAdornment={<InputAdornment>$</InputAdornment>}
      label="Amount"
      name="amount"
      placeholder="0.00"
      type="tel"
      autoComplete="off"
      inputMode="decimal"
    />
  )
}

export default InputMoney
