/**
 * @generated SignedSource<<e9ca81a6d288fd448076dd5f67397873>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SeasonalQuery$variables = {};
export type SeasonalQuery$data = {
  readonly content: {
    readonly onboardingPage: {
      readonly " $fragmentSpreads": FragmentRefs<"useEmploymentQuestionCopy_onboardingPage">;
    } | null;
  };
};
export type SeasonalQuery = {
  response: SeasonalQuery$data;
  variables: SeasonalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "pageName": "Employment Seasonal"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SeasonalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useEmploymentQuestionCopy_onboardingPage"
              }
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Employment Seasonal\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SeasonalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_OnboardingPage",
            "kind": "LinkedField",
            "name": "onboardingPage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "heading",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Cms_RichText",
                "kind": "LinkedField",
                "name": "body",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "raw",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subheading",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mainCtaText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondaryCtaText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": "onboardingPage(where:{\"pageName\":\"Employment Seasonal\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7c840a1e9c99969b646a4c307e8271a",
    "id": null,
    "metadata": {},
    "name": "SeasonalQuery",
    "operationKind": "query",
    "text": "query SeasonalQuery {\n  content {\n    onboardingPage(where: {pageName: \"Employment Seasonal\"}) {\n      ...useEmploymentQuestionCopy_onboardingPage\n      id\n    }\n  }\n}\n\nfragment useEmploymentQuestionCopy_onboardingPage on Cms_OnboardingPage {\n  heading\n  body {\n    raw\n  }\n  subheading\n  mainCtaText\n  secondaryCtaText\n}\n"
  }
};
})();

(node as any).hash = "f966218926839f194231964e15bfa93f";

export default node;
