import { createGlobalStyle } from 'styled-components'
import { fromTheme } from '../theme'

const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    color: ${fromTheme((theme) => theme.colors.textPrimary.raw)};
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: ${fromTheme((theme) => theme.colors.background.raw)};
  }

  html {
    font-size: 62.5%;
  }

  #root {
    height: 100%;
    isolation: isolate;
  }

  ::-ms-reveal {
    display: none;
  }

  code {
    font-family: 'Monaco';
    background: lightgray;
    padding: 0 0.4rem;
  }

  ::selection {
    background-color: ${fromTheme(
      (theme) => theme.colors.foregroundHighlight.raw
    )};
    color: ${fromTheme((theme) => theme.colors.palette.white.main.raw)};
  }
`

function GlobalStylesWithFonts() {
  return (
    <>
      <link
        href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;600;700&family=Unbounded:wght@300;400;600;700&display=swap"
        rel="stylesheet"
      />
      <GlobalStyles />
    </>
  )
}

export default GlobalStylesWithFonts
