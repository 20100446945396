import { Navigate, Route, Routes } from 'react-router-dom'
import { Scramble } from './Scramble'

function Test() {
  if (process.env.REACT_APP_ENV === 'production') {
    return <Navigate to="/" replace />
  }

  return (
    <Routes>
      <Route path="scramble/:phone" element={<Scramble />} />
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  )
}

export default Test
