import { ButtonPrimary } from '@quil/ui'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { LayoutInAppTracked } from '../../common/analytics'
import GoBack from '../../common/components/GoBack'
import RichText from '../../common/components/RichText'
import { useNavigate } from '../../common/react-router-wrappers'
import { LayoffInsuranceQuery } from './__generated__/LayoffInsuranceQuery.graphql'

export default function LayoffInsurance() {
  const pageName = 'Layoff Insurance'
  const navigate = useNavigate()
  const data = useLazyLoadQuery<LayoffInsuranceQuery>(
    graphql`
      query LayoffInsuranceQuery {
        content {
          benefitPage(where: { name: "Layoff Insurance" }) {
            header
            body {
              raw
            }
            mainCtaText
          }
        }
      }
    `,
    {}
  )

  function handleClick() {
    navigate('/home/claims/new')
  }

  return (
    <LayoutInAppTracked
      pageName={pageName}
      title={data.content.benefitPage.header}
      headerStart={<GoBack />}
    >
      <RichText content={data.content.benefitPage.body.raw} />
      <ButtonPrimary
        data-page-name={pageName}
        data-name="Primary CTA"
        onClick={handleClick}
      >
        {data.content.benefitPage.mainCtaText}
      </ButtonPrimary>
    </LayoutInAppTracked>
  )
}
