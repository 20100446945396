import React from 'react'
import styled from 'styled-components'
import { brandFocusRing } from '../../common/styles/focus-ring'
import { ChevronLeft } from '../../icons'
import { fromTheme, useQuilTheme } from '../../theme'

type Props = {
  onClick: (e: React.MouseEvent) => void
  disabled?: boolean
}

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin: 0;
  padding: 0;
  width: 2rem;
  color: ${fromTheme((theme) => theme.colors.textLink.raw)};

  ${brandFocusRing}

  svg {
    width: 2rem;
  }
`

const ButtonBack: React.FC<Props> = (props) => {
  const { colors } = useQuilTheme()
  return (
    <Button aria-label="back" onClick={props.onClick} disabled={props.disabled}>
      <ChevronLeft fill={colors.textLink.raw} />
    </Button>
  )
}

export default ButtonBack
