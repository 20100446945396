/**
 * @generated SignedSource<<7461526bbc324e951f7bf139e462afff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CloseMembershipQuery$variables = {};
export type CloseMembershipQuery$data = {
  readonly content: {
    readonly confirmationModal: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
      readonly secondaryCtaText: string | null;
    } | null;
    readonly logOutModal: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
    readonly profilePage: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly header: string | null;
      readonly mainCtaText: string | null;
    } | null;
    readonly setToCloseModal: {
      readonly body: {
        readonly raw: any;
      } | null;
      readonly heading: string | null;
      readonly mainCtaText: string | null;
    } | null;
  };
};
export type CloseMembershipQuery = {
  response: CloseMembershipQuery$data;
  variables: CloseMembershipQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Close Membership"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Cms_RichText",
  "kind": "LinkedField",
  "name": "body",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "raw",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainCtaText",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Close Membership Confirmation"
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryCtaText",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Account Closed"
    }
  }
],
v8 = [
  (v5/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v9 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "name": "Account Set To Close"
    }
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v5/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CloseMembershipQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Close Membership\"})"
          },
          {
            "alias": "confirmationModal",
            "args": (v4/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": "modal(where:{\"name\":\"Close Membership Confirmation\"})"
          },
          {
            "alias": "logOutModal",
            "args": (v7/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Account Closed\"})"
          },
          {
            "alias": "setToCloseModal",
            "args": (v9/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Account Set To Close\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CloseMembershipQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Cms_ProfilePage",
            "kind": "LinkedField",
            "name": "profilePage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": "profilePage(where:{\"name\":\"Close Membership\"})"
          },
          {
            "alias": "confirmationModal",
            "args": (v4/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": "modal(where:{\"name\":\"Close Membership Confirmation\"})"
          },
          {
            "alias": "logOutModal",
            "args": (v7/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Account Closed\"})"
          },
          {
            "alias": "setToCloseModal",
            "args": (v9/*: any*/),
            "concreteType": "Cms_Modal",
            "kind": "LinkedField",
            "name": "modal",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": "modal(where:{\"name\":\"Account Set To Close\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d37b80b44148fc5466b8373cb4901a8f",
    "id": null,
    "metadata": {},
    "name": "CloseMembershipQuery",
    "operationKind": "query",
    "text": "query CloseMembershipQuery {\n  content {\n    profilePage(where: {name: \"Close Membership\"}) {\n      header\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n    confirmationModal: modal(where: {name: \"Close Membership Confirmation\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      secondaryCtaText\n      id\n    }\n    logOutModal: modal(where: {name: \"Account Closed\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n    setToCloseModal: modal(where: {name: \"Account Set To Close\"}) {\n      heading\n      body {\n        raw\n      }\n      mainCtaText\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41cabf2eca9413f0d8107192e29c7877";

export default node;
